import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import { Grid, Paper, Button, TextField, Select } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import * as authSelectors from '../../store/auth/reducer';
import * as authActions from '../../store/auth/actions';
import * as promptActions from '../../store/prompt/actions';
import _ from 'lodash';
import { titleCase } from "title-case";
class Register extends Component {

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { firstName: null, lastName: null, password: null, role: null, type: null, allLocations: [] };
    this.isFormComplete = false;
    this.key = null;
  }

  async componentDidMount() {
    const result = await this.props.dispatch(authActions.getAvailableLocations());
    const { allLocations } = result.data
    this.setState({ allLocations: allLocations });
  }

  updateField(event, target) {
    this.setState({ [target]: event.target.value });  
  }

  componentDidUpdate() {
    this.isFormComplete = Object.keys(this.state).every(key => {
      this.key = key;
      return !!this.state[key];
    });
  }

  getOptions(data) {
    const { Name } = data;
    return (
      <option value={Name === 'Default' ? 'command_center' : (Name.split(' ').join('_')).toLowerCase()}>{Name}</option>
    )
  }

  async registerUser() {
    if (!this.isFormComplete) {
      this.props.dispatch(promptActions.showSnackBar({ 
        type: 'error', 
        message: `${titleCase(this.key ? `${this.key} is required.` : 'All fields are required.')}`
      }));
    } else {
      const userName =  `${this.state.firstName.trim()}.${this.state.lastName.trim()}`.toLowerCase();
      const result = await this.props.dispatch(authActions.registerUser(userName, this.state));
      const { success, message } = result.data;
      if (success) {
        this.props.dispatch(promptActions.showSnackBar({ type: 'success', message: message }));
        this.props.history.push('/adminPortal');
      } else {
        this.props.dispatch(promptActions.showSnackBar({ type: 'error', message: `Error Registering User.` }));
      }
    }
  }

  render() {
    return (
      <Fragment>
        <div className="home-div">
          <h2> Register A User </h2>
          <Fragment>
            
            <TextField
              label="First Name"
              placeholder="Enter First Name"
              value={this.state.firstName || ""}
              margin="dense"
              variant="outlined"
              inputProps={{ className: 'data-hj-allow' }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {this.updateField(event, 'firstName')}}
              style={{ width: '60%', marginTop: 15, marginBottom: 10 }}
            />
            <TextField
              label="Last Name"
              placeholder="Enter Last Name"
              value={this.state.lastName || ""}
              margin="dense"
              variant="outlined"
              inputProps={{ className: 'data-hj-allow' }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {this.updateField(event, 'lastName')}}
              style={{ width: '60%', marginTop: 2, marginBottom: 10 }}
            />
            <TextField
              label="Choose a password"
              placeholder="Choose a password"
              value={this.state.password || ""} 
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {this.updateField(event, 'password')}}
              style={{ width: '60%', marginTop: 2, marginBottom: 10 }}
            />
            <Select
              native
              margin="dense"
              value={this.state.role || ""}
              onChange={(event) => {this.updateField(event, 'role')}}
              style={{ width: '60%', marginTop: 2, marginBottom: 10 }}
              input={
                <OutlinedInput name="User Role" />
              }
            > 
              <option> Please select user role </option>
              <option value="User">User</option>
              <option value="Admin">Admin</option>
            </Select>
            <Select
              native
              margin="dense"
              value={this.state.type || ""}
              onChange={(event) => {this.updateField(event, 'type')}}
              style={{ width: '60%', marginTop: 2, marginBottom: 10 }}
              input={
                <OutlinedInput name="User Type" />
              }
            >
              <option> Please select user location </option>
              {_.map(this.state.allLocations, this.getOptions)}
            </Select>
            <div style={{ marginTop: 5 }}>
              <Button                  
                style={{ marginTop: 40 }} 
                variant="contained" 
                color="primary"
                onClick={this.registerUser}
              >
                Submit
              </Button>
            </div>
          </Fragment>        
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    allUsers: authSelectors.pullModular(state, 'allUsers'),
  };
}

export default withRouter(connect(mapStateToProps)(Register));

