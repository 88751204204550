import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptSelectors from '../../store/prompt/reducer';
import * as queueSelectors from '../../store/queue/reducer';
import * as authSelectors from '../../store/auth/reducer';
import * as queueActions from '../../store/queue/actions';
import * as promptActions from '../../store/prompt/actions';
import Paper from 'material-ui/Paper';
import _ from 'lodash';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import CallEnd from 'material-ui/svg-icons/communication/call-end';
import DirectionText from '../../components/DirectionText';
import ScriptText from '../../components/ScriptText';
import { retrieveLogger } from '../../lib/logger';
let logger = retrieveLogger();

class ConfirmationScreen extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.endCall = _.debounce(this.endCall, 7000, {
      'leading': true,
      'trailing': false,
    });

  }

  async componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (this.props.pilPaymentCode) {
      await this.props.dispatch(promptActions.modularSendField(false, 'pilCheckTransactionStatus'));
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  async endCall(event) {
    let dialToneToggleCall = this.props.from.dialToneToggleCall;
    let dialToneExit = this.props.from.dialToneExit;
    let locationId = this.props.from.locationId;
    if (this.props.from.parcs == 'flash') {
      this.props.dispatch(promptActions.flashCloseCall());
    }
    if (this.props.pilPaymentCode) {
      const { username, ongoingCallId, pilPaymentCode } = this.props;
      this.props.dispatch(promptActions.pilSaveTransactionData({ username, ongoingCallId, generatedCode: pilPaymentCode }));
    }
    await this.props.dispatch(promptActions.modularSendField(true, 'confirmationScreenLoading'));
    await this.sendTransactionToDB();
    await this.props.dispatch(queueActions.endConn(this.props.username, locationId, dialToneToggleCall, dialToneExit));
    await this.props.dispatch(promptActions.modularSendField(false, 'confirmationScreenLoading'));
    await this.props.dispatch(queueActions.resetToIdle(this.props.username));
  }

  async sendTransactionToDB() {
    let rec = {
      answeredTime: this.props.answeredTime,
      from: this.props.from,
      issue: this.props.issue,
      selectedParker: this.props.selectedParker,
      username: this.props.username,
      ongoingAnalystCallSid: this.props.ongoingAnalystCallSid,
      vendedGate: this.props.vendedGate,
      thirdPartyVendorName: this.props.thirdPartyVendorName,
      thirdPartyReservationNumber: this.props.thirdPartyReservationNumber,
      attendantName: this.props.attendantName,
      selectedParkerName: this.props.parkerNotFound ? null : this.props.selectedParkerName,
      selectedParkerRoomNumber: this.props.selectedParkerRoomNumber,
      selectedParkerCardNumber: this.props.selectedParkerCardNumber,
      serviceWorkerType: this.props.serviceWorkerType,
      companyName: this.props.companyName,
      reportingTo: this.props.reportingTo,
      visitPurpose: this.props.visitPurpose,
      visitingApartmentNumber: this.props.visitingApartmentNumber,
      visitingResidentName: this.props.visitingResidentName,
      vehicleMake: this.props.vehicleMake,
      vehicleModel: this.props.vehicleModel,
      licensePlateNumber: this.props.licensePlateNumber,
      timezone: this.props.from.timezone,
      validationNumber: this.props.validationNumber,
      validationSource: this.props.validationSource,
      validationDateTime: this.props.validationDateTime,
      // Only send the date and time added if there is a date (to avoid moment.js 1970 issue)
      parkerEntranceTime: this.props.parkerEntranceDate && (this.props.parkerEntranceDate + this.props.parkerEntranceTime).replace("T", " "),
      // Only send the date and time added if there is a date (to avoid moment.js 1970 issue)
      parkerExitTime: this.props.parkerExitDate && (this.props.parkerExitDate + this.props.parkerExitTime).replace("T", " "),
      apbLockedOut: this.props.apbLockedOut,
      equipmentMalfunctionDecision: this.props.equipmentMalfunctionDecision,
      terminationLockedOut: this.props.terminationLockedOut,
      wrongFacilityLockedOut: this.props.wrongFacilityLockedOut,
      guestVerified: this.props.guestVerified,
      invalidAccessLocation: this.props.invalidAccessLocation,
      invalidAccessTime: this.props.invalidAccessTime,
      managerActionRequest: this.props.managerActionRequest,
      contactManagersTotalTime: this.props.contactManagersTotalTime,
      onOutsideCall: this.props.onOutsideCall,
      ticketNumber: this.props.ticketNumber ? (this.props.ticketNumber).toString().replace(/\D/g, '') : null,
      monthlyCardIssue: this.props.monthlyCardIssue
    };

    // Pre-first locations do not populate "To" field properly. Let's account for that here.
    if (this.props.from.gateName && (this.props.from.preFirst || this.props.onOutsideCall)) {
      logger.info(`Appending gateName to locationName during sendTransactionToDB.`);
      rec.To = `${this.props.from.locationName} ${this.props.from.gateName}`;
    }
    if (this.props.onOutsideCall) {
      rec.answeredTime = this.props.answeredTime;
    }
    if (this.props.residentUnit) {
      rec.ResidentUnit = this.props.residentUnit;
    }
    if (this.props.transponderNumber) {
      rec.TransponderNumber = this.props.transponderNumber;
    }
    if (this.props.contactNumber) {
      rec.ContactNumber = this.props.contactNumber;
    }

    await this.sendEquipmentMalfunction();
    await this.sendOtherQuestion();
    await this.props.dispatch(queueActions.sendTransactionToDB(rec));
    await this.props.dispatch(queueActions.resetPromptState(rec));
    await this.props.history.push('/');
  }

  sendEquipmentMalfunction() {
    if (!this.props.malfunctionValue) {
      return;
    } else {
      let rec = {
        MalfunctionType: this.props.malfunctionValue,
        MalfunctionDescription: this.props.malfunctionDescription,
        SelectedManagerName: this.props.selectedManagerName,
        Username: this.props.username
      };
      this.props.dispatch(queueActions.sendEquipmentMalfunctionToDB(rec));
    }
  }

  async sendOtherQuestion() {
    if (this.props.otherQuestion == '') {
      return;
    } else {
      //logic for sending otherQuestion to database
      await this.props.dispatch(promptActions.createLightningPayQuestion({
        data: {
          Question: this.props.otherQuestion,
          callId: this.props.ongoingCallId
        }
      }))
    }
  }

  render() {
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
            {!this.props.confirmationScreenLoading ? (
              <Fragment>
                <DirectionText>You have successfully completed the call.</DirectionText>
                <ScriptText>Thank you for calling Cloudpark. Have a great day!</ScriptText>
                {this.props.isParkerCallOnHold &&
                  <DirectionText>
                    <span style={{ color: 'red', fontWeight: 'bolder', marginRight: 2 }}>
                      Note: 
                    </span>
                    Please un-hold the active call before you end this call.
                  </DirectionText>
                }
                <RaisedButton
                  key="end"
                  type="button"
                  disabled={this.props.isParkerCallOnHold}
                  className="cancel-button phone-buttons-end"
                  label="End"
                  labelPosition="after"
                  icon={<CallEnd />}
                  onClick={this.endCall}
                />
              </Fragment>
            ) : (
                <Fragment>
                  <DirectionText>Processing...</DirectionText>
                  <CircularProgress />
                </Fragment>
              )}
          </Paper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    selectedParker: promptSelectors.pullSelectedParker(state),
    username: authSelectors.pullModular(state, 'username'),
    ongoingAnalystCallSid: queueSelectors.pullModular(state, 'ongoingAnalystCallSid'),
    onCall: queueSelectors.pullCallStatus(state),
    isDialing: queueSelectors.pullIsDialing(state),
    isParkerCallOnHold: queueSelectors.pullModular(state, 'isParkerCallOnHold'),
    vendedGate: promptSelectors.pullVendedGate(state),
    thirdPartyVendorName: promptSelectors.pullThirdPartyVendorName(state),
    thirdPartyReservationNumber: promptSelectors.pullThirdPartyReservationNumber(state),
    validationNumber: promptSelectors.pullModular(state, 'validationNumber'),
    validationSource: promptSelectors.pullModular(state, 'validationSource'),
    validationDateTime: promptSelectors.pullModular(state, 'validationDateTime'),
    parkerEntranceDate: promptSelectors.pullModular(state, 'parkerEntranceDate'),
    parkerEntranceTime: promptSelectors.pullModular(state, 'parkerEntranceTime'),
    parkerExitDate: promptSelectors.pullModular(state, 'parkerExitDate'),
    parkerExitTime: promptSelectors.pullModular(state, 'parkerExitTime'),
    attendantName: promptSelectors.pullAttendantName(state),
    malfunctionValue: promptSelectors.pullModular(state, 'malfunctionValue'),
    malfunctionDescription: promptSelectors.pullModular(state, 'malfunctionDescription'),
    selectedManagerName: promptSelectors.pullModular(state, 'selectedManagerName'),
    answeredTime: promptSelectors.pullModular(state, 'answeredTime'),
    selectedParkerName: promptSelectors.pullModular(state, 'selectedParkerName'),
    selectedParkerRoomNumber: promptSelectors.pullModular(state, 'selectedParkerRoomNumber'),
    serviceWorkerType: promptSelectors.pullModular(state, 'serviceWorkerType'),
    ticketDiscrepancyEntranceTime: promptSelectors.pullModular(state, 'ticketDiscrepancyEntranceTime'),
    ticketDiscrepancyEntranceDate: promptSelectors.pullModular(state, 'ticketDiscrepancyEntranceDate'),
    ticketDiscrepancyExitTime: promptSelectors.pullModular(state, 'ticketDiscrepancyExitTime'),
    ticketDiscrepancyExitDate: promptSelectors.pullModular(state, 'ticketDiscrepancyExitDate'),
    ticketDiscrepancyFeeDue: promptSelectors.pullModular(state, 'ticketDiscrepancyFeeDue'),
    ticketDiscrepancyFirstName: promptSelectors.pullModular(state, 'ticketDiscrepancyFirstName'),
    ticketDiscrepancyLastName: promptSelectors.pullModular(state, 'ticketDiscrepancyLastName'),
    ticketDiscrepancyAddress: promptSelectors.pullModular(state, 'ticketDiscrepancyAddress'),
    ticketDiscrepancyPhoneNumber: promptSelectors.pullModular(state, 'ticketDiscrepancyPhoneNumber'),
    ticketDiscrepancyCity: promptSelectors.pullModular(state, 'ticketDiscrepancyCity'),
    ticketDiscrepancyState: promptSelectors.pullModular(state, 'ticketDiscrepancyState'),
    ticketDiscrepancyZip: promptSelectors.pullModular(state, 'ticketDiscrepancyZip'),
    ticketDiscrepancyComplete: promptSelectors.pullModular(state, 'ticketDiscrepancyComplete'),
    contactNumber: promptSelectors.pullModular(state, 'contactNumber'),
    residentUnit: promptSelectors.pullModular(state, 'residentUnit'),
    transponderNumber: promptSelectors.pullModular(state, 'transponderNumber'),
    companyName: promptSelectors.pullModular(state, 'companyName'),
    reportingTo: promptSelectors.pullModular(state, 'reportingTo'),
    visitPurpose: promptSelectors.pullModular(state, 'visitPurpose'),
    visitingApartmentNumber: promptSelectors.pullModular(state, 'visitingApartmentNumber'),
    visitingResidentName: promptSelectors.pullModular(state, 'visitingResidentName'),
    vehicleMake: promptSelectors.pullModular(state, 'vehicleMake'),
    vehicleModel: promptSelectors.pullModular(state, 'vehicleModel'),
    licensePlateNumber: promptSelectors.pullModular(state, 'licensePlateNumber'),
    selectedParkerCardNumber: promptSelectors.pullModular(state, 'selectedParkerCardNumber'),
    apbLockedOut: promptSelectors.pullModular(state, 'apbLockedOut'),
    equipmentMalfunctionDecision: promptSelectors.pullModular(state, 'equipmentMalfunctionDecision'),
    terminationLockedOut: promptSelectors.pullModular(state, 'terminationLockedOut'),
    wrongFacilityLockedOut: promptSelectors.pullModular(state, 'wrongFacilityLockedOut'),
    guestVerified: promptSelectors.pullModular(state, 'guestVerified'),
    invalidAccessLocation: promptSelectors.pullModular(state, 'invalidAccessLocation'),
    invalidAccessTime: promptSelectors.pullModular(state, 'invalidAccessTime'),
    managerActionRequest: promptSelectors.pullModular(state, 'managerActionRequest'),
    contactManagersTotalTime: promptSelectors.pullModular(state, 'contactManagersTotalTime'),
    onOutsideCall: promptSelectors.pullModular(state, 'onOutsideCall'),
    ticketNumber: promptSelectors.pullModular(state, 'ticketNumber'),
    confirmationScreenLoading: promptSelectors.pullModular(state, 'confirmationScreenLoading'),
    ticketDiscrepancyLicensePlate: promptSelectors.pullModular(state, 'ticketDiscrepancyLicensePlate'),
    monthlyCardIssue: promptSelectors.pullModular(state, 'monthlyCardIssue'),
    parkerNotFound: promptSelectors.pullModular(state, 'parkerNotFound'),
    ongoingCallId: queueSelectors.pullModular(state, 'ongoingCallId'),
    pilPaymentCode: promptSelectors.pullModular(state, 'pilPaymentCode'),
    otherQuestion: promptSelectors.pullModular(state, 'otherQuestion')
  };
}

export default withRouter(connect(mapStateToProps)(ConfirmationScreen));
