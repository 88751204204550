import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router';
import * as config from '../../../lib/config';
import * as authSelectors from '../../../store/auth/reducer';
import * as queueSelectors from '../../../store/queue/reducer';
import * as promptSelectors from '../../../store/prompt/reducer';
import RaisedButton from 'material-ui/RaisedButton';
import ScriptText from '../../../components/ScriptText';
import DirectionText from '../../../components/DirectionText';
import PromptBox from '../../../components/promptBox';
import ContactManagers from '../ContactManagers';

class Transient extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { showContacts: false }
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.replace('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  renderEntrance() {
    return (
      <Fragment>
        <ScriptText>Please pull a ticket and pay as you exit.</ScriptText>
        <RaisedButton label="Exit" onClick={() => { this.props.history.push('/confirmationScreen') }} />
        <RaisedButton label="Contact Managers" onClick={() => { this.setState({ showContacts: true }) }} />
      </Fragment>
    )
  }

  renderExit() {
    return (
      <Fragment>
        <ScriptText>Please use the ticket you pulled at the entrance lane to pay as expected. If you don't have that ticket, please press the Lost Ticket button.</ScriptText>
        <DirectionText>Note: If they refuse to pay and no manager responds, please tell them that we have no one to assist and they will have to pay, or fill a Ticket Discrepancy.</DirectionText>
        <RaisedButton label="Agrees to Pay" onClick={() => { this.props.history.push('/confirmationScreen')}} />
        <RaisedButton label="Refuses to Pay" onClick={() => { this.setState({ showContacts: true })}} />
      </Fragment>
    )
  }

  render() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        { this.props.from.laneType == 'Entrance' && this.renderEntrance()}
        { this.props.from.laneType !== 'Entrance' && this.renderExit()}
        { this.state.showContacts &&
          <ContactManagers showButtons={['managerAssisting', 'vendGate', 'ticketDiscrepancy']} />
        }
      </PromptBox>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username')
  };
}

export default withRouter(connect(mapStateToProps)(Transient));
