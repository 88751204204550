import { retrieveLogger } from '../lib/logger';
let logger = retrieveLogger();
const axiosInstance = require('../lib/axiosInstance');

class SendActionService {
  
  async sendAccessAction(locInfo, vendingUrl, username) {
    logger.info('Sending accessAction request inside of service.');
    try {
      await axiosInstance({
        method: 'post',
        url: `/accessAction`,
        data: { locInfo, vendingUrl, username }
      });
    } catch (error) {
      logger.error(error);
    }
    return;
  }
}

export default new SendActionService();
