import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import autoBind from "react-autobind";
import { withRouter, Link } from "react-router-dom";
import * as config from "../../../lib/config";
import * as promptSelectors from "../../../store/prompt/reducer";
import * as authSelectors from "../../../store/auth/reducer";
import * as promptActions from "../../../store/prompt/actions";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import Checkbox from 'material-ui/Checkbox';
import ScriptText from "../../../components/ScriptText";
import DirectionText from "../../../components/DirectionText";
import PromptBox from '../../../components/promptBox';
import ContactManagers from '../ContactManagers';
import { map } from 'lodash';
import ManagersList from "../ManagersList";

const textFieldStyle = {
  display: 'inline-block',
  width: '98%',
  marginTop: 15
}

class HotelVerification extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { 
      renderComponent: { stepOne: true, showInvalidGuest: false, showFrontDeskNoAnswer: false,  showPushRateQuestion: false, showShouldRedirectToIntercom: false },
    }
  }

  async componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push("/login");
    }
    if (this.props.from.redirectToIntercomPrompts) {
      const shouldRedirect = await this.props.dispatch(promptActions.shouldRedirectToIntercom("hotelIssue"));
      if (shouldRedirect) {
        this.showComponent(['showShouldRedirectToIntercom']);
      }
    }
    if (this.props.guestVerified === null) {
      this.props.dispatch(promptActions.modularSendField(false, 'guestVerified'));
    }

    if (this.props.from.hotelContactManagers) {
      this.props.history.push('/contactManagers')
    }
  }

  sendField(e, v, field) {
    this.props.dispatch(promptActions.modularSendField(v, field));
  }

  updateState(event, target) {
    this.setState({ [target]: event.target.value });
  }

  notEmpty = (propsArr) => {
    for (let i = propsArr.length - 1; i >= 0; --i) {
      if (this.props[propsArr[i]] === null) {
        return false;
      }
      if (this.props[propsArr[i]] != null && this.props[propsArr[i]].trim() === "") {
        return false;
      }
    }
    return true;
  }

  sendVerificationCheckbox() {
    if (this.props.guestVerified === null || this.props.guestVerified === false) {
      this.props.dispatch(promptActions.modularSendField(true, 'guestVerified'));
    }
    if (this.props.guestVerified === true) {
      this.props.dispatch(promptActions.modularSendField(false, 'guestVerified'));
    }
    return;
  }

  completeStep(step) {
    this.props.dispatch(promptActions.updateIssueStep(step));
  }

  goVendGate(from) {
    const { attendantName, selectedParkerName, selectedParkerRoomNumber } = this.props; 
    if (from == 'frontDesk' && !selectedParkerRoomNumber) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: 'Room Number is required.'
      }))
    } else if (from == 'frontDesk' && !selectedParkerName) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: 'Guest Name is required.'
      }));
    } else if (from == 'frontDesk' && !attendantName) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: 'Attendant Name is required.'
      }));
    } else {
      this.props.history.push('/vendGate');
    }
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'submitHotelDetails':
        const { selectedParkerName, selectedParkerRoomNumber, attendantName } = this.props;
        if (!selectedParkerRoomNumber) {
          return this.props.dispatch(promptActions.showSnackBar({
            type: 'error',
            message: 'Room Number is required.'
          }))
        } else if (!selectedParkerName) {
          return this.props.dispatch(promptActions.showSnackBar({
            type: 'error',
            message: 'Hotel Guest Name is required.'
          }))
        } else if (!attendantName && routeTo != 'showFrontDeskNoAnswer') {
          return this.props.dispatch(promptActions.showSnackBar({
            type: 'error',
            message: 'Attendant Name is required.'
          }))
        } else {
          this.showComponent([routeTo]);
          break;
        }
      case 'showPushRateForm':
        if (routeTo == 'hasTicket') {
          this.props.dispatch(promptActions.modularSendField(true, 'pushRateQuestion'));
        }
        this.props.history.push('/tibaAPI/tibaManagerPushRate');
        break;
      case 'showPushRateQuestion':
        this.showComponent(['showPushRateQuestion']);
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  showComponent(toRender) {
    const allComponents = {...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }

  render() {
    let roomNumberHintText;
    this.props.from.hotelVerificationParameter ? roomNumberHintText = this.props.from.hotelVerificationParameter : roomNumberHintText = `Customer's Room Number`;
    if (this.props.from.hotelVerification) {
      return (
        <div className="home-div">
          <div className="home-div-content">
            <Paper zDepth={1} className="home-div-header-short">
              <h2>{this.props.from.locationName}</h2>
            </Paper>
            <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: "20px" }}>

              {this.state.renderComponent.showShouldRedirectToIntercom &&
                <Fragment>
                  <DirectionText>
                    {this.props.from.hotelInstructions[0]}
                  </DirectionText>
                  <RaisedButton style={{ marginTop: 15 }} label="Next Step" onClick={() => this.props.history.push('/redirectToIntercom')} />
                </Fragment>
              }
              
              {this.state.renderComponent.stepOne &&
                <Fragment>
                  <div style={{ marginBottom: 15 }}>
                    { this.props.from.hotelInstructions && map(this.props.from.hotelInstructions, (instruction, index) => {
                      return (<DirectionText key={`hotelInstructions${index}`}>{instruction}</DirectionText>);
                    })}
                  </div>
                  <TextField
                    inputProps={{ className: 'data-hj-allow' }}
                    hintText={roomNumberHintText}
                    style={textFieldStyle}
                    value={this.props.selectedParkerRoomNumber || ""}
                    onChange={(e, v) => {
                      this.sendField(e, v, "selectedParkerRoomNumber");
                    }}
                  />
                  {!this.props.from.hotelParkerNameDisable &&
                    <TextField
                      hintText="Customer's Name"
                      style={textFieldStyle}
                      value={this.props.selectedParkerName || ""}
                      onChange={(e, v) => {
                        this.sendField(e, v, "selectedParkerName");
                      }}
                      inputProps={{ className: 'data-hj-allow' }}
                    />
                  }
                  {this.props.from.hotelManagersList && !this.props.from.hotelParkerReport &&
                    <div style={{ marginTop: 15 }}>
                      <ManagersList managers={this.props.from.hotelManagersList} />
                    </div>
                  }

                  {this.props.from.hotelManagersList  && !this.props.from.hotelParkerReport &&
                    <TextField
                      inputProps={{ className: 'data-hj-allow' }}
                      hintText="Front Desk Attendant Name"
                      style={textFieldStyle}
                      value={this.props.attendantName || ""}
                      onChange={(e, v) => {
                        this.sendField(e, v, "attendantName");
                      }}
                    />
                  }

                  <Checkbox
                    label="Was guest verified?"
                    labelPosition="right"
                    checked={this.props.guestVerified || false}
                    onCheck={this.sendVerificationCheckbox}
                    style={{width: '50%', display: 'inline-block', marginTop: 15 }}
                  />

                  <div style={{ marginTop: 15 }}>
                    {(this.props.from.hotelManagersList && !this.props.from.hotelParkerReport)
                    ?
                      <Fragment>
                        <RaisedButton label="Invalid Guest" onClick={() => this.btnClick('submitHotelDetails', 'showInvalidGuest')} />
                        <RaisedButton label="Front Desk Asked To Vend" onClick={() => this.goVendGate('frontDesk')} />
                        <RaisedButton label="No Response From Front Desk" onClick={() => this.btnClick('submitHotelDetails','showFrontDeskNoAnswer')} />
                        <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                      </Fragment>
                    :
                      <Fragment>
                        <RaisedButton label="Not Found" onClick={() => this.props.history.push('/hotel/notFound')} />
                        <RaisedButton label="Submit Parker" onClick={this.goVendGate} disabled={!this.notEmpty(['selectedParkerRoomNumber', `${this.props.from.hotelParkerNameDisable ? null : `selectedParkerName`}`])} />
                        <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                      </Fragment>
                    }
                  </div>
                </Fragment>
              }

              {this.state.renderComponent.showInvalidGuest &&
                <Fragment>                    
                  {this.props.from.laneType == 'Entrance' && <ScriptText> Please pull a ticket to Enter. </ScriptText>}
                  {this.props.from.laneType == 'Exit' && <ScriptText> Please play the parking fee to Exit. </ScriptText>}
                  <div style={{ marginTop: 15 }}>
                    <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')}/>
                    {this.props.from.laneType == 'Exit' && <RaisedButton label="Contact Managers" onClick={() => this.showComponent(['showFrontDeskNoAnswer'])}/>}
                  </div>
                </Fragment>
              }

              {this.state.renderComponent.showFrontDeskNoAnswer &&
                <Fragment>
                  {this.props.from.laneType == 'Entrance' && <ScriptText> Please pull a ticket to Enter. </ScriptText>}
                  {this.props.from.laneType == 'Exit' && 
                    <ContactManagers 
                      showButtons={['managerAssisting', 'vendGate', 'ticketDiscrepancy', 'showCustomButton']} 
                      btnClick={this.btnClick}
                      btnFrom="hotelIssue"
                      customButtonData={[
                        {
                          "label": "Manager Asked to Push Rate",
                          "action": `${this.props.from.hotelPushRateQuestion ? 'showPushRateQuestion' : 'showPushRateForm'}`
                        },
                      ]}
                    />
                  }
                </Fragment>
              }

              {this.state.renderComponent.showPushRateQuestion &&
                <Fragment>
                  <ScriptText> Do you have a ticket? </ScriptText>
                  <div style={{ marginTop: 15 }}>
                    <RaisedButton label="Yes" onClick={() => this.btnClick('showPushRateForm', 'hasTicket')} />
                    <RaisedButton label="No" onClick={() => this.btnClick('showPushRateForm')} />
                  </div>
                </Fragment>
              }
            </Paper>
          </div>
        </div>
      );
    } else if (this.props.from.hotelReservations) {
      return (
        <PromptBox locationName={this.props.from.locationName}>
          { this.props.step === 0 &&
            <div>
              <ScriptText>
                Please scan your hotel reservation.
              </ScriptText>
              <Paper zDepth={1} className="paper-padding">
                <span className="verification-span">Is further assistance required?</span>
                <Link to="/confirmationScreen">
                  <RaisedButton label="No"/>
                </Link>
                <RaisedButton label="Yes" onClick={() => {this.completeStep(1)}} />
              </Paper>
            </div>
          }
          { this.props.step === 1 &&
            <Fragment>
              <DirectionText>
                Please contact the on-site team for assistance.
              </DirectionText>
              <ContactManagers showButtons={[]} />              
              <RaisedButton label="No" onClick={() => {this.completeStep(2)}} />
            </Fragment>
          }
          { this.props.step === 2 &&
            <Fragment>
              <ScriptText>
                Please pay the amount requested and save your receipt. You can contact the cashier during regular business hours to discuss reimbursement.
              </ScriptText>
              <Link to="/confirmationScreen">
                <RaisedButton label="Next" />
              </Link>
            </Fragment>
          }
        </PromptBox>
      )
    } else if (this.props.from.hotelRedirect) {
      return (
        <PromptBox locationName={this.props.from.locationName}>
          <ScriptText>
            {this.props.from.hotelRedirect}
          </ScriptText>
          <div className="paper-padding">
            <Link to="/confirmationScreen">
              <RaisedButton label="Complete Call"/>
            </Link>
          </div>
        </PromptBox>
      )
    } else {
      return (
        <div className="home-div">
          <div className="home-div-content">
            <Paper zDepth={1} className="home-div-header-short">
              <h2>{this.props.from.locationName}</h2>
            </Paper>
            <Paper
              zDepth={1}
              className="paper-padding prompt-body"
              style={{ marginTop: "20px" }}
            >
              <ScriptText>
                "Please wait a moment as I verify your guest status at the
                hotel."
              </ScriptText>
              <DirectionText>
                Please verify their hotel guest status outside of this
                application and then return to continue the process.
              </DirectionText>
              <div className="paper-padding">
                <span className="verification-span">
                  Was Hotel Guest Verified?
                </span>
                <Link to="/vendGate">
                  <RaisedButton label="Yes" />
                </Link>
                <Link to="/hotel/notFound">
                  <RaisedButton label="No" />
                </Link>
              </div>
            </Paper>
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username'),
    selectedParkerName: promptSelectors.pullModular(state, "selectedParkerName"),
    selectedParkerRoomNumber: promptSelectors.pullModular(state, "selectedParkerRoomNumber"),
    guestVerified: promptSelectors.pullModular(state, 'guestVerified'),
    step: promptSelectors.pullModular(state, 'step'),
    attendantName: promptSelectors.pullModular(state, 'attendantName')
  };
}

export default withRouter(connect(mapStateToProps)(HotelVerification));
