import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as promptActions from '../../../store/prompt/actions';
import * as authSelectors from '../../../store/auth/reducer';
import _ from 'lodash';

class TicketSearch extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    const loadOptions = inputValue => this.getAvailableTickets(inputValue);
    this.debouncedLoadOptions = _.debounce(loadOptions, 1000, {
      leading: true
    });
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  async getAvailableTickets(value) {
    return new Promise(async (resolve, reject) => {
      const result = await this.props.dispatch(promptActions.flashTicketSearch({ ticketNumber: value }));
      const { success, message, ticketSearchList, found } = result.data;
      if (ticketSearchList && found) {
        const filtered = _.filter(ticketSearchList, o =>
          _.startsWith(_.toLower(o.label), _.toLower(value))
        );
        resolve(filtered.slice(0, 3));
      } else {
        this.props.dispatch(promptActions.modularSendField(value, 'ticketNumber'));
        this.props.dispatch(promptActions.showSnackBar({ type: 'error', message: `Failed to Find Ticket Number: ${value}` }));
      }
    });
  }

  render() {
    return (
      <Fragment>
        <AsyncSelect
          className="MuiOutlinedInput-inputMarginDense max-z-index"
          classNamePrefix="address-select"
          placeholder="Enter Ticket Number"
          isClearable
          value={{ value: this.props.ticketNumber, label: this.props.ticketNumber}}
          loadOptions={inputValue => this.debouncedLoadOptions(inputValue)}
          onChange={this.props.flashSaveTicketDetails}
        />
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username'),
    ticketNumber: promptSelectors.pullModular(state, 'ticketNumber')
  };
}

export default withRouter(connect(mapStateToProps)(TicketSearch));