import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as authSelectors from '../../../store/auth/reducer';
import * as promptActions from '../../../store/prompt/actions';
import PromptBox from '../../../components/promptBox';
import { Fragment } from 'react';
import DirectionText from '../../../components/DirectionText';
import Select from 'react-select';
import { RaisedButton } from 'material-ui';
import ScriptText from '../../../components/ScriptText';

class ZipCar extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { 
      renderComponent: { 
        stepOne: true,
        showNotFound: false
      }
    }
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  getZipCarOptions() {
    const { zipCarData } = this.props.from;
    const options = zipCarData.map(car => {
      return {
        value: car.hangTag,
        label: `${car.hangTag} - ${car.carName} - ${car.make} - ${car.state} - ${car.licensePlate}`
      }
    });

    return options;
  }

  saveZipCarData(data) {
    const { value } = data;
    const { zipCarData } = this.props.from;
    const zipCar = zipCarData.find(car => car.hangTag == value);
    if (data) {
      this.props.history.push('/vendGate');
      this.props.dispatch(promptActions.modularSendField(zipCar.licensePlate, 'licensePlateNumber'));
      this.props.dispatch(promptActions.modularSendField(zipCar.make, 'vehicleMake'));
      this.props.dispatch(promptActions.modularSendField(zipCar.model, 'vehicleModel'));
    }
  }

  showComponent(toRender) {
    const allComponents = {...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }

  render() {
    return (
      <PromptBox locationName={this.props.from.locationName}>

        {this.state.renderComponent.stepOne &&
          <Fragment>
            <DirectionText>
              {this.props.from.zipCarNote}
            </DirectionText>
            <Select
              className="MuiOutlinedInput-inputMarginDense max-z-index"
              classNamePrefix="address-select"
              options={this.getZipCarOptions()}
              onChange={(e) => { this.saveZipCarData(e) }}
            />
            <div style={{ marginTop: 15 }}>
              <RaisedButton label="Not Found" onClick={() => { this.showComponent(['showNotFound']) }} />
              <RaisedButton label="Complete Call" onClick={() => { this.props.history.push('/confirmationScreen')}} />
            </div>
          </Fragment>
        }

        {this.state.renderComponent.showNotFound && 
          <Fragment>
            {this.props.from.laneType == "Entrance" &&
              <Fragment>
                <ScriptText> Please pull a ticket to enter the parking area. </ScriptText>
              </Fragment>
            }
            {this.props.from.laneType == "Exit" &&
              <Fragment>
                <ScriptText> Please pay the fee using the ticket you received on the entrance. </ScriptText>
              </Fragment>
            }
            <div style={{ marginTop: 15 }}>
              <RaisedButton label="Contact Managers" onClick={() => { this.props.history.push('/contactManagers')}} />
              <RaisedButton label="Complete Call" onClick={() => { this.props.history.push('/confirmationScreen')}} />
            </div>
          </Fragment>
        }
      </PromptBox>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username')
  };
}

export default withRouter(connect(mapStateToProps)(ZipCar));
