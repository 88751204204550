import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../lib/config';
import * as promptSelectors from '../store/prompt/reducer';
import * as promptActions from '../store/prompt/actions';
import RaisedButton from 'material-ui/RaisedButton';
import * as authSelectors from '../store/auth/reducer';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import DirectionText from '../components/DirectionText';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import _ from 'lodash';

class AttendantAssisting extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  notEmpty(propsArr) {
    for (let i = propsArr.length - 1; i >= 0; --i) {
      let p = this.props[propsArr[i]];
      if (!p) {
        return false;
      }
      if (p && p === null) {
        return false;
      }
      if (p && p.trim() === "") {
        return false;
      }
      if (p && p.trim() && p.trim().length <= 3) {
        return false;
      }
    }
    return true;
  }

  updateAttendantName(event) {
    let val;
    if (event.target && event.target.value) {
      val = event.target.value;
    }
    else if (event.target && event.target.outerText) {
      val = event.target.outerText;
    }
    this.props.dispatch(promptActions.modularSendField(val, 'attendantName'));
    return;
  }

  generateManager(a, b, c) {
    if (!a.name) {
      return null;
    }
    return (
      <MenuItem primaryText={a.name} value={a.name} />
    );
  }

  generateAttendantAssistingDropdown() {
    return (
      <div className="home-div">
        <div className="home-div-content">
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            <Paper zDepth={1} className="paper-padding">
              <DirectionText>
                Please select the name of the attendant or employee who authorized the gate vend.
              </DirectionText>
              <SelectField
                hintText="Managers"
                value={this.props.attendantName}
                onChange={this.updateAttendantName}
                style={{ width: '100%', textAlign: 'left' }}
                hintStyle={{ paddingLeft: 'calc(50% - 35px)'}}
              >
                {_.map(this.props.from.managers, this.generateManager)}
              </SelectField>
            </Paper>
            <Paper zDepth={1} className="paper-padding">
              {this.notEmpty(["attendantName"]) ?
                <Fragment>
                  <span className="verification-span">Next Steps</span>
                  <Link to="/vendGate">
                    <RaisedButton label="Vend Gate" style={{ margin: '0 10px' }} />
                  </Link>
                  <Link to="/confirmationScreen">
                    <RaisedButton label="Complete Call" />
                  </Link>
                </Fragment>
                : null}
            </Paper>
          </div>
        </div>
      </div>
    );
  }

  generateAttendantAssistingInput() {
    return (
      <div className="home-div">
        <div className="home-div-content">
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            <Paper zDepth={1} className="paper-padding">
              <DirectionText>
                Please enter the name of the attendant or employee who authorized the gate vend.
              </DirectionText>
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Attendant Name"
                maxlength="30"
                style={{ width: '50%' }}
                onChange={this.updateAttendantName} key="attendantName"
              />
            </Paper>
            <Paper zDepth={1} className="paper-padding">
              {this.notEmpty(["attendantName"]) ?
                <Fragment>
                  <span className="verification-span">Next Steps</span>
                  <Link to="/vendGate">
                    <RaisedButton label="Vend Gate" style={{ margin: '0 10px' }} />
                  </Link>
                  <Link to="/confirmationScreen">
                    <RaisedButton label="Complete Call" />
                  </Link>
                </Fragment>
                : null}
            </Paper>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.from.attendantAssistingDropdown && this.props.from.attendantAssistingDropdown === true) {
      return this.generateAttendantAssistingDropdown();
    } else {
      return this.generateAttendantAssistingInput();
    }
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    attendantName: promptSelectors.pullModular(state, "attendantName")
  };
}

export default withRouter(connect(mapStateToProps)(AttendantAssisting));
