import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import * as promptSelectors from '../store/prompt/reducer';
import * as authSelectors from '../store/auth/reducer';
import GoogleMap from '../components/map';

class MapContainer extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    if (this.props.from && this.props.mapLoaded) {
      const locX = this.props.from.locationX || (this.props.locX || 41.768816);
      const locY = this.props.from.locationY || (this.props.locY || -72.68158);
      return (
        <GoogleMap locX={locX} locY={locY} name={this.props.from.locationName} />
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    mapLoaded: promptSelectors.pullModular(state, 'mapLoaded'),
    locX: authSelectors.pullModular(state, 'locX'), 
    locY: authSelectors.pullModular(state, 'locY'), 
  };
}

export default withRouter(connect(mapStateToProps)(MapContainer));
