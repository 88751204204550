import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Link } from 'react-router-dom';
import RaisedButton from 'material-ui/RaisedButton';
import Call from 'material-ui/svg-icons/communication/call';

export default class AnswerButton extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    if (this.props.onCall === false && this.props.isDialing === true) {
      return (
        <Link to="/preFirst">
          <RaisedButton className="answer-button phone-buttons" label="Answer" labelPosition="after" icon={<Call />} onClick={this.props.nextInQueue} />
        </Link>
      );
    } else {
      return (
        <RaisedButton className="answer-button-inactive phone-buttons" label="Answer" labelPosition="after" icon={<Call />} />
      )
    }
  }
}
