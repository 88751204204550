import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Lens from 'material-ui/svg-icons/image/lens';
import { red500 } from 'material-ui/styles/colors';

export default class RecordingDisclaimer extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    return (
      <div className="recording-disclaimer-box">
        { this.props.onCall &&
          <div className="recording-disclaimer">
            <span className="recording-disclaimer-image">
              <Lens
                className="recording-blinker"
                viewBox="0 0 24 24"
                color={red500} 
                style={{ width: '24px', height: '24px', marginTop: '3px'}}
              />
            </span>
            <div className="recording-disclaimer-message">Your call is being recorded</div>
          </div>
        }
      </div>
    );
  }
}