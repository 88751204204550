// import TicketDiscrepancy from '../containers/Prompt/TicketDiscrepancy';
// eslint-disable-next-line
// import ParkWestin from '../containers/Prompt/ServiceWorker';
// import ContactManagers from '../containers/Prompt/ContactManagers';

// // PRODUCTION CONFIG
// export const serverUrl = 'http://35.227.38.241';
// export const TestComponent = null;
// export const TestLocation = null;

// DEVELOPMENT COMPONENT TESTING
// export const serverUrl = 'http://cloudpark-server.ngrok.io';
// export const TestComponent = TicketDiscrepancy;
// export const TestLocation = 'Cell Phone Lot';

// // DEVELOPMENT STANDARD
export const serverUrl = 'https://server.cloudparkassistant.com';
export const TestComponent = null;
export const TestLocation = null;
export const vendingUrl = 'http://35.243.153.199';
export const localId = 'aio_1';
export const mapsApiKey = 'AIzaSyBzxwJSd-gDgywoHUaFKIHE9yd8sXJ2_PM';
// export const managerTestNumber = '2037726664';
// export const managerTestNumber = null;

// // DEVELOPMENT 2 STANDARD
// export const serverUrl = 'http://cloudpark-server-dank-1234567890.ngrok.io';
// export const TestComponent = null;
// export const TestLocation = null;