import React, { Component } from 'react';
import autoBind from 'react-autobind';
import _ from 'lodash';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import TextField from 'material-ui/TextField';
import Divider from 'material-ui/Divider';
import moment from 'moment-timezone';
import CircularProgress from 'material-ui/CircularProgress';

const nameStyles = {
  padding: '0px 6px', textAlign: 'center', width: '25%'
};
const cardStyles = {
  padding: '0px 6px', textAlign: 'center', width: '15%'
};
const etcStyles = {
  padding: '0px 6px', textAlign: 'center'
}

export default class MonthlyParkersList extends Component {
  constructor(props) {
    super(props);
    this.reloadInterval = null;
    autoBind(this);
  }

  generateTableRowColumn(value, index, collection) {
    // Figure out how to compare for which parker is selected, and programatically set the selected to true for that row.
    let selection = false;
    let sP, sC, vN;
    if (this.props.selectedParker.name && this.props.selectedParker.name.toLowerCase) {
      sP = this.props.selectedParker.name.toLowerCase();
    }
    if (this.props.searchCriteria && this.props.searchCriteria.toLowerCase) {
      sC = this.props.searchCriteria.toLowerCase();
    }
    if (value.name && value.name.toLowerCase) {
      vN = value.name.toLowerCase();
    }
    // if (sP === vN) {
    //   selection = true;
    // }
    if (this.props.selectedParkerIndex == index) {
      selection = true;
    }
    if (vN.match(sC) || value.cardNumber.match(sC) ) {
      return (
        <TableRow
          selected={selection}
          key={`${value}_${index}`}
          style={{cursor: 'pointer'}}
        >
          <TableRowColumn style={nameStyles}>{value.name}</TableRowColumn>
          <TableRowColumn style={nameStyles}>{value.accountName}</TableRowColumn>
          <TableRowColumn style={cardStyles}>{value.cardNumber}</TableRowColumn>
          <TableRowColumn style={cardStyles}>{value.terminationDate ? moment(value.terminationDate).format('MM/DD/YYYY') : null }</TableRowColumn>
          <TableRowColumn style={etcStyles}>{value.accessLevel}</TableRowColumn>
        </TableRow>
      );
    } else {
      return (
        <TableRow
          selected={selection}
          key={`${value}_${index}`}
          style={{cursor: 'pointer', height: '0px'}}
        >
        </TableRow>
      )
    }
  }

  render() {
    let windowHeight = window.innerHeight - 600;
    return (
      <div>
        <TextField
          inputProps={{ className: 'data-hj-allow' }}
          hintText="Search for Parkers here"
          style={{width: '98%'}} 
          onChange={this.props.mPSearch}
        />
        <Divider />
        {this.props.parkers[0] ?
        <Table
          bodyStyle={{ height: windowHeight, overflow:'auto'}}
          onCellClick={this.props.sendParkerToState}  
        >
          <TableHeader style={{ borderRight: '32px solid transparent' }} displaySelectAll={false} adjustForCheckbox={false}>
          }
            <TableRow>
              <TableHeaderColumn style={nameStyles}>Name</TableHeaderColumn>
              <TableHeaderColumn style={nameStyles}>Account</TableHeaderColumn>
              <TableHeaderColumn style={cardStyles}>Card Number</TableHeaderColumn>
              <TableHeaderColumn style={cardStyles}>Termination</TableHeaderColumn>
              <TableHeaderColumn style={etcStyles}>Access Level</TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false} stripedRows={true}>
            {_.map(this.props.parkers, this.generateTableRowColumn) }
          </TableBody>
        </Table> : 
        <CircularProgress style={{ margin: 'calc(50% - 75px) 0 0 0' }}/>
        }
      </div>
    );
  }
}
