import React from "react";
import { connect } from "react-redux";
import ScriptText from "../../../../components/ScriptText";
import { pullModular as promptPullModular } from '../../../../store/prompt/reducer';

const TicketInstructions = (props) => {
  return (
    <ScriptText>
      {props.from.ticketInstructionsNote 
      ?
      props.from.ticketInstructionsNote 
      : 
      'Please insert your ticket with the barcode facing up.'}
    </ScriptText>
  )
}

function mapStateToProps(state) {
  return {
  from: promptPullModular(state, 'from')
  }
}

export default (connect(mapStateToProps)(TicketInstructions));