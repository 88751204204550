import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptActions from '../../../store/prompt/actions';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as authSelectors from '../../../store/auth/reducer';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import ScriptText from '../../../components/ScriptText';
import DirectionText from '../../../components/DirectionText';
import ErrorBoundary from '../../ErrorBoundary';

class CardAcceptanceIssue extends Component {
  constructor(props) {
    super(props);
    this.reloadInterval = null;
    autoBind(this);
  }

  componentWillMount() {
    this.props.dispatch(promptActions.updateMonthlyParkers(this.props.from.locationId));
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    // reset global step prop 
    this.props.dispatch(promptActions.updateIssueStep(0));
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentWillUnmount() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  updateEmployeeNumber(event) {
    this.props.dispatch(promptActions.modularSendField(event.target.value, 'selectedParkerCardNumber'));
  }

  shouldShowVendButton() {
    if (this.props.selectedParkerCardNumber && this.props.selectedParkerCardNumber.length >= 6) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <ErrorBoundary>
        <div className="home-div">
          <div className={`home-div-content`}>
            <Paper zDepth={1} className="home-div-header-short">
              <h2>{this.props.from.locationName}</h2>
            </Paper>
            {this.props.from.specialMonthlyLane === "Doctor"
            ?
              (
                <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
                  <DirectionText>
                    If Parker is a Doctor, vend the gate.
                  </DirectionText>
                  <Link to="/vendGate">
                    <RaisedButton label="Vend Gate" style={{ margin: '16px 10px' }} />
                  </Link>
                  <Link to="/confirmationScreen">
                    <RaisedButton label="End Call" style={{ margin: '16px 10px' }} />
                  </Link>
                </Paper>
              )
            :
              (
                <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
                  <ScriptText>
                    Please provide your six-digit employee number and I will proceed to open the gate for you.
                  </ScriptText>
                  <DirectionText>
                    If number is less than six digits, just pre-populate the number with zeros.
                  </DirectionText>
                  <TextField
                    inputProps={{ className: 'data-hj-allow' }}
                    hintText="Six Digit Employee Number"
                    style={{ width: '98%' }}
                    onChange={this.updateEmployeeNumber}
                  />
                  {this.shouldShowVendButton() &&
                    <Link to="/vendGate">
                      <RaisedButton label="Vend Gate" style={{ margin: '16px 10px' }} />
                    </Link>
                  }
                  <Link to="/confirmationScreen">
                    <RaisedButton label="End Call" style={{ margin: '16px 10px' }} />
                  </Link>
                </Paper>
              )
            }
          </div>
        </div>
      </ErrorBoundary>
    )
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    selectedParker: promptSelectors.pullModular(state, "selectedParker"),
    selectedParkerName: promptSelectors.pullModular(state, "selectedParkerName"),
    selectedParkerCardNumber: promptSelectors.pullModular(state, 'selectedParkerCardNumber'),
    username: authSelectors.pullModular(state, 'username'),
    step: promptSelectors.pullStep(state),
    companyName: promptSelectors.pullModular(state, "companyName")
  };
}

export default withRouter(connect(mapStateToProps)(CardAcceptanceIssue));
