import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptSelectors from '../../store/prompt/reducer';
import * as authSelectors from '../../store/auth/reducer';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import _ from 'lodash';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table';
import DirectionText from '../../components/DirectionText';

const nameStyles = {
  textAlign: 'center',
  whiteSpace: 'normal',
  wordWrap: 'break-word'
};

class GeneralInquiry extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  generateInquiryTips(value, index, collection) {
    let val, bool;
    switch (this.props.from[value]) {
      case true:
        bool = 'Yes';
        break;
      case false:
      case undefined:
        bool = 'No';
        break;
      default:
        bool = this.props.from[value];
        break;
    }
    // camelCase to Title Case.
    val = value.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) {
      return str.toUpperCase();
    });
    switch (value) {
      case 'cashPayment':
        val = 'Cash Payment Available?';
        break;
      case 'cardPayment':
        val = 'Card Payment Available?';
        break;
      case 'cardTicket':
        val = 'Does Card Act as Ticket?';
        break;
      case 'ticketAcceptanceRedirect':
        val = 'Any Lanes to Redirect Parker To?';
        break;
      default:
        break;
    }
    return (
      <TableRow key={`${value}_${index}`} style={{ cursor: 'pointer' }}>
        <TableRowColumn style={nameStyles}>{val}</TableRowColumn>
        <TableRowColumn style={nameStyles}>{bool}</TableRowColumn>
      </TableRow>
    );
  }


  generateCustomInquiryTips(value, index, collection) {
   // value: an object
    // object key is question and value is answer
    return (
        <TableRow style={{ cursor: 'pointer' }}>
          <TableRowColumn style={nameStyles}>{Object.keys(value)[0]}</TableRowColumn>
          <TableRowColumn style={nameStyles}>{Object.values(value)[0]}</TableRowColumn>
        </TableRow>
    );
  }


  render() {
    let windowHeight = window.innerHeight - 340;
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
            <DirectionText>Below is a table containing general information related to the lot, which may assist in the general inquiry.</DirectionText>
            <Table bodyStyle={{ height: windowHeight, overflow: 'auto' }} onCellClick={this.props.sendParkerToState}>
              <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                <TableRow>
                  <TableHeaderColumn style={nameStyles}>Field</TableHeaderColumn>
                  <TableHeaderColumn style={nameStyles}>Value</TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody displayRowCheckbox={false} stripedRows={true}>
                { this.props.from.inquiryTips && _.map(this.props.from.inquiryTips, this.generateInquiryTips)}
                { this.props.from.customInquiryTips && _.map(this.props.from.customInquiryTips, this.generateCustomInquiryTips)}
              </TableBody>
            </Table>
            <Link to="/confirmationScreen">
              <RaisedButton label="Complete Call" />
            </Link>
          </Paper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    valCompanyIndex: promptSelectors.pullModular(state, 'companyIndex'),
    ticketNumber: promptSelectors.pullTicketNum(state),
    username: authSelectors.pullModular(state, 'username')
  };
}

export default withRouter(connect(mapStateToProps)(GeneralInquiry));
