import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import { TextField, Button } from '@material-ui/core';
import serverInteraction from '../../services/serverInteraction';
import ReactInputMask from 'react-input-mask';
import { retrieveLogger } from '../../lib/logger';
let logger = retrieveLogger();

class SetNumberMap extends Component {

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      sipIdInput: '',
      locationNameInput: '',
      userMessage: '',
      locationNameList: [],
      selectedLocationName: null,
      locationDetails: null,
    };
  }

  async componentDidMount() {
    this.getLocationNameList()
  }


  updateSipIdInput(event) {
    if (event.target && event.target.value) {
      return this.setState({ sipIdInput: event.target.value })
    }
    return this.setState({ sipIdInput: '' })
  }

  updateLocationNameInput(event) {
    if (event.target && event.target.value) {
      return this.setState({ locationNameInput: event.target.value })
    }
    return this.setState({ locationNameInput: '' })
  }

  async saveButtonClick() {
    // const results = await promptActions.setNumberMapRequest({ sipId: this.state.sipIdInput, locName: locationNameInput });
    const results = await this.setNumberMapRequest({ sipId: this.state.sipIdInput, locName: this.state.locationNameInput });
  }

  async setNumberMapRequest({ sipId, locName }) {
    if (!sipId || !locName) {
      // TODO: Add Snackbar message to alert user that they need to fill in both sipid and locName.
      return logger.info('Please provide a valid sipId and locName!');
    }
    const requestResults = await serverInteraction.sendServerPOSTRequest('/updateNumberMapAdmin', {
      sipId,
      locName
    })
    const { data } = requestResults;
    const { message } = data;
    if (message) {
      this.setState({ userMessage: message })
    }
    return requestResults;
  }

  async getLocationNameList() {
    const requestResults = await serverInteraction.sendServerGETRequest('/retrieveEndpointNames', {})
    const { data } = requestResults;
    const { endpointNames } = data;
    this.setState({ locationNameList: endpointNames })
  }

  async getLocationDetails(locationName) {
    if (!locationName) {
      //TO DO: Send snackbar alert to user if they are attempting this with no selectedLocationName.
      logger.info('Running getLocationDetails without this.state.selectedLocationName.')
      return
    }
    const requestResults = await serverInteraction.sendServerPOSTRequest('/retrieveLocationDetailsByEndpoint', { endpointName: locationName })
    const { data } = requestResults
    const { queryResults } = data
    const stringifiedResults = JSON.stringify(queryResults, null, 2)
    // TODO: Loop over each key/value pair in queryResults, and push them as a string in the following format: "key: value" to a locationDetails array.
    // We can then loop over that array and for each of them, create a <p> tag with that 1 line of key/value pair and this should help the styling quite a bit.
    this.setState({ locationDetails: stringifiedResults })
  }

  locationListNameClick(event) {
    event.preventDefault()
    const locationName = event.target.innerText;
    this.setState({ selectedLocationName: locationName })
    this.setState({ locationNameInput: locationName })
    this.getLocationDetails(locationName)
  }


  render() {
    return (
      <div className="home-div">
        <h2>Set Number Map</h2>
        <ReactInputMask
          mask="999999"
          maskChar=''
          value={this.state.sipIdInput}
          onChange={this.updateSipIdInput}>
          {() => <TextField
            label="Select SIP ID to Edit"
            variant='outlined'
            margin='dense'>

          </TextField>}
        </ReactInputMask>
        <TextField
          label="Select Location Name"
          value={this.state.locationNameInput}
          variant='outlined'
          margin='dense'
          onChange={this.updateLocationNameInput}
        />
        <Button
          disabled={!this.state.sipIdInput || !this.state.locationNameInput}
          style={{ marginTop: 10 }}
          variant="contained"
          color="primary"
          onClick={this.saveButtonClick}
        >
          Save
        </Button>
        <p className={`${this.state.userMessage && 'element-selected-style'} user-message-style`}>
          {this.state.userMessage && this.state.userMessage}
        </p>
        <div id='outer-box' className='location-list-outer-box-style'>
          <h3 style={{ marginBottom: '12px' }}>Location Name</h3>
          <ul className={'location-list-ul-style'}>
            {this.state.locationNameList.map((value) => {
              const shouldSelect = value == this.state.selectedLocationName
              return (
                <li
                  className={`${shouldSelect && 'element-selected-style'} location-list-li`}
                  onClick={this.locationListNameClick}
                  key={value}
                >
                  {value}
                </li>
              )
            })}
          </ul>
          <h3 style={{ marginBottom: '2px' }}>Location Details</h3>
          <pre>
            <code>
              <p className={'location-details-style'}>
                {this.state.locationDetails && this.state.locationDetails}
              </p>
            </code>
          </pre>
        </div>
      </div >
    )
  }
}

function mapStateToProps(state) {
  return {
    // from: promptSelectors.pullLocationId(state)
  };
}

export default withRouter(connect(mapStateToProps)(SetNumberMap));
