import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import RaisedButton from 'material-ui/RaisedButton';
import _ from 'lodash';
import * as config from '../../lib/config';
import * as promptSelectors from '../../store/prompt/reducer';
import * as authSelectors from '../../store/auth/reducer';
import DirectionText from '../../components/DirectionText';
import PromptBox from '../../components/promptBox';

class RedirectToIntercom extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        <DirectionText> Since the parker called from the cell phone you wont be able to vend the gate. Please ask the parker to call from the Intercom. </DirectionText>
        <div style={{ marginTop: 20 }}>
          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
        </div>
      </PromptBox>
    )
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username')
  };
}

export default withRouter(connect(mapStateToProps)(RedirectToIntercom));