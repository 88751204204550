import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import WarningIcon from '@mui/icons-material/Warning';
import PromptWrapper from './Prompt/PrepaidFlows/PromptWrapper';
import { pullModular as promptPullModular } from '../store/prompt/reducer';
import { retrieveLogger } from '../lib/logger';
import { Paper } from '@mui/material';
let logger = retrieveLogger();

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    logger.error(`Caught error in ErrorBoundary.`, { 
      issueType: this.props.issue.type, 
      issueSubtype: this.props.issue.subtype, 
      error 
    });
  }

  render() {
    if (this.state.errorInfo) {    
      return (
        <PromptWrapper>
          <WarningIcon sx={{ fontSize: 100, color:'#E0D600' }} />
          <Paper className='errorboundary-card-style'><h2>Something went wrong, but please continue the call!</h2></Paper>
          <Paper className='errorboundary-card-style'><p>There was a software issue and our Engineering team has been notified.</p></Paper>
          <Paper className='errorboundary-card-style'><p>The call is still live. Please press the Home icon below and choose a different flow to follow.</p></Paper>
        </PromptWrapper>
      )}
    return this.props.children;
  } 
} 

function mapStateToProps(state) {
  return {
    issue: promptPullModular(state, 'issue')
  }
}

export default connect(mapStateToProps)(ErrorBoundary);