import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as promptActions from '../../../store/prompt/actions';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import ContactManagers from '../ContactManagers';
import DirectionText from '../../../components/DirectionText';
import PromptBox from '../../../components/promptBox';
import ScriptText from '../../../components/ScriptText';
import TextField from 'material-ui/TextField';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ErrorBoundary from '../../ErrorBoundary';

const dateStyles = {
  display: 'inline-block',
  width: '100%'
};

class ValidationSplit extends Component {

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      renderComponent: {
        showValidationSources: true,
        showManagersList: false,
        showManagersListWithCustomButton: false,
        showValidationForm: false,
        showNoSticker: false,
        showHasSticker: false,
        showNoManagerResponseNote: false
      },
      parkerEntranceTime: null, parkerExitTime: null, hideValidationNumber: false
    }
  }

  componentDidMount() {
    if (this.props.from.validationTimes) {
      const formattedDate = moment().format('YYYY-MM-DD');
      this.props.dispatch(promptActions.sendDateTime(formattedDate, 'parkerExitDate'));
      const formattedTime = moment().startOf('minute').format('THH:mm:ss');
      this.props.dispatch(promptActions.sendDateTime(formattedTime, 'parkerExitTime'));
    }
  }

  updateField(event, value, target) {
    this.props.dispatch(promptActions.modularSendField(value, target));
  }

  displayValidationArray(a) {
    return (
      <li style={{ textAlign: "left" }} key={a.name}>
        {`${a.name}: ${a.duration ? a.duration : 'N/A'}`}
      </li>
    );
  }

  displayFullValidations(val) {
    if (val.full) {
      return (
        <li style={{ textAlign: 'left' }} key={val.name}>
          {`${val.name}`}
        </li>
      )
    }
  }

  displayPartialValidations(val) {
    if (!val.full) {
      return (
        <li style={{ textAlign: 'left' }} key={val.name}>
          {`${val.name} - ${val.duration}`}
        </li>
      )
    }
  }

  navigate(toRoute) {
    this.props.history.push(toRoute)
  }

  saveDate(date, target) {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    this.props.dispatch(promptActions.sendDateTime(formattedDate, target));
  }

  saveTime(time, target) {
    const formattedTime = moment(time).startOf('minute').format('THH:mm:ss');
    this.props.dispatch(promptActions.sendDateTime(formattedTime, target));
    this.setState({ [target]: time });
  }

  submitValidation() {
    const { parkerEntranceDate, parkerEntranceTime, validationDateTime, ticketNumber, validationNumber, validationSource } = this.props;
    // Catch to make sure they put entrance and exit time.
    if (!parkerEntranceDate || !parkerEntranceTime) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: `Entrance & Exit Time is required`
      }));
    }
    // Check to make sure they put the ticket validation date.
    if (this.props.from.validationDateTime && !validationDateTime) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: 'Please enter the Ticket Validation Date.'
      }));
    }
    if (this.props.from.validationDateTime && validationDateTime) {
      const today = moment().format('YYYY:MM:DD');
      if (!moment(today, 'YYYY:MM:DD').isSame(moment(validationDateTime, 'YYYY:MM:DD'))) {
        return this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'This validation is Invalid, please click Invalid Validation to proceed.'
        }));
      }
    }
    if (this.props.from.validationTicketNumber && !ticketNumber) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: 'Please enter the Ticket Number.'
      }));
    }
    if (!this.state.hideValidationNumber && !validationNumber) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: `Validation Number is required.`
      }));
    }
    if (!validationSource) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: `Validation Source is required.`
      }));
    }
    // If it gets this far without any of the checks returning and showing a snackbar, we are good and let's go to vend that gate...
    if (this.props.from.hideInvalidValidation) {
      this.props.history.push('/contactManagers')
    } else {
      this.navigate('/vendGate');
    }
  }

  showComponent(toRender) {
    const allComponents = { ...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'showNoManagerResponseNote':
        this.showComponent(['showNoManagerResponseNote']);
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  render() {
    if (this.props.from.laneType === "Exit" || this.props.from.laneType == 'Paystation') {
      // This is basically a copy and paste of the component rendered in "else", except we are going to leverage the new "full" key in the validationArray locVar.
      // We are going to display the full and partial validations in a more clear way.
      if (this.props.from.parcs === 'tibaAPI') {
        return (
          <ErrorBoundary>
            <div className="home-div">
              <div className="home-div-content">
                <Paper zDepth={1} className="home-div-header-short">
                  <h2>{this.props.from.locationName}</h2>
                </Paper>
                <div className="home-div-body">
                  <Paper zDepth={1} className="paper-padding">

                    {this.state.renderComponent.showValidationSources &&
                      <Fragment>
                        <DirectionText>{this.props.from.validationNote}</DirectionText>
                        {this.props.from.validationArray &&
                          <Paper style={{ padding: 20 }}>
                            <span style={{ textAlign: "left", fontWeight: "bolder" }}>The following locations offer full validations.</span>
                            <ul>
                              {_.map(this.props.from.validationArray, this.displayFullValidations)}
                            </ul>
                            <span style={{ textAlign: "left", fontWeight: "bolder" }}>The following locations only partially validate their tickets!</span>
                            <ul>
                              {_.map(this.props.from.validationArray, this.displayPartialValidations)}
                            </ul>
                          </Paper>
                        }

                        <div style={{ marginTop: 15 }}>
                          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                          {!this.props.from.validationInputAfterManager && !this.props.from.validationScanProblem &&
                            <RaisedButton
                              label="Log Validation Number"
                              onClick={() => this.showComponent(['showValidationForm'])}
                            />
                          }
                          {this.props.from.validationStickers &&
                            <Fragment>
                              <RaisedButton label="No Sticker" onClick={() => this.showComponent(['showNoSticker'])} />
                              <RaisedButton label="Has Sticker" onClick={() => this.showComponent(['showHasSticker'])} />
                            </Fragment>
                          }
                          <RaisedButton label="Contact Manager" onClick={() => this.showComponent(['showManagersList'])} />
                        </div>
                      </Fragment>
                    }

                    {this.state.renderComponent.showNoSticker &&
                      <Fragment>
                        <ScriptText>
                          Please get the ticket validated or pay the parking fee.
                        </ScriptText>
                        <div style={{ marginTop: 15 }}>
                          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                          <RaisedButton label="Contact Manager" onClick={() => this.showComponent(['showManagersListWithCustomButton'])} />
                        </div>
                      </Fragment>
                    }

                    {this.state.renderComponent.showHasSticker &&
                      <Fragment>
                        <ScriptText>
                          Please insert the ticket and let me know if the gate goes up.
                        </ScriptText>
                        <div style={{ marginTop: 15 }}>
                          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                          <RaisedButton label="Gate Didn't Go Up" onClick={() => this.showComponent(['showValidationForm'])} />
                        </div>
                      </Fragment>
                    }

                    {this.state.renderComponent.showNoManagerResponseNote &&
                      <Fragment>
                        <ScriptText>
                          We don't have anyone onsite at the moment so please get the Validation Sticker on their ticket or pay for the parking.
                        </ScriptText>
                        <div style={{ marginTop: 15 }}>
                          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                        </div>
                      </Fragment>
                    }

                    {this.state.renderComponent.showManagersList &&
                      <Fragment>
                        <ContactManagers showButtons={[]} />
                        {!this.props.from.validationInfoNotRequired &&
                          <RaisedButton
                            disabled={!this.props.managerVendConfirmation10s}
                            label="Log Validation Number"
                            onClick={() => this.showComponent(['showValidationForm'])}
                          />
                        }
                      </Fragment>
                    }

                    {this.state.renderComponent.showManagersListWithCustomButton &&
                      <Fragment>
                        <ContactManagers
                          showButtons={['managerAssisting', 'vendGate', 'showCustomButton']}
                          btnClick={this.btnClick}
                          btnFrom="ticketAcceptanceIssue"
                          customButtonData={[
                            {
                              "label": "No Manager Response",
                              "action": "showNoManagerResponseNote"
                            },
                          ]}
                        />
                      </Fragment>
                    }

                    {this.state.renderComponent.showValidationForm &&
                      <Fragment>
                        <DirectionText> Recoard all the parker information and then proceed. </DirectionText>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <Grid style={{ marginTop: 15, padding: 5 }} container spacing={2}>
                            <Grid item xs>
                              <DatePicker
                                label="Entrance Date"
                                format="YYYY-MM-DD"
                                value={this.props.parkerEntranceDate}
                                onChange={date => this.saveDate(date, 'parkerEntranceDate')}
                                style={dateStyles}
                                fullWidth={true}
                                inputProps={{ className: 'data-hj-allow' }}
                              />
                            </Grid>
                            <Grid item xs>
                              <TimePicker
                                ampm={false}
                                openTo="hours"
                                views={["hours", "minutes"]}
                                format="HH:mm"
                                value={this.state.parkerEntranceTime}
                                onChange={time => this.saveTime(time, 'parkerEntranceTime')}
                                label="Entrance Time"
                                style={dateStyles}
                                fullWidth={true}
                                inputProps={{ className: 'data-hj-allow' }}
                              />
                            </Grid>
                            <Grid item xs>
                              <DatePicker
                                format="YYYY-MM-DD"
                                value={this.props.parkerExitDate || new Date()}
                                onChange={date => this.saveDate(date, 'parkerExitDate')}
                                label="Exit Date"
                                style={dateStyles}
                                fullWidth={true}
                                inputProps={{ className: 'data-hj-allow' }}
                              />
                            </Grid>
                            <Grid item xs>
                              <TimePicker
                                ampm={false}
                                openTo="hours"
                                views={["hours", "minutes"]}
                                format="HH:mm"
                                value={this.state.parkerExitTime || new Date()}
                                onChange={time => this.saveTime(time, 'parkerExitTime')}
                                label="Exit Time"
                                style={dateStyles}
                                fullWidth={true}
                                inputProps={{ className: 'data-hj-allow' }}
                              />
                            </Grid>
                          </Grid>
                        </MuiPickersUtilsProvider>

                        {this.props.from.validationDateTime &&
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Grid style={{ marginTop: 15, padding: 5 }} container spacing={2}>
                              <Grid item xs>
                                <DatePicker
                                  label="Validation Sticker Date"
                                  format="YYYY-MM-DD"
                                  value={this.props.validationDateTime}
                                  onChange={date => this.saveDate(date, 'validationDateTime')}
                                  style={dateStyles}
                                  fullWidth={true}
                                  inputProps={{ className: 'data-hj-allow' }}
                                />
                              </Grid>
                            </Grid>
                          </MuiPickersUtilsProvider>
                        }

                        {this.props.from.validationTicketNumber &&
                          <TextField
                            inputProps={{ className: 'data-hj-allow' }}
                            floatingLabelText="Ticket Number"
                            hintText="Ticket Number"
                            style={{ width: '98%' }}
                            onChange={(e, v) => { this.updateField(e, v, 'ticketNumber') }}
                          />
                        }

                        {/* Hide Validation when user clicks on Forgot Validation button */}
                        {!this.state.hideValidationNumber &&
                          <TextField
                            inputProps={{ className: 'data-hj-allow' }}
                            floatingLabelText="Validation Number"
                            hintText="Validation Number"
                            style={{ width: '98%' }}
                            onChange={(e, v) => { this.updateField(e, v, 'validationNumber') }}
                          />
                        }

                        <TextField
                          inputProps={{ className: 'data-hj-allow' }}
                          floatingLabelText="Validation Source"
                          hintText="Validation Source"
                          style={{ width: '98%' }}
                          onChange={(e, v) => { this.updateField(e, v, 'validationSource') }}
                        />

                        <div style={{ marginTop: 15 }}>
                          <RaisedButton label="Submit Validation" onClick={() => this.submitValidation()} />
                          <RaisedButton label="Invalid Validation" onClick={() => this.showComponent(['showNoSticker'])} />
                          <RaisedButton label="Confirmation Screen" onClick={() => this.props.history.push('/confirmationScreen')} />
                        </div>
                      </Fragment>
                    }
                  </Paper>
                </div>
              </div>
            </div>
          </ErrorBoundary>
        )
      } else {
        return (
          <ErrorBoundary>
            <div className="home-div">
              <div className="home-div-content">
                <Paper zDepth={1} className="home-div-header-short">
                  <h2>{this.props.from.locationName}</h2>
                </Paper>
                <div className="home-div-body">
                  <Paper zDepth={1} className="paper-padding">

                    {this.state.renderComponent.showValidationSources &&
                      <Fragment>
                        <DirectionText>{this.props.from.validationNote}</DirectionText>
                        {!this.props.from.hidePartialValidationNote &&
                          <DirectionText>
                            <span style={{ color: 'red', fontWeight: 'bolder' }} >Note: </span>
                            If the machine is charging them an amount/ the gate didn't open that means their ticket has partial validation and they have to pay the remaining fee.
                          </DirectionText>
                        }

                        {this.props.from.validationArray &&
                          <Paper style={{ padding: 20 }}>
                            <span style={{ textAlign: "left", fontWeight: "bolder" }}> Validation Sources </span>
                            <ul>
                              {_.map(this.props.from.validationArray, this.displayValidationArray)}
                            </ul>
                          </Paper>
                        }

                        <div style={{ marginTop: 15 }}>
                          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                          {(!this.props.from.validationInputAfterManager && !this.props.from.validationScanProblem && !this.props.from.validationInfoNotRequired) &&
                            <RaisedButton
                              label="Log Validation Number"
                              onClick={() => this.showComponent(['showValidationForm'])}
                            />
                          }
                          {this.props.from.validationStickers &&
                            <Fragment>
                              <RaisedButton label="No Sticker" onClick={() => this.showComponent(['showNoSticker'])} />
                              <RaisedButton label="Has Sticker" onClick={() => this.showComponent(['showHasSticker'])} />
                            </Fragment>
                          }
                          <RaisedButton label="Contact Manager" onClick={() => this.showComponent(['showManagersList'])} />
                        </div>
                      </Fragment>
                    }

                    {this.state.renderComponent.showNoSticker &&
                      <Fragment>
                        <ScriptText>
                          Please get the ticket validated or pay the parking fee.
                        </ScriptText>
                        <div style={{ marginTop: 15 }}>
                          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                          <RaisedButton label="Contact Manager" onClick={() => this.showComponent(['showManagersListWithCustomButton'])} />
                        </div>
                      </Fragment>
                    }

                    {this.state.renderComponent.showHasSticker &&
                      <Fragment>
                        <ScriptText>
                          Please insert the ticket and let me know if the gate goes up.
                        </ScriptText>
                        <div style={{ marginTop: 15 }}>
                          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                          <RaisedButton label="Gate Didn't Go Up" onClick={() => this.showComponent(['showValidationForm'])} />
                        </div>
                      </Fragment>
                    }

                    {this.state.renderComponent.showNoManagerResponseNote &&
                      <Fragment>
                        <ScriptText>
                          We don't have anyone onsite at the moment so please get the Validation Sticker on the ticket or pay for the parking.
                        </ScriptText>
                        <div style={{ marginTop: 15 }}>
                          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                        </div>
                      </Fragment>
                    }

                    {this.state.renderComponent.showManagersList &&
                      <Fragment>
                        <ContactManagers showButtons={['vendGate', 'managerAssisting', 'ticketDiscrepancy']} />
                        {!this.props.from.validationInfoNotRequired &&
                          <RaisedButton
                            disabled={!this.props.managerVendConfirmation10s}
                            label="Log Validation Number"
                            onClick={() => this.showComponent(['showValidationForm'])}
                          />
                        }
                      </Fragment>
                    }

                    {this.state.renderComponent.showManagersListWithCustomButton &&
                      <Fragment>
                        <ContactManagers
                          showButtons={['managerAssisting', 'vendGate', 'showCustomButton']}
                          btnClick={this.btnClick}
                          btnFrom="ticketAcceptanceIssue"
                          customButtonData={[
                            {
                              "label": "No Manager Response",
                              "action": "showNoManagerResponseNote"
                            },
                          ]}
                        />
                      </Fragment>
                    }

                    {this.state.renderComponent.showValidationForm &&
                      <Fragment>
                        <DirectionText> Recoard all the parker information and then proceed. </DirectionText>
                        {this.props.from.validationArray &&
                          <Paper style={{ padding: 20 }}>
                            <span style={{ textAlign: "left", fontWeight: "bolder" }}> Validation Sources </span>
                            <ul>
                              {_.map(this.props.from.validationArray, this.displayValidationArray)}
                            </ul>
                          </Paper>
                        }
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <Grid style={{ marginTop: 15, padding: 5 }} container spacing={2}>
                            <Grid item xs>
                              <DatePicker
                                label="Entrance Date"
                                format="YYYY-MM-DD"
                                value={this.props.parkerEntranceDate}
                                onChange={date => this.saveDate(date, 'parkerEntranceDate')}
                                style={dateStyles}
                                fullWidth={true}
                                inputProps={{ className: 'data-hj-allow' }}
                              />
                            </Grid>
                            <Grid item xs>
                              <TimePicker
                                ampm={false}
                                openTo="hours"
                                views={["hours", "minutes"]}
                                format="HH:mm"
                                value={this.state.parkerEntranceTime}
                                onChange={time => this.saveTime(time, 'parkerEntranceTime')}
                                label="Entrance Time"
                                style={dateStyles}
                                fullWidth={true}
                                inputProps={{ className: 'data-hj-allow' }}
                              />
                            </Grid>
                            <Grid item xs>
                              <DatePicker
                                format="YYYY-MM-DD"
                                value={this.props.parkerExitDate || new Date()}
                                onChange={date => this.saveDate(date, 'parkerExitDate')}
                                label="Exit Date"
                                style={dateStyles}
                                fullWidth={true}
                                inputProps={{ className: 'data-hj-allow' }}
                              />
                            </Grid>
                            <Grid item xs>
                              <TimePicker
                                ampm={false}
                                openTo="hours"
                                views={["hours", "minutes"]}
                                format="HH:mm"
                                value={this.state.parkerExitTime || new Date()}
                                onChange={time => this.saveTime(time, 'parkerExitTime')}
                                label="Exit Time"
                                style={dateStyles}
                                fullWidth={true}
                                inputProps={{ className: 'data-hj-allow' }}
                              />
                            </Grid>
                          </Grid>
                        </MuiPickersUtilsProvider>

                        {this.props.from.validationDateTime &&
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Grid style={{ marginTop: 15, padding: 5 }} container spacing={2}>
                              <Grid item xs>
                                <DatePicker
                                  label="Validation Sticker Date"
                                  format="YYYY-MM-DD"
                                  value={this.props.validationDateTime}
                                  onChange={date => this.saveDate(date, 'validationDateTime')}
                                  style={dateStyles}
                                  fullWidth={true}
                                  inputProps={{ className: 'data-hj-allow' }}
                                />
                              </Grid>
                            </Grid>
                          </MuiPickersUtilsProvider>
                        }

                        {this.props.from.validationTicketNumber &&
                          <TextField
                            inputProps={{ className: 'data-hj-allow' }}
                            floatingLabelText="Ticket Number"
                            hintText="Ticket Number"
                            style={{ width: '98%' }}
                            onChange={(e, v) => { this.updateField(e, v, 'ticketNumber') }}
                          />
                        }

                        {/* Hide Validation when user clicks on Forgot Validation button */}
                        {!this.state.hideValidationNumber &&
                          <TextField
                            inputProps={{ className: 'data-hj-allow' }}
                            floatingLabelText="Validation Number"
                            hintText="Validation Number"
                            style={{ width: '98%' }}
                            onChange={(e, v) => { this.updateField(e, v, 'validationNumber') }}
                          />
                        }

                        <TextField
                          inputProps={{ className: 'data-hj-allow' }}
                          floatingLabelText="Validation Source"
                          hintText="Validation Source"
                          style={{ width: '98%' }}
                          onChange={(e, v) => { this.updateField(e, v, 'validationSource') }}
                        />

                        <div style={{ marginTop: 15 }}>
                          <RaisedButton label="Submit Validation" onClick={() => this.submitValidation()} />
                          {!this.props.from.hideInvalidValidation && <RaisedButton label="Invalid Validation" onClick={() => this.showComponent(['showNoSticker'])} />}
                          <RaisedButton label="Confirmation Screen" onClick={() => this.props.history.push('/confirmationScreen')} />
                        </div>
                      </Fragment>
                    }
                  </Paper>
                </div>
              </div>
            </div>
          </ErrorBoundary>
        )
      }
    }

    if (this.props.from.laneType === "Entrance") {
      return (
        <PromptBox locationName={this.props.from.locationName}>
          <DirectionText>Please pull a ticket to enter the parking area & get it validated before you exit.</DirectionText>
          <Link to="/confirmationScreen">
            <RaisedButton label="Complete Call" />
          </Link>
        </PromptBox>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    validationNumber: promptSelectors.pullModular(state, 'validationNumber'),
    validationSource: promptSelectors.pullModular(state, 'validationSource'),
    ticketNumber: promptSelectors.pullModular(state, 'ticketNumber'),
    managerVendConfirmation10s: promptSelectors.pullModular(state, 'managerVendConfirmation10s'),
    parkerEntranceDate: promptSelectors.pullModular(state, 'parkerEntranceDate'),
    parkerEntranceTime: promptSelectors.pullModular(state, 'parkerEntranceTime'),
    parkerExitDate: promptSelectors.pullModular(state, 'parkerExitDate'),
    parkerExitTime: promptSelectors.pullModular(state, 'parkerExitTime'),
    validationDateTime: promptSelectors.pullModular(state, 'validationDateTime')
  };
}

export default withRouter(connect(mapStateToProps)(ValidationSplit));
