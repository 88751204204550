import { retrieveLogger } from '../lib/logger';
let logger = retrieveLogger();
const axiosInstance = require('../lib/axiosInstance');

class MonthlyService {
  async updateMonthlies(locId) {
    const monthlyParkers = await axiosInstance({
      method: 'post',
      url: `/updateMonthlies`,
      data: {'locId': locId}
    }).then((data) => {
      return data.data;
    }).catch((error) => {
      logger.error(error);
    });
    return monthlyParkers;
  }
}
  
  export default new MonthlyService();
  