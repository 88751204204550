import React, { Component } from 'react';
import autoBind from 'react-autobind';
// eslint-disable-next-line
import DirectionText from './DirectionText';
import Paper from 'material-ui/Paper';
import { Link } from 'react-router-dom';
import RaisedButton from 'material-ui/RaisedButton';

export default class ParkWestinPrompt extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.locationName}</h2>
          </Paper>
          <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
            <h3>
              <i>Caller found our number on ParkWestin.com</i>
            </h3>
            <h4>Talking points for questions they may have:</h4>
            <ul className="park-westin-ul">
              <li className="bullet-padding">You save money reserving at ParkWestin.com as we charge no reservation fees</li>
              <li className="bullet-padding">You can cancel at any time - just call or message us via ParkWestin.com</li>
              <li className="bullet-padding">We are open 24/7</li>
              <li className="bullet-padding">We are available 24/7 for any questions, comments, or concerns</li>
              <li className="bullet-padding">You pay at the time you book, not at the facility</li>
              <li className="bullet-padding">You may check in anytime, but must check out by 2:00PM</li>
              <li className="bullet-padding">
                Direct parkers to search for <b>"The Westin O'Hare"</b> or <b>"6100 North River Road, Rosemont, IL 60018"</b> on their phone's navigation to find the parking lot.
              </li>
            </ul>
          </Paper>
          <Paper zDepth={1} className="paper-padding prompt-body">
            <p>If caller asks who we are,</p>
            <p>
              <b>We are Cloudpark Remote Management Facility and run ParkWestin.com</b>
            </p>
            <Link to="/confirmationScreen">
              <RaisedButton label="End Call" />
            </Link>
          </Paper>
        </div>
      </div>
    );
  }
}
