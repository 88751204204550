import { showSnackBar } from "../../../../store/prompt/actions";


export const shouldDisable = (managerCalledFor10s, managerCalled, isParkerCallOnHold) => {
  if (!managerCalledFor10s) {
    return 'You must call at least one manager for at least 10 seconds before you can continue';
  } 
  if (managerCalled) {
    return 'You must end the manager call before you can continue';
  } 
  if (isParkerCallOnHold) {
    return 'You must return to the parker call before you can continue';
  }
  return false;
}

export const disableContinue = ({ dispatch, isParkerCallOnHold, action, managerCalledFor10s, managerCalled }) => {
  const shouldDisableMessage = shouldDisable(managerCalledFor10s, managerCalled, isParkerCallOnHold);
  if (shouldDisableMessage) {
    return dispatch(showSnackBar({
      type: 'error', 
      message: shouldDisableMessage
    }))
  } else {
    action();
  }

}

