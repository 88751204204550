import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as authSelectors from '../../../store/auth/reducer';
import Paper from 'material-ui/Paper';
import DirectionText from '../../../components/DirectionText';
import ErrorBoundary from '../../ErrorBoundary';

class PriceInput extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <div className="home-div">
          <div className="home-div">
            <div className="home-div-content">
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <div className="home-div-body">
                <Paper zDepth={1} className="paper-padding">
                  <DirectionText>
                    Please manually input the Lost Ticket into the TIBA system
                    using the following instructions.
                  </DirectionText>
                  <ol>
                    <li>
                      Please visit{' '}
                      <a
                        href="http://secure.logmein.com"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        LogMeIn
                      </a>{' '}
                      and navigate to The Morgan at Provost Square.
                    </li>
                    <li>
                      Navigate through the steps to achieve a remote connection to
                      The Morgan at Provost Square's TIBA computer.
                    </li>
                    <li>Right Click on the Exit Gobbler</li>
                    <li>Select Set Price</li>
                    <li>Enter Amount</li>
                  </ol>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username')
  };
}

export default withRouter(connect(mapStateToProps)(PriceInput));
