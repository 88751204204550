import React from 'react';
import { connect } from 'react-redux';
import ScriptText from '../../../../components/ScriptText';
import { pullModular as promptPullModular } from '../../../../store/prompt/reducer';

export const PullATicket = (props) => {

  return (
    <div>
        {props.from.ticketlessLocation ?
        <ScriptText>Please use your credit/debit card to enter the facility</ScriptText>
        :
        <ScriptText>Please pull a ticket and enter the facility.</ScriptText>}
    </div>
  )

}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from')
  }
}

export default (connect(mapStateToProps)(PullATicket));
