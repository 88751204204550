import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptSelectors from '../../store/prompt/reducer';
import * as promptActions from '../../store/prompt/actions';
import * as authSelectors from '../../store/auth/reducer';
import _ from 'lodash';
import { Typography, TextField, Select, OutlinedInput } from '@material-ui/core';
import RaisedButton from 'material-ui/RaisedButton';
import DirectionText from '../../components/DirectionText';
import moment from 'moment-timezone';

class ContactManagerInfo extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    } 
  }
  
  getTime() {
    const { parkerEntranceDate, parkerEntranceTime } = this.props;
    if (parkerEntranceDate && parkerEntranceTime) {
      const splitEntry = parkerEntranceDate.split(":");
      const splitEixt = parkerEntranceTime.split(":");
      return splitEntry[0] + "-" + splitEntry[1] + "-" + splitEntry[2] + splitEixt[0] + ":" + splitEixt[1];
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  updateField(event, target) {
    this.props.dispatch(promptActions.modularSendField(event.target.value, target));
  }

  renderTextFields(data) {
    if (data.field == "parkerEntranceTime") {
      return (
        <TextField
          id="datetime-local"
          label={data.label}
          type="datetime-local"
          inputProps={{ className: 'data-hj-allow' }}
          InputLabelProps={{
            shrink: true,
          }}
          value={this.getTime()}
          style={{ width: '100%', marginTop: 15 }}
          onChange={(event) => {this.updateField(event, data.field)}}
        />
      )
    } else {
      return (
        <TextField
          label={data.label}
          margin="dense"
          variant="outlined"
          inputProps={{ className: 'data-hj-allow' }}
          InputLabelProps={{
            shrink: true,
          }}
          value={this.props[data.field] || ""}
          onChange={(event) => {this.updateField(event, data.field)}}
          style={{ width: '100%', marginTop: 15 }}
        />
      )
    }
  }

  renderButtons(data) {
    return (
      <RaisedButton label={data.label} onClick={() => this.btnClick(data.action)} />
    )
  }

  renderForm(formData) {
    const { textfields, buttons } = formData;
    return (
      <Fragment>
        {_.map(textfields, this.renderTextFields)}
        <div style={{ marginTop: 15 }}>
          {_.map(buttons, this.renderButtons)}
        </div>
      </Fragment>
    )
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case "submitForm":
        const { formData, selectedParkerName, machineMessage, ticketNumber, validationSource, validationDuration, entranceDateTime, parkerFeeDue } = this.props;
        const textFields = formData.textfields.map(data => { return data.field});
        if (textFields.includes('parkerName') && !selectedParkerName) {
          return this.props.dispatch(promptActions.showSnackBar({type: 'error', message: 'Parker Name is required.' }));
        } else if (textFields.includes('ticketNumber') && !ticketNumber)  {
          return this.props.dispatch(promptActions.showSnackBar({type: 'error', message: 'Ticket Number is required.' }));
        } else if (textFields.includes('parkerFeeDue') && !parkerFeeDue)  {
          return this.props.dispatch(promptActions.showSnackBar({type: 'error', message: 'Fee Due is required.' }));
        } else if (textFields.includes('entranceDateTime') && !entranceDateTime)  {
          return this.props.dispatch(promptActions.showSnackBar({type: 'error', message: 'Entrance Time is required.' }));
        } else if (textFields.includes('validationSource') && !validationSource)  {
          return this.props.dispatch(promptActions.showSnackBar({type: 'error', message: 'Validation Source is required.' }));
        } else if (textFields.includes('validationDuration') && !validationDuration)  {
          return this.props.dispatch(promptActions.showSnackBar({type: 'error', message: 'Validation Duration is required.' }));
        } else if (textFields.includes('machineMessage') && !machineMessage)  {
          return this.props.dispatch(promptActions.showSnackBar({type: 'error', message: 'Message on the machine is required.' }));
        } else {
          this.props.showContactManager();
        }
        break;
      default: 
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
        break;
    }
  }

  render() {
    return (
      <Fragment>
        <DirectionText> Please take the information below then contact managers. </DirectionText>
        {this.renderForm(this.props.formData)}
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username'),
    selectedParkerName: promptSelectors.pullModular(state, 'selectedParkerName'),
    ticketNumber: promptSelectors.pullModular(state, 'ticketNumber'),
    validationSource: promptSelectors.pullModular(state, 'validationSource'),
    validationDuration: promptSelectors.pullModular(state, 'validationDuration'),
    parkerFeeDue: promptSelectors.pullModular(state, 'parkerFeeDue'),
    machineMessage: promptSelectors.pullModular(state, 'machineMessage'),
    parkerEntranceTime: promptSelectors.pullModular(state, 'parkerEntranceTime'),
    parkerEntranceDate: promptSelectors.pullModular(state, 'parkerEntranceDate'),
    parkerEntranceTime: promptSelectors.pullModular(state, 'parkerEntranceTime')
  };
}

export default withRouter(connect(mapStateToProps)(ContactManagerInfo));