import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import { Grid, Paper, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import * as authActions from '../../store/auth/actions';
import * as authSelectors from '../../store/auth/reducer';
import * as promptSelectors from '../../store/prompt/reducer';
import * as queueSelectors from '../../store/queue/reducer';
import Tooltip from '@material-ui/core/Tooltip';

class ReportIssue extends Component {
  
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { disableSubmit: true };
  }

  toggleModal(event, toggleState) {
    if(toggleState === false) {
      this.props.dispatch(authActions.modularSendField(true, 'isModalOpen'));
    } else {
      this.props.dispatch(authActions.modularSendField(false, 'isModalOpen'));
    }
  }

  updateField(event, target) {
    const value = event.target.value;
    this.props.dispatch(authActions.modularSendField(value, target));
    this.setState({ disableSubmit: false });
    if (!value) {
      this.props.dispatch(authActions.modularSendField(value, 'reportedIssueNotes'));
      this.setState({ disableSubmit: true });
    }
  }

  saveReportedIssue(event) {
    const currentAppState = this.props;   
    this.props.dispatch(authActions.saveReportedIssue(currentAppState));
    this.setState({ disableSubmit: true });
  }

  render() {
    return (
      <Fragment>
          <Tooltip title="Report a Bug" position="top">            
            <Button color="secondary" aria-label="Report Bug" onClick={(event) => this.toggleModal(event, this.props.isModalOpen)}>
              <i className="material-icons" style={{ color: '#ff6666' }}> bug_report </i>
            </Button>      
          </Tooltip>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.props.isModalOpen}
            style={{ width: '50%',  height: '100%',  margin: '0 auto', marginTop: 50 }}
          >          
            <Paper style={{ padding: 30 }}>
              <Paper style={{ padding: 30, textAlign: 'center', fontWeight: 'bolder' }}>
                Submit an Issue
              </Paper>
              
              <Grid container style={{ marginTop: 30, marginBottom: 30 }}>
                <Grid item xs={12}>
                  <TextField
                    required                
                    margin="dense"
                    label="Notes"
                    multiline
                    rows="6"
                    placeholder="Please describe the issue.."
                    variant="outlined"
                    inputProps={{ className: 'data-hj-allow' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => this.updateField(event, 'reportedIssueNotes')}               
                    style={{ width: '100%', marginTop: 15, marginBottom: 10 }}
                  />
                </Grid>
              </Grid>
              
              <Grid container>
                <Grid item xs={6} style={{ textAlign: 'left' }}>
                  <Button 
                    variant="contained" 
                    color="secondary"
                    onClick={(event) => this.toggleModal(event, this.props.isModalOpen)}
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <Button 
                    variant="contained" 
                    color="primary"
                    disabled={this.state.disableSubmit}
                    onClick={(event) => this.saveReportedIssue(event)}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Modal>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    authState: authSelectors.pullState(state),
    promptState: promptSelectors.pullState(state),
    queueState: queueSelectors.pullState(state),
    reportedIssueNotes: authSelectors.pullModular(state, 'reportedIssueNotes'),
    isModalOpen: authSelectors.pullModular(state, 'isModalOpen')
  };
}

export default withRouter(connect(mapStateToProps)(ReportIssue));