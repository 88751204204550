import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as authSelectors from '../../../store/auth/reducer';
import * as promptActions from '../../../store/prompt/actions';
import RaisedButton from 'material-ui/RaisedButton';
import _ from 'lodash';
import ContactManagers from '../ContactManagers';
import ScriptText from '../../../components/ScriptText';
import DirectionText from '../../../components/DirectionText';
import PromptBox from '../../../components/promptBox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ErrorBoundary from '../../ErrorBoundary';

class RateDispute extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      renderComponent: {
        showManagersList: true,
        showCustomNoManagerResponse: false
      }
    };
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }

    if (this.props.from.rateDisputeCalculateFee) {
      this.props.history.push('/tibaAPI/tibaRateByTicket');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  showComponent(toRender) {
    const allComponents = { ...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'showCustomNoManagerResponse':
        this.showComponent(['showCustomNoManagerResponse']);
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  render() {
    if (this.props.from.rateDisputeManager) {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className="home-div-content">
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <div className="home-div-body">
                {this.state.renderComponent.showManagersList &&
                  <Fragment>
                    <ScriptText>
                      This location does not allow Cloudpark Command Center to handle rate disputes. You will need to pay the full amount and request reimbursement through the
                      facility's manager.
                    </ScriptText>
                    <ContactManagers showButtons={
                      [
                        'vendGate', 'managerAssisting',
                        `${this.props.from.rateDisputeManagerTicketDiscrepancy && 'ticketDiscrepancy'}`,
                        `${this.props.from.rateDisputeManagerCustomButton && 'showCustomButton'}`
                      ]
                    }
                      btnClick={this.btnClick}
                      btnFrom="rateDispute"
                      customButtonData={this.props.from.rateDisputeManagerCustomButton
                      ?
                        [
                          {
                            "label": "No Manager Response",
                            "action": "showCustomNoManagerResponse"
                          }
                        ]
                      :
                        null
                      }
                    />
                  </Fragment>
                }

                {this.state.renderComponent.showCustomNoManagerResponse &&
                  <Fragment>
                    <ScriptText>
                      We were not able to contact the managers. Please take the facility manager’s information down and follow up with them regarding your rate dispute.
                    </ScriptText>
                    <Paper style={{ marginBottom: 20 }}>
                      <Table>
                        <TableHead style={{ backgroundColor: '#297EB6' }}>
                          <TableRow>
                            <TableCell style={{ color: 'White', fontWeight: 'bolder' }} align="left"> Name </TableCell>
                            <TableCell style={{ color: 'White', fontWeight: 'bolder' }} align="left"> Phone Number </TableCell>
                            <TableCell style={{ color: 'White', fontWeight: 'bolder' }} align="left"> Email </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {_.map(this.props.from.managers, (v, i, c) => {
                            return (
                              <TableRow>
                                <TableCell component="th" scope="row">{v.name} </TableCell>
                                <TableCell align="left">({v.number})</TableCell>
                                <TableCell align="left">({v.email})</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Paper>
                    <div style={{ marginTop: 15 }}>
                      <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                    </div>
                  </Fragment>
                }
              </div>
            </div>
          </div>
        </ErrorBoundary>
      );
    } else {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className="home-div-content">
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <div className="home-div-body">
                <Paper zDepth={1} className="paper-padding">
                  {this.props.from.rateDisputeNote
                  ?
                    (
                      <ScriptText>
                        {this.props.from.rateDisputeNote}
                      </ScriptText>
                    )
                  :
                    (
                      <ScriptText>
                        This location does not allow Cloudpark Command Center to handle rate disputes. You will need to pay the full amount and request reimbursement through the
                        facility's manager.
                      </ScriptText>
                    )
                  }
                  {this.props.from.rateDisputeAdditionalText &&
                    <ScriptText>
                      {this.props.from.rateDisputeAdditionalText}
                    </ScriptText>
                  }
                  <ContactManagers showButtons={['ticketDiscrepancy']} />
                </Paper>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    managerVendConfirmation10s: promptSelectors.pullModular(state, 'managerVendConfirmation10s')
  };
}

export default withRouter(connect(mapStateToProps)(RateDispute));
