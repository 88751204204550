import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import { TextField } from '@material-ui/core';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as queueSelectors from '../../../store/queue/reducer';
import * as promptActions from '../../../store/prompt/actions';
import * as authSelectors from '../../../store/auth/reducer';
import ContactManagers from '../../Prompt/ContactManagers';
import ScriptText from '../../../components/ScriptText';
import DirectionText from '../../../components/DirectionText';
import PromptBox from '../../../components/promptBox';
import RaisedButton from 'material-ui/RaisedButton';
import _ from 'lodash';

class TibaLPRSearch extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    const loadOptions = inputValue => this.tibaGetLicensePlate(inputValue);
    this.debouncedLoadOptions = _.debounce(loadOptions, 1000, {
      leading: true
    });

    this.state = {
      renderComponent: {
        showLostTicketFeeForm: true,
        showlicensePlateNotFound: false,
        showLostTicketCalculationResult: false,
      },
      licensePlate: null,
      lostTicketNumber: null
    }
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  updateState(event, target) {
    this.setState({ [target]: event.target.value });
  }

  async tibaGetLicensePlate(value) {
    return new Promise(async (resolve, reject) => {
      const result = await this.props.dispatch(promptActions.tibaGetLicensePlate({ licensePlate: value, locationIdParcsDb: this.props.from.locationIdParcsDb }));
      const { success, lprData, message } = result.data;
      if (success && lprData) {
        const filtered = _.filter(lprData, option => option.label);
        resolve(filtered);
      } else {
        this.props.dispatch(promptActions.showSnackBar({ type: 'error', message: message }));
      }
    });
  }

  async saveLicensePlate(data) {
    if (!data) {
      return;
    }
    await this.setState({ 
      licensePlate: data.label,
      lostTicketNumber: data.value
    });
  }

  async LPRNotFound() {
    if (this.props.issue.subtype == 'lostTicket') {
      this.showComponent(['showlicensePlateNotFound'])
    } else {
      this.props.history.push('/tibaAPI/tibaRateByEntryTime');
    }
  }

  async getLostTicketRate() {
    this.props.dispatch(promptActions.modularSendField(true, 'showLoader'));
    const { licensePlate, lostTicketNumber } = this.state;
    if (!licensePlate) {
      this.props.dispatch(promptActions.modularSendField(false, 'showLoader'));
      return this.props.dispatch(promptActions.showSnackBar({ type: 'error', message: `Licese Plate is required.` }));
    } else if (!lostTicketNumber) {
      this.props.dispatch(promptActions.modularSendField(false, 'showLoader'));
      if (this.props.issue.subtype == 'lostTicket') {
        this.showComponent(['showlicensePlateNotFound']);
      } else {
        return this.props.dispatch(promptActions.showSnackBar({ type: 'error', message: `A ticket Number for this Licese Plate was not found.` }));
      } 
    } else {
      const { apiParams, rateList, gracePeriodInt, timezone, baseURL } = this.props.from;
      const data = { apiParams, rateList, gracePeriodInt, ticketNumber: lostTicketNumber, timezone, baseURL }; 
      const result = await this.props.dispatch(promptActions.tibaGetTicketInfo(data));
      const { success, rate, message, shouldVend, entryDate, notFound } = result.data;
      const formattedEntryDate = moment(entryDate, 'YYYY:MM:DD HH:mm:ss').format('LLLL');
      if (notFound) {
        if (this.props.issue.subtype == 'lostTicket') {
          this.showComponent(['showlicensePlateNotFound']);
        } else {
          this.props.dispatch(promptActions.modularSendField(false, 'showLoader'));
          return this.props.dispatch(promptActions.showSnackBar({ type: 'error', message: `A ticket Number for this Licese Plate was not found.` }));
        }
      } else if (success && shouldVend) {
        this.setState({ calculatedParkerRate: null, shouldVend: true });
        this.showComponent(['showLostTicketCalculationResult']);
      } else if (success && !shouldVend) {
        this.setState({ shouldVend: false, calculatedParkerRate: rate, parkerEntryDateResult: formattedEntryDate });
        this.props.dispatch(promptActions.modularSendField({ rate, shouldVend, parkerEntryDateResult: formattedEntryDate} , 'tibaRateResult'));
        this.showComponent(['showLostTicketCalculationResult']);
      } else {
        this.showComponent(['showlicensePlateNotFound']);
      }
    }
    this.props.dispatch(promptActions.modularSendField(false, 'showLoader'));
  }

  async pushRateToMachine(pushRateType) {
    const { apiParams, controlDeviceData, lostTicketPushRate, baseURL } = this.props.from;
    const { ongoingCallId, username } = this.props;
    const rateToPush = pushRateType == 'lostTicket' ? lostTicketPushRate : this.state.calculatedParkerRate;
    const data = { username, ongoingCallId, apiParams, baseURL, commandType: controlDeviceData.pushRateNewTicket, 
      actionId: controlDeviceData.pushRateDBId, controlDeviceData, rateToPush
    };
    const result = await this.props.dispatch(promptActions.tibaSendControlDeviceAction(data));
    const { success, message } = result.data;
    if (success) {
      this.props.dispatch(promptActions.showSnackBar({ 
        type: 'success', message: `Rate $${rateToPush} pushed to device, if parker denies payment contact managers.` 
      }));
    } else if (!success) {
      this.props.dispatch(promptActions.showSnackBar({ 
        type: 'error', message: message
      }));
    }
  }

  showComponent(toRender) {
    const allComponents = {...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'showPushRateForm':
        this.props.history.push('/tibaAPI/tibaManagerPushRate');
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  render() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        {this.state.renderComponent.showLostTicketFeeForm &&
          <Fragment>
            <ScriptText>
              May I please have your License Plate? 
            </ScriptText>
            <AsyncSelect
              className="MuiOutlinedInput-inputMarginDense max-z-index"
              placeholder="Enter License Plate"
              isClearable
              value={{ value: this.state.licensePlate, label: this.state.licensePlate }}
              loadOptions={inputValue => this.debouncedLoadOptions(inputValue)}
              onChange={this.saveLicensePlate}
            />
            <div style={{ marginTop: 15 }}>
              <RaisedButton label="Submit" onClick={() => this.getLostTicketRate()} />
              <RaisedButton label="Not Found" onClick={() => this.LPRNotFound()} />
              <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
            </div>
          </Fragment>
        }

        {this.state.renderComponent.showlicensePlateNotFound && 
          <Fragment>
            <DirectionText>
              The License Plate/ Ticket Number was not found, please push the Lost Ticket Fee.
            </DirectionText>
            <ScriptText>
              {this.props.from.lostTicketNotes}
            </ScriptText>
            <div style={{ marginTop: 15 }}>
              <RaisedButton label="Push Rate to Machine" onClick={() => this.pushRateToMachine('lostTicket')} />
              <RaisedButton label="Contact Managers" onClick={() => this.showComponent(['showManagersList'])} />
              <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
            </div>
          </Fragment>
        }

        {this.state.renderComponent.showLostTicketCalculationResult && 
          <Fragment>
            {this.state.shouldVend 
            ? 
              <DirectionText>
                The parker is within the Grace Period. Please vend the Gate.
              </DirectionText>
            : 
              <Fragment>
                <DirectionText>
                  The License Plate <span style={{ fontWeight: 'bolder' }}> {this.state.licensePlate} </span> was found. 
                </DirectionText>
                <DirectionText>
                  {this.state.parkerEntryDateResult && `Entry Date Time:`} <span style={{ fontWeight: 'bolder', marginRight: 5 }}>  {this.state.parkerEntryDateResult} </span>
                  {this.state.parkerEntryDateResult && `Ticket Number:`} <span style={{ fontWeight: 'bolder' }}>  {this.state.lostTicketNumber} </span>
                </DirectionText>
                <ScriptText>
                  Please pay the ${this.state.calculatedParkerRate} fee to exit the parking garage.
                </ScriptText>
              </Fragment>
            }
            <div style={{ marginTop: 15 }}>
              {this.state.shouldVend && <RaisedButton label="Vend Gate" onClick={() => this.btnClick('pushTo', 'vendGate')} />}
              {this.props.from.lostTicketPushRate && <RaisedButton label="Push Rate to Machine" onClick={() => this.pushRateToMachine()} />}
              <RaisedButton label="Contact Managers" onClick={() => this.showComponent(['showManagersList'])} />
              <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
            </div>
          </Fragment>
        }

        {this.state.renderComponent.showManagersList && 
          <ContactManagers 
            showButtons={['ticketDiscrepancy', 'vendGate', 'managerAssisting', 'showCustomButton']} 
            btnClick={this.btnClick}
            btnFrom="lostTicket"
            customButtonData={[
              {
                "label": "Manager Asked to Push Rate",
                "action": "showPushRateForm"
              },
            ]}
          />
        }
      </PromptBox>
    )
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username'),
    ticketNumber: promptSelectors.pullModular(state, 'ticketNumber'),
    ongoingCallId: queueSelectors.pullModular(state, 'ongoingCallId'),
  };
}

export default withRouter(connect(mapStateToProps)(TibaLPRSearch));