import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import RaisedButton from 'material-ui/RaisedButton';
import _ from 'lodash';
import { Grid, TextField, Paper } from '@material-ui/core';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as promptActions from '../../../store/prompt/actions';
import * as authSelectors from '../../../store/auth/reducer';
import ScriptText from '../../../components/ScriptText';
import PromptBox from '../../../components/promptBox';
import DirectionText from '../../../components/DirectionText';

class PILPaymentResult extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    this.props.dispatch(promptActions.modularSendField(false, 'pilCheckTransactionStatus'));
  }

  render() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        <ScriptText>
          Your payment of ${this.props.pilPaymentAmount} was processed successfully. I am in the process of vending the gate for you.
        </ScriptText>
        <div style={{ marginTop: 15 }}>
          <RaisedButton label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />
          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
        </div>
      </PromptBox>
    )
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    ongoingCallId: promptSelectors.pullModular(state, 'ongoingCallId'),
    pilPaymentAmount: promptSelectors.pullModular(state, 'pilPaymentAmount'),
    pilCheckTransactionStatus: promptSelectors.pullModular(state, 'pilCheckTransactionStatus'),
  };
}

export default withRouter(connect(mapStateToProps)(PILPaymentResult));