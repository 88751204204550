import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import autoBind from "react-autobind";
import { withRouter, Link } from "react-router-dom";
import * as config from "../../../lib/config";
import * as promptSelectors from "../../../store/prompt/reducer";
import * as authSelectors from "../../../store/auth/reducer";
import * as promptActions from "../../../store/prompt/actions";
import Paper from "material-ui/Paper";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import DirectionText from "../../../components/DirectionText";
import RaisedButton from 'material-ui/RaisedButton';
import SocketContext from '../../../services/socketContext'
import ScriptText from '../../../components/ScriptText';
import _ from 'lodash';
import ErrorBoundary from "../../ErrorBoundary";

const paperStyle = {
  display: "inline-block",
  margin: "16px 32px 16px 32px"
};

class MonthlyIssue extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { showMonthlyPrompt: true };
  }

  async componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push("/login");
    }
    this.props.dispatch(promptActions.updateIssueStep(0));
    if (this.props.from.fourOakMonthlyAccess) {
      const shouldShowMonthlyPrompt = await this.props.dispatch(promptActions.fourOakMonthlyAccess());
      if (shouldShowMonthlyPrompt) {
        this.setState({ showMonthlyPrompt: true });
      } else {
        this.setState({ showMonthlyPrompt: false });
      }
    }
    if (this.props.from.monthlyRedirectEntrance) {
      this.setState({ showMonthlyPrompt: false });
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push("/login");
    }
  }

  sendSubtype(type) {
    this.props.dispatch(promptActions.updateSubtype(type));
  }

  render() {
    return (
      <ErrorBoundary>
        <div className="home-div">
          <div className={`home-div-content`}>
            <Paper zDepth={1} className="home-div-header-short">
              <h2>{this.props.from.locationName}</h2>
            </Paper>
            <Paper
              zDepth={1}
              className="paper-padding prompt-body"
              style={{ marginTop: "20px" }}
            >
              {!this.props.from.monthlyLane && (this.props.from.locationId === 647 || this.props.from.locationId === 650)
                ?
                (
                  <Fragment>
                    <DirectionText>
                      {this.props.from.noMonthlyParkerAllowedText}
                    </DirectionText>
                    <Link to="/confirmationScreen">
                      <RaisedButton label="End Call" style={{ margin: '16px 10px' }} />
                    </Link>
                  </Fragment>
                )
                :
                (
                  <Fragment>
                    {this.state.showMonthlyPrompt
                      ?
                      <Fragment>
                        <DirectionText>
                          Which of the following Monthly issues are you experiencing?
                        </DirectionText>
                        <Paper style={paperStyle}>
                          <Menu>
                            <Link
                              to="/monthly/monthlyVerification"
                              style={{ textDecoration: "none" }}
                              onClick={() => {
                                this.sendSubtype("cardAcceptanceIssue");
                              }}
                            >
                              <MenuItem primaryText="Card Acceptance Issue" />
                            </Link>
                            <Link
                              to="/monthly/monthlyVerification"
                              style={{ textDecoration: "none" }}
                              onClick={() => {
                                this.sendSubtype("noCard");
                              }}
                            >
                              <MenuItem primaryText="No Card" />
                            </Link>
                            {this.props.from.genericComponent && !this.props.from.genericComponentDisableMonthly &&
                              <Link
                                to="/genericComponent"
                                style={{ textDecoration: 'none' }}
                                onClick={() => {
                                  this.sendSubtype(_.camelCase(this.props.from.genericComponent));
                                }}
                              >
                                <MenuItem primaryText={this.props.from.genericComponent} />
                              </Link>
                            }
                          </Menu>
                        </Paper>
                      </Fragment>
                      :
                      <Fragment>
                        <ScriptText>
                          {this.props.from.monthlyRedirect}
                        </ScriptText>
                        <div style={{ marginTop: 15 }}>
                          <RaisedButton label="Complete Call" onClick={() => {
                            this.sendSubtype("monthlyRedirect");
                            this.props.history.push('/confirmationScreen');
                          }}
                          />
                        </div>
                      </Fragment>
                    }
                  </Fragment>
                )
              }
            </Paper>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username')
  };
}

const monthlySocket = (props) => (
  <SocketContext.Consumer>
    {socket => <MonthlyIssue {...props} socket={socket} />}
  </SocketContext.Consumer>
)

export default withRouter(connect(mapStateToProps)(monthlySocket));
