import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as promptActions from "../../store/prompt/actions";
import Divider from 'material-ui/Divider';
import Paper from 'material-ui/Paper';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import * as authSelectors from '../../store/auth/reducer';
import * as authActions from '../../store/auth/actions';

const paperStyle = {
    display: 'inline-block',
    margin: '16px 32px 16px 32px',
  };

class AdminPortal extends Component {

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.dispatch(promptActions.modularSendField(false, 'mapLoaded'));
  }

  toggleAdminCall(event) {
    const toggleState = event.target.checked;
    // If toggle true then make the user IDLE
    if(toggleState === true) {
      this.props.dispatch(authActions.modularSendField(true, 'isAdminTakingCalls'));
      this.props.dispatch(authActions.updateWorker(this.props.username, 'Idle'));
    } else {
      this.props.dispatch(authActions.modularSendField(false, 'isAdminTakingCalls'));
      this.props.dispatch(authActions.updateWorker(this.props.username, 'Offline'));
    }
  }

  render() {
    return (
      <div className="home-div">
          <h2>Admin Portal</h2>
          <Divider />
          <Paper style={paperStyle}>
            <Menu>
              <Link to='/passwordReset' style={{textDecoration: 'none'}} >
                <MenuItem primaryText="Password Reset" />
              </Link>
              <Link to='/register' style={{textDecoration: 'none'}} >
                <MenuItem primaryText="Register a User" />
              </Link>
              <Link to='/setNumberMap' style={{textDecoration: 'none'}} >
                <MenuItem primaryText="Set Number Map" />
              </Link>
              <Link to='/updateManager' style={{textDecoration: 'none'}} >
                <MenuItem primaryText="Update Manager List" />
              </Link>
              <Link to='/disableAnalyst' style={{textDecoration: 'none'}} >
                <MenuItem primaryText="Disable/Enable Analyst" />
              </Link>
              <FormControlLabel
                label={`Accepting Call ${this.props.isAdminTakingCalls ? 'Enabled' : 'Disabled' }`}
                labelPlacement="start"
                control={
                  <Switch
                    checked={this.props.isAdminTakingCalls}
                    color="primary"
                    onChange={(event) => this.toggleAdminCall(event)}
                  />
                }
              /> 
            </Menu>
          </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: authSelectors.pullModular(state, 'username'),
    isAdminTakingCalls: authSelectors.pullModular(state, 'isAdminTakingCalls'),
  };
}

export default withRouter(connect(mapStateToProps)(AdminPortal));
