import React, { Fragment } from "react";
import Button from '@mui/material/Button'
import ScriptText from '../../../components/ScriptText';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { pullModular as promptPullModular } from '../../../store/prompt/reducer';
import PromptWrapper from './PromptWrapper';
import { Paper } from "@mui/material";

const PrepaidRedirectPoF = (props) => {
  //No specific Nodes required since this Flow is one Node long
  //If there are no redirect lanes, then it means that prepaid passes aren't enabled here
  return (
    <PromptWrapper>
      { props.from.prepaidRedirectLanes ?
      <Fragment>
      <ScriptText>This paystation doesn't take prepaid reservations, so please use one of the following lanes:</ScriptText>
      <Paper>
        <ul>
        {props.from.prepaidRedirectLanes && props.from.prepaidRedirectLanes.map((redirectLane) => {
          return <li className='prepaid-redirect-lane-list'>{redirectLane}</li>
        })}
        </ul>
      </Paper>
      <Button className='button-style' variant='outlined' onClick={() => {props.history.push('/confirmationScreen')}}>Complete Call</Button>
        </Fragment>
      :
      <Fragment>
        <ScriptText>
          This location does not accept prepaid reservations</ScriptText>
        <Button className='button-style' variant='outlined' onClick={() => {props.history.push('/confirmationScreen')}}>Complete Call</Button>
      </Fragment>
      }
    </PromptWrapper>
  )
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from')
  };
}

export default withRouter(connect(mapStateToProps)(PrepaidRedirectPoF));