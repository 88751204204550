import React from "react";
import { connect } from "react-redux";
import ScriptText from "../../../../components/ScriptText";
import FreshDirectionText from "../FreshDirectionText";
import { pullModular as promptPullModular } from '../../../../store/prompt/reducer';

const PrepaidDisabledExit = (props) => {
  return (
    <div>
      {props.from.prepaidDisabledExitNote ?
      <ScriptText>{props.from.prepaidDisabledExitNote}</ScriptText>
      : 
      <ScriptText>We don't take prepaid reservations at this location. Please pay with your credit/debit card.</ScriptText>
      }
      <FreshDirectionText>If the parker insists that they have a prepaid pass, please ask how they entered, then log their prepaid details and contact the managers</FreshDirectionText>
      <ScriptText>How did you enter the lot?</ScriptText>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from')
  };
}
export default connect(mapStateToProps)(PrepaidDisabledExit);