import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

export const pilFlatRateCheck = ({
    parkerEntranceDateTime,
    parkerExitDateTime, 
    parkerEntranceDate, 
    parkerExitDate, 
    pilFlatRateInt, 
    pilFlatRateTimeGateStart, 
    pilFlatRateTimeGateEnd, 
    pilFlatRateDay 
  }) => {
  const pilFlatRateTimeGateStartProperDate =
    pilFlatRateTimeGateStart > pilFlatRateTimeGateEnd 
    && 
    (parkerEntranceDate !== parkerExitDate 
    || 
    (dayjs(parkerEntranceDateTime).hour() < pilFlatRateTimeGateEnd 
    && 
    dayjs(parkerExitDateTime).hour() < pilFlatRateTimeGateEnd ))
      ?
      dayjs(parkerExitDateTime).subtract(1, 'day').format('YYYY-MM-DD')
      :
      dayjs(parkerExitDateTime).format('YYYY-MM-DD')
  const pilFlatRateTimeGateEndProperDate =
    pilFlatRateTimeGateStart <= pilFlatRateTimeGateEnd
      ?
      dayjs(parkerExitDateTime).add(1, 'day').format('YYYY-MM-DD')
      :
      dayjs(parkerExitDateTime).format('YYYY-MM-DD')
  const scrubbedPilFlatRateTimeGateStart = dayjs(`${pilFlatRateTimeGateStartProperDate}T${pilFlatRateTimeGateStart}:00:00`, 'YYYY-MM-DD H:mm:ss');
  const scrubbedPilFlatRateTimeGateEnd = dayjs(`${pilFlatRateTimeGateEndProperDate}T${pilFlatRateTimeGateEnd}:00:00`, 'YYYY-MM-DD H:mm:ss');
  const pilFlatRateEntranceCheck = pilFlatRateTimeGateStart && dayjs(parkerEntranceDateTime).isAfter(dayjs(scrubbedPilFlatRateTimeGateStart));
  const pilFlatRateExitCheck = pilFlatRateTimeGateEnd && dayjs(parkerExitDateTime).isBefore(dayjs(scrubbedPilFlatRateTimeGateEnd));
  const pilSameDayBeforeExitCheck = 
    pilFlatRateTimeGateEnd 
    && 
    scrubbedPilFlatRateTimeGateEnd.isSame(dayjs(parkerEntranceDateTime, 'day'))
    &&
    scrubbedPilFlatRateTimeGateEnd.isSame(dayjs(parkerExitDateTime, 'day')) 
    && 
    dayjs(parkerExitDateTime).hour() <= pilFlatRateTimeGateEnd;
  const pilFlatRateDayCheck =
    pilFlatRateDay
    &&
    (dayjs(parkerEntranceDate).day().isSame(dayjs().day(pilFlatRateDay))
      &&
      dayjs(parkerExitDate).day().isSame(dayjs().day(pilFlatRateDay)));
  if ((pilFlatRateInt && pilFlatRateEntranceCheck && pilFlatRateExitCheck) || (pilFlatRateInt && pilFlatRateDayCheck)) {
    return pilFlatRateInt;
  } else {
    return null;
  }
}

