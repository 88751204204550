const initialState = {
  allUsers: [],
  isFormComplete: false,
  username: null,
  workerSid: null,
  friendlyName: null,
  authLevel: null,
  reportedIssueNotes: null,
  isModalOpen: false,
  reportIssueMessage: null,
  isAdminTakingCalls: false,
  locX: null,
  locY: null,
  shouldAnalystLogout: false,
  localTime: null,
  locationTime: null, 
  analystDetailsList: []
}

export default function reduce(state = initialState, action = {}) {
  let newState;
  switch (action.type) {
    case 'SUBMIT_LOGIN':
      newState = {
        ...state,
        username: action.username,
        authLevel: action.authLevel,
        friendlyName: action.friendlyName
      }
      return newState;
    case 'SUBMIT_LOGOUT':
      return { ...state, username: null, authLevel: null, friendlyName: null, targetQueue: null, isAdminTakingCalls: false, isModalOpen: false,  };
    case 'SEND_PASSWORD_RESET_USERNAME':
      newState = { ...state, passwordResetUsername: action.username };
      return newState;
    case 'SEND_PASSWORD_RESET_NEW_PASSWORD':
      newState = { ...state, passwordResetNewPassword: action.password};
      return newState;
    case 'UPDATE_RECORDS':
      return { ...state, records: action.records};
    case 'MODULAR_AUTH_UPDATE':
        newState = { ...state };
        newState[action.target] = action.value;
        return newState;
    case 'SAVE_WORKER_ACTIVITY_SIDS':
      newState = { ...state };
      Object.keys(action.value).forEach(key => {
        newState[key] = action.value[key];
      });
      return newState;
    default:
      return state;
  }
}

export function pullState(state) {
  return state.auth;
}

export function pullModular(state, field) {
  return state.auth[field];
}