import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as authSelectors from '../../store/auth/reducer';
import * as queueSelectors from '../../store/queue/reducer';
import * as queueActions from '../../store/queue/actions';
import * as authActions from '../../store/auth/actions';
import * as promptActions from "../../store/prompt/actions";
import * as promptSelectors from '../../store/prompt/reducer';
import { Grid, AppBar, Button, Tooltip, Paper, Typography, Modal }  from '@material-ui/core';
import ReportIssue from './ReportIssue';
import { titleCase } from "title-case";

const axiosInstance = require('../../lib/axiosInstance');
class AuthBar extends Component {

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { modalOpen: false }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.history !== nextProps.history) {
      return true;
    }
    if (this.props.isDialing !== nextProps.isDialing) {
      return true;
    }
    if (this.props.onCall !== nextProps.onCall) {
      return true;
    }
    if (this.props.location !== nextProps.location) {
      return true;
    }
    if (this.props.onOutsideCall !== nextProps.onOutsideCall) {
      return true;
    }
    if (this.props.queue && this.props.queue.length !== nextProps.queue.length) {
      return true;
    }
    if (this.props.isCallingManager !== nextProps.isCallingManager) {
      return true;
    }
    if (this.state.modalOpen !== nextProps.modalOpen) {
      return true;
    }
    for (let i = 0; i < this.props.queue.length; i++) {
      if (this.props.queue[i] && nextProps.queue[i] && this.props.queue[i].Connected !== nextProps.queue[i].Connected) {
        return true;
      }
    }
    return false;
  }
  
  goBack() {
    // Do nothing if on home screen.
    if (this.props.location.pathname === '/') {
      return;
    }
    if (this.props.from.isShuttle) {
      if (this.props.location.pathname === '/confirmationScreen') {
        return this.props.history.goBack();
      }
      if (this.props.node === '2') {
        return this.props.setNodeState('1a');
      }
      return this.props.setNodeState('0');
    }
    if (this.props.location.pathname === '/outsideCall') {
      this.props.dispatch(queueActions.resetToIdle(this.props.username));
    }
    this.props.dispatch(promptActions.modularSendField("", "contactNumber"));
    this.props.dispatch(promptActions.modularSendField("", "selectedParkerName"));
    this.props.dispatch(promptActions.modularSendField("", "companyName"));
    this.props.history.goBack();
  }

  goForward() {
    //this.props.history.push('/transient');
    this.props.history.goForward();
  }

  goHome() {
    if (this.props.from.isLandline ||
        this.props.from.isAttendanceCallIn)
    {
      return;
    }
    // Disable home button from outsideCall route. (They should not be able to reach Home screen without submitting call.)
    if (this.props.location.pathname && this.props.location.pathname === '/outsideCall') {
      return;
    }
    if (this.props.from.isShuttle) {
      if (this.props.location.pathname === '/confirmationScreen') {
        this.props.setNodeState('0');
        return this.props.history.goBack();
      }
      return this.props.setNodeState('0');
    }
    if (this.props.onCall || this.props.onOutsideCall) {
      this.props.history.push('/first');
    } else {
      this.props.history.push('/');
    }
    // If we get this far, we have successfully directed them to home. Let's clear the type / subtype, as long as the gate has not been vended yet 
    // (Otherwise it will be null)
    if (!this.props.vendedGate) {
      this.props.dispatch(promptActions.updateIssueType(null));
      this.props.dispatch(promptActions.updateSubtype(null));
    }
  }

  toggleDialing() {
    this.props.dispatch(queueActions.forceDialing(!this.props.isDialing));
  }

  toggleOnCall() {
    this.props.dispatch(queueActions.forceOnCall(!this.props.onCall));
  }

  outsideCallClick() {
    // Reserve worker, and retrieve the outside call endpoints.
    this.props.dispatch(queueActions.reserveWorker(this.props.username));
    this.props.dispatch(queueActions.retrieveOutsideCallEndpoints());
    // This is for clicking Outside Call when you are already on an outside call.
    if (this.props.onOutsideCall === true) {
      //eslint-disable-next-line
      this.props.dispatch(promptActions.modularSendField(new Object(), 'from'));
      this.props.history.push('/outsideCall');
      return;
    }
    // Only set onOutsideCall to true if it isn't already.
    this.props.dispatch(promptActions.modularSendField(true, 'onOutsideCall'));
    this.props.history.push('/outsideCall');
  }

  handleOutsideCallClose() {
    this.props.dispatch(promptActions.modularSendField('', 'outsideCallMenuSelector'));
  }

  sendLogOut() {
    setTimeout(this.props.dispatch(queueActions.destroyDeviceOnLogout, 5000));
    this.props.dispatch(authActions.sendLogout(this.props.username));
    this.props.dispatch(queueActions.destroyDevice());
    this.props.dispatch(promptActions.modularSendField(false, 'mapLoaded'));
    setTimeout(() => {this.props.history.push('/')}, 1000);
  }

  setType() {
    this.props.dispatch(promptActions.modularSendField(true, 'hideGhostCall'));
    this.props.dispatch(promptActions.updateIssueType('attendantAssisting'));
    this.props.history.push('/attendantAssisting');
  }

  sendIssueTypeToStore(event) {
    this.props.dispatch(promptActions.updateIssueType(event));
  }

  showOutsideCall() {
    if (this.isQueueEmptyForMe() === false) {
      return false;
    }
    if (this.props.isDialing) {
      return false;
    }
    if (this.props.onCall) {
      return false;
    }
    if (!this.props.username) {
      return false;
    }
    return true;
  }

  isQueueEmptyForMe() {
    return this.props.queue.filter((item) => {
      return item.Username && item.Username === this.props.username;
    });
  }

  shouldDisableLogout() {
    const disableBtn = this.props.username ? false : true;
    return this.props.onCall ? true : disableBtn;
  }

  async purgeMyCalls() {
    this.setState({ modalOpen: false });
    const { username, workerSid, idleSid } = this.props;
    try { 
      if (this.props.isAnswered) {
        await this.props.dispatch(queueActions.forceClose());
        await this.props.history.push('/');
      } 
      await this.props.dispatch(queueActions.purgeMyCalls({ username, workerSid, idleSid }));
      this.props.dispatch(promptActions.showSnackBar({ 
        type: 'success', 
        message: `Call purged successfully`
      }));
    } catch {
      this.props.dispatch(promptActions.showSnackBar({ 
        type: 'error', 
        message: `Failed to purge call. Please try again.`
      }));
    }
  }

  async toggleModal() {
    this.setState({ modalOpen: !this.state.modalOpen })
  }

  render() {
    return (      
      <Fragment>
        <AppBar color="primary" position="fixed" style= {{ top: 'auto', bottom: 0, width: '100%', height: 50, padding: 0}}>         
          <Grid container item xs={12} justifyContent="flex-end" direction="row" style={{ margin: 'auto' }}>
            { this.props.username &&
              <Fragment>
                <Grid container justifyContent="flex-start" alignItems="center" item xs={3} style={{ margin: 'auto', textAlign: 'left' }}>            
                  <Grid item>
                    <Button onClick={this.goBack} style={{ padding: '0px 8px' }}>
                      <i className="material-icons" style={{ color: 'white' }}> arrow_back </i>
                    </Button>
                    <Button onClick={this.goForward} style={{ padding: '0px 8px' }}>
                      <i className="material-icons" style={{ color: 'white' }}> arrow_forward </i>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Paper style={{ padding: 5 }}>
                      <Typography variant="body2" style={{ margin: 'auto' }}>
                        {titleCase(this.props.username.replace('.', ' '))}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              <Grid container item xs={6}>
                <Grid item xs={2} />
                <Grid item xs style={{ margin: 'auto' }}>
                  <Tooltip title="Home" placement="top" >
                    <div>
                      <Button color="secondary" 
                        disabled={!this.props.username}
                        onClick={(event) => this.goHome(event)}>
                        <i className="material-icons">home</i>
                      </Button>
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item xs style={{ margin: 'auto' }}>
                  <Tooltip title="Off Platform Call" placement="top">
                    <div>
                      <Button color="secondary" 
                        onClick={this.outsideCallClick}
                        disabled={this.props.onCall || !this.props.username}
                      >
                      <i className="material-icons">call_split</i>
                      </Button>
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item xs style={{ margin: 'auto' }}>
                  <Tooltip title="Logout" position="top">
                    <div>
                      <Button color="secondary" disabled={this.shouldDisableLogout()}
                        onClick={(event) => this.sendLogOut(event)}>
                          <i className="material-icons">exit_to_app</i>
                      </Button>
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item xs style={{ margin: 'auto' }}>
                  <Tooltip title="Attendant Assisting" position="top">
                    <div>
                      <Button color="secondary" onClick={this.setType} disabled={this.props.onCall ? this.props.managerRequestedVendGate ? true : false : true}>
                        <i className="material-icons">accessibility</i>
                      </Button>
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item xs style={{ margin: 'auto' }}>
                  { this.props.username &&
                    <Tooltip title="Call Stuck?" position="top">
                    <div>
                      <Button onClick={this.toggleModal} color="secondary">
                        <i className="material-icons">help</i>
                      </Button>
                    </div>
                  </Tooltip>
                  }
                </Grid>
                <Grid item xs={2} />
              </Grid>
              <Grid container item xs={3}> 
                <Grid item xs style={{ margin: 'auto', textAlign: 'right' }}>
                  { this.props.authLevel === 0 &&
                    <Tooltip title="Admin Dashboard" position="top" >
                      <Link to="/adminPortal" style={{ textDecoration: 'none' }}>
                        <Button color="secondary" >
                          <i className="material-icons" style={{ color: 'white' }}>dashboard</i>
                        </Button>
                      </Link>    
                    </Tooltip>
                  }
                  {this.props.username && <ReportIssue />}
                </Grid>
              </Grid>
            </Fragment>
          }
          </Grid>          
        </AppBar>
        <Modal open={this.state.modalOpen} onClose={this.toggleModal}>
          <div style={{
            position: 'absolute',
            width: 400,
            backgroundColor: 'white',
            border: '2px solid #000',
            left: '50%',
            marginLeft: '-200px',
            height: '200px',
            top: '50%',
            marginTop: '-100px',
            textAlign: 'center'
          }}>
            <div style={{
              padding: '10px 20px'
            }}>
              <p style={{ padding: '0px' }}>Are you sure you would like to forcefully reset your user to idle? Please only click this when you are currently unable to accept any incoming calls.</p>
              <b>DON'T DO THIS IF YOU ARE ON AN ACTIVE CALL</b>
              <Button color="primary" variant="contained" style={{ marginRight: '20px', marginTop: '20px' }} onClick={this.purgeMyCalls}>Yes</Button>
              <Button color="secondary" variant="contained" style={{ marginTop: '20px' }} onClick={this.toggleModal}>No</Button>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    authLevel: authSelectors.pullModular(state, 'authLevel'),
    onCall: queueSelectors.pullCallStatus(state),
    isDialing: queueSelectors.pullIsDialing(state),
    outsideCallMenuSelector: promptSelectors.pullModular(state, 'outsideCallMenuSelector'),
    onOutsideCall: promptSelectors.pullModular(state, 'onOutsideCall'),    
    isCallingManager: queueSelectors.pullModular(state, 'isCallingManager'),
    queue: queueSelectors.pullQueue(state),
    managerRequestedVendGate: promptSelectors.pullModular(state, 'managerRequestedVendGate'),
    idleSid: authSelectors.pullModular(state, 'Idle'),
    workerSid: authSelectors.pullModular(state, 'workerSid'),
    isAnswered: queueSelectors.pullModular(state, 'isAnswered'),
    vendedGate: queueSelectors.pullModular(state, 'vendedGate'),
    localTime: authSelectors.pullModular(state, 'localTime'),
    locationTime: authSelectors.pullModular(state, 'locationTime')
  };
}

export default withRouter(connect(mapStateToProps)(AuthBar));