import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptActions from '../../store/prompt/actions';
import * as promptSelectors from '../../store/prompt/reducer';
import * as authSelectors from '../../store/auth/reducer';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import DirectionText from '../../components/DirectionText';
import InputMask from 'react-input-mask';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const dateStyles = {
  display: 'inline-block',
  width: '100%'
};

class ContractorIssue extends Component {
  constructor(props) {
    super(props);
    this.reloadInterval = null;
    autoBind(this);
    this.state = { disableButtons: true, parkerEntranceTime: null, parkerExitTime: null };
  }
  
  requiredFields = ['selectedParkerName', 'companyName', 'reportingTo'];
  
  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (this.props.from.contractorIssueTimes) {
      this.requiredFields.push('parkerEntranceDate', 'parkerEntranceTime', 'parkerExitDate', 'parkerExitTime');
      const formattedDate = moment().format('YYYY-MM-DD');
      this.props.dispatch(promptActions.sendDateTime(formattedDate, 'parkerExitDate'));
      const formattedTime = moment().startOf('minute').format('THH:mm:ss');
      this.props.dispatch(promptActions.sendDateTime(formattedTime, 'parkerExitTime'));
    }
    /* reset global step prop */
    this.props.dispatch(promptActions.updateIssueStep(0));
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (this.props.selectedParkerName && this.props.contactNumber && this.props.companyName && this.props.reportingTo && this.props.visitPurpose) {
      if (this.props.contactNumber.match(/\(\d\d\d\) \d\d\d \d\d\d\d/)) {
        this.props.dispatch(promptActions.modularSendField(true, 'employeeIssueComplete'))
      }
    }
  }

  componentWillUnmount() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  updateField(event, value, target) {
    this.props.dispatch(promptActions.modularSendField(value, target));
  }

  notEmpty = (propsArr) => {
    for (let i = propsArr.length - 1; i >= 0; --i) {
      if (this.props[propsArr[i]] === null) {
        return false;
      }
      if (this.props[propsArr[i]].trim() === "") {
        return false;
      }
    }
    return true;
  }


  saveDate(date, target) {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    this.props.dispatch(promptActions.sendDateTime(formattedDate, target));
  }

  saveTime(time, target) {
    const formattedTime = moment(time).startOf('minute').format('THH:mm:ss');
    this.props.dispatch(promptActions.sendDateTime(formattedTime, target));
    this.setState({ [target]: time });
  }

  renderAtlantic() {
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            <Paper zDepth={1} className="paper-padding">
              <DirectionText>
                Park on street or at a section towards the left of the entrance. Contractors are not allowed in Garage.
              </DirectionText>
            </Paper>
            <Paper zDepth={1} className="paper-padding">
              <Link to="/confirmationScreen">
                <RaisedButton label="End Call" />
              </Link>
            </Paper>

          </div>
        </div>
      </div>
    )
  }

  renderTrump() {
    let phoneHintText = 'Phone Number';
    if (this.props.contactNumber && this.props.contactNumber.length > 0) {
      phoneHintText = '';
    }
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            <Paper zDepth={1} className="paper-padding">
              <DirectionText>
                Please ask the customer for the following pieces of information before allowing access.
              </DirectionText>
              <DirectionText>
                Fields denoted with an asterisk (*) are required, but some parkers may not have all of the information listed below.
              </DirectionText>
              { this.props.from.contractorIssueTimes && 
                <Fragment>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Grid style={{ marginTop: 15, padding: 5 }} container spacing={2}>
                      <Grid item xs>
                        <DatePicker  
                          label="Entrance Date"
                          format="YYYY-MM-DD"
                          value={this.props.parkerEntranceDate}
                          onChange={date => this.saveDate(date, 'parkerEntranceDate')}  
                          style={dateStyles}
                          fullWidth={true}
                          inputProps={{ className: 'data-hj-allow' }}
                        />
                      </Grid>
                      <Grid item xs>
                        <TimePicker
                          ampm={false}
                          openTo="hours"
                          views={["hours", "minutes"]}
                          format="HH:mm"
                          value={this.state.parkerEntranceTime}
                          onChange={time => this.saveTime(time, 'parkerEntranceTime')}
                          label="Entrance Time"
                          style={dateStyles}
                          fullWidth={true}
                          inputProps={{ className: 'data-hj-allow' }}
                        />
                      </Grid>
                      <Grid item xs>
                        <DatePicker
                          format="YYYY-MM-DD"
                          value={this.props.parkerExitDate || new Date()}
                          onChange={date => this.saveDate(date, 'parkerExitDate')} 
                          label="Exit Date"
                          style={dateStyles}
                          fullWidth={true}
                          inputProps={{ className: 'data-hj-allow' }}
                        />
                      </Grid>
                      <Grid item xs>
                        <TimePicker
                          ampm={false}
                          openTo="hours"
                          views={["hours", "minutes"]}
                          format="HH:mm"
                          value={this.state.parkerExitTime || new Date()}
                          onChange={time => this.saveTime(time, 'parkerExitTime')}
                          label="Exit Time"
                          style={dateStyles}
                          fullWidth={true}
                          inputProps={{ className: 'data-hj-allow' }}
                        />
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>   
                </Fragment>
              }
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Contractor Name"
                floatingLabelText="Contractor Name"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'selectedParkerName');
                }}
              />
              <span>*</span>
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Company Name"
                floatingLabelText="Contractor Company Name"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'companyName');
                }}
              />
              <span>*</span>
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Who Does Employee Report To?"
                floatingLabelText="Supervisor"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'reportingTo');
                }}
              />
              <span>*</span>
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                style={{ width: '98%' }}
                hintText={phoneHintText}
                floatingLabelText="Phone Number"
                floatingLabelFixed={true}
                ref="phoneNumber"
                name="phoneNumber"
                type="text"
                value={this.props.contactNumber}
                onChange={(e, v) => {
                  this.updateField(e, v, 'contactNumber');
                }}>
                <InputMask mask="(999) 999 9999" maskChar=" " />
              </TextField>
              <span>*</span>
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Visit Purpose"
                floatingLabelText="Visit Purpose"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'visitPurpose');
                }}
              />
            </Paper>
            <Paper zDepth={1} className="paper-padding">   
            {
                this.props.contactNumber.trim().length > 13 &&
                  this.notEmpty(this.requiredFields) ?
                  <Link to="/vendGate">
                    <RaisedButton label="Submit Employee Info" />
                  </Link> :
                  <DirectionText>Please complete the form above to continue.</DirectionText>
              }

            </Paper>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.from.locationId === 583) {
      return this.renderAtlantic();
    } else {
      return this.renderTrump();
    }

  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    selectedParkerName: promptSelectors.pullModular(state, 'selectedParkerName'),
    contactNumber: promptSelectors.pullModular(state, 'contactNumber'),
    companyName: promptSelectors.pullModular(state, 'companyName'),
    reportingTo: promptSelectors.pullModular(state, 'reportingTo'),
    visitPurpose: promptSelectors.pullModular(state, 'visitPurpose'),
    employeeIssueComplete: promptSelectors.pullModular(state, 'employeeIssueComplete'),
    parkerEntranceDate: promptSelectors.pullModular(state, 'parkerEntranceDate'),
    parkerEntranceTime: promptSelectors.pullModular(state, 'parkerEntranceTime'),
    parkerExitDate: promptSelectors.pullModular(state, 'parkerExitDate'),
    parkerExitTime: promptSelectors.pullModular(state, 'parkerExitTime'),
  };
}

export default withRouter(connect(mapStateToProps)(ContractorIssue));
