import React, { Component } from 'react';
import _ from 'lodash';
import autoBind from 'react-autobind';
import { Link } from 'react-router-dom';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import ScriptText from './ScriptText';

export default class SpecialMonthlyOnlyLane extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  
  generateAdditionalLanes(lane) {
      return (<li>{lane}</li>)
  }

  render() {
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            <Paper zDepth={1} className="paper-padding">
              <ScriptText>
                "You are in a {this.props.specialMonthlyLane}-Only lane. Please redirect to one of the following lanes:"
              </ScriptText>
              <div className="bullet-padding">
                <ul>
                  {_.map(this.props.redirect, this.generateAdditionalLanes)}
                </ul>
              </div>
              <Link to="/confirmationScreen">
                <RaisedButton label="Complete Call" />
              </Link>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}
