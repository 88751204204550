import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import * as config from '../../lib/config';
import { withRouter, Link } from 'react-router-dom';
import * as promptSelectors from '../../store/prompt/reducer';
import * as authSelectors from '../../store/auth/reducer';
import * as promptActions from '../../store/prompt/actions';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import AirportParkersList from '../../components/airportParkersList';
import ScriptText from '../../components/ScriptText';
import DirectionText from '../../components/DirectionText';
import PromptBox from '../../components/promptBox';
import ContactManagers from './ContactManagers';
import { TextField } from '@material-ui/core';

class AirportParking extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (this.props.from.airportCode) {
      this.props.dispatch(promptActions.updateAirportParkers(this.props.from.airportCode));
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  completeStep(step) {
    this.props.dispatch(promptActions.updateIssueStep(step));
  }

  updateField(event, target) {
    this.props.dispatch(promptActions.modularSendField(event.target.value, target));
  }

  submitReservationInfo() {
    const { selectedParkerName, thirdPartyReservationNumber } = this.props;
    if (!selectedParkerName) {
      return this.props.dispatch(promptActions.showSnackBar({ 
        type: 'error', 
        message: "Parker Name is required."
      }));
    } else if (!thirdPartyReservationNumber) {
      return this.props.dispatch(promptActions.showSnackBar({ 
        type: 'error', 
        message: "Reservation ID is required."
      }));
    } else {
      this.props.history.push('/vendGate');
    }

  }

  airportParkerSearch(event) {
    if (this.reloadInterval) {
      clearTimeout(this.reloadInterval);
    }
    let val = event.target.value;
    this.reloadInterval = setTimeout(() => {
      this.props.dispatch(promptActions.airportParkerSearch(val));
    }, 500);
  }

  sendParkerToState(column, row, event) {
    let selectedParker = this.props.airportParkersList[column];
    this.props.dispatch(promptActions.sendParkerToState(selectedParker));
  }

  renderAPR() {
    return (
      <div className="home-div">
        <div className="home-div">
          <div className="home-div-content">
            <Paper zDepth={1} className="home-div-header-short">
              <h2>{this.props.from.locationName}</h2>
            </Paper>
            <div className="home-div-body">
              <Paper zDepth={1} className="paper-padding">
                <DirectionText>Please verify the airport parker using the table below.</DirectionText>
                <AirportParkersList
                  aPSearch={this.airportParkerSearch}
                  parkers={this.props.airportParkersList}
                  searchCriteria={this.props.searchCriteria}
                  sendParkerToState={this.sendParkerToState}
                  selectedParker={this.props.selectedParker}
                />
              </Paper>
              <Paper zDepth={1} className="paper-padding">
                <Link to="/vendGate">
                  <RaisedButton label="Able to Verify Parker" style={{ margin: '16px 0' }} onClick={this.migrateSearchToSelected} />
                </Link>
                <Link to="/airportNotFound">
                  <RaisedButton label="Unable to Verify Parker" />
                </Link>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    );
  }

  rendeAirportReservationInfo() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        {
        this.props.from.airportReservationText 
        ?
        <ScriptText>
        {this.props.from.airportReservationText}
      </ScriptText>
        :
        <ScriptText>
          May I please have your ReservationID and Name?
        </ScriptText>
        }
        <TextField
          label="Parker Name"
          value={this.props.selectedParkerName}
          onChange={(event) => {this.updateField(event, 'selectedParkerName')}}
          margin="dense"
          variant="outlined"
          fullWidth={true}
        />
        <TextField
          label="Reservation ID"
          value={this.props.thirdPartyReservationNumber}
          onChange={(event) => {this.updateField(event, 'thirdPartyReservationNumber')}}
          margin="dense"
          variant="outlined"
          fullWidth={true}
        />
        <div style={{ marginTop: 15 }}>
          <RaisedButton label="Submit" onClick={() => this.submitReservationInfo()} />
          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
        </div>
      </PromptBox>
    )
  }

  renderStandard() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
      { this.props.step === 0 &&
        <div>
          <ScriptText>
            If your reservation was purchased online, please scan it while exiting and entering the facility.
          </ScriptText>
          <Paper zDepth={1} className="paper-padding">
            <span className="verification-span">Is further assistance required?</span>
              <Link to="/confirmationScreen">
                <RaisedButton label="No" />
              </Link>
              <RaisedButton label="Yes" onClick={() => { this.completeStep(1)}} />
          </Paper>
        </div>
      }
      { this.props.step === 1 && <ContactManagers showButtons={['managerAssisting', 'vendGate', 'ticketDiscrepancy']} /> }
      </PromptBox>
    )
  }
  
  render() {
    if (this.props.from.airportReservationInfo) {
      return this.rendeAirportReservationInfo();
    } else {
      return this.renderStandard();
    }
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    valCompanyIndex: promptSelectors.pullModular(state, 'companyIndex'),
    ticketNumber: promptSelectors.pullTicketNum(state),
    username: authSelectors.pullModular(state, 'username'),
    airportParkersList: promptSelectors.pullAirportParkers(state),
    selectedParker: promptSelectors.pullSelectedParker(state),
    searchCriteria: promptSelectors.pullAirportSearchText(state),
    step: promptSelectors.pullModular(state, 'step'),
    selectedParkerName: promptSelectors.pullModular(state, 'selectedParkerName'),
    thirdPartyReservationNumber: promptSelectors.pullModular(state, 'thirdPartyReservationNumber')
  };
}

export default withRouter(connect(mapStateToProps)(AirportParking));
