import React from 'react';
import ScriptText from '../../../../components/ScriptText';

const PrepaidHowParkerEntered = () => {

  return (
    <ScriptText>How did you enter the lot?</ScriptText>
  )

}

export default PrepaidHowParkerEntered;