import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as queueSelectors from '../../../store/queue/reducer';
import * as promptActions from '../../../store/prompt/actions';
import * as authSelectors from '../../../store/auth/reducer';
import ContactManagers from '../../Prompt/ContactManagers';
import DirectionText from '../../../components/DirectionText';
import PromptBox from '../../../components/promptBox';
import RaisedButton from 'material-ui/RaisedButton';
import _ from 'lodash';
import ErrorBoundary from '../../ErrorBoundary';

class TibaRateResult extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { showRateResult: true, showManagersList: false }
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  updateState(event, target) {
    this.setState({ [target]: event.target.value });
  }

  updateField(event, target) {
    this.props.dispatch(promptActions.modularSendField(event.target.value, target));
  }
  
  async pushRateToMachine() {
    const { apiParams, controlDeviceData, lostTicketPushRate, baseURL } = this.props.from;
    const { ongoingCallId, username, tibaRateResult } = this.props;
    const rateToPush = parseInt(tibaRateResult.rate);
    const data = { username, ongoingCallId, apiParams, baseURL, commandType: controlDeviceData.pushRateNewTicket, 
      actionId: controlDeviceData.pushRateDBId, controlDeviceData, rateToPush
    };
    const result = await this.props.dispatch(promptActions.tibaSendControlDeviceAction(data));
    const { success, message } = result.data;
    if (success) {
      this.props.dispatch(promptActions.showSnackBar({ 
        type: 'success', message: `Rate $${rateToPush} pushed to device, if parker denies payment contact managers.` 
      }));
    } else if (!success) {
      this.props.dispatch(promptActions.showSnackBar({ 
        type: 'error', message: message
      }));
    }
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'showPushRateForm':
        this.props.history.push('/tibaAPI/tibaManagerPushRate');
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  render() {
    const { ticketNumber, tibaRateResult } = this.props;
    return (
      <PromptBox locationName={this.props.from.locationName}>
        <ErrorBoundary>
          {this.state.showRateResult &&
            <Fragment>
              {ticketNumber && <DirectionText> <span style={{ fontWeight: "bolder" }}> Ticket Number: </span> {ticketNumber} </DirectionText>}
              {tibaRateResult.rate && <DirectionText> <span style={{ fontWeight: "bolder" }}> Fee Due: </span> ${tibaRateResult.rate} </DirectionText> }
              {tibaRateResult.parkerEntryDateResult && <DirectionText> <span style={{ fontWeight: "bolder" }}> Entry Time: </span> {tibaRateResult.parkerEntryDateResult || 0} </DirectionText> }
              {tibaRateResult.shouldVend && <DirectionText> Parker is within Grace period, please vend the gate. </DirectionText>} 
              <div style={{ marginTop: 15 }}>
                {tibaRateResult.shouldVend    
                ?
                  <RaisedButton label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />
                :
                  <RaisedButton label="Push Rate To Machine" onClick={() => this.pushRateToMachine()} />
                }
                {this.props.from.enablePIL && this.props.from.enablePIL.includes('tiba') 
                  ? 
                  <RaisedButton label="Next Step" onClick={() => this.props.history.push('/pil/pilEntryInput')} />
                  :
                <RaisedButton label="Contact Managers" onClick={() => this.setState({ showRateResult: false, showManagersList: true})} />
                }
                <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
              </div>
            </Fragment>
          }

          {this.state.showManagersList &&
            <ContactManagers 
              showButtons={['managerAssisting', 'vendGate', 'ticketDiscrepancy', 'showCustomButton']} 
              btnClick={this.btnClick}
              btnFrom="tibaRateResult"
              customButtonData={[
                {
                  "label": "Manager Asked to Push Rate",
                  "action": "showPushRateForm"
                },
              ]}
            />
          }
        </ErrorBoundary>
      </PromptBox>
    )
  }
  
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username'),
    ticketNumber: promptSelectors.pullModular(state, 'ticketNumber'),
    tibaRateResult: promptSelectors.pullModular(state, 'tibaRateResult'),
    ongoingCallId: queueSelectors.pullModular(state, 'ongoingCallId'),
  };
}

export default withRouter(connect(mapStateToProps)(TibaRateResult));