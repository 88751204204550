const initialState = {
  "queue": [],
  "activeConn": {},
  "isDialing": false,
  "onCall": false,
  "initialized": false,
  "isAnswered": false,
  "workerActivity": '',
  "isParkerCallOnHold": false,
  "isCallingManager": false,
  "parkerCallStatus": null,
  "ongoingParkerCallSid": '',
  "ongoingAnalystCallSid": '',
  "ongoingReservationSid": '',
  "ongoingConferenceSid": '',
  "ongoingManagerCallSid": '',
  "callingManagerName": '',
  "callingManagerNumber": '',
  "callingManagerIndex": '',
  "callingManagerExtension": null,
  targetQueue: null,
  isIntercomCall: null,
  ongoingCallId: null,
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case `queue.QUEUE`:
      return { ...state, queue: action.queue }
    case `TWILIO_INIT`:
      return { ...state, initialized: action.value };
    case `TWILIO_INC`:
      return { ...state, isDialing: true, ongoingAnalystCallSid: action.ongoingAnalystCallSid };
    case `TWILIO_ACCEPT`:
      return { ...state, onCall: true, isDialing: false, answeredTime: action.value };
    case `TWILIO_CALL_END`:
      return { ...state, onCall: false, isDialing: false };
    case `TWILIO_RECEIVE_HANGUP`:
      return { ...state, onCall: false, isDialing: false };
    case `TWILIO_QUEUE_REFRESH`:
      return { ...state, queue: action.queue };
    case 'SEND_ANSWERED_TIME':
      return { ...state, answeredTime: action.value };
    case 'SUBMIT_LOGIN':
        return { ...state, targetQueue: action.targetQueue};
    case 'SUBMIT_LOGOUT':
      return { ...state, initialized: false, targetQueue: null };
    case 'FORCE_DIALING':
      return { ...state, isDialing: action.value };
    case 'FORCE_ONCALL':
      return { ...state, onCall: action.value }
    case 'MODULAR_QUEUE_UPDATE':
      let newState = { ...state };
      newState[action.target] = action.value;
      return newState;
    case 'CALL_ANSWERED':
      return { ...state, isAnswered: true };
    case 'RESET_PROMPT_STATE':
      return { ...state, isAnswered: false, answeredTime: '' };
    case 'WORKER_ACTIVITY_UPDATE':
      return { ...state, workerActivity: action.workerActivity};
    case 'UPDATE_CALL_SIDS': 
      return { ...state,
        ongoingCallId: action.value.id,
        ongoingTempCallId: action.value.ongoingTempCallId,
        ongoingParkerCallSid: action.value.ParkerCallSid,
        ongoingReservationSid: action.value.ReservationSid,
        ongoingConferenceSid: action.value.ConferenceSid,
        isIntercomCall: action.value.IsIntercomCall
      };
    case 'QUEUE_OBJECT_UPDATE':
      const thisState = {...state};
      Object.keys(action.value).forEach(key => {
        thisState[key] = action.value[key];
      });
      return thisState;
    case 'RESET_QUEUE_STATE':
      return { ...state,   
        parkerCallStatus: '', 
        isParkerCallOnHold: false,
        isCallingManager: false, 
        ongoingCallId: '',
        ongoingTempCallId: '',
        ongoingParkerCallSid: '',
        ongoingConferenceSid: '', 
        ongoingManagerCallSid: '',
        ongoingAnalystCallSid: '',
        ongoingReservationSid: '',
        callingManagerName: '',
        callingManagerNumber: '',
        callingManagerIndex: '',
        callingManagerExtension: null,
        isIntercomCall: null
      }
    default:
      return state;
  }
}

// selectors
export function pullState(state) {
  return state.queue;
}

export function pullQueue(state) {
  return state.queue.queue;
}

export function pullCallStatus(state) {
  return state.queue.onCall;
}

export function pullCallSid(state) {
  return state.queue.ongoingAnalystCallSid;
}

export function pullIsDialing(state) {
  return state.queue.isDialing;
}

export function pullInitialized(state) {
  return state.queue.initialized;
}

export function pullAnsweredTime(state) {
  return state.queue.answeredTime;
}

export function pullModular(state, field) {
  return state.queue[field];
}