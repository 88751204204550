import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router';
import * as config from '../../lib/config';
import * as authSelectors from '../../store/auth/reducer';
import * as queueSelectors from '../../store/queue/reducer';
import * as queueActions from '../../store/queue/actions';
import * as authActions from '../../store/auth/actions';
import * as promptSelectors from '../../store/prompt/reducer';
import * as promptActions from '../../store/prompt/actions';
import cloudparkLogo from '../../assets/cloudpark.jpg';
import Paper from 'material-ui/Paper';

class Home extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.replace('/login');
    } else {
      this.props.dispatch(promptActions.modularSendField(true, 'mapLoaded'))
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Since we manually pull the queue every half second, that caused componentDidUpdate to spam.
    // Let's only update the component when things actually change.
    if (JSON.stringify(this.props.queue) === JSON.stringify(nextProps.queue) &&
        this.props.from === nextProps.from &&
        this.props.username === nextProps.username &&
        this.props.authLevel === nextProps.authLevel &&
        this.props.onCall === nextProps.onCall &&
        this.props.isDialing === nextProps.isDialing &&
        this.props.workerActivity === nextProps.workerActivity  
    ) {
      return false;
    }
    return true;
  }

  shouldReset() {
    return !this.props.onCall && !this.props.isDialing && this.props.workerActivity === 'Busy';
  }

  sendLogOut() {
    setTimeout(this.props.dispatch(queueActions.destroyDeviceOnLogout, 5000));
    this.props.dispatch(authActions.sendLogout(this.props.username));
    this.props.dispatch(queueActions.destroyDevice());
    this.props.dispatch(promptActions.modularSendField(false, 'mapLoaded'));
    this.props.dispatch(authActions.modularSendField(false, 'shouldAnalystLogout'));
    setTimeout(() => {this.props.history.push('/')}, 1000);
    setTimeout(() => {window.location.reload(true)}, 1000);
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (this.shouldReset()) {
      if (this.busyResetTimeout) {
        clearTimeout(this.busyResetTimeout);
      }
      this.busyResetTimeout = setTimeout(() => {
        // Recheck if we should reset after 5 seconds.
        if (this.shouldReset()) {
          this.props.dispatch(queueActions.resetToIdle(this.props.username));
        }
      }, 5000);
    }
  }

  componentWillUnmount() {
    if (this.busyResetTimeout) {
      clearTimeout(this.busyResetTimeout);
    }
  }

  render() {
    return (
      <div className="home-div">
        <div className={`home-div-content ${this.props.isDialing && !this.props.onCall && 'flash-alert'}`}>
          <Paper zDepth={1} className="home-div-header">
            <img alt="Cloudpark logo" src={cloudparkLogo} className="cloudpark-image" />
          </Paper>
          <Paper zDepth={1} className="home-div-body">
            <p className="">There is no active call. This screen will change as you accept calls from the queue list on the left.</p>
          </Paper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    authLevel: authSelectors.pullModular(state, 'authLevel'),
    onCall: queueSelectors.pullCallStatus(state),
    isDialing: queueSelectors.pullIsDialing(state),
    workerActivity: queueSelectors.pullModular(state, 'workerActivity'),
    queue: queueSelectors.pullModular(state, 'queue')
  };
}

export default withRouter(connect(mapStateToProps)(Home));
