import { Button } from '@mui/material';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PromptWrapper from '../containers/Prompt/PrepaidFlows/PromptWrapper';
import { pullModular as promptPullModular } from '../store/prompt/reducer';
import FreshContactManagers from '../containers/Prompt/PrepaidFlows/PrepaidNodes/FreshContactManagers';
import DirectionText from '../components/DirectionText';

const VendForAll = (props) => {
  const { history } = props;
  const [shouldShowManager, setShouldShowManager] = useState(false);
  const [managerCalledFor10s, setManagerCalledFor10s] = useState(false);
  const [managerCalled, setManagerCalled] = useState(false);

  return (
    <PromptWrapper>
      <DirectionText>
        Please immediately vend the gate for the parker if they are experiencing issues. If they need help, please contact a manager and relay parker's question. If no manager answers, please vend gate.
      </DirectionText>
      <Button style={{ marginBottom: '15px' }} variant="outlined" onClick={() => { history.push('/vendGate') }}>
        Vend Gate
      </Button>
      <Button style={{ marginBottom: '15px' }} variant="outlined" onClick={() => { setShouldShowManager(true); }}>
        Contact Managers
      </Button>
      {shouldShowManager &&
        <>
          <FreshContactManagers
            managerCalledFor10s={managerCalledFor10s}
            setManagerCalledFor10s={setManagerCalledFor10s}
            managerCalled={managerCalled}
            setManagerCalled={setManagerCalled}
          />
          <Button className='button-style' variant='outlined' onClick={() => { history.push('/confirmationScreen') }}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => { history.push('/vendGate') }}>Manager Requested Gate Vend</Button>
        </>
      }
    </PromptWrapper>
  )
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from')
  }
}

export default withRouter(connect(mapStateToProps)(VendForAll));