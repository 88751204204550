import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Link } from 'react-router-dom';
import Paper from 'material-ui/Paper';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';

const paperStyle = {
  display: 'inline-block',
  margin: '16px 32px 16px 32px'
};

export default class ReasonButtons extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    return (
      <Paper style={paperStyle}>
        <Menu>
          {this.props.from.transientLocation &&
            <Link
              to="/transient"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                this.props.sendIssueTypeToStore('transient');
              }}
            >
              <MenuItem primaryText="Transient Issue" />
            </Link>
          }
          {process.env.REACT_APP_ENV !== 'production' &&
            <Link
              to="/pil/pilEntryInput"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                this.props.sendIssueTypeToStore('PIL');
              }}
            >
              <MenuItem primaryText="Pay In Lane" />
            </Link>
          }
          {this.props.from.monthlyLocation && (
            <Link
              to="/monthly"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                this.props.sendIssueTypeToStore('monthly');
              }}
            >
              <MenuItem primaryText="Monthly Issue" />
            </Link>
          )}
          { this.props.from.specialMonthlyLane &&
            <Link
              to="/specialMonthly"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                this.props.sendIssueTypeToStore('specialMonthly');
              }}
            >
              <MenuItem primaryText={this.props.from.specialMonthlyLane} />
            </Link>
          }
          { this.props.from.uberLocation && (
            <Link
              to="/uber"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                this.props.sendIssueTypeToStore('uber');
              }}
            >
              <MenuItem primaryText="Uber Driver" />
            </Link>
          )}
          { this.props.from.reservationLocation && (
            <Link
              to="/reservation"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                this.props.sendIssueTypeToStore('reservation');
              }}
            >
              <MenuItem primaryText="Reservation Issue" />
            </Link>
          )}
          { this.props.from.residentLocation && (
            <Link
              to="/resident"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                this.props.sendIssueTypeToStore('resident');
              }}
            >
              <MenuItem primaryText="Resident Issue" />
            </Link>
          )}
          {this.props.from.employeeLocation && (
            <Link
              to="/employee"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                this.props.sendIssueTypeToStore('employee');
              }}
            >
              <MenuItem primaryText="Employee Issue" />
            </Link>
          )}
          {this.props.from.contractorLocation && (
            <Link
              to="/contractor"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                this.props.sendIssueTypeToStore('contractor');
              }}
            >
              <MenuItem primaryText="Contractor Issue" />
            </Link>
          )}
          {this.props.from.visitorLocation && (
            <Link
              to="/visitor"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                this.props.sendIssueTypeToStore('visitor');
              }}
            >
              <MenuItem primaryText="Visitor Issue" />
            </Link>
          )}
          <Link
            to="/generalInquiry"
            style={{ textDecoration: 'none' }}
            onClick={() => {
              this.props.sendIssueTypeToStore('generalInquiry');
            }}
          >
            <MenuItem primaryText="General Inquiry" />
          </Link>
          {this.props.from.hotelLocation && (
            <Link
              to="/hotel"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                this.props.sendIssueTypeToStore('hotel');
              }}
            >
              <MenuItem primaryText="Hotel Issue" />
            </Link>
          )}
          {this.props.from.airportLocation && (
            <Link
              to="/airport"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                this.props.sendIssueTypeToStore('airport');
              }}
            >
              <MenuItem primaryText="Airport Parking" />
            </Link>
          )}
          { this.props.from.valetLocation &&
            <Link to="/valet" style={{ textDecoration: 'none' }} onClick={() => { this.props.sendIssueTypeToStore('valet') }}>
              <MenuItem primaryText="Valet Issue" />
            </Link>
          }
          <Link
            to="/damageClaim"
            style={{ textDecoration: 'none' }}
            onClick={() => {
              this.props.sendIssueTypeToStore('damageClaim');
            }}
          >
            <MenuItem primaryText="Damage Claim" />
          </Link>
          { !this.props.from.shouldHideEquipmentMalfunction &&
            <Link
              to="/equipmentMalfunction"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                this.props.sendIssueTypeToStore('equipmentMalfunction');
              }}
            >
            <MenuItem primaryText="Equipment Malfunction" />
          </Link>
          }
          { !this.props.hideGhostCall &&
            <Link
              to="/ghost"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                this.props.sendIssueTypeToStore('ghost');
              }}
            >
              <MenuItem primaryText="Ghost Call" />
            </Link>
          }
          <Link
            to="/confirmationScreen"
            style={{ textDecoration: 'none' }}
            onClick={() => {
              this.props.sendIssueTypeToStore('testCall');
            }}
          >
            <MenuItem primaryText="Test Call" />
          </Link>
          <Link
            to="/communicationIssue"
            style={{ textDecoration: 'none' }}
            onClick={() => {
              this.props.sendIssueTypeToStore('communicationIssue');
            }}
          >
            <MenuItem primaryText="Communication Issue" />
          </Link>
          { !this.props.from.disableServiceWorker &&
            <Link
              to="/serviceWorker"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                this.props.sendIssueTypeToStore('serviceWorker');
              }}
            >
              <MenuItem primaryText="Service Worker" />
            </Link>
          }
          {this.props.from.transientLocation &&
            <Link
              to="/gracePeriod"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                this.props.sendIssueTypeToStore('gracePeriod');
              }}
            >
              <MenuItem primaryText="Grace Period" />
            </Link>
          }
        </Menu>
      </Paper>
    );
  }
}
