/*
  PILEntryInput is a simple input component to get the parkers entry time.
  We use this information to calculate the amount of time a car was parked in the garage.
*/

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import RaisedButton from 'material-ui/RaisedButton';
import _ from 'lodash';
import { Grid, TextField } from '@material-ui/core';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as queueSelectors from '../../../store/queue/reducer';
import * as queueActions from '../../../store/queue/actions';
import * as promptActions from '../../../store/prompt/actions';
import * as authSelectors from '../../../store/auth/reducer';
import ScriptText from '../../../components/ScriptText';
import PromptBox from '../../../components/promptBox';

const dateStyles = {
  display: 'inline-block',
  width: '100%'
};

class PILEntryInput extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      inputError: null,
    }
  }

  async componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    // When a call is froma cell phone and we cannot vend the gate, we redirect the parker and ask them to call from the intercom.
    // This is to avoid taking payemnt from the parker and then not being able to vend the gate.
    if (this.props.from.redirectToIntercomPrompts) {
      const shouldRedirect = await this.props.dispatch(promptActions.shouldRedirectToIntercom("PIL"));
      if (shouldRedirect) {
        this.props.history.push('/redirectToIntercom');
      }
    }
    // Redirect to PILProcessPayment if pilPaymentAmount and pilPaymentCode already exists
    // If Subtype is lost ticket and locVar has lostTicketPushRate. We don't have to calculate the payment amount
    if ((this.props.pilPaymentAmount && this.props.pilPaymentCode) ||
        (this.props.issue.subtype == 'lostTicket' && this.props.from.lostTicketPushRate) ||
        (this.props.issue.type == 'monthly' && this.props.from.monthlyApbRedirectPILAmount)) {
      this.props.history.push('/pil/pilProcessPayment');
    } else {
      this.props.dispatch(promptActions.modularSendField(null, 'pilTransaction'));
    }
  }

  updateDateTime(event) {
    const dateTime = moment(event.target.value).format("YYYY-MM-DD HH:mm:ss");
    this.props.dispatch(promptActions.modularSendField(dateTime.split(" ")[0], 'parkerEntranceDate'));
    this.props.dispatch(promptActions.modularSendField("T"+dateTime.split(" ")[1], 'parkerEntranceTime'));
  }

  updateField(event, target) {
    this.props.dispatch(promptActions.modularSendField(event.target.value, target))
  }

  async submitEntranceTime() {
    const { parkerEntranceDate, parkerEntranceTime, username, ongoingCallId } = this.props;
    const { timezone } = this.props.from;
    const parkerEntranceDateTime = parkerEntranceDate + parkerEntranceTime;
    // The amount of time parked is calculated through Call init time instead of now.
    const initTimeResult = await this.props.dispatch(queueActions.getCallInitTime({ username, ongoingCallId }));
    if (initTimeResult) {
      let { success, initTime } = initTimeResult.data;
      if (success && initTime) {
        const parkerExitDateTime = moment(initTime).tz(timezone);
        const formattedExitDateTime = moment(parkerExitDateTime).format('YYYY-MM-DD HH:mm:ss');
        this.props.dispatch(promptActions.modularSendField(formattedExitDateTime.split(" ")[0] ,'parkerExitDate'));
        this.props.dispatch(promptActions.modularSendField(`${"T"+formattedExitDateTime.split(" ")[1]}` ,'parkerExitTime'));
        if (!parkerEntranceDate || !parkerEntranceTime) {
          return this.setState({ inputError: "Entrance Date and Time is required" });
        } else if (moment(parkerEntranceDateTime, 'YYYY-MM-DD HH:mm:ss').isAfter(moment(formattedExitDateTime, 'YYYY-MM-DD HH:mm:ss'))) {
          return this.setState({ inputError: `Entrance Date can not be after Exit Date. The time at this location is ${moment().tz(timezone).format('LLLL')}.`});
        } else if (moment(parkerEntranceDateTime, 'YYYY-MM-DD HH:mm').isSame(moment(formattedExitDateTime, 'YYYY-MM-DD HH:mm'))) {
          return this.setState({ inputError: "Entrance Date and Exit Date cannot be the same."});
        } else {
          this.setState({ inputError: false });
          this.props.history.push('/pil/pilProcessPayment');
        }
      } else {
        return this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: "Click Submit again."
        }))
      }
    } else {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: "Click Submit again."
      }))
    }
  }

  render() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        <ScriptText> May I please have your ticket number and the time you entered the garage? </ScriptText>
        <Grid container spacing={2} style={{ marginTop:15, margin: 'auto', textAlign: 'center' }}>
          <Grid item xs={12}>
            <TextField
              label={(this.props.from.cardTicket || this.props.issue.subtype == "enteredUsingCreditCard") ? "CC Last 4 Digits used on Entrance (Optional)" : "Ticket Number (Optional)"}
              value={this.props.ticketNumber || ''}
              margin="dense"
              variant="outlined"
              inputProps={{ className: 'data-hj-allow' }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => { this.updateField(event, 'ticketNumber')}}
              style={{ width: '50%', marginTop: 15 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              id="datetime-local"
              label="Entry Date Time"
              type="datetime-local"
              inputProps={{ className: 'data-hj-allow' }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: '50%', marginTop: 15 }}
              onChange={(e) => { this.updateDateTime(e)}}
            />
          </Grid>
        </Grid>
        {this.state.inputError &&
          <Grid item xs={12}>
            <p>
              <span style={{ color: 'red', fontWeight: 'bolder', marginTop: 15, }}> 
                {this.state.inputError}
              </span>
            </p>
          </Grid>
        } 
        <div style={{ marginTop: 20 }}>
          <RaisedButton label="Submit" onClick={() => this.submitEntranceTime()} />
          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
        </div>
      </PromptBox>
    )
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    parkerEntranceDate: promptSelectors.pullModular(state, 'parkerEntranceDate'),
    parkerEntranceTime: promptSelectors.pullModular(state, 'parkerEntranceTime'),
    pilPaymentCode: promptSelectors.pullModular(state, 'pilPaymentCode'),
    pilPaymentAmount: promptSelectors.pullModular(state, 'pilPaymentAmount'),
    pilCheckTransactionStatus: promptSelectors.pullModular(state, 'pilCheckTransactionStatus'),
    ticketNumber: promptSelectors.pullModular(state, 'ticketNumber'),
    issue: promptSelectors.pullIssue(state),
    ongoingCallId: queueSelectors.pullModular(state, 'ongoingCallId'),
    isIntercomCall: queueSelectors.pullModular(state, 'isIntercomCall')
  };
}

export default withRouter(connect(mapStateToProps)(PILEntryInput));