import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as promptActions from '../../../store/prompt/actions';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as authSelectors from '../../../store/auth/reducer';
import _ from 'lodash';
import PromptBox from '../../../components/promptBox';
import { TextField } from '@material-ui/core';
import RaisedButton from 'material-ui/RaisedButton';
import DirectionText from '../../../components/DirectionText';

class Brokers extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { firstName: null, lastName: null, suite: null };
  }

  updateField(event, target) {
    this.setState({ [target]: event.target.value });  
  }

  submitInfo() {
    const { firstName, lastName, suite } = this.state;
    if (!firstName || !lastName) {
      this.props.dispatch(promptActions.showSnackBar({ 
        type: 'error', 
        message: "First & Last Name is required."
      }));
    } else if (!suite) {
      this.props.dispatch(promptActions.showSnackBar({ 
        type: 'error', 
        message: "Suite is required."
      }));
    } else {
      this.props.dispatch(promptActions.modularSendField(`${firstName} ${lastName}`, 'selectedParkerName'));
      this.props.dispatch(promptActions.modularSendField(suite, 'selectedParkerRoomNumber'));
      this.props.history.push('/vendGate');
    }
  }

  render() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        <DirectionText>
          Please log the parkers Name and Suite# they are seeing.
        </DirectionText>
        <TextField
          label="First Name"
          placeholder="Enter First Name"
          value={this.state.firstName || ""}
          margin="dense"
          variant="outlined"
          inputProps={{ className: 'data-hj-allow' }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {this.updateField(event, 'firstName')}}
          style={{ width: '60%', marginTop: 15, marginBottom: 10 }}
        />
        <TextField
          label="Last Name"
          placeholder="Enter Last Name"
          value={this.state.lastName || ""}
          margin="dense"
          variant="outlined"
          inputProps={{ className: 'data-hj-allow' }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {this.updateField(event, 'lastName')}}
          style={{ width: '60%', marginTop: 15, marginBottom: 10 }}
        />
        <TextField
          label="Suite #"
          placeholder="Enter Suite#"
          value={this.state.suite || ""}
          margin="dense"
          variant="outlined"
          inputProps={{ className: 'data-hj-allow' }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {this.updateField(event, 'suite')}}
          style={{ width: '60%', marginTop: 15, marginBottom: 10 }}
        />
        <div style={{ marginTop: 15 }}>
          <RaisedButton label="Vend Gate" onClick={() => this.submitInfo()} />
          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
        </div>
      </PromptBox>
    )
  }
    
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    managerVendConfirmation10s: promptSelectors.pullModular(state, 'managerVendConfirmation10s')
  };
}

export default withRouter(connect(mapStateToProps)(Brokers));
