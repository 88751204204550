import _ from 'lodash';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Paper from 'material-ui/Paper';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';

export default class ReasonButtons extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    let lanes = this.props.lanes;
    return (
      <Paper zDepth={1} className="paper-padding prompt-body lane-list" style={{ marginTop: '20px' }}>
        <Menu>
          {_.map(lanes, (v, i) => {
            return (
              <MenuItem key={i} primaryText={v.name} onClick={() => { this.props.pushToFirst(v) }} />
            );
          })}
        </Menu>
      </Paper>
    );
  }
}
