import React, { useEffect } from "react";
import TitleBox from "./TitleBox";
import * as config from './../../../lib/config';
import { connect } from 'react-redux';
import { pullModular as authPullModular } from '../../../store/auth/reducer';
import { withRouter } from "react-router-dom";
import { Paper } from "@mui/material";
import ErrorBoundary from "../../ErrorBoundary";

const PromptWrapper = (props) => {

  useEffect(() => {
    if (!props.username && !config.TestComponent) {
      props.history.push('/login');
    }
  })


  return (
    <ErrorBoundary>
      <div className='home-div'>
        <div className='home-div-content'>
          <TitleBox />
          <div className='home-div-body'>
            <Paper elevation={1} className='prompt-wrapper-paper-padding'>
              {props.children}
            </Paper>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

function mapStateToProps(state) {
  return {
    username: authPullModular(state, 'username')
  }
}

export default withRouter(connect(mapStateToProps)(PromptWrapper));