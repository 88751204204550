import React from "react";
import { connect } from "react-redux";
import ScriptText from "../../../../components/ScriptText";
import { pullModular as promptPullModular } from '../../../../store/prompt/reducer';

const PrepaidInstructions = (props) => {
  return (
    <ScriptText>
      {
      props.from.prepaidInstructionsNote 
      ?
      props.from.prepaidInstructionsNote 
      :
      'Please scan the prepaid reservation barcode and the gate will open for you. If you are scanning from your phone, please make sure the brightness is set to high.'
      }
    </ScriptText>
  )
}

function mapStateToProps(state) {
  //just use PullModular errywhrr
  return {
    from: promptPullModular(state, 'from')
  }
}
export default (connect(mapStateToProps)(PrepaidInstructions));