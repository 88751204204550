import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Link } from 'react-router-dom';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import ScriptText from './ScriptText';

export default class MonthlyOnlyLane extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            <Paper zDepth={1} className="paper-padding">
              <ScriptText>"You are in a Monthly-Only lane. Please redirect to one of the following lanes: {this.props.ticketAcceptanceRedirect}."</ScriptText>
              <Link to="/confirmationScreen">
                <RaisedButton label="Complete Call" />
              </Link>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}
