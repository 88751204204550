import React from "react";
import { connect } from "react-redux";
import ScriptText from "../../../../components/ScriptText";
import { pullModular as promptPullModular } from '../../../../store/prompt/reducer';

const PulledTicket = (props) => {
  return (
    <ScriptText>
      {props.from.pulledTicketNote 
      ? 
      props.from.pulledTicketNote 
      : 
      'Please insert the ticket and then scan your prepaid barcode. If you are scanning from your phone, please make sure the brightness is set to high.'}
    </ScriptText> 
  )
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from')
  }
}

export default (connect(mapStateToProps)(PulledTicket));