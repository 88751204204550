import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptActions from '../../../store/prompt/actions';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as queueSelectors from '../../../store/queue/reducer';
import * as authSelectors from '../../../store/auth/reducer';
import * as queueActions from '../../../store/queue/actions';
import MonthlyParkersList from '../../../components/monthlyParkersList';
import MonthlyParkersListApb from '../../../components/monthlyParkersListApb';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import ContactManagers from '../ContactManagers';
import ScriptText from '../../../components/ScriptText';
import DirectionText from '../../../components/DirectionText';
import CircularProgress from 'material-ui/CircularProgress';
import PromptBox from '../../../components/promptBox';
import _ from 'lodash';
import moment from 'moment-timezone';
import Holidays from 'date-holidays';
import SocketContext from '../../../services/socketContext'
import { TextField } from '@material-ui/core';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import MuiPhoneNumber from 'material-ui-phone-number';
import { titleCase } from "title-case";
import { retrieveLogger } from '../../../lib/logger';
import ErrorBoundary from '../../ErrorBoundary';
let logger = retrieveLogger();
moment.suppressDeprecationWarnings = true;

class MonthlyVerification extends Component {
  constructor(props) {
    super(props);
    this.reloadInterval = null;
    autoBind(this);
    this.state = {
      renderComponent: {
        showMonthlyList: true,
        showManagersList: false,
        showMonthlyRedirect: false,
        showCalculateFeeByDateForm: false,
        showCardAcceptancePrompt: false,
        showMonthlyNotFound: false,
        showMonthlyNotFoundTIBA: false,
        showMonthlyFound: false,
        showManagerNoResponse: false
      },
      ticketNumber: null, parkerEntryDateResult: null, shouldVend: null, calculatedParkerRate: null, redirectToLevel: null,
      parkerEntranceDate: null, parkerEntranceDateDisplay: null, parkerEntranceTime: null, parkerEntranceTimeDisplay: null,
      enableTicketNotFoundButton: false, monthlyLoadHang: false, managerName: null, monthlyIssueType: null,
      showCompanyNameField: false, showCardNumberField: false, parkerData: {}, parkerName: null
    };

    const loadOptions = inputValue => this.flashGetMonthlyNames(inputValue);
    this.debouncedLoadOptions = _.debounce(loadOptions, 1000, {
      leading: true
    });
  }

  componentWillMount() {
    if (this.props.from.monthlyFeed) {
      this.props.dispatch(promptActions.updateMonthlyParkers(this.props.from.locationId));
    }
  }

  updatePhoneNumber(value) {
    this.props.dispatch(promptActions.modularSendField((value).slice(2), 'ticketDiscrepancyPhoneNumber'));
  }

  async componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    const { issue } = this.props;
    if (issue.subtype == 'cardAcceptanceIssue' && this.props.from.monthlyCardAcceptanceNote) {
      this.showComponent(['showCardAcceptancePrompt']);
    }
    // reset global step prop
    this.props.dispatch(promptActions.updateIssueStep(0));

    try {
      const { googleSheetInfo } = this.props.from;
      if (googleSheetInfo && googleSheetInfo.monthlyData) {
        this.props.dispatch(promptActions.modularSendField(true, 'showLoader'));
        const result = await this.props.dispatch(promptActions.getMonthlyListFromSheets({ googleSheetInfo }));
        const { sheetData } = result.data;
        const options = await sheetData.map(row => {
          return {
            'value': titleCase(row[0].replace(",", " ")),
            'label': titleCase(row[0].replace(",", " ")),
            'name': row[0],
            'status': row[1],
            'notes': row[2]
          }
        });
        await this.props.dispatch(promptActions.modularSendField(options, 'monthlyParkersListApb'));
        this.props.dispatch(promptActions.modularSendField(false, 'showLoader'));
      }
    } catch (error) {
      logger.error(error);
    }

    if (!this.state.monthlyLoadHang) {
      // 15 seconds after we load the Monthly Verification component, set monthlyLoadHang to true.
      setTimeout(() => {
        this.setState({
          monthlyLoadHang: true
        });
      }, 15000)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }
    if (this.props.nextStepsLoading !== nextProps.nextStepsLoading) {
      return true;
    }
    if (this.props.rerender === false && nextProps.rerender === true) {
      return true;
    }
    if (nextProps.monthlyParkersListApbFiltered.length !== this.props.monthlyParkersListApbFiltered.length) {
      return true;
    }
    if (nextProps.monthlyParkersListApb.length !== this.props.monthlyParkersListApb.length) {
      return true;
    }
    if (nextProps.searchCriteria !== this.props.searchCriteria) {
      return true;
    }
    if (nextProps.selectedParker !== this.props.selectedParker) {
      return true;
    }
    if (nextProps.selectedParkerCardNumber !== this.props.selectedParkerCardNumber) {
      return true;
    }
    if (nextProps.selectedParkerName !== this.props.selectedParkerName) {
      return true;
    }
    if (nextProps.managerVendConfirmation10s !== this.props.managerVendConfirmation10s) {
      return true;
    }
    if (nextProps.step !== this.props.step) {
      return true;
    }
    if (nextProps.companyName !== this.props.companyName) {
      return true;
    }
    if (nextProps.licensePlateNumber !== this.props.licensePlateNumber) {
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentWillUnmount() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  monthlyParkerSearch(event) {
    if (this.reloadInterval) {
      clearTimeout(this.reloadInterval);
    }
    let val = event.target.value;
    this.reloadInterval = setTimeout(() => {
      this.props.dispatch(promptActions.monthlyParkerSearch(val));
      if (this.props.from.parcs === 'wps' && this.props.monthlyParkersListApbFiltered.length <= 3) {
        this.props.monthlyParkersListApbFiltered.forEach((parker) => {
          this.props.dispatch(promptActions.retrieveCustomer(this.props.from.locationId, parker.CustomerId));
        })
      }
      this.props.dispatch(promptActions.manualReload());
    }, 500);
    this.props.dispatch(promptActions.modularSendField(val, "selectedParkerName"));
  }

  sendParkerToState(column, row, event) {
    let selectedParker = this.props.monthlyParkersList[column];
    this.props.dispatch(promptActions.sendParkerToState(selectedParker));
    this.props.dispatch(promptActions.modularSendField(selectedParker.name, "selectedParkerName"));
    this.props.dispatch(promptActions.modularSendField(selectedParker.cardNumber, "selectedParkerCardNumber"));
    this.props.dispatch(promptActions.modularSendField(column, 'selectedParkerIndex'));
  }

  sendParkerToStateApb(column, row, event) {
    let selectedP = this.props.monthlyParkersListApbFiltered[column];
    this.props.dispatch(promptActions.sendParkerToState(selectedP));
    if (this.props.from.parcs === 'tibaAPI') {
      this.tibaApi(selectedP, column);
      return;
    }
    const currentTime = moment().utc();
    const timezone = this.props.from.timezone;
    const disableVendStartTime = moment.tz(this.props.from.disableVendStartTime, 'hhmm', timezone);
    const disableVendEndTime = moment.tz(this.props.from.disableVendEndTime, 'hhmm', timezone);


    this.props.dispatch(promptActions.modularSendField(true, 'nextStepsLoading'));
    this.props.dispatch(promptActions.retrieveCustomer(this.props.from.locationId, selectedP.CustomerId));
    this.props.dispatch(promptActions.modularSendField(`${selectedP.FName} ${selectedP.LName}`, "selectedParkerName"));
    this.props.dispatch(promptActions.modularSendField(selectedP.Badge, "selectedParkerCardNumber"));
    this.props.dispatch(promptActions.modularSendField(column, 'selectedParkerIndex'));

    setTimeout(() => {
      if (this.props.from.monthlyVendAlways) {
        this.props.history.push("/vendGate")
      }
      if (selectedP.Status && selectedP.Status.toLowerCase() === 'terminated') {
        this.props.dispatch(promptActions.modularSendField(true, 'terminationLockedOut'));
      } else {
        this.props.dispatch(promptActions.modularSendField(false, 'terminationLockedOut'));
      }
      if (selectedP.MType == 0) {
        this.props.dispatch(promptActions.modularSendField(true, 'terminationLockedOut'));
      }
      this.props.dispatch(promptActions.modularSendField(false, 'nextStepsLoading'));
      this.props.dispatch(promptActions.modularSendField(true, 'rerender'));
      this.props.dispatch(promptActions.modularSendField(false, 'rerender'));
    }, 1500);

    if (this.props.from.parcs !== 'wps') {
      if (selectedP.LastTrans === 0 || selectedP.LastTrans === 'Neutral') {
        this.props.dispatch(promptActions.modularSendField(false, 'apbLockedOut'));
      }
      if (this.props.from.laneType === 'Exit' && (selectedP.LastTrans === 51 || selectedP.LastTrans === 'Allowed Out' || selectedP.LastTrans == null)) {
        this.props.dispatch(promptActions.modularSendField(false, 'apbLockedOut'));
      }
      if (this.props.from.laneType === 'Exit' && (selectedP.LastTrans === 52 || selectedP.LastTrans === 'Allowed In')) {
        // CLOUD-816
        if (!this.props.from.allowAntiPassback) {
          this.props.dispatch(promptActions.modularSendField(true, 'apbLockedOut'));
        }
      }
      if (this.props.from.laneType === 'Entrance' && (selectedP.LastTrans === 51 || selectedP.LastTrans === 'Allowed Out')) {
        // CLOUD-816
        if (!this.props.from.allowAntiPassback) {
          this.props.dispatch(promptActions.modularSendField(true, 'apbLockedOut'));
        }
      }
      if (this.props.from.laneType === 'Entrance' && (selectedP.LastTrans === 52 || selectedP.LastTrans === 'Allowed In' || selectedP.LastTrans == null)) {
        this.props.dispatch(promptActions.modularSendField(false, 'apbLockedOut'));
      }
    }
    // Currently, only MHMP locations will have an allowedAccessLevels locVar.
    if (this.props.from.allowedAccessLevels && (selectedP.AccessProfileID || selectedP.AccessProfileID === 0)) {
      // We want the value to be null unless it is for a record where the variable applies. So it is by default null, then if the variable applies, make it false.
      this.props.dispatch(promptActions.modularSendField(false, 'invalidAccessLocation'));
      // Then, if the provided allowedAccessLevels does not include the level that the parker has, set to true.
      if (!this.props.from.allowedAccessLevels.includes(Number(selectedP.AccessProfileID))) {
        this.props.dispatch(promptActions.modularSendField(true, 'invalidAccessLocation'));
      }
      // We also need to check on the parker's access level, and if it is a 3, only allow vending during a specified time.
      // The value of 3 is hard-coded based on the specific needs of MHMP. This is not scalable, and we will need to make it scalable for another location to use this.
      if (this.props.from.locationId === 542 && selectedP.AccessProfileID === 3) {
        this.props.dispatch(promptActions.modularSendField(false, 'invalidAccessTime'));
        // If MHMP and the person is time-gated, invalidAccessTime is now relevant. So change it from NULL to FALSE, and then TRUE if we trigger invalid time (below).
        if (currentTime.isAfter(disableVendStartTime) && currentTime.isBefore(disableVendEndTime)) {
          this.props.dispatch(promptActions.modularSendField(true, 'invalidAccessTime'));
        }

      }
      return;
    }
    if (this.props.from.invalidAccessTimeEnabled && this.props.from.disableVendStartTime && this.props.from.disableVendEndTime) {
      selectedP = this.props.monthlyParkersListApbFiltered[column];
      let skip = false;
      let selectedPInterval = setInterval(() => {
        if (selectedP.AfterHours || selectedP.Status) {
          clearInterval(selectedPInterval);
          selectedPInterval = 0;
        }
        if (this.props.from.parcs === 'wps' && !selectedP.AfterHours) {
          // For WPS locations, (or any locations that want to disable vend based on parker status instead of raw time: this will ahve to be hard coded on top of this)
          // let's return if the parker is not an "after hours" as this won't apply to them.
          skip = true;
        }

        let weekend = ['Saturday', 'Sunday'];
        // Get the day name
        let day = moment().tz(timezone).format('dddd');
        // Get the current year
        let year = moment().tz(timezone).format('YYYY');
        // Initialize the Holiday Library
        let hd = new Holidays('US');
        // Get all the holidays for the current year. This is a json which can be changed according to the library.
        let thisYearHolidays = hd.getHolidays(year);
        // Loop over the holidays and find if current time is between the holiday start and end time.
        thisYearHolidays.forEach((key) => {
          const holidayStartDate = moment.tz(key.start, 'hhmm', timezone);
          const holidayEndDate = moment.tz(key.end, 'hhmm', timezone);
          // If it is a holiday allow allow the parker to park
          if ((currentTime >= holidayStartDate && currentTime <= holidayEndDate) || weekend.includes(day)) {
            skip = true;
          } else {
            skip = false;
          }
        });

        if (skip === false) {
          if (currentTime.isAfter(disableVendStartTime) && currentTime.isBefore(disableVendEndTime) && selectedP.AfterHours) {
            this.props.dispatch(promptActions.modularSendField(true, 'invalidAccessTime'));
          }
        }
      }, 1000);
    }
  }

  migrateSearchToSelected() {
    // Selected Parker is an object, not a string so convert
    let sel = { name: this.props.searchCriteria };
    this.props.dispatch(promptActions.migrateSearchToSelected(sel));
    // When submitting search text as parker, if a manager list was supposed to show but didn't, that is an issue. So let's contact manager...
    if (this.props.from.monthlyFeed) {
      if (this.props.from.monthlySearchTextVend) {
        this.props.history.push('/vendGate');
      } else {
      this.props.history.push('/contactManagers');
    }
      // Otherwise, it is business as usual and let's just vend it.
    } else if (this.props.from.monthlyContactManager) {
      if (this.props.from.antiPassbackIntegrated) {
        this.showComponent(['showManagersList']);
      } else {
        this.props.history.push('/contactManagers');
      }
    } else {
      this.props.history.push('/vendGate');
    }
  }

  updateMonthlyParkerName(event) {
    this.props.dispatch(promptActions.modularSendField(event.target.value, "selectedParkerName"));
  }

  updateMonthlyCardNumber(event) {
    this.props.dispatch(promptActions.modularSendField(event.target.value, "selectedParkerCardNumber"));
  }

  updateField(event, field) {
    this.props.dispatch(promptActions.modularSendField(event.target.value, field));
  }

  updateSelectFieldInput(value, options) {
    if (options.action == "input-change") {
      this.props.dispatch(promptActions.modularSendField(value, "selectedParkerName"));
    }
  }

  updateSelectField(event, target) {
    this.props.dispatch(promptActions.modularSendField(event.value, target));
  }

  completeStep(step) {
    this.props.dispatch(promptActions.updateIssueStep(step));
  }

  // Found button leverages selectedParker.name
  shouldShowFoundButton() {
    if (this.props.selectedParker && this.props.selectedParker.name && this.props.selectedParker.name.trim().length > 3) {
      return true;
    }
    return false;
  }

  // Submit Search Text button leverages selectedParkerName
  shouldShowSubmitSearchTextButton() {
    const { companyNameRequired, cardNumberRequired, monthlyLicensePlateRequired, monthlyShowSubmitSearchText } = this.props.from;
    const { companyName, selectedParkerCardNumber, licensePlateNumber } = this.props;

    // Moved these checks above the monthlyLoadHang check as it is necessary.
    // This is set to true for Williams Tower, (and maybe many others, but that's my current test-case), but we do not want it to be. It is causing this to trigger when it shouldnt.
    if (companyNameRequired) {
      if (!companyName) {
        return false;
      }
    }
    // Probably same as above.
    if (cardNumberRequired) {
      if (!selectedParkerCardNumber) {
        return false;
      }
    }

    if (monthlyLicensePlateRequired) {
      if (!licensePlateNumber) {
        return false;
      }
    }

    if (monthlyShowSubmitSearchText) {
      return true;
    }

    // Check for existence of the fields we are conditioning below this line.
    if (!this.props.searchCriteria && !this.props.selectedParkerName) {
      return false;
    }

    // No matter what, if the person's name (either selected or typed) is shorter than 3 characters, don't let the button show.
    if (this.props.searchCriteria.length < 3 && this.props.selectedParkerName.length < 3) {
      return false;
    }

    // monthlyLoadHang is not the BEST way to do it, but is the fastest to implement. In "new mind maps", we should instead watch for an error on the server when retrieving monthlies.
    // That error should bubble its' way back to the client and we should detect the existence of that error to do the job monthlyLoadHang currently is.
    if (this.props.from.monthlyFeed && this.props.monthlyParkersList.length == 0 && this.props.monthlyParkersListApb.length == 0 && this.state.monthlyLoadHang) {
      return true;
    }

    // If APB is integrated and a list is showing, definitely disable.
    if (this.props.from.antiPassbackIntegrated) {
      if (this.props.monthlyParkersListApb.length > 0) {
        return false;
      }
    }

    // Locations that have monthly feeds should not show the list before hang, no matter what.
    if (this.props.from.monthlyFeed && !this.state.monthlyLoadHang) {
      return false;
    }
    return true;
  }

  shouldShowApbAllowButton() {
    if (this.props.nextStepsLoading === true) {
      return false;
    }
    if (this.props.apbLockedOut === true ||
      this.props.terminationLockedOut === true ||
      this.props.wrongFacilityLockedOut === true ||
      this.props.invalidAccessLocation === true ||
      this.props.invalidAccessTime === true) {
      return false;
    }
    // Have to do that weird "||" because 0 index equates to false, and that will be the case often.
    if (this.props.from.antiPassbackIntegrated && (this.props.selectedParkerIndex || this.props.selectedParkerIndex === 0)) {
      return true;
    }
    return false;
  }

  shouldShowContactManagerButton() {
    if (this.props.nextStepsLoading === true) {
      return false;
    }
    if ((this.props.apbLockedOut === true ||
      this.props.terminationLockedOut === true ||
      this.props.wrongFacilityLockedOut === true ||
      this.props.invalidAccessLocation === true ||
      this.props.invalidAccessTime === true) && !this.props.from.monthlyApbRedirectPIL) {
      return true;
    }
    return false;
  }

  shouldShowRedirectPILButton() {
    return this.props.from.monthlyApbRedirectPILNote && this.props.apbLockedOut;
  }

  shouldShowVendButton() {
    // This is for legacy use of selectedParker as an object.
    if (this.props.selectedParker &&
      this.props.selectedParker.name !== undefined &&
      this.props.selectedParker.name !== null &&
      this.props.selectedParker.name.trim().length >= 4) {
      return true;
    }

    if (!this.props.selectedParkerName ||
      this.props.selectedParkerName === undefined ||
      this.props.selectedParkerName === null ||
      this.props.selectedParkerName === '' ||
      this.props.selectedParkerName.trim().length <= 3) {
      return false;
    }

    if (this.props.from.companyNameRequired) {
      if (!this.props.companyName ||
        this.props.companyName === undefined ||
        this.props.companyName === null ||
        this.props.companyName.length <= 0) {
        return false;
      }
    }
    return true;
  }

  generateRedirectLanes() {
    let availableLanes = [];
    let propsFrom = this.props.from;
    let allLanes = this.props.from.laneList;
    let currentLaneType = this.props.from.laneType;
    for (let lane in allLanes) {
      if (allLanes[lane].monthlyLane && allLanes[lane].laneType === currentLaneType) {
        availableLanes.push(propsFrom.laneList[lane].name);
      }
    }
    return _.map(availableLanes, this.generateAvailableLanes)
  }

  generateAvailableLanes(lane) {
    return (<li>{lane}</li>)
  }

  generateFilteredParkers() {
    try {
      let filteredParkers = this.props.monthlyParkersListApb.filter((parker, index, array) => {
        if (`${parker.FName} ${parker.LName}`.toLowerCase().match(this.props.searchCriteria.toLowerCase())) {
          return true;
        } else if (`${parker.Badge}`.match(`${this.props.searchCriteria}`)) {
          return true;
        } else {
          return false;
        }
      });
      this.props.dispatch(promptActions.modularSendField(filteredParkers, 'monthlyParkersListApbFiltered'));
    } catch (e) {
      logger.error(e);
    }
  }

  sendMonthlyRequest() {
    if (this.props.from.parcs === 'amano-pi') {
      queueActions.sendMonthlyRequest(this.props.from.locationId, this.props.username);
    }
  }

  apbAlert() {
    if (!this.props.selectedParker) {
      return '';
    }
    if (this.props.terminationLockedOut === true) {
      return (
        <Paper zDepth={1} className="paper-padding prompt-body apb-alert-violation" style={{ marginTop: '20px' }}>
          <p className="apb-alert-violation">This parker's monthly pass has expired. Direct them to parking management.</p>
        </Paper>
      );
    }
    if (this.props.apbLockedOut === false) {
      return (
        <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
          <p>This parker is verified and should be let out.</p>
        </Paper>
      )
    }
    if (this.props.apbLockedOut === true) {
      return (
        <Paper zDepth={1} className="paper-padding prompt-body apb-alert-violation" style={{ marginTop: '20px' }}>
          <p className="apb-alert-violation">This parker is violating anti-passback policy. Direct them to parking management.</p>
        </Paper>
      )
    }
  }

  clearLockoutData() {
    this.props.dispatch(promptActions.modularSendField(true, 'parkerNotFound'));
    this.props.dispatch(promptActions.modularSendField(false, 'apbLockedOut'));
    this.props.dispatch(promptActions.modularSendField(false, 'terminationLockedOut'));
    this.props.dispatch(promptActions.modularSendField(false, 'wrongFacilityLockedOut'));
    this.props.dispatch(promptActions.modularSendField(null, 'selectedParkerName'));
    this.props.dispatch(promptActions.modularSendField(null, 'selectedParkerCardNumber'));
    this.props.dispatch(promptActions.modularSendField(null, 'selectedParkerAccount'));
    const { monthlyNotFoundManager, monthlyNotFoundDirectToTransient, monthlyCalculateFee, lostTicketLPR } = this.props.from;
    if (monthlyNotFoundManager) {
      this.showComponent(['showManagersList']);
    } else if (monthlyNotFoundDirectToTransient) {
      this.props.history.push('/monthly/transient');
    } else if (this.props.from.monthylNotFoundForm) {
      this.showComponent(['showMonthlyNotFound'])
    } else if (monthlyCalculateFee) {
      if (lostTicketLPR) {
        this.showComponent(['showMonthlyNotFoundTIBA']);
      } else {
        this.props.history.push('/tibaAPI/tibaRateByTicket');
      }
    } else if (this.props.from.monthlyNotFoundTicketAcceptance) {
      this.props.history.push('/transient/ticketAcceptanceIssue');
    } else {
      this.props.history.push('/monthly/notFound');
    }
  }

  clearParkerData() {
    this.props.dispatch(promptActions.modularSendField(false, 'apbLockedOut'));
    this.props.dispatch(promptActions.modularSendField(false, 'terminationLockedOut'));
    this.props.dispatch(promptActions.modularSendField(false, 'wrongFacilityLockedOut'));
    this.props.dispatch(promptActions.modularSendField(null, 'selectedParkerName'));
    this.props.dispatch(promptActions.modularSendField(null, 'selectedParkerCardNumber'));
    this.props.dispatch(promptActions.modularSendField(null, 'selectedParkerAccount'));
  }

  saveIssueType(data) {
    this.setState({ monthlyIssueType: data });
    this.props.dispatch(promptActions.modularSendField(data.value, 'monthlyCardIssue'));
  }

  saveMonthlyNotFound() {
    const { selectedParkerName, companyName, monthlyCardIssue, issue } = this.props;
    if (issue.subtype == 'noCard' && (!selectedParkerName || !companyName)) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: 'All fields are required to Vend the Gate.'
      }))
    } else if (issue.subtype == 'cardAcceptanceIssue' && (!selectedParkerName || !companyName || !monthlyCardIssue)) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: 'All fields are required to Vend the Gate.'
      }))
    } else {
      this.props.history.push('/vendGate');
    }
  }

  async tibaApi(parker, column) {
    this.clearParkerData();
    const { MonthlyID, FName, LName, Badge, LastTransaction, ParkerStatus, MonthlyType, AccessProfileID, Terminated } = parker;
    this.props.dispatch(promptActions.modularSendField(true, 'nextStepsLoading'));
    this.props.dispatch(promptActions.modularSendField(`${FName} ${LName}`, "selectedParkerName"));
    this.props.dispatch(promptActions.modularSendField(Badge, "selectedParkerCardNumber"));
    this.props.dispatch(promptActions.modularSendField(column, 'selectedParkerIndex'));
    // Notice we wrap this whole functionality inside of another if to only trigger if we plug locVars in that are relevant.
    // This was triggering for locations that it shouldn't...
    if (this.props.from.monthlyParkerAccessId || this.props.from.skipParkerAccessCheck) {
      // Instead of wrapping inside of a setTimeout, you can use promises to make the wait a bit cleaner.
      await new Promise((resolve) => { setTimeout(() => { resolve(); }, 1500) });
      // Access not allowed "skipParkerAccessCheck" is to avoid the first check
      if (this.props.from.monthlyParkerAccessId && this.props.from.monthlyParkerAccessId.includes(AccessProfileID) || this.props.from.skipParkerAccessCheck) {
        // Terminated
        const isTerminated = (Terminated || MonthlyType == 0);
        this.props.dispatch(promptActions.modularSendField(isTerminated, 'terminationLockedOut'));
        // If parker status is Unknown then no AntiPassback
        if (ParkerStatus == 'Unknown') {
          this.props.dispatch(promptActions.modularSendField(false, 'apbLockedOut'));
        } else if (this.props.from.laneType == "Entrance" && ParkerStatus == 'In') {
          this.props.dispatch(promptActions.modularSendField(true, 'apbLockedOut'));
        } else if (this.props.from.laneType == "Exit" && ParkerStatus == 'Out') {
          this.props.dispatch(promptActions.modularSendField(true, 'apbLockedOut'));
        } else {
          this.props.dispatch(promptActions.modularSendField(false, 'apbLockedOut'));
        }
        this.showComponent(['showMonthlyList']);
      } else {
        // Find where the parker belongs
        if (AccessProfileID) {
          // We need to add all of the lane IDs here. This will not scale past HFC either as we are hard-coding values here.
          // Also, we need to update the Level 2, 3, 4 for North to have the proper monthlyParkerAccessId values.
          const accessProfile = [
            {
              id: 23,
              redirectToLevel: 'Please redirect the parker to Level 2.',
            },
            {
              id: 25,
              redirectToLevel: 'Please redirect the parker to Level 4.'
            },
            {
              id: 42,
              redirectToLevel: 'Please redirect the parker to Level 2.'
            },
            {
              id: 43,
              redirectToLevel: 'Please redirect the parker to Level 4.'
            },
            {
              id: 35,
              redirectToLevel: 'Please redirect the parker to Level 4.'
            },
            {
              id: 52,
              redirectToLevel: 'Please redirect the parker to Level 2.'
            }
          ];
          const profileResult = await accessProfile.find(access => access.id == AccessProfileID);
          if (profileResult) {
            this.setState({ redirectToLevel: profileResult.redirectToLevel });
          }
          this.props.dispatch(promptActions.modularSendField(true, 'wrongFacilityLockedOut'));
          this.showComponent(['showMonthlyList', 'showMonthlyRedirect']);
        }
      }
    }
    this.props.dispatch(promptActions.modularSendField(false, 'nextStepsLoading'));
    this.props.dispatch(promptActions.modularSendField(true, 'rerender'));
    this.props.dispatch(promptActions.modularSendField(false, 'rerender'));
  }

  async flashSubmitParkerDetails(routeTo) {
    if (routeTo == 'notFound') {
      this.props.dispatch(promptActions.modularSendField(true, 'parkerNotFound'));
      if (this.props.from.monthlyNotFoundTicketAcceptance) {
        this.props.history.push('/transient/ticketAcceptanceIssue');
      } else {
        this.showComponent(['showMonthlyNotFound']);
      }
    } else if (routeTo == 'found') {
      if (!this.state.parkerName) {
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Select a valid parker'
        }));
      } else {
        this.props.dispatch(promptActions.modularSendField(false, 'parkerNotFound'));
        if (this.props.from.flashMonthlyVend) {
          this.props.history.push('/vendGate');
        } else {
          const toContactManagers = await this.shouldShowContactManagerButton()
          if (toContactManagers) {
            this.showComponent(['showMonthlyFound']);
          } else {
            this.props.history.push('/vendGate');
          }
        }
      }
    }
  }

  async flashGetParkerDetails(data) {
    if (data) {
      this.props.dispatch(promptActions.modularSendField(true, 'showLoader'));
      const { monthlyID, parkerName, companyName, status, cardNumber } = data;
      this.props.dispatch(promptActions.modularSendField(monthlyID, 'flashMonthlyCardID'));
      this.props.dispatch(promptActions.modularSendField(parkerName, 'selectedParkerName'));
      this.props.dispatch(promptActions.modularSendField(status, 'selectedParkerMonthlyStatus'));
      const result = await this.props.dispatch(promptActions.flashGetMonthlyAccountDetails(data));
      const { monthlyAccountTag } = result.data;
      if (monthlyAccountTag) {
        this.props.dispatch(promptActions.modularSendField(monthlyAccountTag, 'selectedParkerCardNumber'));
      }
      // Parkers card is blocked.
      if (status.toLowerCase() == 'invalid') {
        this.props.dispatch(promptActions.modularSendField(true, 'terminationLockedOut'));
      } else {
        this.props.dispatch(promptActions.modularSendField(false, 'terminationLockedOut'));
      }
      // if not company name - get it from the parker in the form field.
      if (!companyName) {
        this.setState({ showCompanyNameField: true });
      } else if (this.props.from.monthlyAllowVend && this.props.from.monthlyAllowVend.includes(companyName)) {
        this.props.dispatch(promptActions.modularSendField(companyName, 'companyName'));
        this.props.history.push('/monthly/flashResetMonthlyCard');
      } else {
        this.setState({ showCompanyNameField: false });
        this.props.dispatch(promptActions.modularSendField(companyName, 'companyName'));
      }
      if (!this.props.selectedParkerCardNumber) {
        this.setState({ showCardNumberField: true });
      } else {
        this.setState({ showCardNumberField: false });
      }
      this.props.dispatch(promptActions.modularSendField(false, 'showLoader'));
    }
  }

  async shouldShowPIL() {
    const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('monthly'));
    if (shouldEnablePIL) {
      return true;
    }
  }

  async flashGetMonthlyNames(value) {
    this.setState({ parkerName: value });
    return new Promise(async (resolve, reject) => {
      const result = await this.props.dispatch(promptActions.flashMonthlyAccountSearch({ parkerName: value }));
      const { success, monthlyList, message } = result.data;
      if (success && monthlyList) {
        const filtered = _.filter(monthlyList, o =>
          _.startsWith(_.toLower(o.label), _.toLower(value))
        );
        resolve(filtered.slice(0, 3));
      } else {
        this.props.dispatch(promptActions.modularSendField(value, 'selectedParkerName'));
        this.props.dispatch(promptActions.showSnackBar({ type: 'error', message: message }));
      }
    });
  }

  generateDirectionText(note) {
    return (
      <DirectionText>
        {note}
      </DirectionText>
    )
  }

  saveDate(date, target) {
    const formattedDate = moment(date).format('YYYY:MM:DD');
    this.setState({ parkerEntranceDate: formattedDate, parkerEntranceDateDisplay: date });
  }

  saveTime(time, target) {
    const formattedTime = moment(time).startOf('minute').format('THH:mm:ss');
    this.setState({ parkerEntranceTime: formattedTime, parkerEntranceTimeDisplay: time });
  }

  updateState(event, target) {
    this.setState({ [target]: event.target.value });
  }

  // Google Sheet Integration List
  async submitMonthlyParker() {
    const { monthlyParkersListApb, selectedParkerName } = this.props;
    const parkerData = await monthlyParkersListApb.find(parker => titleCase((parker.name).replace(",", " ")) == titleCase((selectedParkerName).replace(",", " ")));
    if (_.lowerCase(parkerData.status).trim() == 'active' || (this.props.from.monthlyAllowVend)) {
      this.props.history.push('/vendGate');
      return;
    }
    if ((_.lowerCase(parkerData.status).trim() == 'terminated') || (_.lowerCase(parkerData.status).trim() == 'blocked')) {
      if (this.props.from.monthlyAllowVend) {
        this.props.history.push('/vendGate');
        return;
      } else if (this.props.from.monthlyRedirect) {
        this.showComponent(['showMonthlyRedirect']);
        return;
      } else {
        this.props.dispatch(promptActions.modularSendField(true, 'terminationLockedOut'));
        this.setState({ parkerData: parkerData });
        this.showComponent(['showMonthlyFound']);
      }
    } else {
      this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: 'The google sheet has not been populated correctly. Please report this to management.'
      }))
    }
  }

  showComponent(toRender) {
    const allComponents = { ...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'noManagerResponsePIL':
        this.props.history.push('/pil/PILEntryInput');
        break;
      case 'showCalculateFeeByTicketForm':
        this.props.history.push('/tibaAPI/tibaRateByTicket');
        break;
      case 'showPushRateForm':
        this.props.history.push('/tibaAPI/tibaManagerPushRate');
        break;
      case 'ticketDiscrepancy':
        this.props.history.push('/ticketDiscrepancy');
        break;
      case 'ticketNotFound':
        this.showComponent(['showCalculateFeeByDateForm']);
        break;
      case 'showManagerNoResponse':
        this.props.dispatch(promptActions.modularSendField(true, 'noManagerResponse'))
        this.showComponent(['showManagerNoResponse']);
        break;
      case 'managerNoResponseVend':
        this.props.history.push('/vendGate');
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  render() {
    if (this.props.from.monthlyEntranceTicket && this.props.from.laneType == 'Entrance') {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className="home-div-content">
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <div className="home-div-body">
                <Paper zDepth={1} className="paper-padding">
                  <ScriptText>"Please pull a ticket and see the Garage Manager"</ScriptText>
                  <Link to="/confirmationScreen">
                    <RaisedButton label="Complete Call" />
                  </Link>
                </Paper>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      )
    }

    if ((this.props.from.locationId === 484 && !this.props.from.contractorLane && this.props.from.transientLane) ||
      (!this.props.from.monthlyLane && this.props.from.transientLane)) {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className="home-div-content">
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <div className="home-div-body">
                <Paper zDepth={1} className="paper-padding">
                  <ScriptText>"You are in a Transient-Only lane. Please redirect to one of the following lanes: {this.props.from.monthlyRedirect}."</ScriptText>
                  <Link to="/confirmationScreen">
                    <RaisedButton label="Complete Call" />
                  </Link>
                </Paper>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      );
    }

    if (!this.props.from.monthlyLane && !this.props.from.transientLane && this.props.from.specialMonthlyLane) {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className="home-div-content">
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <div className="home-div-body">
                <Paper zDepth={1} className="paper-padding">
                  <ScriptText>"You are in a {this.props.from.specialMonthlyLane} lane. Please redirect to one of the following lanes: "</ScriptText>
                  <div className="bullet-padding">
                    <ul>
                      {this.generateRedirectLanes()}
                    </ul>
                  </div>
                  <Link to="/confirmationScreen">
                    <RaisedButton label="Complete Call" />
                  </Link>
                </Paper>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      );
    }

    if (this.props.from.vendingSeconds === 0) {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className={`home-div-content`}>
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <div className="home-div-body">
                <ContactManagers showButtons={[]} />
              </div>
            </div>
          </div>
        </ErrorBoundary>
      );
    }

    // Used for Anti-Passback enabled locations
    if (this.props.from.antiPassbackIntegrated) {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className={`home-div-content`}>
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>

                {this.props.from.monthlyDynamicSearch
                ?
                  <Fragment>
                    {this.state.renderComponent.showMonthlyList &&
                      <Fragment>
                        <ScriptText>
                          May I please have your first and last name?
                        </ScriptText>
                        <AsyncSelect
                          className="MuiOutlinedInput-inputMarginDense max-z-index"
                          classNamePrefix="address-select"
                          placeholder="Enter Parker Name"
                          isClearable
                          value={{ value: this.state.parkerName, label: this.state.parkerName }}
                          loadOptions={inputValue => this.debouncedLoadOptions(inputValue)}
                          onChange={this.flashGetParkerDetails}
                        />

                        {this.state.showCompanyNameField &&
                          <Fragment>
                            <ScriptText>
                              May I please have your Company Name?
                            </ScriptText>
                            <TextField
                              label="Company Name"
                              margin="dense"
                              variant="outlined"
                              inputProps={{ className: 'data-hj-allow' }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => { this.updateField(e, 'companyName') }}
                              style={{ width: '100%', marginTop: 15 }}
                            />
                          </Fragment>
                        }

                        {this.state.showCardNumberField &&
                          <Fragment>
                            <ScriptText>
                              May I please have your card number?
                            </ScriptText>
                            <TextField
                              label="Card Number"
                              margin="dense"
                              variant="outlined"
                              inputProps={{ className: 'data-hj-allow' }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => { this.updateField(e, 'selectedParkerCardNumber') }}
                              style={{ width: '100%', marginTop: 15 }}
                            />
                          </Fragment>
                        }

                        <div style={{ marginTop: 15 }}>
                          {!this.props.selectedParkerCardNumber && <RaisedButton label="Not Found" onClick={() => this.flashSubmitParkerDetails('notFound')} />}
                          <RaisedButton label="Submit" onClick={() => this.flashSubmitParkerDetails('found')} />
                          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                        </div>
                      </Fragment>
                    }

                    {this.state.renderComponent.showMonthlyFound &&
                      <Fragment>
                        <ContactManagers showButtons={
                          [
                            'managerAssisting',
                            'vendGate',
                            'showCustomButton'
                          ]}
                          btnClick={this.btnClick}
                          btnFrom="monthly"
                          customButtonData={[
                            {
                              "label": "No Manager Response",
                              "action": "managerNoResponseVend"
                            },
                            {
                              "label": "No Manager Response",
                              "action": "showManagerNoResponse"
                            }
                          ]}
                        />
                      </Fragment>
                    }

                    {this.state.renderComponent.showMonthlyNotFound &&
                      <Fragment>
                        {this.props.from.laneType == "Entrance"
                        ?
                          <Fragment>
                            <ScriptText>
                              {
                                this.props.from.monthlyTicketlessAllowVendNote
                                ?
                                  this.props.from.monthlyTicketlessAllowVendNote
                                :
                                  'Please pull a ticket from the machine to enter. Contact area manager to get your card fixed.'
                              }
                            </ScriptText>
                            <div style={{ marginTop: 15 }}>
                              {this.props.from.monthlyEntranceNotFoundAllowVend &&
                                <RaisedButton label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />
                              }
                              <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                            </div>
                          </Fragment>
                        :
                          <Fragment>
                            {
                              this.props.from.monthlyTicketlessAllowVendNote
                              ?
                                <Fragment>
                                  <ScriptText>{this.props.from.monthlyTicketlessAllowVendNote}</ScriptText>
                                  <RaisedButton label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />
                                  <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                                </Fragment>
                              :
                                <ContactManagers showButtons={
                                  [
                                    'managerAssisting',
                                    'vendGate',
                                    'showCustomButton'
                                  ]}
                                  btnClick={this.btnClick}
                                  btnFrom="monthly"
                                  customButtonData={[
                                    {
                                      "label": "No Manager Response",
                                      "action": "showManagerNoResponse"
                                    }
                                  ]}
                                />}
                          </Fragment>
                        }
                      </Fragment>
                    }

                    {this.state.renderComponent.showManagerNoResponse &&
                      <Fragment>
                        <ScriptText>
                          {this.props.from.monthlyNoManagerResponseNote}
                        </ScriptText>
                        <div style={{ marginTop: 15 }}>
                          {this.shouldShowPIL
                          ?
                            <RaisedButton label="Pay Using Cell Phone" onClick={() => this.props.history.push('/pil/PILEntryInput')} />
                          :
                            <RaisedButton label="Ticket Discrepancy" onClick={() => this.props.history.push('/ticketDiscrepancy')} />
                          }
                          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                        </div>
                      </Fragment>
                    }
                  </Fragment>
                :
                  <Fragment>
                    {this.state.renderComponent.showCardAcceptancePrompt &&
                      <Fragment>
                        {_.map(this.props.from.monthlyCardAcceptanceNote, this.generateDirectionText)}

                        <Select
                          className="MuiOutlinedInput-inputMarginDense max-z-index"
                          placeholder="Select Issue Type"
                          onChange={this.saveIssueType}
                          value={this.state.monthlyIssueType}
                          style={{ marginTop: 15 }}
                          options={[{ value: "Access Badge Read Issue", label: "Access Badge Read Issue" }, { value: "Toll Tag Read Issue", label: "Toll Tag Read Issue" }]}
                        />

                        <TextField
                          label="Company Name"
                          margin="dense"
                          variant="outlined"
                          inputProps={{ className: 'data-hj-allow' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => { this.updateField(e, 'companyName') }}
                          style={{ width: '100%', marginTop: 15 }}
                        />

                        <div style={{ marginTop: 15 }}>
                          <RaisedButton label="Monthly List" disabled={!this.state.monthlyIssueType || !this.props.companyName} onClick={() => this.showComponent(['showMonthlyList'])} />
                          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                        </div>
                      </Fragment>
                    }

                    {this.state.renderComponent.showMonthlyList &&
                      <Fragment>
                        {this.props.from.monthlyLaneInstruction &&
                          <p> <span style={{ fontWeight: 'bolder', color: 'Red' }}> Note: </span> {this.props.from.monthlyLaneInstruction} </p>
                        }
                        <MonthlyParkersListApb
                          mPSearch={this.monthlyParkerSearch}
                          parkers={this.props.monthlyParkersListApb}
                          parkersFiltered={this.props.monthlyParkersListApbFiltered}
                          searchCriteria={this.props.searchCriteria}
                          sendParkerToState={this.sendParkerToStateApb}
                          selectedParker={this.props.selectedParker}
                          selectedParkerIndex={this.props.selectedParkerIndex}
                          generateFilteredParkers={this.generateFilteredParkers}
                          apbLockedOut={this.props.apbLockedOut}
                          locationId={this.props.from.locationId}
                          parcs={this.props.from.parcs}
                          parcsLaneId={this.props.from.parcsLaneId}
                          from={this.props.from}
                        />

                        {this.state.renderComponent.showMonthlyRedirect && this.props.from.monthlyRedirect &&
                          <ScriptText>
                            {this.props.from.monthlyRedirect} {this.state.redirectToLevel || null}
                          </ScriptText>
                        }

                        {this.props.nextStepsLoading === true && <CircularProgress />}
                        {this.props.monthlyParkersListApb && this.props.nextStepsLoading === false &&
                          <RaisedButton
                            label="Not Found"
                            onClick={() => this.clearLockoutData({
                              monthlyNotFoundManager: this.props.from.monthlyNotFoundManager,
                              monthlyNotFoundDirectToTransient: this.props.from.monthlyNotFoundDirectToTransient
                            })}
                            style={{ margin: '16px 10px' }}
                          />
                        }
                        {this.shouldShowApbAllowButton() && <RaisedButton label="Continue" onClick={() => this.props.history.push('/vendGate')} />}
                        {this.shouldShowRedirectPILButton() && <RaisedButton label="Direct Parker to Pay" onClick={() => { this.props.history.push('/transient/ticketAcceptanceIssue') }} />}
                        {this.shouldShowContactManagerButton() && <RaisedButton label="Contact Managers" onClick={() => this.showComponent(['showManagersList'])} />}
                        {(!this.props.monthlyParkersListApb[0] && !this.props.from.monthlyDynamicSearch) &&
                          <RaisedButton label="Retry Parker Pull" onClick={this.sendMonthlyRequest} />
                        }
                        {this.shouldShowSubmitSearchTextButton() &&
                          <RaisedButton label="Submit Search Text As Parker" style={{ margin: '16px 0' }} onClick={this.migrateSearchToSelected} />
                        }
                      </Fragment>
                    }

                    {this.state.renderComponent.showMonthlyNotFound &&
                      <Fragment>
                        <DirectionText>
                          Take down the Parker information and Vend the gate.
                        </DirectionText>
                        <TextField
                          label="Parker Name"
                          margin="dense"
                          variant="outlined"
                          inputProps={{ className: 'data-hj-allow' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => this.updateField(e, 'selectedParkerName')}
                          style={{ width: '100%', marginTop: 15 }}
                        />

                        {this.props.issue.subtype === "noCard" &&
                          <TextField
                            label="Company Name"
                            margin="dense"
                            variant="outlined"
                            inputProps={{ className: 'data-hj-allow' }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) => { this.updateField(e, 'companyName') }}
                            style={{ width: '100%', marginTop: 15 }}
                          />
                        }

                        <div style={{ marginTop: 15 }}>
                          <RaisedButton label="Vend Gate" onClick={() => this.saveMonthlyNotFound()} />
                          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                        </div>
                      </Fragment>
                    }

                    {this.state.renderComponent.showMonthlyNotFoundTIBA &&
                      <Fragment>
                        <ScriptText>
                          Did you pull a ticket on the entrance?
                        </ScriptText>
                        <div style={{ marginTop: 15 }}>
                          <RaisedButton label="Yes" onClick={() => this.props.history.push('/transient/ticketAcceptanceIssue')} />
                          <RaisedButton label="No" onClick={() => this.props.history.push('/tibaAPI/tibaLPRSearch')} />
                        </div>
                      </Fragment>
                    }

                    {this.state.renderComponent.showManagersList &&
                      <Fragment>
                        <ContactManagers showButtons={
                          [
                            !this.props.from.customManagerNoResponse && 'ticketDiscrepancy',
                            'managerAssisting',
                            'vendGate',
                            'showCustomButton'
                          ]}
                          btnClick={this.btnClick}
                          btnFrom="monthly"
                          customButtonData={[
                            {
                              "label": "No Manager Response",
                              "action": "noManagerResponsePIL"
                            },
                            {
                              "label": "No Manager Response",
                              "action": `${this.state.enableTicketNotFoundButton ? 'ticketDiscrepancy' : 'showCalculateFeeByTicketForm'}`
                            },
                            {
                              "label": "No Manager Response",
                              "action": "managerNoResponseVend"
                            },
                            {
                              "label": "Manager Asked to Push Rate",
                              "action": "showPushRateForm"
                            },
                          ]}
                        />
                      </Fragment>
                    }
                  </Fragment>
                }
              </Paper>
            </div>
          </div>
        </ErrorBoundary>
      )
    }

    // Location is integrated with monthly parkers and requires company name
    if (this.props.from.monthlyFeed && this.props.from.companyNameRequired) {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className={`home-div-content`}>
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
                <ScriptText>"May you please provide your name or Monthly Parking Card number?"</ScriptText>
                <DirectionText>NOTE: If the Termination Date has passed, please do not vend the gate for the parker.</DirectionText>

                <DirectionText>
                  Additionally, please verify the name of the parker's company's name.
                </DirectionText>
                {
                  this.props.from.additionalMonthlyText &&
                  <DirectionText>
                    {this.props.from.additionalMonthlyText}
                  </DirectionText>
                }
              </Paper>
              <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
                <MonthlyParkersList
                  mPSearch={this.monthlyParkerSearch}
                  parkers={this.props.monthlyParkersList}
                  searchCriteria={this.props.searchCriteria}
                  sendParkerToState={this.sendParkerToState}
                  selectedParker={this.props.selectedParker}
                  selectedParkerIndex={this.props.selectedParkerIndex}
                />

                {this.props.monthlyParkersList && (
                  <RaisedButton label="Not Found" onClick={() => {
                    this.props.dispatch(promptActions.modularSendField(true, 'parkerNotFound'))
                    this.props.history.push('/monthly/notFound')
                  }}
                    style={{ margin: '16px 10px' }} />
                )
                }
                {
                  (this.shouldShowFoundButton()
                    ?
                    (
                      <Link to="/vendGate">
                        <RaisedButton label="Submit Selected Parker" style={{ margin: '16px 0' }} />
                      </Link>
                    )
                    :
                    this.shouldShowSubmitSearchTextButton() &&
                    (
                      <Link to="/vendGate">
                        <RaisedButton label="Submit Search Text As Parker" style={{ margin: '16px 0' }} onClick={this.migrateSearchToSelected} />
                      </Link>
                    )
                  )
                }
              </Paper>
            </div>
          </div>
        </ErrorBoundary>
      )
    }

    //   Location is NOT integrated with monthly parkers but requires company name
    if (!this.props.from.monthlyFeed && this.props.from.companyNameRequired) {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className={`home-div-content ${this.props.isDialing && !this.props.onCall && 'flash-alert'}`}>
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <div className="home-div-body">
                <Paper zDepth={1} className="paper-padding">
                  {this.state.renderComponent.showMonthlyList &&
                    <Fragment>
                      <DirectionText>
                        This application has not integrated a monthly parking list yet. Please record parker's name and vend the gate for them.
                      </DirectionText>
                      <DirectionText>
                        Additionally, please verify the name of the parker's company's name.
                      </DirectionText>
                      {
                        this.props.from.additionalMonthlyText &&
                        <DirectionText>
                          {this.props.from.additionalMonthlyText}
                        </DirectionText>
                      }
                      {this.props.from.strictMonthly &&
                        <h3>
                          <b>For this location, do not let ANYONE out under any circumstances if you were not able to verify them.</b>
                        </h3>
                      }
                      <TextField
                        inputProps={{ className: 'data-hj-allow' }}
                        id="parker-name"
                        label="Parker Name"
                        hintText="Parker Name"
                        style={{ width: '98%' }}
                        onChange={this.updateMonthlyParkerName}
                      />
                      {this.props.from.companyNameRequired && (
                        <TextField
                          inputProps={{ className: 'data-hj-allow' }}
                          id="company-name"
                          label="Company Name"
                          hintText="Company Name / Unit Number"
                          style={{ width: '98%' }}
                          onChange={(e) => this.updateField(e, 'companyName')}
                        />
                      )}
                      {this.props.from.cardNumberRequired && (
                        <TextField
                          inputProps={{ className: 'data-hj-allow' }}
                          id="card-number"
                          label="Card Number"
                          hintText="Card Number"
                          style={{ width: '98%' }}
                          onChange={(e) => this.updateField(e, 'selectedParkerCardNumber')}
                        />
                      )}
                      {this.props.step > 0 && <ContactManagers showButtons={['managerAssisting', 'vendGate', 'ticketDiscrepancy']} />}
                      <div style={{ marginTop: 15 }}>
                        {this.props.step === 0 || (this.props.step === 0 && !this.props.from.noVerifyMonthlies) ? (
                          <RaisedButton
                            label="Unable to Verify Parker"
                            onClick={() => {
                              this.props.history.push('/monthly/notFound')
                            }}
                          />
                        ) : (
                          <Link to="/confirmationScreen">
                            <RaisedButton label="End Call" />
                          </Link>
                        )
                        }
                        {this.shouldShowSubmitSearchTextButton() && (
                          <RaisedButton
                            label={this.props.from.noVerifyMonthlies ? "Next Step" : "Able to Verify Parker"}
                            style={{ margin: '16px 0' }}
                            onClick={this.migrateSearchToSelected}
                          />
                        )
                        }
                      </div>
                    </Fragment>
                  }

                  {this.state.renderComponent.showManagersList &&
                    <Fragment>
                      <ContactManagers showButtons={
                        [
                          !this.props.from.customManagerNoResponse && 'ticketDiscrepancy',
                          'managerAssisting',
                          'vendGate',
                          'showCustomButton'
                        ]}
                        btnClick={this.btnClick}
                        btnFrom="monthly"
                        customButtonData={[
                          {
                            "label": "No Manager Response",
                            "action": "showManagerNoResponse"
                          },
                        ]}
                      />
                    </Fragment>
                  }

                  {this.state.renderComponent.showManagerNoResponse &&
                    <Fragment>
                      {this.props.from.laneType == 'Entrance' && <ScriptText> Please pull a ticket to enter the parking garage. </ScriptText>}
                      {this.props.from.laneType == 'Exit' && <DirectionText> Vend the gate for the parker. </DirectionText>}
                      <div style={{ marginTop: 15 }}>
                        {this.props.from.laneType == 'Exit' && <RaisedButton label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />}
                        <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                      </div>
                    </Fragment>
                  }
                </Paper>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      )
    }

    // Location is integrated with a Google Sheet that local managers update.
    if (this.props.from.monthlyFeed && this.props.from.googleSheetInfo && this.props.from.googleSheetInfo.monthlyData) {
      return (
        <PromptBox locationName={this.props.from.locationName}>
          {this.state.renderComponent.showMonthlyList &&
            <Fragment>
              <ScriptText> May I please have your name? </ScriptText>
              <Select
                className="MuiOutlinedInput-inputMarginDense max-z-index"
                classNamePrefix="address-select"
                value={{ 'value': this.props.selectedParkerName, 'label': this.props.selectedParkerName }}
                options={this.props.monthlyParkersListApb}
                onInputChange={this.updateSelectFieldInput}
                onChange={(e) => { this.updateSelectField(e, 'selectedParkerName') }}
              />
              <div style={{ marginTop: 20 }}>
                <RaisedButton label="Not Found" onClick={() => {
                  if (this.props.from.monthlyNotFound) {
                    this.showComponent(['showMonthlyNotFound'])
                  } else {
                    this.props.dispatch(promptActions.modularSendField(true, 'parkerNotFound'));
                    this.props.history.push('/contactManagers')
                  }
                }
                } />
                <RaisedButton label="Next Step" onClick={() => this.submitMonthlyParker()} />
              </div>
            </Fragment>
          }

          {this.state.renderComponent.showMonthlyRedirect && this.props.from.monthlyRedirect &&
            <Fragment>
              <ScriptText>
                {this.props.from.monthlyRedirect}
              </ScriptText>
              <div style={{ marginTop: 20 }}>
                {this.props.from.monthlyRedirectPIL
                  ?
                  <RaisedButton label="Pay In Lane" onClick={() => this.props.history.push('/pil/pilEntryInput')} />
                  :
                  <RaisedButton label="Contact Managers" onClick={() => this.props.history.push('/contactManagers')} />
                }
                <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
              </div>
            </Fragment>
          }

          {this.state.renderComponent.showMonthlyFound &&
            <Fragment>
              <DirectionText> <span style={{ fontWeight: "bolder" }}> Parker Name: </span> {titleCase((this.state.parkerData.name).replace(",", " "))} </DirectionText>
              <DirectionText> <span style={{ fontWeight: "bolder" }}> Status: </span> {titleCase(this.state.parkerData.status)} </DirectionText>
              <DirectionText> <span style={{ fontWeight: "bolder" }}> Reason: </span> {this.state.parkerData.notes} </DirectionText>
              <ScriptText> </ScriptText>
              <div style={{ marginTop: 20 }}>
                <RaisedButton label="Contact Managers" onClick={() => this.props.history.push('/contactManagers')} />
                <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
              </div>
            </Fragment>
          }

          {this.state.renderComponent.showMonthlyNotFound &&
            <Fragment>
              {this.props.from.laneType === "Exit" &&
                <Fragment>
                  <ScriptText> May I please have your phone number? </ScriptText>
                  <MuiPhoneNumber
                    defaultCountry={'us'}
                    onlyCountries={['us']}
                    style={{ width: '100%', height: '100%' }}
                    onChange={this.updatePhoneNumber}
                    variant="outlined"
                    margin="dense"
                    inputClass='data-hj-allow'
                  />
                  <div style={{ marginTop: 20 }}>
                    <RaisedButton label="Vend Gate" onClick={() => {
                      if (!this.props.ticketDiscrepancyPhoneNumber) {
                        return this.props.dispatch(promptActions.showSnackBar({
                          type: 'error',
                          message: 'Phone Number is required.'
                        }))
                      } else {
                        this.props.history.push('/vendGate')
                      }
                    }} />
                  </div>
                </Fragment>
              }
              {this.props.from.laneType === "Entrance" &&
                <Fragment>
                  <ScriptText> {this.props.from.monthlyNotFoundNote} </ScriptText>
                  <div style={{ marginTop: 20 }}>
                    <RaisedButton label="Contact Managers" onClick={() => this.props.history.push('/contactManagers')} />
                  </div>
                </Fragment>
              }
            </Fragment>
          }
        </PromptBox>
      )
    }

    //   Location is integrated with monthly parkers but DOES NOT require company name
    if (this.props.from.monthlyFeed && !this.props.from.companyNameRequired) {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className={`home-div-content`}>
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
                <ScriptText>"May you please provide your name or Monthly Parking Card number?"</ScriptText>
                <DirectionText>NOTE: If the Termination Date has passed, please do not vend the gate for the parker.</DirectionText>
                {
                  this.props.from.additionalMonthlyText &&
                  <DirectionText>
                    {this.props.from.additionalMonthlyText}
                  </DirectionText>
                }
              </Paper>
              <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
                <MonthlyParkersList
                  mPSearch={this.monthlyParkerSearch}
                  parkers={this.props.monthlyParkersList}
                  searchCriteria={this.props.searchCriteria}
                  sendParkerToState={this.sendParkerToState}
                  selectedParker={this.props.selectedParker}
                  selectedParkerIndex={this.props.selectedParkerIndex}
                />
                {this.props.monthlyParkersList && (
                  <RaisedButton label="Not Found" onClick={() => {
                    this.props.dispatch(promptActions.modularSendField(true, 'parkerNotFound'))
                    this.props.history.push('/monthly/notFound')
                  }}
                    style={{ margin: '16px 10px' }} />
                )}
                {/* Use the selected parker from the list where available,
                 otherwiseuse the text entry,
                 if both are empty display nothing
              */}
                {this.shouldShowSubmitSearchTextButton() ?
                  <Link to="/vendGate">
                    <RaisedButton label="Submit Search Text As Parker" style={{ margin: '16px 0' }} onClick={this.migrateSearchToSelected} />
                  </Link>
                  :
                  this.shouldShowVendButton() &&
                  <Link to="/vendGate">
                    <RaisedButton label="Submit Selected Parker" style={{ margin: '16px 0' }} />
                  </Link>
                }
              </Paper>
            </div>
          </div>
        </ErrorBoundary>
      )
    }

    //   Location is NOT integrated with monthly parkers and DOES NOT require company name
    if (!this.props.from.monthlyFeed && !this.props.from.companyNameRequired && !this.props.from.monthlyInputNoVerification) {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className={`home-div-content ${this.props.isDialing && !this.props.onCall && 'flash-alert'}`}>
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <div className="home-div-body">
                <Paper zDepth={1} className="paper-padding">
                  <DirectionText>
                    This application has not integrated a monthly parking list yet. Please record parker's name and vend the gate for them.
                  </DirectionText>
                  {
                    this.props.from.additionalMonthlyText &&
                    <DirectionText>
                      {this.props.from.additionalMonthlyText}
                    </DirectionText>
                  }
                  {this.props.from.strictMonthly &&
                    <h3>
                      <b>For this location, do not let ANYONE out under any circumstances if you were not able to verify them.</b>
                    </h3>
                  }
                  {this.props.step === 0 &&
                    <TextField
                      inputProps={{ className: 'data-hj-allow' }}
                      id="parker-name"
                      label="Parker Name"
                      hintText="Enter Parker Full Name Here"
                      style={{ width: '98%' }}
                      onChange={this.updateMonthlyParkerName}
                    />
                  }
                  {this.props.step === 0 && this.props.from.monthlyCardNumberInput &&
                    <TextField
                      inputProps={{ className: 'data-hj-allow' }}
                      id="card-number"
                      label="Card Number"
                      hintText="Enter Monthly Card Number Here"
                      style={{ width: '98%' }}
                      onChange={this.updateMonthlyCardNumber}
                    />
                  }
                  {this.props.from.monthlyLicensePlateRequired && (
                    <TextField
                      inputProps={{ className: 'data-hj-allow' }}
                      id="license-plate"
                      label="License Plate"
                      hintText="License Plate"
                      style={{ width: '98%' }}
                      onChange={(e) => this.updateField(e, 'licensePlateNumber')}
                    />
                  )}
                  {this.props.step > 0 && <ContactManagers showButtons={['managerAssisting', 'vendGate', 'ticketDiscrepancy']} />}
                </Paper>
                <Paper zDepth={1} className="paper-padding">
                  {this.props.step === 0 && !this.props.from.noVerifyMonthlies ? (
                    <Link to="/monthly/notFound">
                      <RaisedButton
                        label="Unable to Verify Parker"
                      />
                    </Link>
                  ) : (
                    <Link to="/confirmationScreen">
                      <RaisedButton label="End Call" />
                    </Link>
                  )
                  }
                  {this.shouldShowSubmitSearchTextButton() ? (
                    <RaisedButton label={this.props.from.noVerifyMonthlies ? "Next Steps" : "Able to Verify Parker"} style={{ margin: '16px 0' }} onClick={this.migrateSearchToSelected} />
                  )
                    : null
                  }
                </Paper>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      )
    }
    if (this.props.from.monthlyInputNoVerification) {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className={`home-div-content ${this.props.isDialing && !this.props.onCall && 'flash-alert'}`}>
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <div className="home-div-body">
                <Paper zDepth={1} className="paper-padding">
                  {this.props.from.laneType === "Entrance" &&
                    <Fragment>
                      <ScriptText>
                        Please Pull Ticket.
                      </ScriptText>
                      <Link to="/confirmationScreen">
                        <RaisedButton label="End Call" />
                      </Link>
                    </Fragment>
                  }
                  {this.props.from.laneType === "Exit" &&
                    <Fragment>
                      <DirectionText>
                        Please take parkers Validation Number or Employee ID.
                      </DirectionText>
                      <TextField
                        inputProps={{ className: 'data-hj-allow' }}
                        hintText="Enter Monthly Card Number or Employee ID Here"
                        style={{ width: '98%' }}
                        onChange={this.updateMonthlyCardNumber}
                      />
                      <Link to="/vendGate">
                        <RaisedButton label="Able to Verify Parker" style={{ margin: '16px 0' }} onClick={this.migrateSearchToSelected} />
                      </Link>
                      <Link to="/confirmationScreen">
                        <RaisedButton label="End Call" />
                      </Link>
                    </Fragment>
                  }
                </Paper>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    monthlyParkersList: promptSelectors.pullMonthlyParkers(state),
    ongoingCallId: queueSelectors.pullModular(state, 'ongoingCallId'),
    searchCriteria: promptSelectors.pullMonthlySearchText(state),
    selectedParker: promptSelectors.pullModular(state, "selectedParker"),
    selectedParkerName: promptSelectors.pullModular(state, "selectedParkerName"),
    selectedParkerCardNumber: promptSelectors.pullModular(state, 'selectedParkerCardNumber'),
    username: authSelectors.pullModular(state, 'username'),
    step: promptSelectors.pullStep(state),
    companyName: promptSelectors.pullModular(state, "companyName"),
    monthlyParkersListApb: promptSelectors.pullModular(state, 'monthlyParkersListApb'),
    monthlyParkersListApbFiltered: promptSelectors.pullModular(state, 'monthlyParkersListApbFiltered'),
    selectedParkerIndex: promptSelectors.pullModular(state, 'selectedParkerIndex'),
    apbLockedOut: promptSelectors.pullModular(state, 'apbLockedOut'),
    terminationLockedOut: promptSelectors.pullModular(state, 'terminationLockedOut'),
    rerender: promptSelectors.pullModular(state, 'rerender'),
    wrongFacilityLockedOut: promptSelectors.pullModular(state, 'wrongFacilityLockedOut'),
    nextStepsLoading: promptSelectors.pullModular(state, 'nextStepsLoading'),
    invalidAccessLocation: promptSelectors.pullModular(state, 'invalidAccessLocation'),
    invalidAccessTime: promptSelectors.pullModular(state, 'invalidAccessTime'),
    managerVendConfirmation10s: promptSelectors.pullModular(state, 'managerVendConfirmation10s'),
    monthlyCardIssue: promptSelectors.pullModular(state, 'monthlyCardIssue'),
    licensePlateNumber: promptSelectors.pullModular(state, 'licensePlateNumber'),
    ticketDiscrepancyPhoneNumber: promptSelectors.pullModular(state, 'ticketDiscrepancyPhoneNumber')
  };
}

const monthlyVerificationSocket = (props) => (
  <SocketContext.Consumer>
    {socket => <MonthlyVerification {...props} socket={socket} />}
  </SocketContext.Consumer>
)

export default withRouter(connect(mapStateToProps)(monthlyVerificationSocket));
