import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as promptActions from "../../../store/prompt/actions";
import * as authSelectors from '../../../store/auth/reducer';
import ScriptText from '../../../components/ScriptText';
import RaisedButton from "material-ui/RaisedButton";
import PromptBox from '../../../components/promptBox';
import ContactManagers from '../ContactManagers';
import DirectionText from '../../../components/DirectionText';
import TextField from "material-ui/TextField";
import ManagersList from "../ManagersList";

const textFieldStyle = {
  display: 'inline-block',
  width: '98%',
  marginTop: 15
}

class HotelNotFound extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { 
      renderComponent: {
        showHotelIssue: true,
        showManagersList: false,
        showInvalidParker: false,
        showPushRateQuestion: false
      }
    }
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  showComponent(toRender) {
    const allComponents = {...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'showPushRateForm':
        if (routeTo == 'hasTicket') {
          this.props.dispatch(promptActions.modularSendField(true, 'pushRateQuestion'));
        }
        this.props.history.push('/tibaAPI/tibaManagerPushRate');
        break;
      case 'showPushRateQuestion':
        this.showComponent(['showPushRateQuestion']);
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  render() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        {this.state.renderComponent.showHotelIssue &&
          <Fragment>
            {this.props.from.hotelIssueText
            ?
              <Fragment>
                <ScriptText>
                  {this.props.from.hotelIssueText}
                </ScriptText>
                {this.props.from.hotelManagersList &&
                  <div style={{ marginTop: 15 }}>
                    <ManagersList managers={this.props.from.hotelManagersList} />
                  </div>
                }
              </Fragment>
            :
              <Fragment>
                <ScriptText>
                  "I was unable to verify you as a hotel guest for this location. Either contact the hotel to resolve this issue or pay the lost ticket fee."
                </ScriptText>
              </Fragment>
            }
            <div style={{ marginTop: '15px' }}>                
              {this.props.from.hotelManagersList && <RaisedButton label="Hotel Asked to Vend" onClick={() => this.props.history.push('/vendGate')} />}
              <RaisedButton label="Invalid Parker" onClick={() => this.showComponent(['showInvalidParker'])} />
              <RaisedButton label="Contact Managers" onClick={() => this.showComponent(['showManagersList'])} />
              <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} /> 
            </div>
          </Fragment>
        }

        {this.state.renderComponent.showManagersList &&
          <Fragment>
            <ContactManagers showButtons={['managerAssisting', 'vendGate', 'ticketDiscrepancy', 'showCustomButton']} 
              btnClick={this.btnClick}
              btnFrom="hotelIssue"
              customButtonData={[
                {
                  "label": "Manager Asked to Push Rate",
                  "action": `${this.props.from.hotelPushRateQuestion ? 'showPushRateQuestion' : 'showPushRateForm'}`
                },
              ]}
            />
          </Fragment>
        }

        {this.state.renderComponent.showPushRateQuestion &&
          <Fragment>
            <ScriptText> Do you have a ticket? </ScriptText>
            <div style={{ marginTop: 15 }}>
              <RaisedButton label="Yes" onClick={() => this.btnClick('showPushRateForm', 'hasTicket')} />
              <RaisedButton label="No" onClick={() => this.btnClick('showPushRateForm')} />
            </div>
          </Fragment>
        }

        {this.state.renderComponent.showInvalidParker &&
          <Fragment>
            <ScriptText>
              We could not verify you as a Hotel Guest, please pay the amount.
            </ScriptText>
            <div style={{ marginTop: '15px' }}>                
              <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} /> 
            </div>
          </Fragment>
        }
      </PromptBox>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username')
  };
}

export default withRouter(connect(mapStateToProps)(HotelNotFound));
