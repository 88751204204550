const axiosInstance = require('../lib/axiosInstance'); 

class TwilioApiCalls {
  async completeCall(callSid) {
    const results = await axiosInstance({
      method: 'post',
      url: `/endCallApi`,
      data: { callSid }
    });
    
    return results;
  }
}

export default new TwilioApiCalls();
