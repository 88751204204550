import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptActions from '../../store/prompt/actions';
import * as promptSelectors from '../../store/prompt/reducer';
import * as authSelectors from '../../store/auth/reducer';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import DirectionText from '../../components/DirectionText';
import InputMask from 'react-input-mask';
import { retrieveLogger } from '../../lib/logger';
let logger = retrieveLogger();

class ResidentIssue extends Component {
  constructor(props) {
    super(props);
    this.reloadInterval = null;
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    /* reset global step prop */
    this.props.dispatch(promptActions.updateIssueStep(0));
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (this.props.selectedParkerName && this.props.residentUnit && this.props.transponderNumber && this.props.contactNumber) {
      if (this.props.contactNumber.match(/\(\d\d\d\) \d\d\d \d\d\d\d/)) {
        this.props.dispatch(promptActions.modularSendField(true, 'residentIssueComplete'))
      }
    }
  }

  componentWillUnmount() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  updateField(event, value, target) {
    this.props.dispatch(promptActions.modularSendField(value, target));
  }

  notEmpty = (propsArr) => {
    for (let i = propsArr.length - 1; i >= 0; --i) {
      if (this.props[propsArr[i]] === null) {
        return false;
      }
      if (this.props[propsArr[i]].trim() === "") {
        return false;
      }
    }
    return true;
  }

  showLatitude() {
    return (
      <div className="home-div">
        <div className="home-div-body">
          <Paper zDepth={1} className="paper-padding">
            <DirectionText>
              Please ask the parker to visit the concierge. Concierge should be able to verify their residency and vend the gate for them.
            </DirectionText>
            <Link to="/confirmationScreen">
              <RaisedButton label="Exit" />
            </Link>
          </Paper>
        </div>
      </div>
    );
  }

  showLincoln() {
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            <Paper zDepth={1} className="paper-padding">
              <DirectionText>
                Please ask the customer for the following pieces of information before allowing access.
              </DirectionText>
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Card Number"
                floatingLabelText="Card Number"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'selectedParkerCardNumber');
                }} 
              />
              <Link to="/vendGate">
                <RaisedButton label="Vend Gate" />
              </Link>
              <Link to="/confirmationScreen">
                <RaisedButton label="Exit" />
              </Link>
            </Paper>
          </div>
        </div>
      </div>
    )
  }

  showTrump() {
    let phoneHintText = 'Phone Number';
    if (this.props.contactNumber && this.props.contactNumber.length > 0) {
      phoneHintText = '';
    }
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            <Paper zDepth={1} className="paper-padding">
              <DirectionText>
                Please ask the customer for the following pieces of information before allowing access.
              </DirectionText>
              <DirectionText>
                Fields denoted with an asterisk (*) are required, but some parkers may not have all of the information listed below.
              </DirectionText>
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Parker Name"
                floatingLabelText="Parker Name"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'selectedParkerName');
                }} />
              <span>*</span>
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Resident Unit "
                floatingLabelText="Resident Unit "
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'residentUnit');
                }} />
              <span>*</span>

              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Transponder Number"
                floatingLabelText="Transponder Number"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'transponderNumber');
                }} />
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                style={{ width: '98%' }}
                hintText={phoneHintText}
                floatingLabelText="Phone Number"
                floatingLabelFixed={true}
                ref="phoneNumber"
                name="phoneNumber"
                type="text"
                value={this.props.contactNumber}
                onChange={(e, v) => {
                  this.updateField(e, v, 'contactNumber');
                }}>
                <InputMask mask="(999) 999 9999" maskChar=" " />
              </TextField>
            </Paper>
            <Paper zDepth={1} className="paper-padding">
              {this.notEmpty(["selectedParkerName", "residentUnit"]) ?
                <Link to="/vendGate">
                  <RaisedButton label="Submit Resident Info" />
                </Link> :
                <DirectionText>Please complete the form above to continue.</DirectionText>
              }

            </Paper>
          </div>
        </div>
      </div>
    );
  }

  render() {
    switch (this.props.from.locationId) {
      case 578:
      case '578':
        return this.showLatitude();
      case '582':
      case 582:
        return this.showLincoln();
      default:
        logger.info('hit default');
        return this.showTrump();
    }
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    selectedParkerName: promptSelectors.pullModular(state, 'selectedParkerName'),
    residentUnit: promptSelectors.pullModular(state, 'residentUnit'),
    transponderNumber: promptSelectors.pullModular(state, 'transponderNumber'),
    contactNumber: promptSelectors.pullModular(state, 'contactNumber'),
    residentIssueComplete: promptSelectors.pullModular(state, 'residentIssueComplete'),
    selectedParkerCardNumber: promptSelectors.pullModular(state, 'selectedParkerCardNumber')
  };
}

export default withRouter(connect(mapStateToProps)(ResidentIssue));
