import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptActions from '../../store/prompt/actions';
import * as promptSelectors from '../../store/prompt/reducer';
import * as authSelectors from '../../store/auth/reducer';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import DirectionText from '../../components/DirectionText';
import InputMask from 'react-input-mask';

class VisitorIssue extends Component {
  constructor(props) {
    super(props);
    this.reloadInterval = null;
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    /* reset global step prop */
    this.props.dispatch(promptActions.updateIssueStep(0));
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (this.props.selectedParkerName &&
      this.props.visitingApartmentNumber &&
      this.props.visitingResidentName &&
      this.props.licensePlateNumber &&
      this.props.vehicleMake &&
      this.props.vehicleModel) {
      this.props.dispatch(promptActions.modularSendField(true, 'visitorIssueComplete'))
    }
  }

  componentWillUnmount() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  updateField(event, value, target) {
    this.props.dispatch(promptActions.modularSendField(value, target));
  }

  notEmpty = (propsArr) => {
    for (let i = propsArr.length - 1; i >= 0; --i) {
      if (this.props[propsArr[i]] === null) {
        return false;
      }
      if (this.props[propsArr[i]].trim() === "") {
        return false;
      }
    }
    return true;
  }

  render() {
    let phoneHintText = 'Phone Number';
    if (this.props.contactNumber && this.props.contactNumber.length > 0) {
      phoneHintText = '';
    }
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            <Paper zDepth={1} className="paper-padding">
              <DirectionText>
                Please ask the customer for the following pieces of information before allowing access.
              </DirectionText>
              <DirectionText>
                Fields denoted with an asterisk (*) are required, but some parkers may not have all of the information listed below.
              </DirectionText>
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Visitor Name"
                floatingLabelText="Visitor Name"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'selectedParkerName');
                }}
              />
              <span>*</span>

              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Visiting Apartment Number"
                floatingLabelText="Visiting Apartment Number"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                type="number"
                onChange={(e, v) => {
                  this.updateField(e, v, 'visitingApartmentNumber');
                }}
              />
              <span>*</span>

              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Resident's Name You Are Visiting"
                floatingLabelText="Resident's Name"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'visitingResidentName');
                }}
              />
              <span>*</span>

              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                style={{ width: '98%' }}
                hintText={phoneHintText}
                floatingLabelText="Phone Number"
                floatingLabelFixed={true}
                ref="phoneNumber"
                name="phoneNumber"
                type="text"
                value={this.props.contactNumber}
                onChange={(e, v) => {
                  this.updateField(e, v, 'contactNumber');
                }}>
                <InputMask mask="(999) 999 9999" maskChar=" " />
              </TextField>
              <span>*</span>
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Vehicle Make"
                floatingLabelText="Vehicle Make (Honda)"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'vehicleMake');
                }}
              />
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Vehicle Model"
                floatingLabelText="Vehicle Model (Civic)"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'vehicleModel');
                }}
              />
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="License Plate Number"
                floatingLabelText="License Plate Number"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'licensePlateNumber');
                }}
              />
            </Paper>
            <Paper zDepth={1} className="paper-padding">
              {this.notEmpty(["selectedParkerName", "visitingApartmentNumber", "visitingResidentName"]) &&
                this.props.contactNumber.trim().length > 13
                ?
                <Link to="/vendGate">
                  <RaisedButton label="Submit Visitor Info" />
                </Link> :
                <DirectionText>Please complete the form above to continue.</DirectionText>
              }

            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    selectedParkerName: promptSelectors.pullModular(state, 'selectedParkerName'),
    visitingApartmentNumber: promptSelectors.pullModular(state, 'visitingApartmentNumber'),
    visitingResidentName: promptSelectors.pullModular(state, 'visitingResidentName'),
    vehicleMake: promptSelectors.pullModular(state, 'vehicleMake'),
    vehicleModel: promptSelectors.pullModular(state, 'vehicleModel'),
    licensePlateNumber: promptSelectors.pullModular(state, 'licensePlateNumber'),
    visitorIssueComplete: promptSelectors.pullModular(state, 'visitorIssueComplete'),
    contactNumber: promptSelectors.pullModular(state, 'contactNumber'),
  };
}

export default withRouter(connect(mapStateToProps)(VisitorIssue));
