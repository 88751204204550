import { showSnackBar } from "../../../../store/prompt/actions";

export const logPrepaidDetailsSubmit = ({ dispatch, thirdPartyVendorId, thirdPartyReservationNumber, selectedParkerName, action }) => {
  if (!thirdPartyVendorId && thirdPartyVendorId !== 0) {
    return dispatch(showSnackBar({
      type: 'error', message: 'Vendor Name is required'
    }));
  } else if (!thirdPartyReservationNumber) {
      return dispatch(showSnackBar({
        type: 'error', message: 'Reservation Number is required'
      }));
  } else if (!selectedParkerName || selectedParkerName == '') {
      return dispatch(showSnackBar({
        type: 'error', message: 'Parker Name is required'
      }));
  } else {
      action();
      return dispatch(showSnackBar({
        type: 'success', message: 'Submitted Successfully'
      }));
  }
}