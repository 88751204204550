import React, { Component, Fragment } from 'react';
import autoBind from 'react-autobind';
import _ from 'lodash';
import AnswerButton from './answerButton';
import RecordingDisclaimer from '../components/recordingDisclaimer';
import QueueEntry from './queueEntry';
import { Grid, Paper }  from '@material-ui/core';
import Home from '@material-ui/icons/Home';
import Room from '@material-ui/icons/Room';

export default class QueueList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  
  displayQueueEntry(entry, index) {
    if (entry.Username && entry.Username !== this.props.username) {
      return;
    }
    let animationClass = 'queue-card-enter';
    setTimeout(() => {
      animationClass = '';
    }, 2000);
    if (entry.Status === 'wrap_up') {
      animationClass = 'queue-card-leave-graceful';
    }
    if (this.props.descriptor && entry.To == this.props.queue[0].To && index == 0) {
      this.props.queue[index].descriptor = this.props.descriptor;
    }
    return (
      <QueueEntry
        animationClass={animationClass}
        entry={entry}
        toggleParkerCallHold={this.props.toggleParkerCallHold}
        isParkerCallOnHold={this.props.isParkerCallOnHold}
        isManagerCallOnHold={this.props.isManagerCallOnHold}
        isCallingManager={this.props.isCallingManager}
        key={entry.id}
      />
    );
  }

  render() {
    return (
      <Fragment>
        <div className="queueDiv">
          <div className="queue-container">
            <AnswerButton queue={this.props.queue} nextInQueue={this.props.nextInQueue} onCall={this.props.onCall} isDialing={this.props.isDialing} />
            <div className="queueList">
              {_.map(this.props.queue, this.displayQueueEntry)}
            </div>
          </div>
          <Grid className="queue-grid" container item xs={12}>
            <RecordingDisclaimer onCall={this.props.onCall} />
            <Grid item md={12} lg={6} style={{ textAlign: 'center' }}>
              <Paper>
                <Room style={{ fontSize: '1.5rem', lineHeight: '30px', marginRight: '5px', position: 'relative', top: '2px' }} />
                <span style={{ fontSize: '0.8rem', position: 'relative', top: '-5px', minWidth: '111px', display: 'inline-block'}}>{this.props.locationTime}</span>
              </Paper>
            </Grid> 
            <Grid item md={12} lg={6} style={{ textAlign: 'center' }}>
              <Paper>
                <Home style={{ fontSize: '1.5rem', lineHeight: '30px', marginRight: '5px', position: 'relative', top: '2px' }} />
                <span style={{ fontSize: '0.8rem', position: 'relative', top: '-5px'}}>{this.props.localTime}</span>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Fragment>
    );
  }
}