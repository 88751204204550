import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import { TextField, Button, Paper } from '@material-ui/core';
import serverInteraction from '../../services/serverInteraction';
import ReactInputMask from 'react-input-mask';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import * as promptActions from '../../store/prompt/actions';
import { retrieveLogger } from '../../lib/logger';
let logger = retrieveLogger();

class UpdateManager extends Component {

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      locationNameList: [],
      selectedLocationName: null,
      managerDetails: []
    };
  }

  async componentDidMount() {
    this.getLocationNameList();
  }

  async getLocationNameList() {
    const requestResults = await serverInteraction.sendServerGETRequest('/retrieveEndpointNames', {});
    const { data } = requestResults;
    const { endpointNames } = data;
    this.setState({ locationNameList: endpointNames });
  }

  locationListNameClick(event) {
    event.preventDefault()
    const locationName = event.target.innerText;
    this.setState({ selectedLocationName: locationName });
    this.getLocationDetails(locationName);
  }

  async getLocationDetails(locationName) {
    if (!locationName) {
      logger.info('Running getLocationDetails without this.state.selectedLocationName.')
      return
    }
    const requestResults = await serverInteraction.sendServerPOSTRequest('/retrieveLocationDetailsByEndpoint', { endpointName: locationName })
    const { data } = requestResults
    const { queryResults } = data
    const { managers } = queryResults
    this.setState({ managerDetails: managers });
  }

callOrderUpClick(event, index){
  event.preventDefault();
  const selectedManager = this.state.managerDetails.find((manager, innerIndex) => {
    return innerIndex == index;
  });
  const managerDetailsCopy = this.state.managerDetails;
  // Delete the selected manager from the array.
  managerDetailsCopy.splice(index, 1);
  // Insert the selected manager into the new array at position 1 before its' previous position.
  // The ternary is ensuring that index is not negative, because in that case it will insert the selectedManager in very much the wrong position.
  managerDetailsCopy.splice((index - 1 < 0 ? 0 : index - 1), 0, selectedManager)
  this.setState({ managerDetails: managerDetailsCopy });
}

callOrderDownClick(event, index, array){
  event.preventDefault();
  const selectedManager = this.state.managerDetails.find((manager, innerIndex) => {
    return innerIndex == index;
  });
  const managerDetailsCopy = this.state.managerDetails;
  managerDetailsCopy.splice(index, 1);
  // We would have to do array.length - 1, but we have already reduced the array's length by 1 with the previous splice,
  // so array.length is doing what you would expect array.length - 1 to do.
  managerDetailsCopy.splice((index + 1 > array.length ? array.length : index + 1), 0, selectedManager);
  this.setState({managerDetails: managerDetailsCopy});
}

addNewManager(){
  // May want to add some checks here where if the last element is a blank object, don't add another.
  const newBlankRecord = {
    availability: null,
    callOrder: null,
    email: null,
    name: null,
    number: null
  };
  const newManagers = [ ...this.state.managerDetails, newBlankRecord ];
  this.setState({ managerDetails: newManagers });
}

deleteManager(event, index) {
  event.preventDefault();
  const managerDetailsCopy = this.state.managerDetails;
  managerDetailsCopy.splice(index, 1);
  this.setState({managerDetails: managerDetailsCopy});

}

editManagerField(event, index, key) {
  event.preventDefault();
  const managerDetailsCopy = this.state.managerDetails;
  let targetValue = event.target.value;
  // If managerDetalsCopy[index][key] doesn't exist, maybe display an error or something.
  managerDetailsCopy[index][key] = event.target.value;
  this.setState({managerDetails: managerDetailsCopy});
}

async saveChanges(){
  logger.info(this.state.managerDetails);
  if (!this.state.managerDetails) {
    logger.info('Running  without this.state.managerDetails');
    this.props.dispatch(promptActions.showSnackBar({ 
      type: 'error', 
      message: `Failed to Update Manager Details for ${this.state.selectedLocationName}`
    }));
    return
  }
  const requestResults = await serverInteraction.sendServerPOSTRequest('/updateManagerDetails', { locationName: this.state.selectedLocationName, managerDetails: this.state.managerDetails });
  this.props.dispatch(promptActions.showSnackBar({ 
    type: 'success', 
    message: `Successfully Updated Manager List for ${this.state.selectedLocationName}`
  }));
  logger.info(requestResults);
  
}

  render() {
    return (
      <div className='home-div'>
        <h2>Update Manager List</h2>
        <div>
          <h3 style={{ marginBottom: '12px' }}>Location Name</h3>
          <ul className={'location-list-ul-style'}>
            {this.state.locationNameList.map((value) => {
              const shouldSelect = value == this.state.selectedLocationName
              return (
                <li
                  className={`${shouldSelect && 'element-selected-style'} location-list-li`}
                  onClick={this.locationListNameClick}
                  key={value}
                >
                  {value}
                </li>
              )
            })}
          </ul>
          <h3>{this.state.selectedLocationName ?
            `${this.state.selectedLocationName}: Managers` :
            `Select a Location`
          }
          </h3>
          <div className='update-manager-results-box'>
          {this.state.managerDetails.map((manager, index, array) => {
            return (
              <div key={index} className='update-manager-box'>
                <Paper>
                  <TextField 
                    value={manager.name || ''} 
                    onChange={(event) => {this.editManagerField(event, index, 'name')}}
                    className='update-manager-textfield' 
                    size='small' 
                    label='Name' variant='outlined'
                  />
                  <ReactInputMask
                    mask={'(999) 999-9999'}
                    maskChar= ''
                    onChange={(event) => {this.editManagerField(event, index, 'number')}}
                    value={manager.number || ''} 
                    >
                    {() => { return (
                      <TextField
                        className='update-manager-textfield' 
                        size='small' 
                        style={{ marginLeft: '5px' }} 
                        label='Number' 
                        variant='outlined'
                      />
                    )}}
                  </ReactInputMask>
                  <TextField 
                    onChange={(event) => {this.editManagerField(event, index, 'email')}}
                    className='update-manager-textfield' 
                    size='small' 
                    value={manager.email || ''} 
                    label='Email' 
                    variant='outlined'
                  />
                  <TextField 
                    onChange={(event) => {this.editManagerField(event, index, 'availability')}}
                    className='update-manager-textfield' 
                    size='small' 
                    style={{ marginLeft: '5px' }} 
                    value={manager.availability || ''} 
                    label='Availability' 
                    variant='outlined'
                  />
                  <IconButton
                    disabled={index == 0}
                    key={`${index}-up`}
                    aria-label="up"
                    size="small"
                    onClick={(event) => { this.callOrderUpClick(event, index) }}
                  >
                    <ArrowUpwardIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    disabled={index == array.length - 1}
                    key={`${index}-down`}
                    aria-label="down"
                    size="small"
                    onClick={(event) => { this.callOrderDownClick(event, index, array) }}
                  >
                    <ArrowDownwardIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton 
                    onClick={(event) => { this.deleteManager(event, index) }}
                    aria-label="delete" 
                    size="small">
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Paper>
              </div>
            )
          })}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: "space-between",
            width: "350px",
            margin: "auto"
          }}
        >
          <Button
            onClick={this.addNewManager}
            style={{ marginTop: 10 }}
            variant="contained"
            color="primary"
          >
            Add Manager
          </Button>
          <Button
            onClick={this.saveChanges}
            style={{ marginTop: 10 }}
            variant="contained"
            color="primary"
          >
            Save All Changes
          </Button>
        </div>
      </div>
    )
  }

}
function mapStateToProps(state) {
  return {
    // from: promptSelectors.pullLocationId(state)
  };
}

export default withRouter(connect(mapStateToProps)(UpdateManager));