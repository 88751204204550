import React, { Component, Fragment } from 'react';
import autoBind from 'react-autobind';
import * as moment from 'moment-timezone';
import { Card, CardHeader } from 'material-ui/Card';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import PhoneIcon from '../assets/PhoneIcon.svg';
import IntercomIcon from '../assets/IntercomIcon.svg';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import { titleCase } from "title-case";

export default class QueueEntry extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  holdCallButtonColor() {
    const btnColor = this.props.isParkerCallOnHold ? (this.props.isCallingManager ? (this.props.isManagerCallOnHold ? 'Green' : 'Grey') : 'Green') : 'Orange';
    return btnColor;
  } 

  render() {
    this.props.entry.FormattedStatus = this.props.entry.Status.replace(/_/g, ' ');
    const now = moment();
    const init = moment(this.props.entry.InitTime).valueOf();
    const duration = moment.duration(now.diff(init));
    const secondsPassed = duration.asSeconds();
    const cardBgColor = this.props.entry.Status === 'on_call' ? (this.props.isCallOnHold ? '#ffccbc' : '#E6F4EA')  : (this.props.entry.Status === 'canceled_by_caller') ? '#ffebee' : 'White';
    return (
      <Fragment>
        <Card className={this.props.animationClass}>
          <Card>
            <Grid container item xs={12} direction="row" style={{ backgroundColor: cardBgColor, margin: 'auto' }}>
              <Grid item xs={2} style={{ width: '100%', height: '100%', margin: 'auto', textAlign: 'center' }}>
                <Tooltip title={this.props.entry.IsIntercomCall == 3 ? 'Phone Call' : 'Intercom Call'} placement="bottom" >
                  <Avatar style={{ borderRadius: 0, margin: 'auto', textAlign: 'center' }}
                    alt={this.props.entry.IsIntercomCall == 3 ? 'phone icon' : 'intercom icon'}
                    src={this.props.entry.IsIntercomCall == 3 ? PhoneIcon : IntercomIcon}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs style={{ width: '100%', height: '100%', margin: 'auto' }}>
                <CardHeader
                  className="queue-card-header title-case"
                  style={{ padding: 10 }}
                  title={
                    <Fragment>
                      {(this.props.entry.To).length > 35
                      ?
                        (
                          <Fragment>
                            <Tooltip title={this.props.entry.To} placement="top">
                              <span>{`${(this.props.entry.To).slice(0, 35)}...`}</span>
                            </Tooltip>
                          </Fragment>
                        )
                      :
                        (
                          <Fragment>
                            <span>{this.props.entry.To}</span>
                          </Fragment>
                        )
                      }
                    </Fragment>
                  }
                  subtitle={
                    <Fragment>
                      <span>{this.props.entry.descriptor || '-'}</span>
                      <span className="float-right">{Math.floor(secondsPassed)}s</span>
                      <p>{(this.props.isParkerCallOnHold && this.props.entry.Status === 'on_call') ? 'On Hold' : titleCase(this.props.entry.FormattedStatus) }</p>
                    </Fragment>
                  }
                />
              </Grid>
              {this.props.entry.ConferenceSid &&
                <Fragment>
                  { (this.props.entry.Status === 'on_call' || (this.props.entry.Status === 'canceled_by_caller' && this.props.isParkerCallOnHold )) &&
                    <Grid item xs={2} style={{ width: '100%', height: '100%', margin: 'auto', textAlign: 'center' }}> 
                      <Tooltip title={this.props.isParkerCallOnHold ? 'Resume Call' : 'Hold Call'} placement="top">               
                        <Fab 
                          size="small"
                          style={{ margin: 'auto', textAlign: 'center', backgroundColor: this.holdCallButtonColor() }}
                          disabled={(this.props.isCallingManager ? (this.props.isManagerCallOnHold ? false : true ) : false)}
                          onClick={() => this.props.toggleParkerCallHold(this.props.entry)}
                        >  
                          {this.props.isParkerCallOnHold
                          ? 
                            <i className="material-icons" style={{ fontSize: 25 }}>play_arrow</i>
                          : 
                            <i className="material-icons" style={{ fontSize: 25 }}>pause</i>
                          }                     
                        </Fab>
                      </Tooltip> 
                    </Grid>
                  }
                </Fragment> 
              }
            </Grid>   
          </Card>    
        </Card>
      </Fragment>
    );
  }
}
