import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import * as authSelectors from '../../store/auth/reducer';
import * as authActions from '../../store/auth/actions';
import * as promptSelectors from '../../store/prompt/reducer';
import * as queueActions from '../../store/queue/actions';
import * as queueSelectors from '../../store/queue/reducer';
import RaisedButton from 'material-ui/RaisedButton';
import cloudparkLogo from '../../assets/cloudpark.jpg';
import Paper from 'material-ui/Paper';
import TextField from '@material-ui/core/TextField';
import * as config from '../../lib/config';
import SocketContext from '../../services/socketContext'
import { Fragment } from 'react';

class Login extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { username: null, password: null, disableLogin: false, isMobileDevice: null }
  }

  componentDidMount() {
    // This is here to counter the forward button bringing to auth.
    if (this.props.username) {
      this.props.history.goBack();
    }
    this.setState({ isMobileDevice: (window.innerWidth < 850 || window.innerHeight < 600) ? true : false })
  }

  componentDidUpdate() {
    if (this.props.username) {
      // Before we go back to where we came from, let's send a request to set our worker to idle.
      // If user is an admin do not make IDLE. There is a toggle to swich the status
      // authLevel 1 = User. 0 = Admin
      if (this.props.authLevel === 1) {
        this.props.dispatch(authActions.updateWorker(this.props.username, 'Idle'));
      }
      
      if (this.props.initialized === false) {
        this.props.dispatch(queueActions.initTwilio());
      }
      this.props.history.replace('/');
    }
  }

  handleTextChange(target, event) {
    this.setState({ [target]: event.target.value });
  }

  async submitLogin() {
    this.setState({ disableLogin: true });
    const result = await this.props.dispatch(authActions.submitLogin(this.state.username, this.state.password, this.props.socket));
    if (!result) {
      this.setState({ disableLogin: false });
    }
    else if (result.snackbarData.type == 'error') {  
      this.setState({ disableLogin: false })
    }
  }

  submitTestLogin(username, password) {
    this.props.dispatch(authActions.submitLogin(username, password, this.props.socket));
  }

  render() {
    return (
      <Fragment>
        {this.state.isMobileDevice 
        ?
          <Fragment>
            <Paper zDepth={2} className="home-div-body">
              <p> Use of Mobile device is restricted on this application. </p>
            </Paper>
          </Fragment>
        :
          <div className="home-div">
            <div className="home-div-content">
              <Paper zDepth={1} className="home-div-header">
                <img
                  alt="Cloudpark logo"
                  src={cloudparkLogo}
                  className="cloudpark-image"
                  />
              </Paper>
              <Paper zDepth={2} className="home-div-body">
                <TextField
                  label="Username"
                  value={this.state.username || ""}
                  fullWidth={true}
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => this.handleTextChange('username', event)}
                />
                <TextField
                  label="Password"
                  value={this.state.password || ""}
                  fullWidth={true}
                  type="password"              
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => this.handleTextChange('password', event)}
                />
                <RaisedButton style={{ marginTop: 15 }} label="Login" disabled={this.state.disableLogin} onClick={this.submitLogin} />
                { (process.env.REACT_APP_SERVER_URL).match('.ngrok.io') &&
                  <RaisedButton label="Test Login" onClick={() => this.submitTestLogin('b', 'b')} />
                }
                { (process.env.REACT_APP_SERVER_URL).match('.ngrok.io') &&
                  <RaisedButton label="HFC Login" onClick={() => this.submitTestLogin('houston.first', 'b')} />
                }
              </Paper>
            </div>
          </div>
        }

      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    initialized: queueSelectors.pullInitialized(state),
    authLevel: authSelectors.pullModular(state, 'authLevel'),
  };
}

const loginSocket = (props) => (
  <SocketContext.Consumer>
    {socket => <Login {...props} socket={socket} />}
  </SocketContext.Consumer>
)

export default withRouter(connect(mapStateToProps)(loginSocket));
