import Promise from 'bluebird';
import serverInteraction from '../../services/serverInteraction';
import twilioToken from '../../lib/twilioToken';
import asyncWait from '../../lib/asyncWait.js';
import twilioApiCalls from '../../services/twilioApiCalls';
import * as promptActions from '../prompt/actions';
import queueService from '../../services/getQueue';
import { retrieveLogger } from '../../lib/logger';
let logger = retrieveLogger();
const request = Promise.promisifyAll(require(`request`));
const pkg = require('../../../package.json');
const axiosInstance = require('../../lib/axiosInstance');

let { Twilio } = window;
let activeConn = {};
let worker = null;
let clientDevice = null;

export function fetchQueue(targetQueue) {
  return async (dispatch, getState) => {
    try {
      const queueArray = await queueService.getQueue(targetQueue);
      let queue = queueArray;
      // Figure out how we want to break down queueArray here.
      dispatch({ type: 'queue.QUEUE', queue });
    } catch (e) {
      logger.error(e);
    }
  };
}

export function retrieveOutsideCallEndpoints() {
  return async (dispatch, getState) => {
    let results = await serverInteraction.retrieveOutsideCallEndpoints();
    dispatch({ type: 'MODULAR_PROMPT_UPDATE', value: results, target: 'outsideLocations'});
  }
}

export function initTwilio() {
  return (dispatch, getState) => {
    try {
      logger.info('Starting initTwilio');
      if (Twilio.Device.instance) {
        Twilio.Device.instance = null;
      }
      const username = getState().auth.username;
      twilioToken({ username, client: true, taskrouter: true })
        .then(async (data) => {
          const token = data.token;
          clientDevice = Twilio.Device.setup(token);
          logger.info('Successfully setup client device.');
          const taskrouterToken = data.taskrouterToken;
          worker = new Twilio.TaskRouter.Worker(taskrouterToken, true, null, null, true);
          logger.info('Successfully setup worker object');
          return;
        })
        .then(() => {
          worker.on('activity.update', function (worker) {
            if (worker.activityName === 'Offline') {
              clientDevice.instance = null;
            }
            if (worker.activityName === 'Idle') {
              // Some calls hung up around 4-5 seconds will forward to the Twilio incoming event handler, but won't
              // trigger the disconnect handler. That left isDialing as true. Let's hard-set it to false here as well.
              dispatch({ type: 'FORCE_DIALING', value: false });
            }
            dispatch({ type: 'WORKER_ACTIVITY_UPDATE', workerActivity: worker.activityName });
          });
          worker.on('reservation.canceled', (reservation) => {
            const activitySid = getState().auth.Idle;
            const workerSid = getState().auth.workerSid;
            handleCancel({ username, activitySid, workerSid, type: 'reservation' });
            dispatch({ type: `TWILIO_RECEIVE_HANGUP` });
            dispatch({ type: `TWILIO_CALL_END` });
          });
          worker.on('task.canceled', (task) => {
            let parkerCallSid;
            if (task && task.attributes && task.attributes.call_sid) {
              parkerCallSid = task.attributes.call_sid;
            }
            const activitySid = getState().auth.Idle;
            const workerSid = getState().auth.workerSid;
            handleCancel({ username, activitySid, workerSid, parkerCallSid, type: 'task' });
            dispatch({ type: `TWILIO_RECEIVE_HANGUP` });
            dispatch({ type: `TWILIO_CALL_END` });
          });
          worker.on('token.expired', async () => {
            const token = await twilioToken({ username, client: false, taskrouter: true});
            worker.updateToken(token.taskrouterToken);
          });
          clientDevice.on('incoming', async function (conn) {
            let cS = conn.parameters.CallSid;
            dispatch({ type: 'TWILIO_INC', ongoingAnalystCallSid: cS });
            activeConn = conn;
            activeConn.answered = false;
            let locQuery = await axiosInstance({
              method: 'post',
              url: `/call/connect`,
              data: { Username: username, CallSid: cS }
            });
            let locInfo = locQuery.data.locationInfo;
            dispatch({ type: 'TWILIO_INC_LOC', locationInfo: locInfo });
          });

          clientDevice.on('cancel', async (conn, b) => {
            // Make sure we reject the connection that was canceled.
            conn.reject();
            const activitySid = getState().auth.Idle;
            const workerSid = getState().auth.workerSid;
            // This is only called when the active call is hung up by user.
            dispatch({ type: `TWILIO_RECEIVE_HANGUP` });
            dispatch({ type: `TWILIO_CALL_END` });
            // Clear the location info when the call is canceled.
            //eslint-disable-next-line
            dispatch({ type: 'OUTSIDE_CALL_INC', locationInfo: new Object() });
            const results = await serverInteraction.generalServerPost({ username, activitySid, workerSid }, 'handleHangup');
            return results;
          });
          clientDevice.on('offline', handleOffline(username));
          clientDevice.on('error', e => {
            switch (e.code) {
              case 31205:
                return;
              case 31201:
                dispatch(promptActions.showSnackBar({
                  type: 'error', 
                  message: `Error occurred while accessing microphone. Please fix the headset issue and then re-log.`
                }));
              default:
              logger.error(e);
            }
          });
        })
        .then(() => {
          dispatch({ type: 'TWILIO_INIT', value: true });
        })
        .catch((error) => {
          logger.error(error);
        });
    } catch (error) {
      logger.error(`Failed to setup client device.`);
      logger.error(error);
    }
  };
}

export function destroyDevice() {
  return (dispatch) => {
    if (Twilio.Device.instance) {
      Twilio.Device.instance = null;
      dispatch({ type: 'TWILIO_INIT', value: false });
    } else {
    }
  }
}
export function sendQueueObject(queueObject) {
  return (dispatch) => {    
    dispatch({ type: 'QUEUE_OBJECT_UPDATE', value: queueObject });
  }
}
export function sendDigits(extension) {
  return (dispatch) => {
    activeConn.sendDigits(extension);
  }
}

export function answerConn(username, now, socket) {
  logger.info(`Triggering answerConn.`);
  // Make a call to server here in order to set the active call to "on call" in the queue on the server.
  // This is required in order to color the entry based on call status.
  return async (dispatch, getState) => {
    dispatch({ type: 'PAGE_LOADING', value: true });
    if (getState().queue.isAnswered) {
      return;
    }
    dispatch({ type: 'CALL_ANSWERED' });
    if (!activeConn.parameters) {
      return dispatch({ type: 'TWILIO_ACCEPT_BLANK' });
    }
    const data = await serverInteraction.onAccept({
      from: activeConn.parameters.From,
      to: activeConn.parameters.To,
      callSid: activeConn.parameters.CallSid,
      analystCallSid: activeConn.parameters.CallSid,
      username
    });
    
    const currentNumber = data.To;
    activeConn.accept();
    activeConn.answered = true;

    const locInfo = getState().prompt.from;
    if (locInfo.locationId === 582) {
      await asyncWait(500);
      activeConn.sendDigits('9');
    }

    if (locInfo.dialToneToggleCall) {
      await asyncWait(500);
      activeConn.sendDigits(locInfo.dialToneToggleCall);
    }
    if (locInfo.dialToneAnswer) {
      await asyncWait(500);
      activeConn.sendDigits(locInfo.dialToneAnswer);
    }
    // Here we will want to route to the first page of our prompt, depending on the identity of the caller.
    dispatch({
      type: 'TWILIO_ACCEPT',
      currentNumber: currentNumber,
      value: now,
      locationInfo: locInfo
    });

    if (locInfo.parcs === 'flash') {
      const { apiParams, locationId } = locInfo;
      const result = await serverInteraction.sendServerPOSTRequest('/flash/getSupportCallID', { apiParams, locationId });
      const { supportCallID } = result.data;
      if (!supportCallID) {
        logger.info('Was unable to find a supportCallID.', { relatedCard: 'CLOUD-1770', supportCallID, locationId: locationId });
        dispatch(promptActions.showSnackBar({
          type: 'error', 
          message: `Could not find open support calls.`
        }));
      } else {
        logger.info('Retrieved supportCallID from server.', { relatedCard: 'CLOUD-1770', supportCallID, locationId: locationId });
        dispatch({ type: 'MODULAR_PROMPT_UPDATE', value: supportCallID, target: 'flashSupportCallID'});
      }
    }
    if (locInfo.antiPassbackIntegrated === true && locInfo.parcs === 'tibaAPI') {
      const { apiParams, baseURL, timezone } = locInfo;
      const data = { apiParams, baseURL, timezone };
      const result = await serverInteraction.sendServerPOSTRequest('/tiba/getMonthliesList', data);
      const { success, message, monthliesList } = result.data;
      if (success) {
        dispatch({ type: 'UPDATE_MONTHLY_RECORDS', records: monthliesList });
      } else {
        const snackbarData = { type: 'error', message };
        dispatch({ type: 'SHOW_SNACKBAR', snackbarData });
      }
    }
 
    if (locInfo.antiPassbackIntegrated === true && (locInfo.parcs === 'amano-pi' || locInfo.parcs === 'tiba')) {
      const username = getState().auth.username;
      sendMonthlyRequest(locInfo, username, socket, dispatch);
    }

    if (locInfo.antiPassbackIntegrated && locInfo.antiPassbackIntegrated === true && locInfo.parcs === 'wps') {
      dispatch(promptActions.pullWpsMonthlyNames(locInfo.locationId));
    }

    setTimeout(async (time) => {
      try {
        const reservationSid = data.ReservationSid;
        const result = await serverInteraction.updateClientSids(reservationSid);
        result.data.sids.ongoingTempCallId = data.id;
        dispatch({ type: 'UPDATE_CALL_SIDS', value: result.data.sids });        
      } catch (e) {
        dispatch(promptActions.showSnackBar({
          type: 'error', 
          message: `Error while attempting to retrieve the client SIDs from the server. This is usually indicative of another issue, such as headset issues.`
        }));
        logger.error(e);
      }
    }, 2000);

    return;
  };
}

export function toggleParkerCallHold(currentCall, toggleValue) {
  return async (dispatch, getState) => {
    try {
      const result = await serverInteraction.toggleParkerCallHold(currentCall, toggleValue);
      if (result.data.success) {
        dispatch({ type: 'MODULAR_QUEUE_UPDATE', value: result.data.toggle, target: 'isParkerCallOnHold'});
        dispatch({ type: 'MODULAR_QUEUE_UPDATE', value: result.data.conferenceSid, target: 'ongoingConferenceSid'});
      }
    } catch (error) {
      logger.error(`Putting Parker Call on hold failed on client side.`);
      logger.error(error);
    }
  }
}

export function managerCall(managerCallData) {
  return async (dispatch, getState) => {
    try {    
      const result = await serverInteraction.managerCall(managerCallData);
      if(result.data.success && result.data.ongoingManagerCallSid) {
        dispatch({ type: 'MODULAR_QUEUE_UPDATE', value: result.data.ongoingManagerCallSid, target: 'ongoingManagerCallSid'});
        setTimeout(() => {
          dispatch({ type: 'MODULAR_PROMPT_UPDATE', value: true, target: 'managerVendConfirmation10s'});
        }, 10000);
      }
      if(!result.data.success) {
        dispatch({ type: 'MODULAR_QUEUE_UPDATE', value: false, target: 'isCallingManager'});
      }      
    } catch (error) {
      logger.error(`Calling ${managerCallData.myNumber} failed on client side.`);
      logger.error(error);
    }
  }
}

export function sendMonthlyRequest(locInfo, username, socket, dispatch) {
  logger.info(`Requesting parkers from Server for Location: ${locInfo.locationId}`);
  const { locationId, parcsLanesAllowed } = locInfo;
  try {
    const message = { requestType: 'requestParkersFromServer', locationId, parcsLanesAllowed, username, cloudparkClientSocketId: socket.id };
    socket.emit('message', message);
    socket.on('returnParkersToClient', (data) => {
      logger.info('websocket listening for returnParkersToClient', {locationId: locationId, cloudparkClientSocketId: socket.id, username: username});
      if (!data) {
        logger.info('Received a "returnParkersToClient" request, but it did not send any records.');
        return;
      }
      dispatch({ type: 'UPDATE_MONTHLY_RECORDS', records: data });
    });
  } catch (error) {
    logger.error(`Error retrieving parkers for location id ${locInfo.locationId}`);
    logger.error(error);
  }
}

export function answerConnTest(currentNumber, now) {
  return async dispatch => {
    let locQuery = await axiosInstance({
      method: 'post',
      url: `/getLocInfo`,
      data: { currentNumber }
    });
    let locInfo = JSON.parse(locQuery.body).locationInfo;
    return dispatch({
      type: 'ACCEPT',
      currentNumber: currentNumber,
      value: now,
      locationInfo: locInfo
    });
  };
}

export function twilioConn() {
  return async (dispatch, getState) => {
    return clientDevice.connect(conn => {
      dispatch({ type: `TWILIO_CALL_INC`, conn });
    });
  };
}

export function endConn(user, locId, dialToneToggleCall, dialToneExit) {
  logger.info(`Triggering endConn.`);
  return async (dispatch, getState) => {
    if (!activeConn.parameters) {
      logger.error({message: 'No active parameters in endConn call.'});
    }
    try {
      // Lincoln Harbor
      if (locId === 582) {
        await asyncWait(500);
        activeConn.sendDigits('#');
      }

      if (dialToneToggleCall) {
        await asyncWait(500);
        logger.info(`Sending ${dialToneToggleCall} to end call.`);
        activeConn.sendDigits(dialToneToggleCall);
        await asyncWait(500);
      }
      if (dialToneExit) {
        await asyncWait(500);
        activeConn.sendDigits(dialToneExit);
        await asyncWait(500);
      }
      // Brief wait after we send digits
      await asyncWait(500);
    } catch (e) {
      logger.error(e);
    }
    // This check needs to be here for Outside Calls... they do not have an activeConn and it throws an error.
    if (activeConn.parameters) {
      let callSidToHangup = activeConn.parameters.CallSid;
      activeConn.disconnect();
      logger.info(`Just disconnected active call inside of endConn.`);
      // This is a safe-guard as calls are rarely not hung-up, to ensure that they always are. (Most of time this is overkill/unnecessary)
      twilioApiCalls.completeCall(callSidToHangup);
    }
  };
}

// Does not dispatch an action, but will invoke a service to send nR to the DB. Not sure if right place but oh well.
export function sendTransactionToDB(record) {
  return async (dispatch, getState) => {
    let nR = {};
    //eslint-disable-next-line
    record.To ? nR.To = record.To : null;
    //eslint-disable-next-line
    record.ResidentUnit ? nR.ResidentUnit = record.ResidentUnit : null;
    //eslint-disable-next-line
    record.TransponderNumber ? nR.TransponderNumber = record.TransponderNumber : null;
    //eslint-disable-next-line
    record.ContactNumber ? nR.ContactNumber = record.ContactNumber : null;
    nR.answeredTime = record.answeredTime;
    nR.gateId = record.from.gateId;
    nR.gateName = record.from.gateName;
    nR.locationId = record.from.locationId;
    nR.locationName = record.from.locationName;
    nR.paymentIssue = record.issue.paymentIssue;
    nR.type = record.issue.type;
    nR.subtype = record.issue.subtype;
    nR.ticketNumber = record.issue.ticketNumber;
    nR.selectedParkerName = record.selectedParkerName;
    nR.selectedParkerRoomNumber = record.selectedParkerRoomNumber;
    // eslint-disable-next-line
    if (record.selectedParker) {
      nR.selectedParkerName = record.selectedParker.name;
      nR.selectedParkerAccount = record.selectedParker.accountName;
      nR.airportParkingReservationId = record.selectedParker.trans_id;
    }
    // eslint-disable-next-line
    record.selectedParkerName ? nR.selectedParkerName = record.selectedParkerName : null;
    nR.username = record.username;
    nR.callSid = record.ongoingAnalystCallSid;
    nR.vendedGate = record.vendedGate;
    nR.thirdPartyVendorName = record.thirdPartyVendorName;
    nR.thirdPartyReservationNumber = record.thirdPartyReservationNumber;
    nR.validationNumber = record.validationNumber;
    nR.validationSource = record.validationSource;
    nR.validationDateTime = record.validationDateTime;
    nR.parkerEntranceTime = record.parkerEntranceTime;
    nR.parkerExitTime = record.parkerExitTime;
    nR.attendantName = record.attendantName;
    nR.serviceWorkerType = record.serviceWorkerType;
    nR.timezone = record.from.timezone;
    nR.companyName = record.companyName;
    nR.reportingTo = record.reportingTo;
    nR.visitPurpose = record.visitPurpose;
    nR.visitingApartmentNumber = record.visitingApartmentNumber;
    nR.visitingResidentName = record.visitingResidentName;
    nR.vehicleMake = record.vehicleMake;
    nR.vehicleModel = record.vehicleModel;
    nR.licensePlateNumber = record.licensePlateNumber;
    nR.selectedParkerCardNumber = record.selectedParkerCardNumber;
    nR.apbLockedOut = record.apbLockedOut;
    nR.equipmentMalfunctionDecision = record.equipmentMalfunctionDecision;
    nR.terminationLockedOut = record.terminationLockedOut;
    nR.wrongFacilityLockedOut = record.wrongFacilityLockedOut;
    nR.guestVerified = record.guestVerified;
    nR.invalidAccessLocation = record.invalidAccessLocation;
    nR.invalidAccessTime = record.invalidAccessTime;
    nR.managerActionRequest = record.managerActionRequest;
    nR.clientVersion = pkg.version;
    nR.contactManagersTotalTime = record.contactManagersTotalTime;
    nR.onOutsideCall = record.onOutsideCall;
    nR.ticketNumber =  record.ticketNumber ? (record.ticketNumber).toString().replace(/\D/g,'') : null;
    nR.monthlyCardIssue = record.monthlyCardIssue;
    if (record.answeredTime) {
      nR.answeredTime = record.answeredTime;
    }
    let results = await serverInteraction.sendTransactionToDB(nR);
    dispatch({ type: 'TWILIO_CALL_END' });
    return results;
  };
}

export function sendEquipmentMalfunctionToDB(record) {
  return async (dispatch, getState) => {
    let nR = {};
    nR.MalfunctionType = record.MalfunctionType;
    nR.MalfunctionDescription = record.MalfunctionDescription;
    nR.SelectedManagerName = record.SelectedManagerName;
    nR.Username = record.Username;
    let results = await serverInteraction.sendEquipmentMalfunctionToDB(nR);
    return results;
  };
}

export function deleteFromQueue(ongoingAnalystCallSid) {
  return async (dispatch, getState) => {
    let results = await serverInteraction.sendDeleteFromQueue(ongoingAnalystCallSid);
    return results;
  };
}

export function forceDialing(bool) {
  return async (dispatch, getState) => {
    dispatch({ type: 'FORCE_DIALING', value: bool })
  }
}

export function forceOnCall(bool) {
  return async (dispatch, getState) => {
    dispatch({ type: 'FORCE_ONCALL', value: bool })
  }
}

export function resetPromptState(rec) {
  return async (dispatch, getState) => {
    dispatch({ type: 'RESET_PROMPT_STATE' });
    return;
  };
}

export function reserveWorker(username) {
  return async (dispatch, getState) => {
    const activitySid = getState().auth.Reserved;
    const workerSid = getState().auth.workerSid;
    const results = await serverInteraction.reserveWorker(username, workerSid, activitySid);
    return results;
  }
}

export function resetToIdle(username) {
  return async (dispatch, getState) => {
    const activitySid = getState().auth.Idle;
    const workerSid = getState().auth.workerSid;
    let results = await serverInteraction.resetToIdle(username, workerSid, activitySid);
    dispatch({ type: 'RESET_QUEUE_STATE' });
    return results;
  }
}

export function dialToneVend(num) {
  return async (dispatch) => {
    await asyncWait(500);
    clientDevice.activeConnection().sendDigits(num);
    dispatch({ type: 'ACTION_SEND' });
    return;
  };
}

export function processLostTicketDigits(num) {
  return async (dispatch) => {
    await asyncWait(500);
    clientDevice.activeConnection().sendDigits(num);
    return;
  };
}

export function destroyDeviceOnLogout() {
  return async (dispatch, getState) => {
    clientDevice._events = {};
    clientDevice.destroy();
  }
}

export function handleOffline(username) {
  return async (conn) => {
    let cT = await twilioToken({ username, client: true, taskrouter: false });
    clientDevice.updateToken(cT.token);
  }
}

export function modularSendField(val, tar) {
  return async (dispatch) => {
    dispatch({ type: 'MODULAR_QUEUE_UPDATE', value: val, target: tar });
  }
}

export async function handleCancel({ username, activitySid, workerSid, parkerCallSid, type }) {
  if (activeConn && activeConn.reject) {
    try {
      activeConn.reject();
    } catch(e) {
      logger.error(e);
    }
  }  
  if (type == 'task') {
    await serverInteraction.generalServerPost({ username, activitySid, workerSid, parkerCallSid }, 'handleHangup');
  }
}

export function purgeMyCalls(options) {
  return async (dispatch) => {
    await serverInteraction.generalServerPost(options, 'purgeMyCalls');
  }
}

export function getCallInitTime(data) {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/call/getCallInitTime', data);
  }
}

export function getIsInteromCall(data) {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/call/getIsInteromCall', data);
  }
}

export function forceClose() {
  return async (dispatch) => {
    dispatch({ type: 'TWILIO_CALL_END' });
    dispatch({ type: 'RESET_PROMPT_STATE' });
  }
}