import React, { Component } from 'react';
// eslint-disable-next-line
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
// eslint-disable-next-line
import { withRouter } from 'react-router-dom';

export default class VideoPlaceholder extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    return (
      <div className="video-placeholder">
        <div className="video-placeholder-content">VIDEO</div>
      </div>
    );
  }
}
