import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as authSelectors from '../../../store/auth/reducer';
import Paper from 'material-ui/Paper';
import ScriptText from '../../../components/ScriptText';
import { RaisedButton } from 'material-ui';
import ErrorBoundary from '../../ErrorBoundary';

class HowToEnter extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <div className="home-div">
          <div className="home-div-content">
            <Paper zDepth={1} className="home-div-header-short">
              <h2>{this.props.from.locationName}</h2>
            </Paper>
            <div className="home-div-body">
              <Paper zDepth={1} className="paper-padding">
                {this.props.from.howToEnterMessage 
                ?
                  <ScriptText>{this.props.from.howToEnterMessage}</ScriptText>
                :
                  <ScriptText>Please press the button to pull a ticket,
                    and then use the same ticket to pay when you exit the lot.</ScriptText>
                }
                <Link to="/confirmationScreen">
                  <RaisedButton label="Exit" />
                </Link>
              </Paper>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
  };
}

export default withRouter(connect(mapStateToProps)(HowToEnter));