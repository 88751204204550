import Button from '@mui/material/Button'
import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { pullModular as promptPullModular} from '../../../store/prompt/reducer';
import LogPrepaidDetails from './PrepaidNodes/LogPrepaidDetails';
import PrepaidInstructions from './PrepaidNodes/PrepaidInstructions';
import PullATicket from './PrepaidNodes/PullATicket';
import PromptWrapper from './PromptWrapper';
import { logPrepaidDetailsSubmit } from './lib/logPrepaidDetailsFunctions';


const PrepaidEntrance2 = (props) => {

  const { 
    thirdPartyVendorId,  
    thirdPartyReservationNumber, 
    selectedParkerName, 
    dispatch,
    history 
  } = props;

  const [node, setNode] = useState('0');

    return (
    <PromptWrapper>
      {node == '0' &&
        <Fragment>
          <PrepaidInstructions />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {setNode('1')}}>Next Steps</Button>
        </Fragment>
      }
      {node == '1' &&
        <Fragment>
          <PullATicket />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {setNode('2')}}>Next Steps</Button>
        </Fragment>
      }
      {node == '2' &&
        <Fragment>
          <LogPrepaidDetails />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined'  onClick={() => {
            logPrepaidDetailsSubmit({
              dispatch,
              thirdPartyVendorId,
              thirdPartyReservationNumber,
              selectedParkerName,
              action: () => {history.push('/vendGate')}
            })}}>Vend Gate</Button>
        </Fragment>
      }
    </PromptWrapper>
  )
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from'),
    thirdPartyVendorId: promptPullModular(state, 'thirdPartyVendorId'),
    thirdPartyReservationNumber: promptPullModular(state, "thirdPartyReservationNumber"),
    selectedParkerName: promptPullModular(state, 'selectedParkerName')
  }
}

export default withRouter(connect(mapStateToProps)(PrepaidEntrance2));