import React, { useState, Fragment } from 'react';
import Button from '@mui/material/Button'
import LogPrepaidDetails from './PrepaidNodes/LogPrepaidDetails';
import { withRouter } from 'react-router';
import PromptWrapper from './PromptWrapper';
import PulledTicket from './PrepaidNodes/PulledTicket';
import PrepaidHowParkerEntered from './PrepaidNodes/PrepaidHowParkerEntered';
import PrepaidScannedReservation from './PrepaidNodes/PrepaidScannedReservation';
import { pullModular as promptPullModular } from '../../../store/prompt/reducer';
import { connect } from 'react-redux';
import { logPrepaidDetailsSubmit } from './lib/logPrepaidDetailsFunctions';


const PrepaidExit1 = (props) => {

  //node is a string for branching paths in a flow
  const [node, setNode] = useState('0');

  const { 
    thirdPartyVendorId,  
    thirdPartyReservationNumber, 
    selectedParkerName, 
    history, 
    dispatch, 
    from 
  } = props;

  return (
    <PromptWrapper>
      {node == '0' &&
        <Fragment>
          <PrepaidHowParkerEntered />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {setNode('1a')}}>{from.ticketlessLocation ? 'Used Credit/Debit Card' : 'Pulled a Ticket'}</Button>
          <Button className='button-style' variant='outlined' onClick={() => {setNode('1b')}}>Scanned Reservation</Button>
          <Button className='button-style' variant='outlined' onClick={() => {setNode('1b')}}>Gate Open on Entrance</Button>
        </Fragment>
      }
      {node == '1a' &&
        <Fragment>
          <PulledTicket />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {setNode('2')}}>Next Steps</Button>
        </Fragment>
      }
      {node == '1b' &&
        <Fragment>
          <PrepaidScannedReservation />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {setNode('2')}}>Next Steps</Button>
        </Fragment>
      }
      {node == '2' &&
        <Fragment>
          <LogPrepaidDetails />
          <Button variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {
            logPrepaidDetailsSubmit({
              dispatch,
              thirdPartyVendorId,
              thirdPartyReservationNumber,
              selectedParkerName,
              action: () => {history.push('/vendGate')} 
            })}}>
              Vend Gate
          </Button>
        </Fragment>
      }
    </PromptWrapper>
  )
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from'),
    thirdPartyVendorId: promptPullModular(state, 'thirdPartyVendorId'),
    thirdPartyReservationNumber: promptPullModular(state, "thirdPartyReservationNumber"),
    selectedParkerName: promptPullModular(state, 'selectedParkerName')
  }
}

export default withRouter(connect(mapStateToProps)(PrepaidExit1));