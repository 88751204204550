import { Select, FormControl } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import ScriptText from '../../../../components/ScriptText';
import { TextField } from '@mui/material';
import { modularSendField, sendThirdPartyVendor, resetPrepaidDetails } from '../../../../store/prompt/actions';
import { MenuItem } from 'material-ui';
import { connect } from 'react-redux';
import { pullModular as promptPullModular } from '../../../../store/prompt/reducer';

const LogPrepaidDetails = (props) => {

  const { thirdPartyVendorId } = props;

  useEffect(() => {
    props.dispatch(resetPrepaidDetails());
  }, []);

  // Function to update the form fields. 
  const updateField = (event, target) => {
    const { value } = event.target;
    if (!value) {
      return props.dispatch(modularSendField(false, 'formComplete'));
    }
    props.dispatch(modularSendField(value, target));
  }

  // Function to Update the thirdPartyVendorId
  const updateThirdPartyVendor = (event) => {
    const index = event.target.value;
    const value = prepaidSources[index];
    props.dispatch(sendThirdPartyVendor(index, value));
  }

  const prepaidSources = (props.from && props.from.prepaidSources) || 0;

  return (
    <Fragment>
      <ScriptText>Which prepaid vendor did you purchase your ticket from?</ScriptText>
      <FormControl fullWidth>
        <Select
          native={true}
          label='Third Party Vendor'
          // TODO: See if both data-testid and inputProps are required here.
          data-testid='source-select'
          inputProps={{ 'data-testid': 'source-input'}}
          value={(thirdPartyVendorId || thirdPartyVendorId == 0) ? thirdPartyVendorId : ''}
          className='log-prepaid-details-select'
          onChange={updateThirdPartyVendor}>
            <option>Select a Vendor</option>
          {prepaidSources && prepaidSources.map((source, index) => {
            return <option source={source} value={index} key={`menuItem${index}`}>{source}</option>
          })}
          <option source='Other' value={prepaidSources.length}>Other</option>
        </Select>
      </FormControl>
      <ScriptText>"Please read me the reservation ID from your parking pass."</ScriptText>
      <TextField
        inputProps={{ className: 'data-hj-allow' }}
        label='Reservation Number'
        fullWidth={true}
        variant='outlined'
        margin='dense'
        onChange={(event, value) => { updateField(event, 'thirdPartyReservationNumber') }}
        style={{ width: '98%', textAlign: 'left' }} />
      <ScriptText>May I please have the name on the reservation?</ScriptText>
      <TextField
        inputProps={{ className: 'data-hj-allow' }}
        label='Parker Name'
        fullWidth={true}
        variant='outlined'
        margin='dense'
        onChange={(event, value) => { updateField(event, 'selectedParkerName') }}
        style={{ width: '98%', textAlign: 'left' }}
      />
    </Fragment>
  )
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from'),
    thirdPartyVendorId: promptPullModular(state, 'thirdPartyVendorId'),
    thirdPartyVendorName: promptPullModular(state, 'thirdPartyVendorName'),
    selectedParkerName: promptPullModular(state, 'selectedParkerName'),
    thirdPartyReservationNumber: promptPullModular(state, 'thirdPartyReservationNumber')
  }
}

export default (connect(mapStateToProps)(LogPrepaidDetails));