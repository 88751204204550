import { Paper } from '@mui/material';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { retrieveLogger } from '../lib/logger';
import { pullModular as promptPullModular } from '../store/prompt/reducer';
let logger = retrieveLogger();

class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    logger.error(`Caught error in AppErrorBoundary.`, { 
      issueType: this.props.issue.type, 
      issueSubtype: this.props.issue.subtype, 
      error 
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className='home-div'>
          <div className='home-div-content'>
            <div className='home-div-body'>
              <Paper elevation={1} className='paper-padding'>
              <h2>Something went wrong!</h2>
              <p>Please refresh the page and sign back in</p>
              </Paper>
            </div>
          </div>
        </div>
      )
    }
    return this.props.children;
  }
}

function mapStateToProps(state) {
  return {
    issue: promptPullModular(state, 'issue')
  }
}

export default connect(mapStateToProps)(AppErrorBoundary);