import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import CallEnd from 'material-ui/svg-icons/communication/call-end';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import * as promptSelectors from '../../store/prompt/reducer';
import * as queueSelectors from '../../store/queue/reducer';
import * as authSelectors from '../../store/auth/reducer';
import * as queueActions from '../../store/queue/actions';
import * as promptActions from '../../store/prompt/actions';
import DirectionText from '../../components/DirectionText';
import ScriptText from '../../components/ScriptText';
import * as config from '../../lib/config';
import PromptBox from '../../components/promptBox';
import { retrieveLogger } from '../../lib/logger';
let logger = retrieveLogger();

class GhostCall extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  async endCall(event) {
    let dialToneToggleCall = this.props.from.dialToneToggleCall;
    let dialToneExit = this.props.from.dialToneExit;
    let locationId = this.props.from.locationId;
    await this.props.dispatch(promptActions.modularSendField(true, 'ghostCallScreenLoading'));
    /* Also sends the transaction */
    await this.sendTransactionToDB();
    await this.props.dispatch(queueActions.endConn(this.props.username, locationId, dialToneToggleCall, dialToneExit));
    await this.props.dispatch(queueActions.resetToIdle(this.props.username));
    await this.props.dispatch(promptActions.modularSendField(false, 'ghostCallScreenLoading'));
  }

  async sendTransactionToDB() {
    let rec = {
      answeredTime: this.props.answeredTime,
      from: this.props.from,
      issue: this.props.issue,
      selectedParker: this.props.selectedParker,
      username: this.props.username,
      ongoingAnalystCallSid: this.props.ongoingAnalystCallSid,
      vendedGate: this.props.vendedGate,
      thirdPartyVendorName: this.props.thirdPartyVendorName,
      thirdPartyReservationNumber: this.props.thirdPartyReservationNumber,
      validationNumber: this.props.validationNumber,
      attendantName: this.props.attendantName,
      selectedParkerName: this.props.parkerNotFound ? null : this.props.selectedParkerName,
      selectedParkerRoomNumber: this.props.selectedParkerRoomNumber,
      selectedParkerCardNumber: this.props.selectedParkerCardNumber,
      serviceWorkerType: this.props.serviceWorkerType,
      companyName: this.props.companyName,
      reportingTo: this.props.reportingTo,
      visitPurpose: this.props.visitPurpose,
      visitingApartmentNumber: this.props.visitingApartmentNumber,
      visitingResidentName: this.props.visitingResidentName,
      vehicleMake: this.props.vehicleMake,
      vehicleModel: this.props.vehicleModel,
      licensePlateNumber: this.props.licensePlateNumber,
      timezone: this.props.from.timezone,
      validationSource: this.props.validationSource,
      validationDateTime: this.props.validationDateTime,
      apbLockedOut: this.props.apbLockedOut,
      equipmentMalfunctionDecision: this.props.equipmentMalfunctionDecision,
      terminationLockedOut: this.props.terminationLockedOut,
      wrongFacilityLockedOut: this.props.wrongFacilityLockedOut,
      guestVerified: this.props.guestVerified,
      invalidAccessLocation: this.props.invalidAccessLocation,
      invalidAccessTime: this.props.invalidAccessTime,
      managerActionRequest: this.props.managerActionRequest,
      contactManagersTotalTime: this.props.contactManagersTotalTime,
      onOutsideCall: this.props.onOutsideCall,
      monthlyCardIssue: this.props.monthlyCardIssue
    };
    // Pre-first locations do not populate "To" field properly. Let's account for that here.
    if (this.props.from.gateName && (this.props.from.preFirst || this.props.onOutsideCall)) {
      logger.info(`Appending gateName to locationName during sendTransactionToDB.`);
      rec.To = `${this.props.from.locationName} ${this.props.from.gateName}`;
    }
    if (this.props.onOutsideCall) {
      rec.answeredTime = this.props.answeredTime;
    }
    if (this.props.residentUnit) {
      rec.ResidentUnit = this.props.residentUnit;
    }
    if (this.props.transponderNumber) {
      rec.TransponderNumber = this.props.transponderNumber;
    }
    if (this.props.contactNumber) {
      rec.ContactNumber = this.props.contactNumber;
    }
    await this.props.dispatch(queueActions.sendTransactionToDB(rec));
    await this.props.dispatch(queueActions.resetPromptState(rec));
    await this.props.history.push('/');
  }

  render() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        { !this.props.ghostCallScreenLoading ? 
          <div>
            <ScriptText>"Cloudpark Command Center, this is {this.props.friendlyName} speaking. What is the issue I can assist you with today?"</ScriptText>
            <DirectionText>Wait a few seconds, and if there is still no response:</DirectionText>
            <ScriptText>"This is Cloudpark Command Center. Is anyone there?"</ScriptText>
            <DirectionText>Wait a few seconds, and if there is still no response:</DirectionText>
            <ScriptText>"I'm not able to hear anything and I'm going to end this call. If additional assistance is needed, please press the button again. Thank you for calling Cloudpark."</ScriptText>
            <RaisedButton
              key="end"
              type="button"
              disabled={this.props.isParkerCallOnHold}
              className="cancel-button phone-buttons-end"
              label="End"
              labelPosition="after"
              icon={<CallEnd />}
              onClick={this.endCall}
            /> 
          </div>
        : 
        (
          <Fragment>
            <DirectionText>Processing...</DirectionText>
            <CircularProgress />
          </Fragment>
        )
        }
      </PromptBox>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    selectedParker: promptSelectors.pullSelectedParker(state),
    username: authSelectors.pullModular(state, 'username'),
    ongoingAnalystCallSid: queueSelectors.pullModular(state, 'ongoingAnalystCallSid'),
    onCall: queueSelectors.pullCallStatus(state),
    isDialing: queueSelectors.pullIsDialing(state),
    isParkerCallOnHold: queueSelectors.pullModular(state, 'isParkerCallOnHold'),
    vendedGate: promptSelectors.pullVendedGate(state),
    thirdPartyVendorName: promptSelectors.pullThirdPartyVendorName(state),
    thirdPartyReservationNumber: promptSelectors.pullThirdPartyReservationNumber(state),
    validationNumber: promptSelectors.pullModular(state, 'validationNumber'),
    validationSource: promptSelectors.pullModular(state, 'validationSource'),
    validationDateTime: promptSelectors.pullModular(state, 'validationDateTime'),
    attendantName: promptSelectors.pullAttendantName(state),
    malfunctionValue: promptSelectors.pullModular(state, 'malfunctionValue'),
    malfunctionDescription: promptSelectors.pullModular(state, 'malfunctionDescription'),
    selectedManagerName: promptSelectors.pullModular(state, 'selectedManagerName'),
    answeredTime: promptSelectors.pullModular(state, 'answeredTime'),
    selectedParkerName: promptSelectors.pullModular(state, 'selectedParkerName'),
    selectedParkerRoomNumber: promptSelectors.pullModular(state, 'selectedParkerRoomNumber'),
    serviceWorkerType: promptSelectors.pullModular(state, 'serviceWorkerType'),
    ticketDiscrepancyEntranceTime: promptSelectors.pullModular(state, 'ticketDiscrepancyEntranceTime'),
    ticketDiscrepancyEntranceDate: promptSelectors.pullModular(state, 'ticketDiscrepancyEntranceDate'),
    ticketDiscrepancyExitTime: promptSelectors.pullModular(state, 'ticketDiscrepancyExitTime'),
    ticketDiscrepancyExitDate: promptSelectors.pullModular(state, 'ticketDiscrepancyExitDate'),
    ticketDiscrepancyFeeDue: promptSelectors.pullModular(state, 'ticketDiscrepancyFeeDue'),
    ticketDiscrepancyFirstName: promptSelectors.pullModular(state, 'ticketDiscrepancyFirstName'),
    ticketDiscrepancyLastName: promptSelectors.pullModular(state, 'ticketDiscrepancyLastName'),
    ticketDiscrepancyAddress: promptSelectors.pullModular(state, 'ticketDiscrepancyAddress'),
    ticketDiscrepancyPhoneNumber: promptSelectors.pullModular(state, 'ticketDiscrepancyPhoneNumber'),
    ticketDiscrepancyCity: promptSelectors.pullModular(state, 'ticketDiscrepancyCity'),
    ticketDiscrepancyState: promptSelectors.pullModular(state, 'ticketDiscrepancyState'),
    ticketDiscrepancyZip: promptSelectors.pullModular(state, 'ticketDiscrepancyZip'),
    ticketDiscrepancyComplete: promptSelectors.pullModular(state, 'ticketDiscrepancyComplete'),
    contactNumber: promptSelectors.pullModular(state, 'contactNumber'),
    residentUnit: promptSelectors.pullModular(state, 'residentUnit'),
    transponderNumber: promptSelectors.pullModular(state, 'transponderNumber'),
    companyName: promptSelectors.pullModular(state, 'companyName'),
    reportingTo: promptSelectors.pullModular(state, 'reportingTo'),
    visitPurpose: promptSelectors.pullModular(state, 'visitPurpose'),
    visitingApartmentNumber: promptSelectors.pullModular(state, 'visitingApartmentNumber'),
    visitingResidentName: promptSelectors.pullModular(state, 'visitingResidentName'),
    vehicleMake: promptSelectors.pullModular(state, 'vehicleMake'),
    vehicleModel: promptSelectors.pullModular(state, 'vehicleModel'),
    licensePlateNumber: promptSelectors.pullModular(state, 'licensePlateNumber'),
    selectedParkerCardNumber: promptSelectors.pullModular(state, 'selectedParkerCardNumber'),
    apbLockedOut: promptSelectors.pullModular(state, 'apbLockedOut'),
    equipmentMalfunctionDecision: promptSelectors.pullModular(state, 'equipmentMalfunctionDecision'),
    terminationLockedOut: promptSelectors.pullModular(state, 'terminationLockedOut'),
    wrongFacilityLockedOut: promptSelectors.pullModular(state, 'wrongFacilityLockedOut'),
    guestVerified: promptSelectors.pullModular(state, 'guestVerified'),
    invalidAccessLocation: promptSelectors.pullModular(state, 'invalidAccessLocation'),
    invalidAccessTime: promptSelectors.pullModular(state, 'invalidAccessTime'),
    managerActionRequest: promptSelectors.pullModular(state, 'managerActionRequest'),
    contactManagersTotalTime: promptSelectors.pullModular(state, 'contactManagersTotalTime'),
    onOutsideCall: promptSelectors.pullModular(state, 'onOutsideCall'),
    ghostCallScreenLoading: promptSelectors.pullModular(state, 'ghostCallScreenLoading'),
    monthlyCardIssue: promptSelectors.pullModular(state, 'monthlyCardIssue'),
    parkerNotFound: promptSelectors.pullModular(state, 'parkerNotFound'),
    friendlyName: authSelectors.pullModular(state, 'friendlyName')
  };
}

export default withRouter(connect(mapStateToProps)(GhostCall));
