import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Link } from 'react-router-dom';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';

export default class NextSteps extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    return (
      <Paper zDepth={1} className={`paper-padding ${this.props.className || ''}`}>
        <span className="verification-span">Next Steps</span>
        <Link to="/confirmationScreen">
          <RaisedButton label="None" />
        </Link>
        {this.props.type === 'route' && (
          <Link to={this.props.route}>
            <RaisedButton label={this.props.name} style={{ margin: '0 10px' }} />
          </Link>
        )}
        {this.props.type === 'state' && <RaisedButton label={this.props.name} style={{ margin: '0 10px' }} onClick={this.props.stateFunction} />}
      </Paper>
    );
  }
}
