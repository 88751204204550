import Button from '@mui/material/Button'
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import FreshContactManager from "./PrepaidNodes/FreshContactManagers";
import LogPrepaidDetails from "./PrepaidNodes/LogPrepaidDetails";
import PrepaidHowParkerEntered from "./PrepaidNodes/PrepaidHowParkerEntered";
import PrepaidScannedReservation from "./PrepaidNodes/PrepaidScannedReservation";
import PulledTicket from "./PrepaidNodes/PulledTicket";
import PromptWrapper from "./PromptWrapper";
import { pullModular as promptPullModular } from '../../../store/prompt/reducer';
import { disableContinue } from './lib/contactManagerFunctions';
import { logPrepaidDetailsSubmit } from './lib/logPrepaidDetailsFunctions';

const PrepaidExit2 = (props) => {
  
  const { 
    thirdPartyVendorId,  
    thirdPartyReservationNumber, 
    thirdPartyVendorName,
    selectedParkerName, 
    dispatch, 
    isParkerCallOnHold,
    history,
    managerCalledFor10s,
    setManagerCalledFor10s,
    managerCalled,
    setManagerCalled
  } = props;
  
  const [node, setNode] = useState('0');

  return (
    <PromptWrapper>
      {node == '0' &&
        <Fragment>
          <PrepaidHowParkerEntered />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => { setNode('1a') }}>Pulled Ticket</Button>
          <Button className='button-style' variant='outlined' onClick={() => {setNode('1b')}}>Scanned Reservation</Button>
          <Button className='button-style' variant='outlined' onClick={() => {setNode('1b')}}>Gate Open on Entrance</Button>
        </Fragment>
      }
      {node == '1a' &&
        <Fragment>
          <PulledTicket />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => { setNode('2') }}>Next Steps</Button>
        </Fragment>
      }
      {node == '1b' &&
        <Fragment>
          <PrepaidScannedReservation />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => { setNode('2') }}>Next Steps</Button>
        </Fragment>
      }
      {node == '2' &&
        <Fragment>
          <LogPrepaidDetails />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {
            logPrepaidDetailsSubmit({
              dispatch,
              thirdPartyVendorId,
              thirdPartyReservationNumber,
              selectedParkerName,
              action: () => { setNode('3') }
            })}}>
            Next Steps
          </Button>
        </Fragment>
      }
      {node == '3' &&
        <Fragment>
          <p>
            <span className='contact-managers-header'>{' '}Reservation Source:{' '}</span>{thirdPartyVendorName},
            <span className='contact-managers-header'>{' '}Reservation Number:{' '}</span>{thirdPartyReservationNumber},
            <span className='contact-managers-header'>{' '}Parker Name:{' '}</span>{selectedParkerName}
          </p>
          <FreshContactManager 
             managerCalledFor10s={managerCalledFor10s} 
             setManagerCalledFor10s={setManagerCalledFor10s}
             managerCalled={managerCalled}
             setManagerCalled={setManagerCalled}
          />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {
            disableContinue({
              dispatch,
              isParkerCallOnHold,
              action: () => { history.push('/confirmationScreen') },
              managerCalledFor10s,
              managerCalled
            })
          }}>
            Manager Assisting
          </Button>
          <Button className='button-style' variant='outlined' onClick={() => {
            disableContinue({
              dispatch,
              isParkerCallOnHold,
              action: () => { history.push('/vendGate') },
              managerCalledFor10s,
              managerCalled
            })
          }}>
            Manager Requested Gate Vend
          </Button>
          <Button className='button-style' variant='outlined' onClick={() => {
            disableContinue({
              dispatch,
              isParkerCallOnHold,
              action: () => { history.push('/vendGate') },
              managerCalledFor10s,
              managerCalled
            })
          }}>
            No Manager Response
          </Button>
        </Fragment>
      }
    </PromptWrapper>
  )
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from'),
    thirdPartyVendorId: promptPullModular(state, 'thirdPartyVendorId'),
    thirdPartyReservationNumber: promptPullModular(state, "thirdPartyReservationNumber"),
    thirdPartyVendorName: promptPullModular(state, 'thirdPartyVendorName'),
    selectedParkerName: promptPullModular(state, 'selectedParkerName')
  }
}

export default withRouter(connect(mapStateToProps)(PrepaidExit2));