import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../lib/config';
import * as authSelectors from '../../store/auth/reducer';
import * as promptSelectors from '../../store/prompt/reducer';
import * as promptActions from '../../store/prompt/actions';
import RaisedButton from 'material-ui/RaisedButton';
import PromptBox from '../../components/promptBox';
import DirectionText from '../../components/DirectionText';
import ContactManagers from './ContactManagers';

class GenericComponent extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      showManagersList: false,
      renderPIL: false
    }
  }

  async componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    await this.shouldShowPIL();
  }

  async shouldShowPIL() {
    const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('genericComponent'));
    if (shouldEnablePIL) {
      this.setState({ renderPIL: true });
    } else {
      this.setState({ renderPIL: false });
    }
  }

  render() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        <Fragment>
          <DirectionText>
            {this.props.from.genericComponentText}
          </DirectionText>
          <div style={{ marginTop: 15 }}>
            {!this.state.showManagersList
            ?
              (
                <Fragment>
                  {this.props.from.genericComponentVend && <RaisedButton label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />}
                  {this.state.renderPIL
                    ?
                    <RaisedButton label="Next Step" onClick={() => this.props.history.push('/pil/PILEntryInput')} />
                    :
                    <Fragment>
                      {this.props.from.genericDisableContactManagers 
                      ?
                      <Fragment></Fragment>
                      : 
                      <RaisedButton label="Contact Manager" onClick={() => { this.setState({ showManagersList: true }) }} />}
                    </Fragment>
                  }
                  <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />

                </Fragment>
              )
            :
              (
                <Fragment>
                  <ContactManagers showButtons={[]} />
                  <RaisedButton label="Back" onClick={() => { this.setState({ showManagersList: false }) }} />
                </Fragment>
              )
            }
          </div>
        </Fragment>
      </PromptBox>
    )
  }
}

function mapStateToProps(state) {
  return {
    username: authSelectors.pullModular(state, 'username'),
    authLevel: authSelectors.pullModular(state, 'authLevel'),
    from: promptSelectors.pullLocationId(state)
  };
}

export default withRouter(connect(mapStateToProps)(GenericComponent));
