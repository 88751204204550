import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { TextField } from '@material-ui/core';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as promptActions from '../../../store/prompt/actions';
import * as authSelectors from '../../../store/auth/reducer';
import DirectionText from '../../../components/DirectionText';
import PromptBox from '../../../components/promptBox';
import RaisedButton from 'material-ui/RaisedButton';
import _ from 'lodash';
class TibaRateByTicket extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      enableTicketNotFoundButton: false
    }
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  updateState(event, target) {
    this.setState({ [target]: event.target.value });
  }

  updateField(event, target) {
    if (isNaN(event.target.value)) {
      this.setState({ enableTicketNotFoundButton: true });
      return this.props.dispatch(promptActions.showSnackBar({ 
        type: 'error', message: 'Ticket Number should only include numbers.' 
      }));
    } else {
      this.props.dispatch(promptActions.modularSendField(event.target.value, target))
    }
  }

  showComponent(toRender) {
    const allComponents = {...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }

  async tibaGetTicketInfo() {
    const { ticketNumber } = this.props;
    if (!ticketNumber) {
      this.props.dispatch(promptActions.showSnackBar({ 
        type: 'error', message: 'Please enter the Ticket Number.' 
      }));
    } else {
      const { apiParams, rateList, gracePeriodInt, timezone, baseURL } = this.props.from;
      const data = { ticketNumber, apiParams, rateList, gracePeriodInt, timezone, baseURL };
      const result = await this.props.dispatch(promptActions.tibaGetTicketInfo(data));
      const { success, notFound, message, shouldVend, rate, entryDate } = result.data;
      const parkerEntryDateResult = moment(entryDate, 'YYYY:MM:DD HH:mm:ss').format('LLLL');
      if (success && notFound) {
        this.setState({ enableTicketNotFoundButton: true });
        this.props.dispatch(promptActions.showSnackBar({ type: 'error', message: "Ticket not found, please click Not Found button." }));
      } else if (success && !notFound) {
        await this.props.dispatch(promptActions.modularSendField({rate, shouldVend, parkerEntryDateResult }, 'tibaRateResult'));
        this.props.history.push('/tibaAPI/tibaRateResult')
      } else if (!success) {
        this.setState({ enableTicketNotFoundButton: true });
        this.props.dispatch(promptActions.showSnackBar({ type: 'error', message }));
      }
    }
  }

  render() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        {this.props.issue.type == 'gracePeriod' && <DirectionText> {this.props.from.gracePeriodFormNote} </DirectionText>}
        <TextField
          label="Ticket Number"
          value={this.props.ticketNumber}
          margin="dense"
          variant="outlined"
          inputProps={{ className: 'data-hj-allow' }}
          InputLabelProps={{
            shrink: true,
          }} 
          onChange={(event) => {this.updateField(event, 'ticketNumber')}}
          style={{ width: '100%', marginTop: 15 }}
        />
        <div style={{ marginTop: 15 }}>
          <RaisedButton label="Calculate Fee" onClick={() => this.tibaGetTicketInfo()} />
          <RaisedButton label="Ticket Not Found" disabled={!(this.state.enableTicketNotFoundButton)} onClick={() => {
              this.props.dispatch(promptActions.modularSendField(null, 'ticketNumber'));
              this.props.history.push(this.props.from.lostTicketLPR ? '/tibaAPI/tibaLPRSearch' : '/tibaAPI/tibaRateByEntryTime')
            }
          } />
          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
        </div>
      </PromptBox>
    )
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username'),
    ticketNumber: promptSelectors.pullModular(state, 'ticketNumber')
  };
}

export default withRouter(connect(mapStateToProps)(TibaRateByTicket));