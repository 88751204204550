import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as authSelectors from '../../../store/auth/reducer';
import * as promptActions from '../../../store/prompt/actions';
import ContactManagers from '../ContactManagers';
import Paper from 'material-ui/Paper';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import ScriptText from '../../../components/ScriptText';
import DirectionText from '../../../components/DirectionText';
import PromptBox from '../../../components/promptBox';
import moment from 'moment-timezone';
import _ from 'lodash';
import ErrorBoundary from '../../ErrorBoundary';

const paperStyle = {
  display: 'inline-block',
  margin: '16px 32px 16px 32px'
};

class TransientIssue extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  sendSubtype(type) {
    this.props.dispatch(promptActions.updateSubtype(type));
  }

  transientTimeGated() {
    if (this.isInTimeGate()) {
      return (
        <PromptBox locationName={this.props.from.locationName}>
          <ScriptText>
            It seems this parker is in a time-gated transient lane. Please redirect to the following lane: {this.props.from.ticketAcceptanceRedirect}
          </ScriptText>
          <Link to="/confirmationScreen">
            <RaisedButton label="Exit" />
          </Link>
        </PromptBox>
      )
    }
  }

  isInTimeGate() {
    let today = moment().isoWeekday();
    let now = moment.tz(moment.now(), this.props.from.timezone).valueOf();
    let timeGateDays = this.props.from.transientTimeGateDays;
    let timeGateTimes = this.props.from.transientTimeGateTimes;
    let startIndex = today * 2;
    let endIndex = today * 2 + 1;
    let startTime = timeGateTimes[startIndex];
    let endTime = timeGateTimes[endIndex];
    let startMoment = moment.tz(startTime, 'HH:mm', this.props.from.timezone).valueOf();
    let endMoment = moment.tz(endTime, 'HH:mm', this.props.from.timezone).valueOf();
    if (!timeGateDays[today]) {
      return false;
    }
    if (moment(now).isBefore(startMoment) || moment(now).isAfter(endMoment)) {
      return false;
    }
    // If none of the return falses are triggered, we are in fact being time-gated.
    return true;
  }

  shouldShowIssue(issueType) {
    const { disableTransientIssues } = this.props.from;
    if (!disableTransientIssues) {
      return true;
    } else if (disableTransientIssues && disableTransientIssues.includes(issueType)) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    if (this.props.from.transientTimeGated && this.isInTimeGate()) {
      return (this.transientTimeGated());
    } else if (this.props.from.transientLocation && this.props.from.transientNotAllowedLot && this.props.from.laneType === "Entrance") {
      return (
        <PromptBox locationName={this.props.from.locationName}>
          <DirectionText>
            {this.props.from.transientNotAllowedNote}
          </DirectionText>
          <div style={{ marginTop: 15 }}>
            <Link to="/confirmationScreen">
              <RaisedButton style={{ marginTop: '20px' }} label="Exit" />
            </Link>
          </div>
        </PromptBox>
      )
    } else if (this.props.from.transientLocation && this.props.from.transientNotAllowedLot && this.props.from.laneType === "Exit") {
      return (
        <PromptBox locationName={this.props.from.locationName}>
          <DirectionText>
            {this.props.from.transientNotAllowedNote}
          </DirectionText>
          <ContactManagers showButtons={['managerAssisting']} />
        </PromptBox>
      )
    } else if (this.props.from.transientLocation) {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className={`home-div-content`}>
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
                <DirectionText>Which of the following Transient issues are you experiencing?</DirectionText>
                <Paper style={paperStyle}>
                  <Menu>
                    {(this.shouldShowIssue("ticketAcceptanceIssue") && this.props.from.laneType !== 'Entrance') &&
                      <Link
                        to="/transient/ticketAcceptanceIssue"
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          this.sendSubtype('ticketAcceptanceIssue');
                        }}>
                        <MenuItem primaryText="Ticket Acceptance Issue" />
                      </Link>
                    }
                    {(this.shouldShowIssue('ticketAcceptanceIssue') && this.props.from.laneType == 'Entrance') &&
                      <Link
                        to="/transient/howToEnter"
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          this.sendSubtype('howToEnter');
                        }}>
                        <MenuItem primaryText="How to Enter" />
                      </Link>
                    }
                    {this.props.from.zipCarLocation &&
                      <Link
                        to="/transient/zipCar"
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          this.sendSubtype('zipCar');
                        }}>
                        <MenuItem primaryText="Zip Car" />
                      </Link>
                    }
                    {(this.shouldShowIssue("paymentIssue") && this.props.from.laneType !== 'Entrance') &&
                      <Link
                        to="/transient/paymentIssue"
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          this.sendSubtype('paymentIssue');
                        }}>
                        <MenuItem primaryText="Payment Issue" />
                      </Link>
                    }
                    {(this.shouldShowIssue("paymentIssue") && this.props.from.laneType == 'Entrance') &&
                      <Link
                        to="/transient/howToPay"
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          this.sendSubtype('howToPay');
                        }}>
                        <MenuItem primaryText="How to Pay" />
                      </Link>
                    }
                    {(this.shouldShowIssue("lostTicket") && this.props.from.laneType !== 'Entrance') &&
                      <Link
                        to="/transient/lostTicket"
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          this.sendSubtype('lostTicket');
                        }}>
                        <MenuItem primaryText={this.props.from.cardTicket ? 'Lost Credit Card' : 'Lost Ticket'} />
                      </Link>
                    }
                    {(this.shouldShowIssue("rateDispute") && this.props.from.laneType !== 'Entrance') &&
                      <Link
                        to="/transient/rateDispute"
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          this.sendSubtype('rateDispute');
                        }}>
                        <MenuItem primaryText="Rate Dispute" />
                      </Link>
                    }
                    {(this.shouldShowIssue("validationIssue") && this.props.from.laneType !== 'Entrance') &&
                      <Link
                        to="/transient/validationIssue"
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          this.sendSubtype('validationIssue');
                        }}>
                        <MenuItem primaryText="Validation Issue" />
                      </Link>
                    }
                    {(this.shouldShowIssue("validationIssue") && this.props.from.laneType == 'Entrance') &&
                      <Link
                        to="/transient/validationInstructions"
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          this.sendSubtype('validationInstructions');
                        }}>
                        <MenuItem primaryText="Validation Instructions" />
                      </Link>
                    }
                    {this.props.from.genericComponent &&
                      <Link
                        to="/genericComponent"
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          this.sendSubtype(_.camelCase(this.props.from.genericComponent));
                        }}
                      >
                        <MenuItem primaryText={this.props.from.genericComponent} />
                      </Link>
                    }
                    {this.props.from.brokersLocation &&
                      <Link
                        to="/transient/brokers"
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          this.sendSubtype('broker');
                        }}>
                        <MenuItem primaryText="Broker" />
                      </Link>
                    }
                    {(this.shouldShowIssue("receiptRequest") && this.props.from.laneType !== 'Entrance') &&
                      <Link
                        to="/transient/receiptRequest"
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          this.sendSubtype('receiptRequest');
                        }}>
                        <MenuItem primaryText="Receipt Request" />
                      </Link>
                    }
                    {this.shouldShowIssue("prepaidGuest") &&
                      <Link
                        to="/transient/prepaidContainer"
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          this.sendSubtype('prepaidGuest');
                        }}>
                        <MenuItem primaryText="Prepaid Guest" />
                      </Link>
                    }
                  </Menu>
                </Paper>
              </Paper>
            </div>
          </div>
        </ErrorBoundary>
      );
    } else {
      // For locations that we do not handle transient for, let's display that here at the root.
      return (
        <div className="home-div">
          <div className="home-div-content">
            <Paper zDepth={1} className="home-div-header-short">
              <h2>{this.props.from.locationName}</h2>
            </Paper>
            <div className="home-div-body">
              <ScriptText>{this.props.from.transientNote}</ScriptText>
              <ContactManagers showButtons={[]} />
            </div>
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username')
  };
}

export default withRouter(connect(mapStateToProps)(TransientIssue));
