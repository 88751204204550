import React, { Component } from "react";
import { connect } from "react-redux";
import autoBind from "react-autobind";
import _ from "lodash";
import { withRouter, Link } from "react-router-dom";
import * as config from "../../lib/config";
import * as promptSelectors from "../../store/prompt/reducer";
import * as authSelectors from "../../store/auth/reducer";
import * as queueSelectors from "../../store/queue/reducer";
import * as promptActions from "../../store/prompt/actions";
import * as queueActions from "../../store/queue/actions";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import ScriptText from "../../components/ScriptText";
import DirectionText from "../../components/DirectionText";
import Warning from "material-ui/svg-icons/alert/warning";
import moment from "moment-timezone";
import Checkbox from "@material-ui/core/Checkbox";

class VendGate extends Component {
  constructor(props) {
    super(props);
    this.reloadInterval = null;
    this.setVendingFalse = null;
    autoBind(this);
    this.state = {
      disableVendGate: false,
      vendGateEjectTicket: false,
      showDisableVendGateNote: false,
      gateKitAttempt: false,
    };
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push("/login");
    }
    if (this.props.from.laneType == "Paystation") {
      this.props.history.replace("/routeDisabled", {
        message:
          "Please call us from the exit so we can vend the gate for you.",
      });
    }

    if (this.props.from.vendGateEjectTicket) {
      this.setState({ disableVendGate: true });
    }

    if (
      this.props.isIntercomCall == 3 &&
      this.props.from.disableVendCellPhoneCall
    ) {
      this.setState({ disableVendGate: true, showDisableVendGateNote: true });
    }

    if (
      this.props.from.disableVendStartTime &&
      this.props.from.disableVendEndTime
    ) {
      // This is just for 445, not ideal but I don't have any more time for this feature right now...
      if (this.props.locationId === 445) {
        let disabledStartTime = moment(
          this.props.from.disableVendStartTime,
          "hhmm"
        ).valueOf();
        let disabledEndTime = moment(
          this.props.from.disableVendEndTime,
          "hhmm"
        ).valueOf();
        let currentTime = moment().valueOf();
        let hasSeenContactManagers = false;
        this.props.history.entries.forEach((ent) => {
          if (ent.pathname === "/contactManagers") {
            hasSeenContactManagers = true;
          }
        });
        if (
          currentTime > disabledStartTime &&
          currentTime < disabledEndTime &&
          !hasSeenContactManagers
        ) {
          this.props.history.replace("/contactManagers");
        }
      }
    }

    this.generateMessageText();
  }

  generateMessageText() {
    if (this.props.terminationLockedOut === true) {
      this.props.dispatch(
        promptActions.modularSendField(
          "Your card is being denied due to inactive payment. Please resolve this issue as soon as possible with your parking manager.",
          "monthlyLockoutText"
        )
      );
      return;
    }
    if (this.props.invalidAccessLocation === true) {
      this.props.dispatch(
        promptActions.modularSendField(true, "vendSuperWarning")
      );
      this.props.dispatch(
        promptActions.modularSendField(
          "You are attempting to park in an area that you are not given access to. Please find the upper-level area by backing up and going up the ramp to your right.",
          "monthlyLockoutText"
        )
      );
      return;
    }
    if (this.props.invalidAccessTime === true) {
      this.props.dispatch(
        promptActions.modularSendField(true, "vendSuperWarning")
      );
      let startTime = moment(this.props.from.disableVendEndTime, "hhmm").format(
        "h:mma"
      );
      let endTime = moment(this.props.from.disableVendStartTime, "hhmm").format(
        "h:mma"
      );
      this.props.dispatch(
        promptActions.modularSendField(
          `Vending is only allowed for this transaction between ${startTime} and ${endTime}. Please direct them to pay as a normal parker or to wait until their allowed time.`,
          "monthlyLockoutText"
        )
      );
      return;
    }
    if (this.props.wrongFacilityLockedOut === true) {
      this.props.dispatch(
        promptActions.modularSendField(
          "Your monthly pass appears to be for another facility. Please redirect to the proper facility to park.",
          "monthlyLockoutText"
        )
      );
      return;
    }
    if (
      this.props.apbLockedOut === true &&
      this.props.from.antiPassbackIntegrated
    ) {
      this.apbGuideText();
      return;
    }
    // If none of those are triggered, we must assume that the parker is good to go. Reset monthlyLockoutText and vendSuperWarning.
    this.props.dispatch(
      promptActions.modularSendField(false, "vendSuperWarning")
    );
    this.props.dispatch(
      promptActions.modularSendField(null, "monthlyLockoutText")
    );
    return;
  }

  componentWillUnmount() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
    if (this.setVendingFalse) {
      clearTimeout(this.setVendingFalse);
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push("/login");
    }
    this.generateMessageText();
  }

  updateVendState(event, target) {
    const value = event.target.checked;
    this.setState({ [target]: value });
    if (value == true) {
      this.setState({ disableVendGate: false });
    } else {
      this.setState({ disableVendGate: true });
    }
  }

  async sendAccessAction(locInfo) {
    let secs = (this.props.from.vendingSeconds || 60) * 1000;
    let locId = locInfo.locationId;
    this.props.dispatch(promptActions.setVending(true));
    if (this.props.from.gateKitId && !this.state.gateKitAttempt) {
      await this.props.dispatch(
        promptActions.gateKitVend({ gateKitId: this.props.from.gateKitId, locationId: this.props.from.locationId, locationName: this.props.from.locationName })
      );
      this.setState({ gateKitAttempt: true });
      this.props.dispatch(promptActions.setVending(false));
      return;
    }
    if (locId === 342) {
      this.props.dispatch(queueActions.dialToneVend("11"));
    } else if (this.props.from.dialToneVend) {
      this.props.dispatch(
        queueActions.dialToneVend(this.props.from.dialToneVend)
      );
    } else {
      const { parcs } = this.props.from;
      switch (parcs) {
        case "tibaAPI":
          const { apiParams, controlDeviceData, baseURL } = this.props.from;
          const { ongoingCallId, username } = this.props;
          const data = {
            username,
            ongoingCallId,
            apiParams,
            baseURL,
            commandType: controlDeviceData.vendGate,
            actionId: controlDeviceData.vendGateDBId,
            controlDeviceData,
          };
          await this.props.dispatch(
            promptActions.tibaSendControlDeviceAction(data)
          );
          this.props.dispatch(
            promptActions.modularSendField(true, "vendedGate")
          );
          break;
        case "flash":
          await this.props.dispatch(promptActions.flashOpenGate());
          this.props.dispatch(
            promptActions.modularSendField(true, "vendedGate")
          );
          break;
        default:
          this.props.dispatch(
            promptActions.sendAccessAction(locInfo, this.props.username)
          );
      }
    }
    this.reloadInterval = setTimeout(() => {
      this.props.dispatch(promptActions.setVending(false));
    }, secs);
  }

  apbGuideText() {
    if (this.props.from.laneType) {
      if (this.props.from.laneType === "Entrance") {
        this.props.dispatch(
          promptActions.modularSendField(
            "Your card is being denied because it was most recently used to enter the facility.",
            "monthlyLockoutText"
          )
        );
      }
      if (this.props.from.laneType === "Exit") {
        this.props.dispatch(
          promptActions.modularSendField(
            "Your card is being denied because it was most recently used to exit the facility.",
            "monthlyLockoutText"
          )
        );
      }
      if (this.props.from.laneType === "Paystation") {
        this.props.dispatch(
          promptActions.modularSendField(
            "Please move to an exit lane to be assisted.",
            "monthlyLockoutText"
          )
        );
      }
    }
  }

  shouldShowVend() {
    if (this.props.vendingStatus) {
      return false;
    }
    if (this.props.allowVend === false) {
      return false;
    }
    if (this.props.wrongFacilityLockedOut === true) {
      return false;
    }
    return true;
  }

  render() {
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <Paper
            zDepth={1}
            className="paper-padding prompt-body"
            style={{ marginTop: "20px" }}
          >
            {this.props.monthlyLockoutText && (
              <ScriptText>{this.props.monthlyLockoutText}</ScriptText>
            )}
            {this.props.vendSuperWarning && (
              <div className="super-warning">
                <span>
                  Vending is not suggested for this interaction. Only vend if
                  you absolutely must. See reason above.
                </span>
                <div className="super-warning-icon-container">
                  <Warning className="super-warning-icon" />
                </div>
              </div>
            )}
            {!this.props.vendStatus && this.props.from.allowVend === false && (
              <DirectionText>
                Vending is not allowed at this time.
              </DirectionText>
            )}
            {this.props.from.noContactEmailRequired &&
              _.map(this.props.from.managers, (v, i, c) => {
                if (v.facilityManager === true) {
                  return (
                    <DirectionText>
                      <span>Facility Manager: </span>
                      <span>{v.name} </span>
                      <span>({v.email})</span>
                    </DirectionText>
                  );
                }
              })}
            {/* Only show this for locations that have not enabled a hard "invalidAccessTime" lockout! */}
            {!this.props.from.invalidAccessTimeEnabled &&
              this.props.selectedParker &&
              this.props.selectedParker.AfterHours &&
              this.props.selectedParker.AfterHours === true && (
                <DirectionText>
                  <span>
                    This parker is an "After Hours" parker. Please only vend the
                    gate under the following circumstances:
                  </span>
                  <ul>
                    <li>It is between 5:00PM and 7:00AM (EST)</li>
                    <li>It is a weekend</li>
                    <li>It is a holiday</li>
                  </ul>
                </DirectionText>
              )}

            {this.shouldShowVend() && (
              <div>
                <ScriptText>
                  "I am in the process of vending the gate for you. Please allow
                  up to {this.props.from.vendingSeconds} seconds for the process
                  to complete."
                </ScriptText>
                {this.props.from.noContactEmailRequired && (
                  <DirectionText>
                    Before vending the gate, ensure that you have called all
                    attendants and managers. If you have contacted all managers
                    and received no response, please send an email to the
                    location manager alerting them that you have not been able
                    to reach any manager.
                  </DirectionText>
                )}
              </div>
            )}
            {this.props.from.vendGateEjectTicket &&
              !this.props.vendingStatus && (
                <div style={{ marginTop: 15, marginBottom: 15 }}>
                  <Checkbox
                    target="vendGateEjectTicket"
                    checked={this.state.vendGateEjectTicket}
                    onChange={(e) =>
                      this.updateVendState(e, "vendGateEjectTicket")
                    }
                    inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                  />
                  Please ask parker to press cancel button and retrieve their
                  ticket before vending the gate.
                </div>
              )}
            {this.props.vendingStatus && (
              <RaisedButton
                label="Please Wait..."
                style={{ margin: "0 10px" }}
              />
            )}
            {this.props.from.gateKitId && (
              <DirectionText>
                Please wait up to 20 seconds for the gate to open. If the gate fails
                to open, please press the Vend Gate button again to use the backup vending
                method.
              </DirectionText>
            )}
            {this.state.showDisableVendGateNote && (
              <p>
                {" "}
                <span style={{ color: "red", fontWeight: "bolder" }}>
                  {" "}
                  Note:{" "}
                </span>{" "}
                This call is from a cell phone, please use the backup method to
                vend the gate{" "}
              </p>
            )}
            {this.shouldShowVend() && (
              <RaisedButton
                label="VEND GATE"
                style={{ margin: "0 10px" }}
                backgroundColor={"#297eb6"}
                labelColor={"#FFF"}
                disabled={this.state.disableVendGate}
                onClick={() => {
                  this.sendAccessAction(this.props.from);
                }}
              />
            )}
            <Link to="/confirmationScreen">
              <RaisedButton label="Exit" />
            </Link>
          </Paper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, "username"),
    vendingStatus: promptSelectors.pullVendingStatus(state),
    ongoingCallId: queueSelectors.pullModular(state, "ongoingCallId"),
    apbLockedOut: promptSelectors.pullModular(state, "apbLockedOut"),
    terminationLockedOut: promptSelectors.pullModular(
      state,
      "terminationLockedOut"
    ),
    wrongFacilityLockedOut: promptSelectors.pullModular(
      state,
      "wrongFacilityLockedOut"
    ),
    selectedParker: promptSelectors.pullModular(state, "selectedParker"),
    invalidAccessLocation: promptSelectors.pullModular(
      state,
      "invalidAccessLocation"
    ),
    invalidAccessTime: promptSelectors.pullModular(state, "invalidAccessTime"),
    monthlyLockoutText: promptSelectors.pullModular(
      state,
      "monthlyLockoutText"
    ),
    vendSuperWarning: promptSelectors.pullModular(state, "vendSuperWarning"),
    pilPaymentCode: promptSelectors.pullModular(state, "pilPaymentCode"),
    isIntercomCall: queueSelectors.pullModular(state, "isIntercomCall"),
  };
}

export default withRouter(connect(mapStateToProps)(VendGate));
