const initialState = {
  from: {},
  issue: {
    type: null,
    subtype: null,
    ticketNumber: null,
    paymentIssue: null,
    paymentIssues: []
  },
  companyIndex: null,
  monthlyParkersList: {},
  monthlyParkersListApb: [],
  monthlyParkersListApbFiltered: [],
  monthlySearchText: '',
  monthlyCardIssue: null,
  airportParkersList: [],
  airportSearchText: '',
  selectedParker: {},
  selectedManagerName: null,
  selectedParkerAidType: null,
  vendedGate: false,
  step: 0,
  dCStatus: false,
  vendingStatus: false,
  showManagers: false,
  email: '',
  contactNumber: '',
  lastFour: '',
  receiptRequestSubmitted: false,
  thirdPartyVendorName: null,
  thirdPartyVendorId: null,
  thirdPartyReservationNumber: null,
  attendantName: null,
  malfunctionValue: null,
  malfunctionIndex: null,
  malfunctionDescription: null,
  ticketDiscrepancyAddressError: null,
  ticketDiscrepancyEntranceTime: null,
  parsableEntranceTime: null,
  ticketDiscrepancyEntranceDate: null,
  ticketDiscrepancyExitTime: null,
  parsableExitTime: null,
  ticketDiscrepancyExitDate: null,
  ticketDiscrepancyFeeDue: null,
  ticketDiscrepancyFirstName: "",
  ticketDiscrepancyLastName: "",
  addressOptions: [],
  ticketDiscrepancyFullAddress: "",
  ticketDiscrepancyAddress: "",
  ticketDiscrepancyPhoneNumber: "",
  ticketDiscrepancyCity: "",
  ticketDiscrepancyState: "",
  ticketDiscrepancyZip: "",
  ticketDiscrepancyEmail: "",
  ticketDiscrepancyNotes: "",
  ticketDiscrepancyComplete: false,
  ticketDiscrepancyLicensePlate: null,
  ticketDiscrepancyCarMake: null,
  ticketDiscrepancyCarModel: null,
  ticketDiscrepancyEjectTicket: null,
  receiptRequestFirstName: null,
  receiptRequestLastName: null,
  receiptRequestFeeDue: null,
  receiptRequestEntranceTime: null,
  receiptRequestEntranceDate: null,
  receiptRequestExitTime: null,
  receiptRequestExitDate: null,
  selectedParkerName: null,
  selectedParkerCardNumber: null,
  selectedParkerMonthlyStatus: null,
  selectedParkerRoomNumber: null,
  selectedParkerIndex: null,
  serviceWorkerType: null,
  error: false,
  errorMessage: null,
  snackbarType: null,
  residentUnit: null,
  transponderNumber: null,
  residentIssueComplete: false,
  companyName: null,
  reportingTo: null,
  visitPurpose: null,
  employeeIssueComplete: false,
  visitingApartmentNumber: null,
  visitingResidentName: null,
  vehicleMake: null,
  vehicleModel: null,
  licensePlateNumber: null,
  visitorIssueComplete: false,
  reservationFeeDue: null,
  reservationNumber: null,
  reservationStatus: null,
  reservationName: null,
  reservationDiscrepancyComplete: null,
  workerNameRequired: false,
  companyNameRequired: false,
  reportingToRequired: false,
  serviceWorkerComplete: false,
  hideGhostCall: false,
  monthlyRedirect: null,
  validationNumber: null,
  validationSource: null,
  validationDateTime: null,
  parkerEntranceDate: null,
  parkerEntranceTime: null,
  parkerExitDate: null,
  parkerExitTime: null,
  laneType: null,
  apbLockedOut: null,
  terminationLockedOut: null,
  equipmentMalfunctionDecision: null,
  managerVendConfirmation10s: false,
  managerRequestedVendGate: false,
  rerender: false,
  wrongFacilityLockedOut: null,
  guestVerified: null,
  nextStepsLoading: false,
  invalidAccessLocation: null,
  invalidAccessTime: null,
  managerActionRequest: null,
  monthlyLockoutText: null,
  vendSuperWarning: null,
  contactManagersInitTime: null,
  contactManagersTotalTime: null,
  outsideCallMenuSelector: null,
  outsideLocations: null,
  outsideLocationSelected: null,
  outsideLocationEndpointSelected: null,
  onOutsideCall: false,
  answeredTime: null,
  ticketNumber: null,
  mapLoaded: false,
  confirmationScreenLoading: false,
  selectIndex: null,
  parkerFirstName: null,
  parkerLastName: null,
  formComplete: false,
  lostTicketNameInput: false,
  isManagerCallOnHold: false,
  enableManagerHoldCallButton: false,
  showLoader: false,
  flashMonthlyCardID: null,
  flashSupportCallID: null,
  machineMessage: null,
  parkerFeeDue: null,
  parkerNotFound: false,
  pushRateQuestion: false,
  pilPaymentCode: null,
  pilPaymentAmount: null,
  pilTransaction: null,
  pilCheckTransactionStatus: null,
  noManagerResponse: false,
  tibaRateResult: {
    rate: null,
    shouldVend: null,
    parkerEntryDateResult: null
  },
  otherQuestion: "",
  vendForAll: false,
  customInquiryTips: null
};

let newState;

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case 'TWILIO_ACCEPT':
      return { ...state };
    case 'OUTSIDE_CALL_INC':
      let lI = action.locationInfo;
      return { ...state, from: lI};
    case 'TWILIO_INC_LOC':
      let locInfo = action.locationInfo;
      return { ...state, from: locInfo };
    case `TWILIO_CALL_END`:
      return state;
    case `UPDATE_ISSUE_TYPE`:
      newState = { ...state };
      newState.issue.type = action.status;
      return newState;
    case 'UPDATE_SUBTYPE':
      newState = state;
      newState.issue.subtype = action.subtype;
      return newState;
    case `UPDATE_ISSUE_STEP`:
      return { ...state, step: action.stepNum };
    case 'UPDATE_PAYMENT_ISSUE_TYPE':
      newState = state;
      newState.issue.paymentIssue = action.issueType;
      newState.issue.paymentIssues = [...state.issue.paymentIssues, action.issueType];
      return newState;
    case 'CLEAR_PAYMENT_ISSUE_TYPE':
      newState = state;
      newState.issue.paymentIssue = null
      newState.issue.paymentIssues = [];
      return newState;
    case 'SEND_VAL_COMPANY_INDEX':
      newState = { ...state };
      newState.companyIndex = action.index;
      return newState;
    case 'SEND_TICKET_TO_STATE':
      newState = { ...state };
      newState.issue.ticketNumber = action.ticketNum;
      return newState;
    case 'SEND_VAL_NUM_TO_STATE':
      newState = { ...state };
      newState.validationNumber = action.valNum;
      return newState;
    case 'UPDATE_MONTHLY_PARKERS':
      return {
        ...state,
        monthlyParkersList: action.monthlyParkers,
        terminationLockedOut: false,
        apbLockedOut: false,
        invalidAccessLocation: false,
        invalidAccessTime: false,
        wrongFacilityLockedOut: false,
        monthlySearchText: '',
        selectedParker: {},
        selectedParkerIndex: null
      };
    case 'UPDATE_MONTHLY_RECORDS':
      return { ...state, monthlyParkersListApb: action.records };
    case 'MONTHLY_PARKER_SEARCH':
      return { ...state, monthlySearchText: action.value };
    case 'UPDATE_SELECTED_PARKER':
      return { ...state, selectedParker: action.selectedParker };
    case 'UPDATE_SHOW_MANAGERS':
      return { ...state, showManagers: action.value };
    case 'RESET_PROMPT_STATE':
      return { ...initialState, issue: { paymentIssues: [] }, validation: {} };
    case 'ACTION_SEND':
      return { ...state, vendedGate: true };
    case 'DC_STATUS':
      return { ...state, dCStatus: action.status };
    case 'SET_VENDING_STATUS':
      return { ...state, vendingStatus: action.status };
    case 'MIGRATE_SEARCH_TO_SELECTED':
      return { ...state, selectedParker: action.value };
    case 'UPDATE_EMAIL':
      return { ...state, email: action.value };
    case 'UPDATE_CONTACT_NUMBER':
      return { ...state, contactNumber: action.value };
    case 'UPDATE_LAST_FOUR':
      return { ...state, lastFour: action.value };
    case 'UPDATE_RECEIPT_REQUEST_SUBMITTED':
      return { ...state, receiptRequestSubmitted: action.value };
    case 'UPDATE_THIRD_PARTY_VENDOR':
      return { ...state, thirdPartyVendorName: action.value, thirdPartyVendorId: action.index };
    case 'UPDATE_ATTENDANT_NAME':
      return { ...state, attendantName: action.value };
    case 'UPDATE_AIRPORT_PARKERS':
      return { ...state, airportParkersList: action.value };
    case 'AIRPORT_PARKER_SEARCH':
      return { ...state, airportSearchText: action.value };
    case 'MODULAR_PROMPT_UPDATE':
      newState = { ...state };
      newState[action.target] = action.value;
      return newState;
    case 'UPDATE_FEE_DUE':
      return { ...state, ticketDiscrepancyFeeDue: action.value };
    case 'SUBMIT_TD_COMPLETE':
      return { ...state, ticketDiscrepancyComplete: action.value };
    case 'SUBMIT_TICKET_DISCREPANCY':
      return { ...state, ticketDiscrepancyComplete: action.success };
    case 'SUBMIT_RESERVATION_DISCREPANCY':
      return { ...state, reservationDiscrepancyComplete: action.success };
    case 'SUBMIT_LOC_PLACESCOMPLETE':
      return { ...state, addressOptions: action.locations };
    case 'SHOW_SNACKBAR':
      const { message, type } = action.snackbarData;
      return { ...state, error: true, errorMessage: message, snackbarType: type };
    case 'RESET_SNACKBAR':
      return { ...state, error: false, errorMessage: null, snackbarType: null };
    case 'PUSH_TO_FIRST':
      return {
        ...state,
        from: {
          ...state.from,
          gateId: action.gateId,
          gateName: action.gateName,
          ticketAcceptanceRedirect: action.ticketAcceptanceRedirect,
          transientLane: action.transientLane,
          monthlyLane: action.monthlyLane,
          specialMonthlyLane: action.specialMonthlyLane,
          prepaidRedirect: action.prepaidRedirect,
          laneType: action.laneType,
          monthlyParkersListApb: [],
          monthlyParkersListApbFiltered: [],
          selectedParkerIndex: null,
          selectedParkerAidType: null,
          apbLockedOut: null,
          terminationLockedOut: null,
          equipmentMalfunctionDecision: null,
          rerender: false,
          wrongFacilityLockedOut: null,
          guestVerified: null,
          nextStepsLoading: false,
          invalidAccessLocation: null,
          invalidAccessTime: null,
          managerActionRequest: null,
          monthlyLockoutText: null,
          vendSuperWarning: null,
          contactManagersInitTime: null,
          contactManagersTotalTime: null,
          outsideCallMenuSelector: null,
          outsideLocationSelected: null,
          outsideLocationEndpointSelected: null,
          onOutsideCall: false,
          answeredTime: null,
          ticketNumber: null,
          lostTicketNameInput: false,
          enablePIL: action.enablePIL,
          equipmentMalfunctionPILRoutes: action.equipmentMalfunctionPILRoutes,
          lostTicketNotes: action.lostTicketNotes,
          lostTicketPushRate: action.lostTicketPushRate,
          googleSheetInfo: action.googleSheetInfo,
          customButtons: action.customButtons,
          customManagerNoResponse: action.customManagerNoResponse,
          directToTDPIL: action.directToTDPIL,
          gracePeriodInt: action.gracePeriodInt,
          disablePILFor: action.disablePILFor,
          transientBarcodeNote: action.transientBarcodeNote,
          validationNote: action.validationNote,
          monthlyRedirectPIL: action.monthlyRedirectPIL,
          monthlyRedirect: action.monthlyRedirect,
          valetNotAllowedLot: action.valetNotAllowedLot,
          valetNotAllowedNote: action.valetNotAllowedNote,
          descriptor: action.descriptor,
          monthlyRedirectEntrance: action.monthlyRedirectEntrance,
          transientNotAllowedLot: action.transientNotAllowedLot,
          transientNotAllowedNote: action.transientNotAllowedNote,
          prepaidOffering: action.prepaidOffering,
          prepaidSources: action.prepaidSources,
          prepaidRedirectLanes: action.prepaidRedirectLanes,
          pulledTicketNote: action.pulledTicketNote,
          monthlyShowSubmitSearchText: action.monthlyShowSubmitSearchText,
          genericComponentVend: action.genericComponentVend,
          genericComponentText: action.genericComponentText
        },
        managerVendConfirmation10s: false,
        managerRequestedVendGate: false,
        parkerNotFound: false,
        pilPaymentCode: null,
        pilPaymentAmount: null,
        pilTransaction: null,
        pilCheckTransactionStatus: null,
        noManagerResponse: false,
        vendForAll: action.vendForAll
      }
    case 'MODIFY_GHOST_CALL':
      return { ...state, hideGhostCall: action.bool };
    // New reducers for new flows:
    case 'RESET_PREPAID_DETAILS': {
      return {
        ...state,
        selectedParkerName: null,
        thirdPartyReservationNumber: null,
        thirdPartyVendorName: null,
        thirdPartyVendorId: null
      }
    }
    default:
      return state;
  }
}

// SELECTORS
export function pullState(state) {
  return state.prompt;
}

export function pullLocationId(state) {
  return state.prompt.from;
}

export function pullIssue(state) {
  return state.prompt.issue;
}

export function pullIssueSteps(state) {
  return state.prompt.stepOne;
}

export function pullStep(state) {
  return state.prompt.step;
}

export function pullPaymentIssue(state) {
  return state.prompt.issue.paymentIssue;
}

export function pullPaymentIssues(state) {
  return state.prompt.issue.paymentIssues;
}

export function pullTicketNum(state) {
  return state.prompt.issue.ticketNumber;
}

export function pullValNum(state) {
  return state.prompt.validationNumber;
}

export function pullMonthlyParkers(state) {
  return state.prompt.monthlyParkersList;
}

export function pullMonthlySearchText(state) {
  return state.prompt.monthlySearchText;
}

export function pullSelectedParker(state) {
  return state.prompt.selectedParker;
}

export function pullVendedGate(state) {
  return state.prompt.vendedGate;
}

export function pullDCStatus(state) {
  return state.prompt.dCStatus;
}

export function pullVendingStatus(state) {
  return state.prompt.vendingStatus;
}

export function pullShowManagers(state) {
  return state.prompt.showManagers;
}

export function pullEmail(state) {
  return state.prompt.email;
}

export function pullContactNumber(state) {
  return state.prompt.contactNumber;
}

export function pullLastFour(state) {
  return state.prompt.lastFour;
}

export function pullReceiptRequestSubmitted(state) {
  return state.prompt.receiptRequestSubmitted;
}

export function pullThirdPartyVendorName(state) {
  return state.prompt.thirdPartyVendorName;
}

export function pullThirdPartyVendorId(state) {
  return state.prompt.thirdPartyVendorId;
}

export function pullThirdPartyReservationNumber(state) {
  return state.prompt.thirdPartyReservationNumber;
}

export function pullAttendantName(state) {
  return state.prompt.attendantName;
}

export function pullAirportParkers(state) {
  return state.prompt.airportParkersList;
}

export function pullAirportSearchText(state) {
  return state.prompt.airportSearchText;
}

export function pullModular(state, field) {
  return state.prompt[field];
}
