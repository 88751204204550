import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as authSelectors from '../../../store/auth/reducer';
import NextSteps from '../../../components/nextSteps';
import Paper from 'material-ui/Paper';
import ScriptText from '../../../components/ScriptText';
import ErrorBoundary from '../../ErrorBoundary';
import ContactManagers from '../ContactManagers';

class MonthlyNotFound extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <div className="home-div">
          <div className="home-div-content">
            <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
              <h2>{this.props.from.locationName}</h2>
            </Paper>
            <div className="home-div-body">
              <Paper zDepth={1} className="paper-padding">
                {this.props.from.monthlyNotFoundNote
                ?
                  <ScriptText>
                    {this.props.from.monthlyNotFoundNote}
                  </ScriptText>
                :
                  <ScriptText>
                    "I was unable to verify you as a monthly parker for this location. You will have to pay the lost ticket fee. If you are positive this is a mistake, please present the lost ticket to
                    the location manager for reimbursement and to resolve this issue."
                  </ScriptText>
                }
              </Paper>
              {this.props.from.monthlyNotFoundManagers && <ContactManagers showButtons={['managerAssisting', 'vendGate', 'ticketDiscrepancy']} />}
              {!this.props.from.monthlyNotFoundManagers && <NextSteps />}
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username')
  };
}

export default withRouter(connect(mapStateToProps)(MonthlyNotFound));
