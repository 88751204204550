import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptSelectors from '../../store/prompt/reducer';
import * as promptActions from '../../store/prompt/actions';
import * as authSelectors from '../../store/auth/reducer';
import LaneList from '../../components/laneList';
import DirectionText from '../../components/DirectionText';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import moment from 'moment-timezone';

class First extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.reloadInterval = null;
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (this.props.from.isAttendanceCallIn) {
      this.props.dispatch(promptActions.updateIssueType('attendanceCallIn'));
      this.props.history.push('/attendanceCallIn');
      return;
    }
    if (this.props.from.isLandline) {
      this.props.dispatch(promptActions.updateIssueType('landline'));
      this.props.history.push('/landline');
      return;
    }
    if (this.props.from.isShuttle) {
      this.props.dispatch(promptActions.updateIssueType('shuttle'));
      this.props.history.push('/shuttle');
      return;
    }
    if (this.props.from.vendForAll) {
      this.props.dispatch(promptActions.updateIssueType('generalInquiry'));
      this.props.history.push('/vendForAll');
      return;
    }
    if (!this.props.from.preFirst) {
      this.props.history.push('/first');
      return;
    }
  }

  componentWillUnmount() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (!this.props.from.preFirst) {
      this.props.history.push('/first');
    }
  }

  pushToFirst(value) {
    this.props.dispatch(promptActions.pushToFirst(value));
    this.props.history.push('/first');
  }


  sendIssueTypeToStore(event) {
    this.props.dispatch(promptActions.updateIssueType(event));
  }

  shouldDisableGhost() {
    if (!this.props.answeredTime) {
      return;
    }
    let elapsedTime = moment.now() - this.props.answeredTime;
    if (elapsedTime >= 30000) {
      return this.props.dispatch(promptActions.modifyGhostCall(true));
    }
  }

  render() {
    this.shouldDisableGhost();
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
            <DirectionText>Please ask the parker which lane they are in.</DirectionText>
            <LaneList pushToFirst={this.pushToFirst} lanes={this.props.from.laneList} />
            <Link
              to="/ghost"
              style={{ textDecoration: 'none', paddingTop: '20px' }}
              onClick={() => {
                this.sendIssueTypeToStore('ghost');
              }}
            >
            { !this.props.hideGhostCall &&
              <RaisedButton className="pre-first-ghost-button" label="Ghost Call" />
            }
            </Link>
          </Paper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    friendlyName: authSelectors.pullModular(state, 'friendlyName'),
    hideGhostCall: promptSelectors.pullModular(state, 'hideGhostCall')
  };
}

export default withRouter(connect(mapStateToProps)(First));
