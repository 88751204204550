/*
  This component is imported in App.js
  It is required so that the setTimeOut that checks for paid PIL transaction is not stopped
  on componentDidUnmount of PILProcessPayment.
*/

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import * as queueSelectors from '../../../store/queue/reducer';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as promptActions from '../../../store/prompt/actions';
import * as authSelectors from '../../../store/auth/reducer';
import { retrieveLogger } from '../../../lib/logger';
let logger = retrieveLogger();

class PILCheckTransactionStatus extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.transactionStatusInterval = null;
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.pilCheckTransactionStatus == nextProps.pilCheckTransactionStatus) {
      return false;
    } else {
      return true;
    }
  }

  componentDidUpdate() {
    const { username, ongoingCallId, pilPaymentCode, pilCheckTransactionStatus } = this.props;
    const { timezone } = this.props.from;
    logger.info(`PIL Check Transaction Status: ${pilCheckTransactionStatus}`);
    if (pilCheckTransactionStatus) {
      this.transactionStatusInterval = setInterval(async () => {
        logger.info(`Checking PIL Transaction Status`);
        const result = await this.props.dispatch(promptActions.pilCheckTransactionStatus({ ongoingCallId, username, generatedCode: pilPaymentCode, timezone }));
        if (result && result.data) {
          const { pilTransaction } = result.data;
          this.props.dispatch(promptActions.modularSendField(pilTransaction, 'pilTransaction'));
          if (pilTransaction && pilTransaction.Status === 3) {
            logger.info(`Clearing PIL Transaction Status: In Success`)
            clearInterval(this.transactionStatusInterval);
            this.props.history.push('/pil/pilPaymentResult');
          }
        }
      }, 5000);
    } else if (pilCheckTransactionStatus == false && pilCheckTransactionStatus !== null) {
      logger.info(`Clearing PIL Transaction Status: In False (Confirmation Screen/ Vend Gate/ Success PIL Page)`);
      clearInterval(this.transactionStatusInterval);
    } else {
      return;
    }
  }

  render() {
    return (<Fragment />)
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    ongoingCallId: queueSelectors.pullModular(state, 'ongoingCallId'),
    pilPaymentCode: promptSelectors.pullModular(state, 'pilPaymentCode'),
    pilCheckTransactionStatus: promptSelectors.pullModular(state, 'pilCheckTransactionStatus'),
  };
}

export default withRouter(connect(mapStateToProps)(PILCheckTransactionStatus));