import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as authSelectors from '../../../store/auth/reducer';
import * as promptActions from '../../../store/prompt/actions';
import { TextField, Grid, Radio } from '@material-ui/core';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import ContactManagers from '../ContactManagers';
import ScriptText from '../../../components/ScriptText';
import DirectionText from '../../../components/DirectionText';
import MonthlyOnlyLane from '../../../components/monthlyOnlyLane';
import SpecialMonthlyOnlyLane from '../../../components/specialMonthlyOnlyLane';
import PromptBox from '../../../components/promptBox';
import ErrorBoundary from '../../ErrorBoundary';

const paperStyle = {
  display: 'inline-block',
  margin: '16px 32px 16px 32px'
};

class PaymentIssue extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { showTicketNumberForm: false, managerName: null, rateToPush: null, pushRateQuestion: true }
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    this.props.dispatch(promptActions.updateIssueStep(0));
    this.props.dispatch(promptActions.clearPaymentIssueType());
    this.props.dispatch(promptActions.modularSendField(false, 'showManagers'));
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  sendPaymentIssueType(type) {
    this.props.dispatch(promptActions.sendPaymentIssueType(type));
  }

  updateState(event, target) {
    this.setState({ [target]: event.target.value });
  }

  mapLocToCardOrientation() {
    switch (this.props.from.locationId) {
      case 1:
        return {
          vertical: 'down',
          horizontal: 'left'
        };
      default:
        return {
          vertical: 'up',
          horizontal: 'right'
        };
    }
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'showPushRateForm':
        this.props.dispatch(promptActions.modularSendField(true, 'pushRateQuestion'));
        this.props.history.push('/tibaAPI/tibaManagerPushRate');
        break;
      case 'showManagers':

        this.props.dispatch(promptActions.modularSendField(true, 'showManagers'));
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  render() {
    let dirs = {
      cD: {
        vertical: null,
        horizontal: null
      }
    };
    if (this.state.showTicketNumberForm) {
      return (
        <PromptBox locationName={this.props.from.locationName}>
          <Fragment>
            <ScriptText>"May I please have your Ticket Number?"</ScriptText>
            <TextField
              label="Ticket Number"
              value={this.props.ticketNumber || ''}
              margin="dense"
              variant="outlined"
              inputProps={{ className: 'data-hj-allow' }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => { this.props.dispatch(promptActions.modularSendField(event.target.value, 'ticketNumber')); }}
              style={{ width: '100%', marginTop: 15 }}
            />
            <RaisedButton label="Continue" disabled={!this.props.ticketNumber} onClick={() => { this.props.history.push('/vendGate') }} />
            <RaisedButton label="Exit" onClick={() => { this.props.history.push('/confirmationScreen') }} />
          </Fragment>
        </PromptBox>
      )
    }
    if (this.props.from.cardDirection) {
      dirs.cD.vertical = this.props.from.cardDirection.vertical;
      dirs.cD.horizontal = this.props.from.cardDirection.horizontal;
    }
    if (this.props.from.contractorLane && !this.props.from.transientLane) {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className={`home-div-content`}>
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <div className="home-div-body">
                <Paper zDepth={1} className="paper-padding">
                  <ScriptText>"You are in a Contractor-Only lane. Please redirect to one of the following lanes: {this.props.from.ticketAcceptanceRedirect}."</ScriptText>
                  <Link to="/confirmationScreen">
                    <RaisedButton label="Complete Call" />
                  </Link>
                </Paper>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      );
    } else if (!this.props.from.transientLane && this.props.from.monthlyLane) {
      return <ErrorBoundary><MonthlyOnlyLane locationName={this.props.from.locationName} ticketAcceptanceRedirect={this.props.from.ticketAcceptanceRedirect} /></ErrorBoundary>
    } else if (!this.props.from.transientLane && !this.props.from.monthlyLane && this.props.from.specialMonthlyLane) {
      let availableLanes = promptActions.prepareAvailableLanes(this.props.from);
      return
      <ErrorBoundary>
        <SpecialMonthlyOnlyLane
          locationName={this.props.from.locationName}
          specialMonthlyLane={this.props.from.specialMonthlyLane}
          redirect={availableLanes}
        />
      </ErrorBoundary>
    } else {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className={`home-div-content`}>
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              {this.props.paymentIssues &&
                this.props.paymentIssues.length <= 0 && (
                  <Paper style={paperStyle}>
                    <Menu>
                      <MenuItem
                        primaryText="Credit Card Read Issue"
                        onClick={() => {
                          this.sendPaymentIssueType('cc');
                        }}
                      />
                      <MenuItem
                        primaryText="Wants To Pay Cash"
                        onClick={() => {
                          this.sendPaymentIssueType('cash');
                        }}
                      />
                    </Menu>
                  </Paper>
                )
              }

              {this.props.paymentIssueType === 'cc' &&
                !this.props.showManagers && (
                  <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
                    {// This conditional render is to only show this section of prompt when credit card is 2nd option for Payment Issue
                      // OR when it is 180 Allyn
                      // Possibly just make this show all the time. Could be redundant but possibly helpful for simplicity's sake.
                      // eslint-disable-next-line
                      (this.props.from.locationId === 22 || (this.props.paymentIssues && this.props.paymentIssues.length >= 2)) && (
                        <div>
                          <ScriptText>"Do you have any debit or credit cards you can pay with?"</ScriptText>
                          <DirectionText>If yes, please read the following instructions to the parker.</DirectionText>
                        </div>
                      )}
                    {this.props.from.cardTicket && (
                      <div>
                        <ScriptText>"Please ensure that you are inserting the same credit card that you entered the lot with."</ScriptText>
                        <DirectionText>If that does not work...</DirectionText>
                      </div>
                    )}
                    {this.props.from.creditCardChipStyle 
                    ?
                      <Fragment>
                        {this.props.from.creditCardChipStyleNote
                        ?
                          <ScriptText>
                            {this.props.from.creditCardChipStyleNote}
                          </ScriptText>
                        :
                          <Fragment>
                            {this.props.from.locationId === 384 &&
                              <DirectionText>
                                <span style={{ color: 'red', fontWeight: 'bolder' }} >Note: </span>
                                If customer’s credit card has chip, they have to press a blue button on machine to enter the chip.
                              </DirectionText>
                            }
                            {this.props.from.payStationButtonNote &&
                              <ScriptText>
                                "Please ensure you are pressing the credit/debit button on the machine."
                              </ScriptText>
                            }
                            <ScriptText>
                              "Please ensure you have inserted the card with the chip inside of the machine. If there is no chip, put the magnetic strip {dirs.cD.vertical && `${dirs.cD.vertical}/`}{dirs.cD.horizontal}"
                            </ScriptText>
                            <ScriptText>"Please ensure that you keep your credit card inside of the machine until the display tells you to remove the card."</ScriptText>
                          </Fragment>
                        }
                      </Fragment>
                    :
                      <div>
                        <ScriptText>
                          {this.props.from.ticketAndCard && `Insert ticket barcode facing up.`}
                          Please ensure that you are inserting the credit card with the proper orientation. Magnetic strip: {dirs.cD.vertical && `${dirs.cD.vertical}/`}{dirs.cD.horizontal}
                        </ScriptText>
                        <ScriptText>"Please ensure that you are inserting and removing the card quickly afterwards."</ScriptText>
                      </div>
                    }
                    <DirectionText>If that does not work...</DirectionText>
                    <ScriptText>"Is there a second card you can try to process payment with?"</ScriptText>
                    {this.props.from.ticketAcceptanceRedirect && (
                      <div>
                        <DirectionText>If that does not work...</DirectionText>
                        <ScriptText>"Please move to the {this.props.from.ticketAcceptanceRedirect} machine to pay."</ScriptText>
                      </div>
                    )}
                    {this.props.from.paymentIssueVendAlways
                    ?
                      <RaisedButton label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />
                    :
                      <Fragment>
                        <span className="verification-span">Is Further Assistance Required?</span>
                        <Link to="/confirmationScreen">
                          <RaisedButton label="No" style={{ margin: '0 10px' }} />
                        </Link>
                        {this.props.paymentIssues.length < 2 && (
                          <RaisedButton
                            label="Yes"
                            onClick={async () => {
                              const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('paymentIssue'));
                              if (shouldEnablePIL) {
                                this.props.history.push('/pil/PILEntryInput')
                              } else {
                                this.sendPaymentIssueType('cash');
                              }
                            }}
                          />
                        )}
                        {this.props.paymentIssues.length >= 2 &&
                          !this.props.from.paymentIssueIgnoreManager &&
                          !this.props.from.paymentIssueCancelInstruction &&
                          <RaisedButton label="Yes" onClick={async () => {
                            const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('paymentIssue'));
                            if (shouldEnablePIL) {
                              this.props.history.push('/pil/PILEntryInput')
                            } else {
                              this.btnClick('showManagers')
                            }
                          }}
                          />
                        }
                        {this.props.paymentIssues.length >= 2 &&
                          this.props.from.paymentIssueIgnoreManager &&
                          !this.props.from.paymentIssueLogTicketNumber &&
                          !this.props.from.paymentIssueCancelInstruction && (
                            <RaisedButton label="Yes" onClick={async () => {
                              const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('paymentIssue'));
                              if (shouldEnablePIL) {
                                this.props.history.push('/pil/PILEntryInput')
                              } else {
                                this.props.history.push('/ticketDiscrepancy')
                              }
                            }}
                            />
                          )}
                        {this.props.paymentIssues.length >= 2 &&
                          this.props.from.paymentIssueIgnoreManager &&
                          this.props.from.paymentIssueLogTicketNumber &&
                          <RaisedButton label="Yes" onClick={() => { this.setState({ showTicketNumberForm: true }) }} />
                        }
                        {this.props.paymentIssues.length >= 2 &&
                          this.props.from.paymentIssueCancelInstruction &&
                          <RaisedButton
                            onClick={() => {
                              this.sendPaymentIssueType('trioCustom');
                            }}
                            label="Yes" />
                        }
                      </Fragment>
                    }
                  </Paper>
                )}

              {this.props.paymentIssueType === 'cash' &&
                !this.props.showManagers && (
                  <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
                    {this.props.paymentIssues &&
                      this.props.paymentIssues.length === 2 &&
                      this.props.from.cashPayment && (
                        <div>
                          <ScriptText>"Do you have any cash you are able to pay with?"</ScriptText>
                          <DirectionText>If yes, please read the following instructions to the parker.</DirectionText>
                        </div>
                      )}
                    <ScriptText>"{this.props.from.cashPaymentNotes}"</ScriptText>
                    {this.props.from.paymentIssueVendAlways
                    ?
                      <RaisedButton label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />
                    :
                      <Fragment>
                        <span className="verification-span">Was Parker Able to Pay?</span>
                        <Link to="/confirmationScreen">
                          <RaisedButton label="Yes" style={{ margin: '0 10px' }} />
                        </Link>
                        {this.props.paymentIssues.length < 2 && (
                          <RaisedButton
                            label="No"
                            onClick={() => {
                              this.sendPaymentIssueType('cc');
                            }}
                          />
                        )}

                        {this.props.paymentIssues.length >= 2 &&
                          !this.props.from.paymentIssueIgnoreManager &&
                          !this.props.from.paymentIssueCancelInstruction &&
                          <RaisedButton label="No" onClick={async () => {
                            const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('paymentIssue'));
                            if (shouldEnablePIL) {
                              this.props.history.push('/pil/PILEntryInput')
                            } else {
                              this.btnClick('showManagers')
                            }
                          }}
                          />
                        }

                        {this.props.paymentIssues.length >= 2 &&
                          this.props.from.paymentIssueIgnoreManager &&
                          !this.props.from.paymentIssueLogTicketNumber &&
                          !this.props.from.paymentIssueCancelInstruction && (
                            <RaisedButton label="No" onClick={async () => {
                              const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('paymentIssue'));
                              if (shouldEnablePIL) {
                                this.props.history.push('/pil/PILEntryInput')
                              } else {
                                this.props.history.push('/ticketDiscrepancy')
                              }
                            }}
                            />
                          )}

                        {this.props.paymentIssues.length >= 2 &&
                          this.props.from.paymentIssueIgnoreManager &&
                          this.props.from.paymentIssueLogTicketNumber &&
                          <RaisedButton label="No" onClick={() => { this.setState({ showTicketNumberForm: true }) }} />
                        }

                        {this.props.paymentIssues.length >= 2 &&
                          this.props.from.paymentIssueCancelInstruction && (
                            <RaisedButton
                              onClick={() => {
                                this.sendPaymentIssueType('trioCustom');
                              }}
                              label="No" />
                          )
                        }
                      </Fragment>
                    }
                  </Paper>
                )}
              {this.props.paymentIssues.length > 2 &&
                <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
                  <DirectionText>Instruct the customer to press cancel and take the ticket then press Lost
                    Ticket button followed by pressing a receipt button then cancel and that will restart the payment process.
                    Insert the ticket again followed by credit card.
                  </DirectionText>
                  <span className="verification-span">Is Further Assistance Required?</span>
                  <Link to="/confirmationScreen">
                    <RaisedButton label="No" style={{ margin: '0 10px' }} />
                  </Link>
                  <RaisedButton label="Yes" onClick={async () => {
                    const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('paymentIssue'));
                    if (shouldEnablePIL) {
                      this.props.history.push('/pil/PILEntryInput')
                    } else {
                      this.props.history.push('/ticketDiscrepancy')
                    }
                  }}
                  />
                </Paper>
              }
              {this.props.showManagers &&
                <Fragment>
                  <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
                    <ContactManagers
                      showButtons={
                        [
                          'managerAssisting',
                          `${this.props.from.vendingSeconds > 0 && 'ticketDiscrepancy'}`,
                          `${this.props.from.paymentIssueDirectToVend && 'vendGate'}`,
                          'showCustomButton',
                          'ticketDiscrepancy'
                        ]
                      }
                      btnClick={this.btnClick}
                      btnFrom="paymentIssue"
                      customButtonData={[
                        {
                          "label": "Manager Asked to Push Rate",
                          "action": "showPushRateForm"
                        },
                      ]}
                    />
                  </Paper>
                </Fragment>
              }
            </div>
          </div>
        </ErrorBoundary>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    paymentIssueType: promptSelectors.pullPaymentIssue(state),
    paymentIssues: promptSelectors.pullPaymentIssues(state),
    username: authSelectors.pullModular(state, 'username'),
    showManagers: promptSelectors.pullShowManagers(state),
    ticketNumber: promptSelectors.pullModular(state, 'ticketNumber')
  };
}

export default withRouter(connect(mapStateToProps)(PaymentIssue));
