import React from "react";
import { connect } from "react-redux";
import ScriptText from "../../../../components/ScriptText";
import { pullModular as promptPullModular } from '../../../../store/prompt/reducer';

const PrepaidScannedReservation = (props) => {
  return (
    <ScriptText>
      {props.from.reservationInstructionsNote
      ? 
      props.from.reservationInstructionsNote
      : 
      'Please scan your reservation barcode. If you are scanning from your phone, please make sure the brightness is set to high.'}
    </ScriptText>
  )
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from')
  }
}

export default (connect(mapStateToProps)(PrepaidScannedReservation));
