import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as promptActions from '../../../store/prompt/actions';
import * as authSelectors from '../../../store/auth/reducer';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import SelectField from 'material-ui/SelectField';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from 'material-ui/MenuItem';
import ScriptText from '../../../components/ScriptText';
import DirectionText from '../../../components/DirectionText';
import PromptBox from '../../../components/promptBox';
import ContactManagers from '../ContactManagers';

class PrepaidGuest extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    // Required form fields before submission. 
    this.requiredFields = [
      'parkerFirstName',
      'parkerLastName',
      'thirdPartyReservationNumber'
    ];
    this.state = { 
      renderComponent: {
        showPrepaidScan: true, showPrepaidDetailsForm: false, showManagersList: false, showPushRateQuestion: false
      },
      prepaidSources: null, prepaidData: []
    }
    this.thirdpartyVendorNames = ['WAY.COM', 'Jiffy Website'];
  }

  async componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (!this.props.from.prepaidRecheck && !this.props.from.prepaidBarcode && !this.props.from.prepaidSplit) {
      this.completeStep(1);
    } else {
      /* reset global step prop */
      this.props.dispatch(promptActions.updateIssueStep(0));
    }
    if (this.props.from.prepaidSelectNotRequired) {
      this.requiredFields.push('thirdPartyVendorName');
    }
    if (this.props.from.prepaidSources) {
      this.setState({
        prepaidSources: this.props.from.prepaidSources
      });
    } else {
      this.setState({
        prepaidSources: [ 'ParkWhiz', 'SpotHero' ]
      })
    }
    const { googleSheetInfo } = this.props.from;
    if (googleSheetInfo && googleSheetInfo.prepaidData) {
      this.props.dispatch(promptActions.modularSendField(true, 'showLoader'));
      const result = await this.props.dispatch(promptActions.getPrepaidDataFromSheets({ googleSheetInfo }));
      const { sheetData } = result.data;
      const data = await sheetData.map(row => {
        return {
          'reservationNumber': row[0],
          'parkerName': row[1]
        }
      });
      this.setState({ prepaidData: data });
      this.props.dispatch(promptActions.modularSendField(false, 'showLoader'));
    }
  }

  async submitPrepaidDetails() {
    const { thirdPartyVendorName,  thirdPartyReservationNumber, contactNumber, selectedParkerName } = this.props;
    if (!thirdPartyVendorName) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: 'Vendor Name is required'
      }));
    } else if (!thirdPartyReservationNumber) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: 'Reservation Number is required'
      }));
    } else if (!selectedParkerName) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: 'Parker Name is required'
      }));
    } else if (!contactNumber) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: 'Contact Number is required'
      }));
    }

    if (this.props.from.confirmPrepaidDetailsWithManager) {
      this.showComponent(['showManagersList']);
    } else {
      this.props.history.push('/vendGate');
    }
  }

  showComponent(toRender) {
    const allComponents = {...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }    
    const complete = this.requiredFields.every((value) => {
      return !!this.props[value];
    });
    if(complete) {
      this.props.dispatch(promptActions.modularSendField(true, 'formComplete'));
    }
  }

  sendThirdPartyVendor(event, index, value) {
    this.props.dispatch(promptActions.sendThirdPartyVendor(value, event.target.innerText));
  }

  //Function to change the state of selectField for conditional rendering next steps.  
  sendReservationIndex(event, index, value) {
    this.props.dispatch(promptActions.modularSendField(this.thirdpartyVendorNames[index], 'thirdPartyVendorName'));
    this.props.dispatch(promptActions.modularSendField(index, 'selectIndex'));
  }
  
  // Function to update the form fields. 
  updateField(event, value, target) {
    if (!value) {
      value = event.target.value;
    }
    this.props.dispatch(promptActions.modularSendField(value, target));
    if (!value) {
      this.props.dispatch(promptActions.modularSendField(false, 'formComplete'));
    }
  }

  splitCompleteStep1() {
    if (this.props.from.prepaidSkipBackup && this.props.from.laneType !== 'Entrance') {
      return this.completeStep(4);
    }
    return this.completeStep(1);
  }
  
  async mergeName() {
    const selectedParkerName = `${this.props.parkerFirstName} ${this.props.parkerLastName}`;
    await this.props.dispatch(promptActions.modularSendField(selectedParkerName, 'selectedParkerName'));
    this.submitPrepaidDetails();
  }
  
  completeStep(step, value) {
    if (value === 'managerRequestedVendGate') {
      this.props.dispatch(promptActions.modularSendField(true, 'managerRequestedVendGate'));
      this.props.history.push('/vendGate');
    }
    this.props.dispatch(promptActions.updateIssueStep(step));
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'showPushRateForm':
        if (routeTo == 'hasTicket') {
          this.props.dispatch(promptActions.modularSendField(true, 'pushRateQuestion'));
        }
        this.props.history.push('/tibaAPI/tibaManagerPushRate');
        break;
      case 'showPushRateQuestion':
        this.showComponent(['showPushRateQuestion']);
        break;
      case 'completeStep-4':
        this.completeStep(4);
        break;
      case 'managerRequestedVendGate':
        if (this.props.from.prepaidFormAfterManagerVend) {
          this.showComponent(['showPrepaidDetailsForm']);
        } else {
          this.completeStep(1, 'managerRequestedVendGate')
        }
        break;
      case 'showPrepaidDetailsForm':
        this.showComponent(['showPrepaidDetailsForm']);
        break;
      case 'ticketDiscrepancy':
        this.props.history.push('/ticketDiscrepancy');
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  renderRedirect() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        <DirectionText>Please redirect the parker to the {this.props.from.prepaidRedirect} lane in order to handle their prepaid parking pass.</DirectionText>
        <Link to="/confirmationScreen">
          <RaisedButton label="Exit" />
        </Link>
      </PromptBox>
    )
  }

  renderDisabled() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        <DirectionText>
          Prepaid transactions are not enabled for this facility. Please do not allow this parker to proceed with their prepaid pass.
        </DirectionText>
        <div style={{ marginTop: '15px'}}>
          <Link to="/confirmationScreen">
            <RaisedButton label="Exit" />
          </Link>
        </div>
      </PromptBox>
    )
  }
  
  disableVendOnInput() {
    if (this.props.thirdPartyVendorName && this.props.thirdPartyReservationNumber && this.props.thirdPartyReservationNumber > 3) {
      if (this.props.from.prepaidGuestParkerName && !this.props.selectedParkerName) {
        return  true;
      }
      if (this.props.from.prepaidGuestFeeDue && !this.props.ticketDiscrepancyFeeDue) {
        return  true;
      }
      return false;
    } else {
      return true;
    }
  }

  renderStandard() {
    return (
      <div className="home-div">
        <div className="home-div-content">
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
              
            {this.state.renderComponent.showPrepaidScan &&
              <Fragment>
                <Paper zDepth={1} className="paper-padding">
                  { this.props.from.prepaidRecheck && typeof this.props.from.prepaidRecheck == 'string' ?
                    <ScriptText>{this.props.from.prepaidRecheck}</ScriptText>
                  :
                    <ScriptText>"Please ensure you are scanning your original ticket, and then the prepaid ticket."</ScriptText>
                  }
                </Paper>
                <div style={{ marginTop: 15 }}>
                  <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                  {this.props.from.prepaidContactManagersRequired 
                  ? 
                    <RaisedButton label="Contact Managers" onClick={() => this.showComponent(['showManagersList'])} />
                  :
                    <Fragment>
                      {(this.props.from.laneType == 'Exit' || this.props.from.prepaidDetailsOnEntrance) && 
                        <RaisedButton label="Next Steps" onClick={() => this.showComponent(['showPrepaidDetailsForm'])} />
                      }
                    </Fragment>
                  }
                </div>
              </Fragment>
            }

            {this.state.renderComponent.showManagersList &&
              <Paper zDepth={1} className="paper-padding">           
                <ContactManagers showButtons={['managerAssisting','showCustomButton']} 
                btnClick={this.btnClick}
                btnFrom="prepaidGuest"
                customButtonData={[
                  {
                    "label": "No Manager Response",
                    "action": `${this.props.from.prepaidFormAfterManager ? 'showPrepaidDetailsForm' : 'ticketDiscrepancy'}`
                  },
                  {
                    "label": "Manager Requested Gate Vend",
                    "action": `${this.props.from.prepaidFormAfterManager ? 'showPrepaidDetailsForm' : 'managerRequestedVendGate'}`
                  },
                  {
                    "label": "Manager Asked to Push Rate",
                    "action": "showPushRateForm"
                  }
                ]}
              />
              </Paper>
            }

            {this.state.renderComponent.showPrepaidDetailsForm &&
              <Fragment>
                <Paper zDepth={1} className="paper-padding">
                  <ScriptText>"Which prepaid vendor did you purchase your ticket from?"</ScriptText>
                  <SelectField
                    floatingLabelText="Third Party Vendor"
                    value={this.props.thirdPartyVendorId}
                    onChange={this.sendThirdPartyVendor}
                    style={{ width: '98%', textAlign: 'left' }}>
                    { this.state.prepaidSources && this.state.prepaidSources.map((source, index) => {
                      return <MenuItem value={index} primaryText={source} />
                    })}
                  </SelectField>
                  <ScriptText>"May you please read me the reservation ID from your parking pass?"</ScriptText>
                  <Autocomplete
                    freeSolo
                    disableClearable
                    options={(this.state.prepaidData).map((option) => option.reservationNumber)}
                    onInputChange={(e, v) => this.updateField(e, v, 'thirdPartyReservationNumber')}
                    renderInput={(params) => (
                      <TextField 
                        {...params}
                        label={`Reservation Number ${this.props.from.prepaidReservationOrTicketNum ? '/ Ticket Number' : ''}`}
                        fullWidth={true}
                        variant="outlined"
                        margin="dense"
                        InputProps={{ ...params.InputProps, type: 'search', className: 'data-hj-allow' }}
                      />
                    )}
                  />
                  <Autocomplete
                    freeSolo
                    disableClearable
                    options={(this.state.prepaidData).map((option) => option.parkerName)}
                    onInputChange={(e, v) => this.updateField(e, v, 'selectedParkerName')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Parker Name"
                        fullWidth={true}
                        variant="outlined"
                        margin="dense"
                        InputProps={{ ...params.InputProps, type: 'search', className: 'data-hj-allow' }}
                      />
                    )}
                  />
                  <TextField 
                    inputProps={{ className: 'data-hj-allow' }}
                    label="Contact Number"
                    fullWidth={true}
                    variant="outlined"
                    margin="dense"
                    onChange={(e, v) => {this.updateField(e, v, 'contactNumber')}}  
                  />
                </Paper>
                <div style={{ marginTop: 15 }}>
                  <Fragment>
                    <RaisedButton label="Submit Prepaid Details" onClick={() => this.submitPrepaidDetails() }/>
                    <RaisedButton label="Contact Managers" onClick={() => this.props.history.push('/contactManagers')} />
                    <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} /> 
                  </Fragment>
                </div>
              </Fragment>
            }
          </div>
        </div>
      </div>
    )
  }

  renderBarcode() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        {this.state.renderComponent.showPrepaidScan &&
          <Fragment>
            <ScriptText>
              {this.props.from.prepaidBarcodeNote 
              ?
                <Fragment>
                  {this.props.from.prepaidBarcodeNote}
                </Fragment>
              :
                <Fragment>
                  "Please scan your prepaid reservation."
                </Fragment>
              }
            </ScriptText>
            <div style={{ marginTop: 15 }}>
              <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')}/>
              {!(this.props.from.locationId == 384) && !(this.props.from.prepaidFormAfterManager) && <RaisedButton label="Validation Form" onClick={() => this.showComponent(['showPrepaidDetailsForm'])} />}
              <RaisedButton label="Contact Manager" onClick={() => this.showComponent(['showManagersList'])} />
            </div>
          </Fragment>
        }

        {this.state.renderComponent.showManagersList &&
          <Fragment>
            <ContactManagers showButtons={['showCustomButton']} 
              btnClick={this.btnClick}
              btnFrom="prepaidGuest"
              customButtonData={[
                {
                  "label": "No Manager Response",
                  "action": `${this.props.from.prepaidFormAfterManager ? 'showPrepaidDetailsForm' : 'completeStep-4'}`
                },
                {
                  "label": "Manager Requested Gate Vend",
                  "action": 'managerRequestedVendGate'
                },
                {
                  "label": "Manager Asked to Push Rate",
                  "action": `${this.props.from.prepaidPushRateQuestion ? 'showPushRateQuestion' : 'showPushRateForm'}`
                }
              ]}
            />
          </Fragment>
        }

        {this.state.renderComponent.showPushRateQuestion &&
          <Fragment>
            <ScriptText> Do you have a ticket? </ScriptText>
            <div style={{ marginTop: 15 }}>
              <RaisedButton label="Yes" onClick={() => this.btnClick('showPushRateForm', 'hasTicket')} />
              <RaisedButton label="No" onClick={() => this.btnClick('showPushRateForm')} />
            </div>
          </Fragment>
        }
          
        {this.state.renderComponent.showPrepaidDetailsForm &&
          <Fragment>
            <DirectionText>
              Ask the parker where the reservation is from.
            </DirectionText>
            {!this.props.from.prepaidSelectNotRequired 
            ?
              <SelectField
                floatingLabelText="Reservation Source"
                hintText="Reservation Source (*)"
                value={this.props.selectIndex}
                onChange={this.sendReservationIndex}
                style={{ width: '100%', textAlign: 'left' }}>
                <MenuItem value={0} primaryText="WAY.com" />
                <MenuItem value={1} primaryText="Jiffy Website" />
                <MenuItem value={2} primaryText="Other" /> 
              </SelectField>
            :
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                label="Reservation Source"
                onChange={(e, v) => {
                this.updateField(e, v, 'thirdPartyVendorName');
                }}
                fullWidth={true}
                variant="outlined"
                margin="dense"
              />
            }

            {(this.props.selectIndex === 0 || this.props.selectIndex === 1 || this.props.from.prepaidSelectNotRequired) && 
              <Fragment>
                <TextField
                  inputProps={{ className: 'data-hj-allow' }}
                  label="First Name"
                  fullWidth={true}
                  variant="outlined"
                  margin="dense"
                  onChange={(e, v) => {
                    this.updateField(e, v, 'parkerFirstName');
                  }}
                />
                <TextField
                  inputProps={{ className: 'data-hj-allow' }}
                  label="Last Name"
                  fullWidth={true}
                  variant="outlined"
                  margin="dense"
                  onChange={(e, v) => {
                    this.updateField(e, v, 'parkerLastName');
                  }}
                />
                <TextField
                  inputProps={{ className: 'data-hj-allow' }}
                  label={`Reservation Number ${this.props.from.prepaidReservationOrTicketNum ? '/ Ticket Number' : ''}`}
                  fullWidth={true}
                  variant="outlined"
                  margin="dense"
                  onChange={(e, v) => {
                    this.updateField(e, v, 'thirdPartyReservationNumber');
                  }}
                />
                <TextField
                  inputProps={{ className: 'data-hj-allow' }}
                  label="Contact Number"
                  fullWidth={true}
                  variant="outlined"
                  margin="dense"
                  onChange={(e, v) => {this.updateField(e, v, 'contactNumber')}}  
                />
                <div style={{ marginTop: 15 }}>
                  {!this.props.formComplete && <DirectionText> All form fields are required for form submission. </DirectionText>}
                  <RaisedButton label="Exit" onClick={() => this.props.history.push('/confirmationScreen')} />
                  <RaisedButton label="Submit" disabled={!this.props.formComplete} onClick={this.mergeName} />        
                </div>
              </Fragment>
            }

            {/* Rendered if Other selectOption is selected */}
            { this.props.selectIndex === 2 &&
              <Fragment> 
                <DirectionText>
                  Tell the parker to pay as a Transient.  
                </DirectionText>
                <ScriptText>
                  "Please pay the full amount that the machine is requesting."
                </ScriptText>
                <Link to="/confirmationScreen">
                  <RaisedButton 
                    label="Exit"
                    style={{ width: '50%'}}              
                  />
                </Link>                  
                <RaisedButton 
                  label="Contact Manager"
                  style={{ width: '50%'}}
                  onClick={() => {this.completeStep(2)}}
                />                        
              </Fragment>
            }
          </Fragment> 
        }
        {/* Form to get the parkers Reservation info from WAY.com and save it in the database. */}
        { this.props.step === 2 &&
          <Fragment>
            <ContactManagers showButtons={[]}  />
            <RaisedButton label="No" onClick={() => {this.completeStep(3)}} />
          </Fragment>
        }
        { this.props.step === 3 &&
          <div>
            <ScriptText>
              Please pay the amount requested and save your receipt. You can contact the cashier during regular business hours to discuss reimbursement.
            </ScriptText>
            <Link to="/confirmationScreen">
              <RaisedButton label="Next" />
            </Link>
          </div>
        }
        { this.props.step === 4 && 
          <Fragment>
            <ScriptText>
              Please Pay the amount requested, save receipt and contact cashier during regular business hours or email info@jiffyseattle.com.
            </ScriptText>
            <div style={{ marginTop: 15 }}>              
              <Link to="/confirmationScreen">
                <RaisedButton label="Confirmation Screen" />
              </Link>
            </div>
          </Fragment>
        }
      </PromptBox>
    )
  }

  simplePrepaid() {
    return (
      <div className="home-div">
        <div className="home-div-content">
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            <Paper zDepth={1} className="paper-padding">
              {this.props.from.laneType == 'Entrance' 
              ?
                ( 
                  <Fragment>
                    {this.props.from.prepaidBarcodeNote 
                    ? 
                      <ScriptText> {this.props.from.prepaidBarcodeNote} </ScriptText>
                    :
                      <DirectionText>Ask the parker to pull a ticket.</DirectionText>
                    }
                    <Link to="/confirmationScreen">
                      <RaisedButton label="Confirmation Screen" />
                    </Link>
                  </Fragment> 
                ) 
              :  
                (
                  <Fragment>
                    <ScriptText>Please attempt to scan your reservation.</ScriptText>
                    <DirectionText>If reservation does not scan, contact on-site team.</DirectionText>
                    <ContactManagers 
                      showButtons={
                        [ 'vendGate', 'managerAssisting', `${!this.props.from.prepaidSkipTD && 'ticketDiscrepancy'}`]
                      }  
                    />
                  </Fragment>
                )
              }
            </Paper>
          </div>
        </div>
      </div>
    );
  }

  renderSplit() {
    if (this.props.step == 0) {
        return (
          <PromptBox locationName={this.props.from.locationName}>
            <ScriptText>{this.props.from.prepaidBarcodeNote ? this.props.from.prepaidBarcodeNote : 'Please scan your reservation.'}</ScriptText>
            <div style={{ marginTop: '15px'}}>
              <RaisedButton onClick={this.splitCompleteStep1} label="Reservation Didn't Scan" />
              <Link to="/confirmationScreen">
                <RaisedButton label="Exit" />
              </Link>
            </div>
          </PromptBox>
        );
    } else {
      if (this.props.from.laneType == 'Entrance' && this.props.step < 2) {
        return (
          <PromptBox locationName={this.props.from.locationName}>
            <ScriptText>Please pull a ticket and enter the facility.</ScriptText>
            <div style={{ marginTop: 15 }}>
              { this.props.from.prepaidSkipContactManager ?
                <RaisedButton label='Complete Call' onClick={() => { this.props.history.push('/confirmationScreen') }} />
                :
                <RaisedButton label="Contact Managers" onClick={() => { this.completeStep(2) }} />
              }
              <RaisedButton label="Exit" onClick={() => { this.props.history.push('/confirmationScreen' )} } />
            </div>
          </PromptBox>
        )
      } else if (this.props.step == 2) {
        return (
          <PromptBox locationName={this.props.from.locationName}>
            <ContactManagers showButtons={['vendGate']}  />
            <RaisedButton label="No Manager Response" disabled={!this.props.managerVendConfirmation10s} onClick={() => { this.completeStep(4) }} />
            <RaisedButton label="Manager Assisting" disabled={!this.props.managerVendConfirmation10s} onClick={() => { this.completeStep(5) }} />
          </PromptBox>
        )
      } else {
        return (
          <PromptBox locationName={this.props.from.locationName}>
            { this.props.step == 1 &&
              <Fragment>
                {this.props.from.prepaidBarcodeNote 
                ?
                  <ScriptText> {this.props.from.prepaidBarcodeNote} </ScriptText>
                :
                  <ScriptText>Can you back up? If so please do and I will call an attendant to assist you immediately.</ScriptText>
                }
                <div style={{ marginTop: '15px'}}>
                  {this.props.from.prepaidContactManagersRequired
                  ?
                    <RaisedButton label="Contact Managers" onClick={() => this.completeStep(2) } />
                  :
                    <Fragment>
                      <RaisedButton onClick={ () => { this.completeStep(2) }} label="Parker Can Back Up" />
                      <RaisedButton onClick={ () => { this.completeStep(4) }} label="Parker Can't Back Up" />
                    </Fragment>  
                  }
                </div>
              </Fragment>
            }
            {/* This is where we will put the place to log their reservation number and source */}
            { this.props.step == 4 &&
              <Fragment>
                <ScriptText>"Which prepaid vendor did you purchase your ticket from?"</ScriptText>
                  <SelectField
                    floatingLabelText="Third Party Vendor"
                    value={this.props.thirdPartyVendorId}
                    onChange={this.sendThirdPartyVendor}
                    style={{ width: '98%', textAlign: 'left' }}>
                    { this.state.prepaidSources && this.state.prepaidSources.map((source, index) => {
                      return <MenuItem value={index} primaryText={source} />
                    })}
                  </SelectField>
                <ScriptText>"May you please read me the reservation ID from your parking pass?"</ScriptText>
                <TextField
                  inputProps={{ className: 'data-hj-allow' }}
                  label={`Reservation Number ${this.props.from.prepaidReservationOrTicketNum ? '/ Ticket Number' : ''}`}
                  fullWidth={true}
                  variant="outlined"
                  margin="dense"
                  onChange={(e, v) => {this.updateField(e, v, 'thirdPartyReservationNumber')}} 
                  style={{ width: '98%', textAlign: 'left' }} 
                />
                {this.props.from.prepaidGuestParkerName &&
                  <TextField
                    inputProps={{ className: 'data-hj-allow' }}
                    label="Parker Name"
                    fullWidth={true}
                    variant="outlined"
                    margin="dense"
                    onChange={(e, v) => {this.updateField(e, v, 'selectedParkerName')}} 
                    style={{ width: '98%', textAlign: 'left' }} 
                  />
                }
                {this.props.from.prepaidGuestFeeDue &&
                  <TextField
                    inputProps={{ className: 'data-hj-allow' }}
                    label="Fee Due"
                    fullWidth={true}
                    variant="outlined"
                    margin="dense"
                    onChange={(e, v) => {this.updateField(e, v, 'ticketDiscrepancyFeeDue')}} 
                    style={{ width: '98%', textAlign: 'left' }} 
                  />
                }
                <div style={{ marginTop: '15px'}}>
                  <RaisedButton disabled={this.disableVendOnInput()} onClick={ () => { this.props.history.push('/vendGate') }} label="Vend Gate" />
                  <RaisedButton onClick={ () => { this.props.history.push('/confirmationScreen') }} label="Exit" />
                </div>
              </Fragment>
            }
            { this.props.step == 5 &&
              <Fragment>
                <ScriptText>The manager is on their way to assist you and will arrive shortly. Please hold.</ScriptText>
                <img src="https://storage.googleapis.com/cloudpark-server-files-public/help_is_on_the_way_dear.gif" style={{ width: '414px'}} />
                <div style={{ marginTop: '15px'}}>
                  <RaisedButton onClick={ () => { this.props.history.push('/confirmationScreen') }} label="Exit" />
                </div>
              </Fragment>
            }
          </PromptBox>
        )
      }
    }
  }

  render() {
    if (this.props.from.prepaidRedirect) {
      return this.renderRedirect();
    } else if (!this.props.from.prepaidLocation) {
      return this.renderDisabled();
    } else if (this.props.from.prepaidBarcode) {
      return this.renderBarcode();
    } else if (this.props.from.simplePrepaid) {
      return this.simplePrepaid();
    } else if(this.props.from.prepaidSplit) {
      return this.renderSplit();
    } else {
      return this.renderStandard();
    }
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    selectedParkerName: promptSelectors.pullModular(state, 'selectedParkerName'),
    ticketDiscrepancyFeeDue: promptSelectors.pullModular(state, 'ticketDiscrepancyFeeDue'),
    contactNumber: promptSelectors.pullModular(state, 'contactNumber'),
    thirdPartyVendorName: promptSelectors.pullThirdPartyVendorName(state),
    thirdPartyVendorId: promptSelectors.pullThirdPartyVendorId(state),
    thirdPartyReservationNumber: promptSelectors.pullThirdPartyReservationNumber(state),
    step: promptSelectors.pullStep(state),
    selectIndex: promptSelectors.pullModular(state, 'selectIndex'),
    parkerFirstName: promptSelectors.pullModular(state, 'parkerFirstName'),
    parkerLastName: promptSelectors.pullModular(state, 'parkerLastName'),
    formComplete: promptSelectors.pullModular(state, 'formComplete'),
    managerVendConfirmation10s: promptSelectors.pullModular(state, 'managerVendConfirmation10s'),
  };
}

export default withRouter(connect(mapStateToProps)(PrepaidGuest));
