import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as authSelectors from '../../../store/auth/reducer';
import * as promptActions from '../../../store/prompt/actions';
import Paper from 'material-ui/Paper';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import DirectionText from '../../../components/DirectionText';
import ScriptText from "../../../components/ScriptText";
import { RaisedButton } from 'material-ui';
import ContactManagers from '../ContactManagers';

const paperStyle = {
  display: 'inline-block',
  margin: '16px 32px 16px 32px'
};

class HotelIssue extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = { hotelIssueSetpOne: true, showManagersList: false };
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  sendSubtype(type) {
    this.props.dispatch(promptActions.updateSubtype(type));
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'showPushRateForm':
        this.props.dispatch(promptActions.modularSendField(true, 'pushRateQuestion'));
        this.props.history.push('/tibaAPI/tibaManagerPushRate');
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  render() {
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
            {this.props.from.hotelIssueTicket
            ?
              <Fragment>
                {this.props.from.laneType == 'Entrance'
                ?
                  <Fragment>
                    <ScriptText>
                      {this.props.from.hotelIssueText}
                    </ScriptText>
                    <div style={{ marginTop: 15 }}>
                      <RaisedButton 
                        label="Complete Call" 
                        onClick={() => {
                          this.sendSubtype('ticketOnEntrance')
                          this.props.history.push('/confirmationScreen')
                        }}
                      />
                    </div>
                  </Fragment>
                :
                  <Fragment>
                    {this.state.hotelIssueSetpOne &&
                      <Fragment>
                        <DirectionText>
                          {this.props.from.hotelIssueText}
                        </DirectionText>
                        <div style={{ marginTop: 15 }}>
                          <RaisedButton 
                            label="No Ticket" 
                            onClick={() => {
                              this.sendSubtype('noTicket')
                              this.props.history.push('/transient/lostTicket')
                            }}
                          />
                          <RaisedButton 
                            label="Has Ticket" 
                            onClick={() => {
                              this.sendSubtype('hasTicket')
                              this.props.history.push('/transient/ticketAcceptanceIssue')
                            }}
                          />
                          <RaisedButton 
                            label="Contact Managers" 
                            onClick={() => {
                              this.sendSubtype('hasTicket')
                              this.setState({ hotelIssueSetpOne: false, showManagersList: true })
                            }}
                          />
                          <RaisedButton 
                            label="Complete Call" 
                            onClick={() => {
                              this.sendSubtype('ticketOnExit')
                              this.props.history.push('/confirmationScreen')
                            }}
                          />
                        </div>
                      </Fragment>
                    }
                    {this.state.showManagersList &&
                      <Fragment>
                        <ContactManagers 
                          showButtons={['managerAssisting', 'vendGate', 'ticketDiscrepancy', 'showCustomButton']} 
                          btnClick={this.btnClick}
                          btnFrom="hotelIssue"
                          customButtonData={[
                            {
                              "label": "Manager Asked to Push Rate",
                              "action": 'showPushRateForm'
                            },
                          ]}
                        />
                      </Fragment>
                    }
                  </Fragment>
                }

              </Fragment>
            :
              <Fragment>
                <DirectionText>Which of the following Hotel issues are you experiencing?</DirectionText>
                <Paper style={paperStyle}>
                  <Menu>
                    <Link
                      to="/hotel/verification"
                      style={{ textDecoration: 'none' }}
                      onClick={() => {
                        this.sendSubtype('cardAcceptanceIssue');
                      }}>
                      <MenuItem primaryText="Card Acceptance Issue" />
                    </Link>
                    <Link
                      to="/hotel/verification"
                      style={{ textDecoration: 'none' }}
                      onClick={() => {
                        this.sendSubtype('noCard');
                      }}>
                      <MenuItem primaryText="No Card" />
                    </Link>
                  </Menu>
                </Paper>
              </Fragment>
            }
          </Paper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username')
  };
}

export default withRouter(connect(mapStateToProps)(HotelIssue));
