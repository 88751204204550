import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import * as config from '../../lib/config';
import * as promptSelectors from '../../store/prompt/reducer';
import * as promptActions from '../../store/prompt/actions';
import * as authSelectors from '../../store/auth/reducer';
import Paper from 'material-ui/Paper';
import { Menu, MenuItem } from 'material-ui';
import DirectionText from '../../components/DirectionText';
import moment from 'moment-timezone';
import SocketContext from '../../services/socketContext';
import { sendMonthlyRequest } from '../../store/queue/actions';

class OutsideCall extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentWillUnmount() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    // This next section handles the animation: which is done by checking for the existence of a prop and if so, sending another prop to state. In the render
    // function, we conditionally set a variable that is used directly in the elements' className if the second mentioned prop exists.

    // Not sure if locationName is the best check, or even if checking for the existence of "from" during componentDidUpdate is the best way
    // to detect that we have pulled the "from" details from the server. Does the trick for now, look into optimizations.
    if (this.props.from.locationName) {
      // answeredTime === null check to ensure that only the first click sets answeredTime, to prevent second click to hide long call times.
      if (!this.props.answeredTime) {
        this.props.dispatch(promptActions.modularSendField(moment().valueOf(), 'answeredTime'));
        this.props.dispatch(promptActions.sendToWorkerMap(this.props.username));
        // Basically thrown in here because we need 180 Allyn to be able to pull parkers here.
        if (this.props.socket && this.props.from.antiPassbackIntegrated === true && this.props.from.parcs === 'amano-pi') {
          sendMonthlyRequest(this.props.from, this.props.username, this.props.socket, this.props.dispatch);
        }
      }
      this.props.history.push('/preFirst');
    }
  }

  generateOutsideLocations(value) {
    return _.map(value, (a) => {
      return (
        <MenuItem primaryText={a.locationName} key={a.locationName} onClick={() => {this.sendEndpointsToState(a.endpoints, a.locationName)} } />
      );
    });
  }

  generateOutsideEndpoints(value) {
    return (<MenuItem primaryText={value} key={value} onClick={() => { this.redirectToOutsidePrompt(value) }}/>);
  }

  sendEndpointsToState(endpoints, locationName) {
    this.props.dispatch(promptActions.modularSendField(locationName, 'outsideLocationSelected'));
    this.props.dispatch(promptActions.modularSendField(endpoints, 'outsideEndpoints'));
  }

  redirectToOutsidePrompt(value) {
    let ep = `${this.props.outsideLocationSelected} ${value}`;
    this.props.dispatch(promptActions.getLocInfoByName(ep));
  }

  render() {
    let primaryMenuClass, secondaryMenuClass;
    if (this.props.outsideLocationSelected) {
      primaryMenuClass = `outside-call-menu outside-call-menu-left`;
      secondaryMenuClass = `outside-call-menu outside-call-menu-fade-in`;
    } else {
      primaryMenuClass = 'outside-call-menu';
      secondaryMenuClass = 'outside-call-menu width-hidden';
    }
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>Off-Platform Call</h2>
          </Paper>
          <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
            <DirectionText>
              Please choose a location from the list below.
            </DirectionText>
            <div className="outside-call-container">
              <Menu className={primaryMenuClass}>
                {_.map(this.props.outsideLocations, this.generateOutsideLocations)}
              </Menu>
              { this.props.outsideEndpoints &&
                <Menu className={secondaryMenuClass}>
                  {_.map(this.props.outsideEndpoints, this.generateOutsideEndpoints)}
                </Menu>
              }
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    outsideLocations: promptSelectors.pullModular(state, 'outsideLocations'),
    outsideEndpoints: promptSelectors.pullModular(state, 'outsideEndpoints'),
    outsideLocationSelected: promptSelectors.pullModular(state, 'outsideLocationSelected'),
    outsideEndpointSelected: promptSelectors.pullModular(state, 'outsideEndpointSelected')
  };
}

const outsideCallSocket = (props) => (
  <SocketContext.Consumer>
    {socket => <OutsideCall {...props} socket={socket} />}
  </SocketContext.Consumer>
)

export default withRouter(connect(mapStateToProps)(outsideCallSocket));
