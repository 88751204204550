import Promise from 'bluebird';
import { retrieveLogger } from '../lib/logger';
let logger = retrieveLogger();
const axiosInstance = require('../lib/axiosInstance');

class AuthService {

  async sendLogin(username, password) {
    try {
      const result = await axiosInstance({
        method: 'post',
        url: `/login`,
        data: { username, password }
      });
        
      return result.data;

    } catch (error) {
      logger.error(`Request to Server Failed in - sendLogin `);
      logger.error(error);
    }
  }

  async sendLogout(username, workerSid, activitySid) {
    try {
      const result = await axiosInstance({
        method: 'post',
        url: `/logout`,
        data: { username, workerSid, activitySid }
      });
      
      return result.data;

    } catch (error) {
      logger.error(`Request to Server Failed in - sendLogin `);
      logger.error(error);
    }
  }
  
  async getAllUsers() {
    try {
      const result = await axiosInstance({
        method: 'post',
        url: `/getAllUsers`
      });
      
      return result;

    } catch (error) { 
      logger.error(`Request to Server Failed in - getAllUsers `);
      logger.error(error);
    }
  }

  async registerUser(userName, userData) {
    try { 
      const result = await axiosInstance({
        method: 'post',
        url: `/registerUser`,
        data: { userName, userData }
      });
      
      return result;

    } catch (error) { 
      logger.error(`Request to Server Failed in - registerUser `);
      logger.error(error);
    }  
  }
  
  async resetPassword(data) {
    try { 
      const result = await axiosInstance({
        method: 'post',
        url: `/resetPassword`,
        data
      });

      return result;

    } catch (error) { 
      logger.error(`Request to Server Failed in - sendPasswordReset `);
      logger.error(error);
    }
  }

  async updateWorker(username, activitySid, workerSid) {

    try { 
      const result = await axiosInstance({
        method: 'post',
        url: `/updateWorker`,
        data: { username, activitySid, workerSid }
      });

      return result;

    } catch (error) { 
      logger.error(`Request to Server Failed in - updateWorker `);
      logger.error(error);
    }
  }

  async saveReportedIssue(currentAppState) { 
    try { 
      const result = await axiosInstance({
        method: 'post',
        url: `/saveReportedIssue`,
        data: { 'currentAppState': currentAppState }
      });

      return result;
    
    } catch (error) { 
      logger.error(`Request to Server Failed in - saveReportedIssue `);
      logger.error(error);
    }

  }
}
  
export default new AuthService();
  