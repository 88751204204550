import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as promptActions from '../../../store/prompt/actions';
import RaisedButton from 'material-ui/RaisedButton';
import * as authSelectors from '../../../store/auth/reducer';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import ScriptText from '../../../components/ScriptText';
import InputMask from 'react-input-mask';
import moment from 'moment-timezone';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';
import Snackbar from 'material-ui/Snackbar';
import ContactManagers from '../ContactManagers';
import ErrorBoundary from '../../ErrorBoundary';

const dateStyles = {
  display: 'inline-block',
  width: '50%'
};

class ReceiptRequest extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.requiredFields = null;
  }

  componentDidMount() {
    this.requiredFields = ['email', 'contactNumber', 'lastFour'];
    // Set the formComplete to flase in case the analyst is coming from Validation issue to Reciept
    this.props.dispatch(promptActions.modularSendField(false, 'formComplete'));
    // Location 4640 Lankershim Blvd
    if (this.props.from.locationId !== 605) {
      // Take required fields out of array.
      let additionalReqFields = ['receiptRequestEntranceDate', 'receiptRequestEntranceTime', 'receiptRequestExitDate', 'receiptRequestExitTime'];
      this.requiredFields = this.requiredFields.concat(additionalReqFields);
    }
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (this.props.from.receiptRequestManager === true) {
      this.props.dispatch(promptActions.showManagers(true));
    }
  }

  componentWillUnmount() {
    if (this.errorTimeout) {
      clearTimeout(this.errorTimeout);
    }
    if (this.requiredFields) {
      this.requiredFields = null;
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    const complete = this.requiredFields.every((value) => {
      return !!this.props[value];
    });
    if (complete) {
      this.props.dispatch(promptActions.modularSendField(true, 'formComplete'));
    }
  }

  updateLastFour(event) {
    this.props.dispatch(promptActions.updateLastFour(event.target.value));
  }

  async submitReceiptRequest() {
    try {
      let record = {
        Email: this.props.email,
        ContactNumber: this.props.contactNumber,
        LastFour: this.props.lastFour,
        FirstName: this.props.receiptRequestFirstName,
        LastName: this.props.receiptRequestLastName,
        Username: this.props.username,
        EntranceTime: this.props.receiptRequestEntranceDate + this.props.receiptRequestEntranceTime,
        ExitTime: this.props.receiptRequestExitDate + this.props.receiptRequestExitTime,
        FeeDue: this.props.receiptRequestFeeDue,
        timezone: this.props.from.timezone
      };
      await this.props.dispatch(promptActions.updateReceiptRequestSubmitted(record));
      this.props.history.push('/confirmationScreen');
    } catch (e) {
      this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: 'Issue when attempting to submit the receipt request. Please check that all required fields are filled.'
      }));
    }
  }

  updateField(event, value, target) {
    this.props.dispatch(promptActions.modularSendField(value, target));
    if (!value) {
      this.props.dispatch(promptActions.modularSendField(false, 'formComplete'));
    }
  }

  updateEntryDate(event, date) {
    let formattedDate = moment(date).format('YYYY-MM-DD');
    this.props.dispatch(promptActions.sendDateTime(formattedDate, 'receiptRequestEntranceDate'));
  }

  updateExitDate(event, date) {
    let formattedDate = moment(date).format('YYYY-MM-DD');
    this.props.dispatch(promptActions.sendDateTime(formattedDate, 'receiptRequestExitDate'));
  }

  updateEntryTime(event, time) {
    let formattedTime = moment(time).format('THH:mm:ssZ');
    this.props.dispatch(promptActions.sendDateTime(formattedTime, 'receiptRequestEntranceTime'));
  }

  updateExitTime(event, time) {
    let formattedTime = moment(time).format('THH:mm:ssZ');
    this.props.dispatch(promptActions.sendDateTime(formattedTime, 'receiptRequestExitTime'));
  }

  render() {
    return (
      <ErrorBoundary>
        <div className="home-div">
          <div className="home-div-content">
            <Paper zDepth={1} className="home-div-header-short">
              <h2>{this.props.from.locationName}</h2>
            </Paper>
            <div className="home-div-body">
              {!this.props.from.receiptRequestManager && !this.props.from.showManagers && !this.props.showManagers &&
                <Fragment>
                  <ScriptText>
                    {`${this.props.from.receiptRequestNote ? this.props.from.receiptRequestNote : ''} If you allow me to take down some of your information, I can forward this message to the location manager who will be able to send you a receipt via email.`}
                  </ScriptText>
                  {!this.props.from.ignoreReceiptRequestTime &&
                    <div>
                      <DatePicker onChange={this.updateEntryDate} hintText="Entry Date (*)" style={dateStyles} inputProps={{ className: 'data-hj-allow' }} />
                      <TimePicker onChange={this.updateEntryTime} hintText="Entry Time (*)" style={dateStyles} inputProps={{ className: 'data-hj-allow' }} />
                      <DatePicker onChange={this.updateExitDate} hintText="Exit Date (*)" style={dateStyles} inputProps={{ className: 'data-hj-allow' }} />
                      <TimePicker onChange={this.updateExitTime} hintText="Exit Time (*)" style={dateStyles} inputProps={{ className: 'data-hj-allow' }} />
                    </div>
                  }
                  <TextField
                    floatingLabelText="First Name"
                    hintText="First Name"
                    inputProps={{ className: 'data-hj-allow' }}
                    onChange={(e, v) => {
                      this.updateField(e, v, 'receiptRequestFirstName');
                    }}
                    style={{ width: '100%' }}
                  />
                  <TextField
                    floatingLabelText="Last Name"
                    hintText="Last Name"
                    inputProps={{ className: 'data-hj-allow' }}
                    onChange={(e, v) => {
                      this.updateField(e, v, 'receiptRequestLastName');
                    }}
                    style={{ width: '100%' }}
                  />
                  <TextField
                    inputProps={{ className: 'data-hj-allow' }}
                    floatingLabelText="Fee Due"
                    style={{ width: '100%' }}
                    hintText="Fee Due"
                    ref="feeDue"
                    name="feeDue"
                    type="text"
                    onChange={(e, v) => {
                      this.updateField(e, v, 'receiptRequestFeeDue');
                    }} />
                  <TextField
                    inputProps={{ className: 'data-hj-allow' }}
                    floatingLabelText="Email Address (*)"
                    hintText="Email Address (*)"
                    style={{ width: '100%' }}
                    onChange={(e, v) => {
                      this.updateField(e, v, 'email');
                    }}
                    key="email"
                  />
                  <TextField
                    inputProps={{ className: 'data-hj-allow' }}
                    floatingLabelText="Contact Number (*)"
                    style={{ width: '100%' }}
                    hintText="Contact Number"
                    ref="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    onChange={(e, v) => {
                      this.updateField(e, v, 'contactNumber');
                    }}>
                    <InputMask mask="(999) 999 9999" maskChar=" " />
                  </TextField>
                  <TextField
                    inputProps={{ className: 'data-hj-allow' }}
                    floatingLabelText="Last 4 Digits on Card (*)"
                    hintText="Last 4 Digits on Card (*)"
                    onChange={(e, v) => {
                      this.updateField(e, v, 'lastFour');
                    }}
                    style={{ width: '100%' }}>
                    <InputMask mask="9999" maskChar="" />
                  </TextField>
                  <p className="required-box">(*) - Required field.</p>
                </Fragment>
              }
              {this.props.showManagers &&
                <Fragment>
                  {this.props.from.receiptRequestNote && <ScriptText>{this.props.from.receiptRequestNote}</ScriptText>}
                  <ContactManagers showButtons={[]} />
                  <RaisedButton label="Back" onClick={(e) => this.updateField(e, false, 'showManagers')} />
                </Fragment>
              }
              <div style={{ marginTop: 15 }}>
                {!this.props.showManagers &&
                  <Fragment>
                    <RaisedButton label="Submit" disabled={!this.props.formComplete} onClick={this.submitReceiptRequest} />
                    <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                  </Fragment>
                }
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    email: promptSelectors.pullModular(state, 'email'),
    contactNumber: promptSelectors.pullModular(state, 'contactNumber'),
    lastFour: promptSelectors.pullLastFour(state),
    receiptRequestSubmitted: promptSelectors.pullReceiptRequestSubmitted(state),
    showManagers: promptSelectors.pullShowManagers(state),
    receiptRequestFeeDue: promptSelectors.pullModular(state, 'receiptRequestFeeDue'),
    receiptRequestEntranceTime: promptSelectors.pullModular(state, 'receiptRequestEntranceTime'),
    receiptRequestExitTime: promptSelectors.pullModular(state, 'receiptRequestExitTime'),
    receiptRequestEntranceDate: promptSelectors.pullModular(state, 'receiptRequestEntranceDate'),
    receiptRequestExitDate: promptSelectors.pullModular(state, 'receiptRequestExitDate'),
    receiptRequestFirstName: promptSelectors.pullModular(state, 'receiptRequestFirstName'),
    receiptRequestLastName: promptSelectors.pullModular(state, 'receiptRequestLastName'),
    error: promptSelectors.pullModular(state, 'error'),
    errorMessage: promptSelectors.pullModular(state, 'errorMessage'),
    locationId: promptSelectors.pullModular(state, 'locationId'),
    formComplete: promptSelectors.pullModular(state, 'formComplete')
  };
}

export default withRouter(connect(mapStateToProps)(ReceiptRequest));
