import React, { Component, Fragment } from 'react';
import autoBind from 'react-autobind';
import _ from 'lodash';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import TextField from 'material-ui/TextField';
import Divider from 'material-ui/Divider';
import CircularProgress from 'material-ui/CircularProgress';
import { titleCase } from 'title-case';
import { retrieveLogger } from '../lib/logger';
let logger = retrieveLogger();

const etcStyles = {
  padding: '0px 6px', textAlign: 'center', width: '20%'
}

export default class MonthlyParkersListApb extends Component {
  constructor(props) {
    super(props);
    this.reloadInterval = null;
    autoBind(this);
  }

  componentDidMount() {
    this.props.generateFilteredParkers();
  }

  componentDidUpdate() {
    this.props.generateFilteredParkers();
  }

  generateTableRowColumn(value, index, collection) {
    // Figure out how to compare for which parker is selected, and programatically set the selected to true for that row.
    let selection = false;
    // sC, or searchCriteria, is used as the criteria that dictates if a parker's record is shown or not. It is populated by user input text field.
    let sC;
    // For APB monthly list, we do not receive "name" as one string, rather as FName and LName. We must combine them, lower cased, into one name field.
    // eslint-disable-next-line
    if (value.FName == undefined || value.FName == 'undefined') {
      value.FName = '';
    }
    // eslint-disable-next-line
    if (value.LName == undefined || value.LName == 'undefined') {
      value.LName = '';
    }
    let fullName = `${value.FName} ${value.LName}` || '';

    if (this.props.searchCriteria && this.props.searchCriteria.toLowerCase) {
      sC = this.props.searchCriteria.toLowerCase() || '';
    }

    if (this.props.selectedParkerIndex === index) {
      selection = true;
    }
    let allowedFacility;
    if (value.AidType && (this.props.from.locationId === 84 || this.props.from.locationId === 97)) {
      if (value.AidType.toLowerCase() === 'rfid') {
        allowedFacility = 'Bank of America';
      } else if (value.AidType.toLowerCase() === 'hid') {
        allowedFacility = 'Alley Theater';
      } else if (value.AidType === 'none') {
        allowedFacility = 'none'
      }
    }
    try {
      if (fullName.toLowerCase().match(sC) || `${value.Badge}`.match(sC) ) {
        return (
          <TableRow
            selected={selection}
            key={`${value}_${index}`}
            style={{cursor: 'pointer'}}
          >
            <TableRowColumn style={etcStyles}>{titleCase(fullName)}</TableRowColumn>
            <TableRowColumn style={etcStyles}>{(value && value.Badge) ? value.Badge : ''}</TableRowColumn>
            {value.Status && this.props.parkersFiltered.length <= 3 && 
              <TableRowColumn style={{padding: '0px 6px', textAlign: 'center', width: '20%', color: (value.Status === 'terminated' ? 'Red' : 'Black') }}>
                {(value && value.Status) ? titleCase(value.Status.toLowerCase()) : ''}
              </TableRowColumn>
            }
            <TableRowColumn style={etcStyles}>
              {(this.props.from.locationId === 84 || this.props.from.locationId === 97) && allowedFacility && titleCase(allowedFacility)}
            </TableRowColumn>
          </TableRow>
        );
      } else {
        return (
          <TableRow
            selected={selection}
            key={`${value}_${index}`}
            style={{cursor: 'pointer', height: '0px'}}
          >
          </TableRow>
        )
      }
    } catch (e) {
      logger.error(e);
    }
  }

  render() {
    // If this triggers each time we hit a key, we can achieve wat we want (make them filterable / scrollable but still have complete list)
    // by always just slicing 10-50 or however many records inside of the _.map, but as we add search criteria, filter out irrelevant parkers from the
    // state itself, or use a second state variable to maintain the filtered list.
    let windowHeight = window.innerHeight - 600;
    return (
      <Fragment>
        <TextField
          inputProps={{ className: 'data-hj-allow' }}
          hintText="Search for Parkers here"
          style={{width: '98%'}} 
          onChange={this.props.mPSearch}
        />
        <Divider />
        {this.props.parkers[0] 
        ?
          <Table
            bodyStyle={{ height: windowHeight, overflow:'auto'}}
            onCellClick={this.props.sendParkerToState}  
          >
            <TableHeader style={{ borderRight: '32px solid transparent' }} displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableHeaderColumn style={etcStyles}>Name</TableHeaderColumn>
                <TableHeaderColumn style={etcStyles}>Badge #</TableHeaderColumn>
                {(this.props.parkersFiltered[0] && this.props.parkersFiltered[0].Status && this.props.parkersFiltered.length <= 3) &&
                  <TableHeaderColumn style={etcStyles}>Status</TableHeaderColumn>
                }
                { (this.props.from.locationId === 84 || this.props.from.locationId === 97) && <TableHeaderColumn style={etcStyles}>Allowed Facility</TableHeaderColumn> }
                {/* <TableHeaderColumn style={etcStyles}>Last Scanned At</TableHeaderColumn> */}
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false} stripedRows={true}>
              {_.map(this.props.parkersFiltered.slice(0, 50), this.generateTableRowColumn) }
            </TableBody>
          </Table> 
        : 
        <CircularProgress style={{ margin: 'calc(50% - 75px) 0 0 0'}} />
        }
      </Fragment>
    );
  }
}
