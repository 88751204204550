import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptActions from '../../../store/prompt/actions';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as authSelectors from '../../../store/auth/reducer';
import NextSteps from '../../../components/nextSteps';
import Paper from 'material-ui/Paper';
import ScriptText from '../../../components/ScriptText';
import { RaisedButton } from 'material-ui';
import ErrorBoundary from '../../ErrorBoundary';

class FlashResetMonthlyCard extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { resetCardSuccess: false, issueType: null }
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    const { issue } = this.props;
    this.setState({ issueType: issue.subtype });
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  async flashResetMonthlyCard() {
    const { flashMonthlyCardID } = this.props;
    this.props.dispatch(promptActions.modularSendField(true, 'showLoader'));
    const result = await this.props.dispatch(promptActions.flashResetMonthlyAccountToNeutral({ monthlyID: flashMonthlyCardID }));
    const { success, code, message } = result.data;
    if (success && this.state.issueType == 'cardAcceptanceIssue') {
      this.setState({ resetCardSuccess: true })
    } else if (success && this.state.issueType == 'noCard') {
      this.props.history.push('/vendGate');
    }
    this.props.dispatch(promptActions.modularSendField(false, 'showLoader'));
  }

  render() {
    return (
      <ErrorBoundary>
        <div className="home-div">
          <div className="home-div-content">
            <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
              <h2>{this.props.from.locationName}</h2>
            </Paper>
            <div className="home-div-body">
              <Paper zDepth={1} className="paper-padding">
                <ScriptText>
                  {!this.state.resetCardSuccess
                  ?
                    "I am in the process of resetting your monthly card, please wait one moment."
                  :
                    "Please try your monthly card to exit the gate."
                  }
                </ScriptText>
                <div style={{ marginTop: 15 }}>
                  {!this.state.resetCardSuccess && <RaisedButton label="Reset Montly Card" onClick={() => this.flashResetMonthlyCard()} />}
                  <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username'),
    flashMonthlyCardID: promptSelectors.pullModular(state, 'flashMonthlyCardID'),
    issue: promptSelectors.pullIssue(state),
  };
}

export default withRouter(connect(mapStateToProps)(FlashResetMonthlyCard));