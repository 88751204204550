import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as promptSelectors from '../../store/prompt/reducer';
import * as promptActions from '../../store/prompt/actions';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import NumberFormat from 'react-number-format';
import * as authSelectors from '../../store/auth/reducer';
import Paper from 'material-ui/Paper';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import * as config from '../../lib/config';
import { retrieveLogger } from '../../lib/logger';
let logger = retrieveLogger();

const requiredFields = [
  'reservationNumber',
  'reservationName',
  'reservationFeeDue',
  'reservationStatus'
];
export class ReservationDiscrpancy extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentWillUnmount() {
    if (this.errorTimeout) {
      logger.info('Clearing errorTimeout');
      clearTimeout(this.errorTimeout);
    }
    if (this.autocompleteTimeout) {
      logger.info(`Clearing autocompleteTimeout`);
      clearTimeout(this.autocompleteTimeout);
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  handleSelectChange(event, newValue) {
    // dispatch action to write to props
    this.props.dispatch(promptActions.modularSendField(event.target.innerText, 'reservationStatus'));
  }

  updateField(event, value, target) {
    this.props.dispatch(promptActions.modularSendField(value, target));
  }

  async submitReservationDiscrepancy() {
    let completed = true;
    let rec = {
      ReservationNumber: this.props.reservationNumber,
      FeeDue: this.props.reservationFeeDue,
      ParkerName: this.props.reservationName,
      ReservationStatus: this.props.reservationStatus,
      Username: this.props.username
    };
    requiredFields.map((keyRec) => {
      // If we are missing a required field, dispatch an error for a few seconds to alert the analyst what field they are missing.
      if (!this.props[keyRec] || this.props[keyRec] === null) {
        let cleanField = keyRec.replace('reservation', '');
        let errorMessage = `Missing ${cleanField} field.`;
        // Send error and error message to state.
        this.props.dispatch(promptActions.modularSendField(errorMessage, 'errorMessage'));
        this.props.dispatch(promptActions.modularSendField(true, 'error'));
        // Clear error message in a few seconds.
        this.errorTimeout = setTimeout(() => {
          this.props.dispatch(promptActions.modularSendField(errorMessage, null));
          this.props.dispatch(promptActions.modularSendField(false, 'error'));
        }, 4000);
        completed = false;
      }
      return null;
    });
    // If we found a missing field in the Object map, let's return so that we don't send this request to the server.
    if (completed === false) {
      return;
    }
    // eslint-disable-next-line
    let submitResults = await this.props.dispatch(promptActions.submitReservationDiscrepancy(rec));
  }

  render() {
    let feeDueHintText = 'Fee Due';
    if (this.props.reservationFeeDue && this.props.reservationFeeDue.length > 0) {
      feeDueHintText = '';
    }
    return (
      <div className="home-div">
        <div className="home-div-content">
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            <Paper zDepth={1} className="paper-padding">
              <h3>Reservation Discrepancy</h3>
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Name on Reservation"
                onChange={(e, v) => {
                  this.updateField(e, v, 'reservationName');
                }}
                style={{ width: '96%' }}
              />
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                style={{ width: '48%' }}
                hintText={feeDueHintText}
                ref="feeDue"
                name="feeDue"
                type="text"
                value={this.props.reservationFeeDue}
                onChange={(e, v) => {
                  this.updateField(e, v, 'reservationFeeDue');
                }}>
                <NumberFormat
                  value={this.props.reservationFeeDue}
                  thousandSeparator={true}
                  prefix={'$'}
                  onValueChange={(vals) => {
                    this.updateField(null, vals.value, 'reservationFeeDue');
                  }}
                />
              </TextField>
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Reservation Number"
                onChange={(e, v) => {
                  this.updateField(e, v, 'reservationNumber');
                }}
                style={{ width: '48%' }}
              />
              <SelectField floatingLabelText="Reservation Status" value={this.props.reservationStatus || ''} onChange={this.handleSelectChange} style={{ width: '96%', textAlign: 'left' }}>
                <MenuItem value={'Completed'} primaryText="Completed" />
                <MenuItem value={'Pending'} primaryText="Pending" />
                <MenuItem value={'Late'} primaryText="Late" />
                <MenuItem value={'Missed'} primaryText="Missed" />
              </SelectField>
              {/* <TextField
                hintText="Reservation Status"
                onChange={(e, v) => {
                  this.updateField(e, v, 'reservationStatus');
                }}
                style={{ width: '48%' }}
              /> */}
              </Paper>
            <Paper zDepth={1} className="paper-padding">
              {this.props.reservationDiscrepancyComplete ? (
                <Link to="/vendGate">
                  <RaisedButton label="Vend Gate" />
                </Link>
              ) : (
                <RaisedButton label="Submit Reservation Discrepancy" onClick={this.submitReservationDiscrepancy} />
              )}
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    reservationNumber: promptSelectors.pullModular(state, 'reservationNumber'),
    reservationStatus: promptSelectors.pullModular(state, 'reservationStatus'),
    reservationFeeDue: promptSelectors.pullModular(state, 'reservationFeeDue'),
    reservationName: promptSelectors.pullModular(state, 'reservationName'),
    reservationDiscrepancyComplete: promptSelectors.pullModular(state, 'reservationDiscrepancyComplete'),
    error: promptSelectors.pullModular(state, 'error'),
    errorMessage: promptSelectors.pullModular(state, 'errorMessage')
  };
}

export default withRouter(connect(mapStateToProps)(ReservationDiscrpancy));
