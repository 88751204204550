import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as promptSelectors from '../../store/prompt/reducer';
import * as promptActions from '../../store/prompt/actions';
import * as authSelectors from '../../store/auth/reducer';
import * as config from '../../lib/config';
import DirectionText from '../../components/DirectionText';
import ScriptText from '../../components/ScriptText';
import PromptBox from '../../components/promptBox';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import ContactManagers from './ContactManagers';
import _ from 'lodash';

const requiredMap = {
  Police: ['companyName'],
  Ambulance: ['companyName'],
  'Fire Department': ['workerName', 'companyName'],
  Plow: ['workerName', 'companyName'],
  Contractor: ['workerName', 'companyName', 'reportingTo'],
  'Tow/AAA': ['workerName', 'companyName'],
  Garbage: ['workerName', 'companyName']
};

const allMap = ['companyName', 'workerName', 'reportingTo'];

let workerNameHintText, companyNameHintText, reportingToHintText;

export class Emergency extends Component {
  constructor(props) {
    super(props);
    this.requiredArray = [];
    autoBind(this);
    this.state = { showManagersList:false }
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    // Reset the service worker type on mount incase they hit home and want to click out of "Contractor"
    this.props.dispatch(promptActions.modularSendField(null, 'serviceWorkerType'));
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (this.props.serviceWorkerType) {
      this.requiredArray = requiredMap[this.props.serviceWorkerType];
      allMap.forEach((requirement) => {
        if (this.requiredArray && this.requiredArray.includes(requirement)) {
          this.props.dispatch(promptActions.modularSendField(true, `${requirement}Required`));
        } else {
          this.props.dispatch(promptActions.modularSendField(false, `${requirement}Required`));
        }
      })
      if (
        ((this.props.workerNameRequired && this.props.selectedParkerName) !== null) &&
        ((this.props.companyNameRequired && this.props.companyName) !== null) &&
        ((this.props.reportingToRequired && this.props.reportingTo) !== null)
      ) {
        this.props.dispatch(promptActions.modularSendField(true, 'serviceWorkerComplete'));
      } else {
        this.props.dispatch(promptActions.modularSendField(false, 'serviceWorkerComplete'));
      }
    }
  }

  submitForm() {
    const { selectedParkerName, companyName } = this.props;
    const { serviceWorkerContractorForm } = this.props.from;
    if (serviceWorkerContractorForm.includes("selectedParkerName") && !selectedParkerName) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: 'Parker Name is required.'
      }))
    } else if (serviceWorkerContractorForm.includes("companyName") && !companyName) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: 'Company Name is required.'
      }))
    } else {
      this.props.history.push('/contactManagers');
    }
  }

  handleChange(event, newValue, target) {
    let text;
    // If event.target.innerText exists, this is being triggered by our one SelectField. TextFields do not properly 
    // populate event.target.innerText, so we instead use the newValue parameter.
    if (event.target.innerText) {
      text = event.target.innerText;
    } else {
      text = newValue;
      if (text.length === 0) {
        text = null;
      }
    }
    let trimText, trimTarget;
    if (text) {
      trimText = text.trim();
    }
    if (target) {
      trimTarget = target.trim()
    }
    this.props.dispatch(promptActions.modularSendField(trimText, trimTarget));
  }

  generateServiceWorkers(value, index, collection) {
    return (
      <MenuItem value={value} primaryText={value} />
    );
  }

  completeStep(step) {
    this.props.dispatch(promptActions.updateIssueStep(step));
  }

  renderStandard() {
    if (this.props.workerNameRequired === true) { workerNameHintText = 'Worker Name (*)';} else { workerNameHintText = 'Worker Name';}
    if (this.props.companyNameRequired === true) { companyNameHintText = 'Company / Department Name (*)';} else { companyNameHintText = 'Company / Department Name';}
    if (this.props.reportingToRequired === true) { reportingToHintText = 'Who Are They Visiting? (*)';} else { reportingToHintText = 'Who Are They Visiting?';}
    return (
      <div className="home-div">
        <div className="home-div-content">
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            <Paper zDepth={1} className="paper-padding">
              <DirectionText>Please select the type of service worker.</DirectionText>
              { this.props.from.allowedServiceWorkerTypes ?
                <SelectField
                  floatingLabelText="Service Worker Type"
                  value={this.props.serviceWorkerType || ''}
                  onChange={(i, v) => {this.handleChange(i, v, 'serviceWorkerType')}}
                  style={{ width: '100%', textAlign: 'left' }}
                >
                {_.map(this.props.from.allowedServiceWorkerTypes, this.generateServiceWorkers)}
                </SelectField> :
                <SelectField
                  floatingLabelText="Service Worker Type"
                  value={this.props.serviceWorkerType || ''}
                  onChange={(i, v) => {this.handleChange(i, v, 'serviceWorkerType')}}
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  <MenuItem value={'Police'} primaryText="Police" />
                  <MenuItem value={'Fire Department'} primaryText="Fire Department" />
                  <MenuItem value={'Ambulance'} primaryText="Ambulance" />
                  <MenuItem value={'Plow'} primaryText="Plow" />
                  <MenuItem value={'Contractor'} primaryText="Contractor" />
                  <MenuItem value={'Tow/AAA'} primaryText="Tow/AAA" />
                  <MenuItem value={'Garbage'} primaryText="Garbage" />
                </SelectField> 
              }
              { this.props.from.contractorAsTransient && this.props.serviceWorkerType === 'Contractor' ?
                <Fragment>
                  {!this.state.showManagersList 
                  ? 
                    (
                      <Fragment>
                        <DirectionText>Please direct the parker to pay as a transient.</DirectionText>
                        <ScriptText> This location does not give contractors free parking. </ScriptText>
                        <div style={{ marginTop: 15}}>
                          <RaisedButton label="Contact Managers" onClick={() => this.setState({ showManagersList: true })} />                        
                          <RaisedButton label="End Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                        </div>
                      </Fragment>
                    ) 
                  : 
                    (
                      <Fragment>
                        <ContactManagers showButtons={[]} />
                        <RaisedButton label="Back" onClick={() => this.setState({ showManagersList: false })} />
                      </Fragment>
                    )
                  } 
                </Fragment>
              : 
                <div>           
                  {( this.props.serviceWorkerType === 'Police' ||
                    this.props.serviceWorkerType === 'Fire Department' ||
                    this.props.serviceWorkerType === 'Plow' ||
                    this.props.serviceWorkerType === 'Contractor' ||
                    this.props.serviceWorkerType === 'Tow/AAA' ||
                    this.props.serviceWorkerType === 'Garbage') &&
                    <TextField
                      inputProps={{ className: 'data-hj-allow' }}
                      hintText={workerNameHintText}
                      style={{ width: '100%' }}
                      onChange={(i, v) => {this.handleChange(i, v, 'selectedParkerName')}}
                      key="workerName"
                    /> 
                  }
                  { this.props.serviceWorkerType &&
                    <TextField
                      inputProps={{ className: 'data-hj-allow' }}
                      hintText={companyNameHintText}
                      style={{ width: '100%' }}
                      onChange={(i, v) => {this.handleChange(i, v, 'companyName')}}
                      key="companyName"
                    /> 
                  }
                  { this.props.serviceWorkerType === 'Contractor' &&
                    <TextField
                      inputProps={{ className: 'data-hj-allow' }}
                      hintText={reportingToHintText}
                      style={{ width: '100%' }}
                      onChange={(i, v) => {this.handleChange(i, v, 'reportingTo')}}
                      key="reportingTo"
                    /> 
                  }
                  { this.props.serviceWorkerComplete &&
                    <Link to="/vendGate">
                      <RaisedButton label="Vend Gate" style={{ margin: '0 10px' }} />
                    </Link>               
                  }
                  <Link to="/confirmationScreen">
                    <RaisedButton label="End Call" style={{ margin: '0 10px' }} />
                  </Link>
                </div>
              }
            </Paper> 
          </div>
        </div>
      </div>
    );
  }

  renderContractorPullTicket() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        { this.props.step === 0 &&   
          <div>
            <ScriptText>
              { this.props.from.serviceWorkerContractorNote ?
                this.props.from.serviceWorkerContractorNote :
                "Ask the contactor to pull a ticket and contact the cashier while exiting."
              }
            </ScriptText>
            {this.props.from.serviceWorkerContractorForm &&
              <Fragment>
                {this.props.from.serviceWorkerContractorForm.includes("selectedParkerName") &&
                  <TextField
                    inputProps={{ className: 'data-hj-allow' }}
                    hintText={workerNameHintText}
                    style={{ width: '100%' }}
                    onChange={(i, v) => {this.handleChange(i, v, 'selectedParkerName')}}
                    key="workerName"
                  />
                }
                {this.props.from.serviceWorkerContractorForm.includes("companyName") &&
                  <TextField
                    inputProps={{ className: 'data-hj-allow' }}
                    hintText={companyNameHintText}
                    style={{ width: '100%' }}
                    onChange={(i, v) => {this.handleChange(i, v, 'companyName')}}
                    key="companyName"
                  /> 
                }
              </Fragment>
            }
            <div style={{ marginTop: 15 }}>
              {this.props.from.serviceWorkerContractorForm 
              ? 
              <RaisedButton label="Submit" onClick={() => this.submitForm()}/>
              :
              <RaisedButton label="Contact Manager" onClick={() => { this.completeStep(1) }} />
              } 
              <RaisedButton label="Complete Call" onClick={() => { this.props.history.push('/confirmationScreen') }} />
            </div>
          </div>       
        }
        {this.props.step === 1 && <ContactManagers showButtons={['vendGate', 'managerAssisting', 'ticketDiscrepancy']} /> }
      </PromptBox>
    )
  }

  serviceWorkerContactManager() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        <ContactManagers showButtons={['vendGate', 'managerAssisting']} />
      </PromptBox>
    )
  }

  simpleServiceWorker() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        <ScriptText>
          {this.props.from.simpleServiceWorkerNote}
        </ScriptText>
        <div style={{ marginTop: 15}}>
          <RaisedButton label="Complete Call" onClick={() => { this.props.history.push('/confirmationScreen') }} />
        </div>
      </PromptBox>
    )
  }

  render() {
    if (this.props.from.serviceWorkerContractorPullTicket && this.props.serviceWorkerType === 'Contractor') {
      return this.renderContractorPullTicket();
    } else if (this.props.from.serviceWorkerContactManager) {
      return this.serviceWorkerContactManager();
    } else if (this.props.from.simpleServiceWorker) {
      return this.simpleServiceWorker();
    } else {
      return this.renderStandard();
    }
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    serviceWorkerType: promptSelectors.pullModular(state, 'serviceWorkerType'),
    selectedParkerName: promptSelectors.pullModular(state, 'selectedParkerName'),
    companyName: promptSelectors.pullModular(state, 'companyName'),
    reportingTo: promptSelectors.pullModular(state, 'reportingTo'),
    workerNameRequired: promptSelectors.pullModular(state, 'workerNameRequired'),
    companyNameRequired: promptSelectors.pullModular(state, 'companyNameRequired'),
    reportingToRequired: promptSelectors.pullModular(state, 'reportingToRequired'),
    serviceWorkerComplete: promptSelectors.pullModular(state, 'serviceWorkerComplete'),
    step: promptSelectors.pullModular(state, 'step')
  };
}

export default withRouter(connect(mapStateToProps)(Emergency));
