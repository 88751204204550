import React, { useState, Fragment } from 'react';
import Button from '@mui/material/Button'
import LogPrepaidDetails from './PrepaidNodes/LogPrepaidDetails';
import { withRouter } from 'react-router';
import PromptWrapper from './PromptWrapper';
import PulledTicket from './PrepaidNodes/PulledTicket';
import PrepaidHowParkerEntered from './PrepaidNodes/PrepaidHowParkerEntered';
import PrepaidScannedReservation from './PrepaidNodes/PrepaidScannedReservation';
import { pullModular as promptPullModular } from '../../../store/prompt/reducer';
import { pullModular as queuePullModular } from '../../../store/queue/reducer';
import { pullModular as authPullModular } from '../../../store/auth/reducer';
import { connect } from 'react-redux';
import FreshContactManagers from './PrepaidNodes/FreshContactManagers';
import TicketInstructions from './PrepaidNodes/TicketInstructions';
import PushRate from './PrepaidNodes/PushRate';
import { showSnackBar, tibaSendControlDeviceAction } from '../../../store/prompt/actions';
import { disableContinue } from './lib/contactManagerFunctions';
import { logPrepaidDetailsSubmit } from './lib/logPrepaidDetailsFunctions';

const PrepaidExit3 = (props) => {
  
  const { 
    thirdPartyVendorId,  
    thirdPartyReservationNumber, 
    thirdPartyVendorName,
    selectedParkerName, 
    ongoingCallId, 
    username, 
    selectedManagerName, 
    tibaRateResult, 
    dispatch, 
    isParkerCallOnHold,
    history,
    from,
    managerCalledFor10s,
    setManagerCalledFor10s,
    managerCalled,
    setManagerCalled
  } = props;

  //node is a string for branching paths in a flow
  const [node, setNode] = useState('0');
  const [usedTicket, setUsedTicket] = useState(false);

  const pushRateToMachine = async () => {
    const { apiParams, controlDeviceData, baseURL } = from;
    if (!selectedManagerName) {
      dispatch(showSnackBar({ 
        type: 'error', message: 'Manager Name is required.'
      }));
    } else if (tibaRateResult && !tibaRateResult.rate) {
      dispatch(showSnackBar({ 
        type: 'error', message: 'Rate is required.'
      }));
    } else {
      const rateToPush = tibaRateResult.rate;
      const data = { 
        username, 
        ongoingCallId, 
        apiParams, 
        baseURL, 
        commandType: controlDeviceData ? controlDeviceData.pushRateNewTicket : '0', 
        actionId: controlDeviceData ? controlDeviceData.pushRateDBId: '0', 
        controlDeviceData, 
        rateToPush, 
        managerName: selectedManagerName || null 
      };
      const result = await dispatch(tibaSendControlDeviceAction(data));
      const { success, message } = result.data;
      if (success) {
        dispatch(showSnackBar({ 
          type: 'success', message: `Rate $${rateToPush} pushed to device, if parker denies payment contact managers.` 
        }));
      } else if (!success) {
        dispatch(showSnackBar({ 
          type: 'error', message: message
        }));
      }
    }
  }

  return (
    <PromptWrapper>
      {node == '0' &&
        <Fragment>
          <PrepaidHowParkerEntered />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {
              setUsedTicket(true);
              setNode('1a')}}>
                {from.ticketlessLocation ? 'Used Credit/Debit Card' : 'Pulled a Ticket'}</Button>
          <Button className='button-style' variant='outlined' onClick={() => {setNode('1b')}}>Scanned Reservation</Button>
          <Button className='button-style' variant='outlined' onClick={() => {setNode('1b')}}>Gate Open on Entrance</Button>
        </Fragment>
      }
      {node == '1a' &&
        <Fragment>
          <PulledTicket />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {setNode('2a')}}>Next Steps</Button>
        </Fragment>
      }
      {node == '1b' &&
        <Fragment>
          <PrepaidScannedReservation />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {setNode('2b')}}>Next Steps</Button>
        </Fragment>
      }
      {node == '2a' &&
        <Fragment>
          <LogPrepaidDetails />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined'  onClick={() => {
            logPrepaidDetailsSubmit({
              dispatch,
              thirdPartyVendorId,
              thirdPartyReservationNumber,
              selectedParkerName,
              action: () => { setNode('3a') }
            })}}>
              Submit
          </Button>
        </Fragment>
      }
      {node == '2b' &&
        <Fragment>
          <LogPrepaidDetails />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined'  onClick={() => {
            logPrepaidDetailsSubmit({
              dispatch,
              thirdPartyVendorId,
              thirdPartyReservationNumber,
              selectedParkerName,
              action: () => { setNode('3b') }
            })}}>
              Submit
          </Button>
        </Fragment>
      }
      {node == '3a' &&
        <Fragment>
          <p>
            <span className='contact-managers-header'>{' '}Reservation Source:{' '}</span>{thirdPartyVendorName},
            <span className='contact-managers-header'>{' '}Reservation Number:{' '}</span>{thirdPartyReservationNumber},
            <span className='contact-managers-header'>{' '}Parker Name:{' '}</span>{selectedParkerName}
          </p>
          <FreshContactManagers 
            managerCalledFor10s={managerCalledFor10s} 
            setManagerCalledFor10s={setManagerCalledFor10s}
            managerCalled={managerCalled}
            setManagerCalled={setManagerCalled}
          />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {
            disableContinue({
              dispatch,
              isParkerCallOnHold,
              action: () => { history.push('/vendGate') },
              managerCalledFor10s,
              managerCalled
            })
          }}>
            Manager Requested Gate Vend
          </Button>
          <Button className='button-style' variant='outlined' onClick={() => {
            disableContinue({
              dispatch,
              isParkerCallOnHold,
              action: () => { setNode('4') },
              managerCalledFor10s,
              managerCalled
            })
          }}>
            Manager Requested Push Rate
            </Button>
          <Button className='button-style' variant='outlined' onClick={() => {
            disableContinue({
              dispatch,
              isParkerCallOnHold,
              action: () => { history.push('/vendGate') },
              managerCalledFor10s,
              managerCalled
            })
          }}>
            No Manager Response
          </Button>
          <Button className='button-style' variant='outlined'onClick={() => {
            disableContinue({
              dispatch,
              isParkerCallOnHold,
              action: () => { history.push('/confirmationScreen') },
              managerCalledFor10s,
              managerCalled
            })
          }}>
            Manager Assisting
          </Button>
        </Fragment>
      }
      {node == '3b' &&
        <Fragment>
          <p>
            <span className='contact-managers-header'>{' '}Reservation Source:{' '}</span>{thirdPartyVendorName},
            <span className='contact-managers-header'>{' '}Reservation Number:{' '}</span>{thirdPartyReservationNumber},
            <span className='contact-managers-header'>{' '}Parker Name:{' '}</span>{selectedParkerName}
          </p>
          <FreshContactManagers 
            managerCalledFor10s={managerCalledFor10s} 
            setManagerCalledFor10s={setManagerCalledFor10s}
            managerCalled={managerCalled}
            setManagerCalled={setManagerCalled}
          />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {
            disableContinue({
              dispatch,
              isParkerCallOnHold,
              action: () => { history.push('/vendGate') },
              managerCalledFor10s,
              managerCalled
            })
          }}>
            Manager Requested Gate Vend
          </Button>
          <Button className='button-style' variant='outlined' onClick={() => {
            disableContinue({
              dispatch,
              isParkerCallOnHold,
              action: () => { setNode('5') },
              managerCalledFor10s,
              managerCalled
            })
          }}>
            Manager Requested Push Rate
          </Button>
          <Button className='button-style' variant='outlined' onClick={() => {
            disableContinue({
              dispatch,
              isParkerCallOnHold,
              action: () => { history.push('/vendGate') },
              managerCalledFor10s,
              managerCalled
            })
          }}>
            No Manager Response
          </Button>
          <Button className='button-style' variant='outlined' onClick={() => {
            disableContinue({
              dispatch,
              isParkerCallOnHold,
              action: () => { history.push('/confirmationScreen') },
              managerCalledFor10s,
              managerCalled
            })
          }}>Manager Assisting</Button>
        </Fragment>
      }
      {node =='4' &&
        <Fragment>
          <TicketInstructions />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {setNode('5')}}>Next Steps</Button>
        </Fragment>
      }
      {node == '5' &&
        <Fragment>
          <PushRate usedTicket={usedTicket} />
          {from.parcs == 'tibaAPI' && <Button variant='outlined' onClick={() => {pushRateToMachine()}}>Push Rate to Machine</Button>}
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
        </Fragment>
      }
    </PromptWrapper>
  )
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from'),
    thirdPartyVendorId: promptPullModular(state, 'thirdPartyVendorId'),
    thirdPartyReservationNumber: promptPullModular(state, "thirdPartyReservationNumber"),
    thirdPartyVendorName: promptPullModular(state, 'thirdPartyVendorName'),
    selectedParkerName: promptPullModular(state, 'selectedParkerName'),
    tibaRateResult: promptPullModular(state, 'tibaRateResult'),
    selectedManagerName: promptPullModular(state, 'selectedManagerName'),
    ongoingCallId: queuePullModular(state, 'ongoingCallId'),
    username: authPullModular(state, 'username'),
    isParkerCallOnHold: queuePullModular(state, 'isParkerCallOnHold'),
  }
}

export default withRouter(connect(mapStateToProps)(PrepaidExit3));