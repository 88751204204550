import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptActions from '../../store/prompt/actions';
import * as promptSelectors from '../../store/prompt/reducer';
import * as authSelectors from '../../store/auth/reducer';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import DirectionText from '../../components/DirectionText';
import ScriptText from '../../components/ScriptText';
import ManagersList from './ManagersList';
import ContactManagers from './ContactManagers';

class ReservationIssue extends Component {
  constructor(props) {
    super(props);
    this.reloadInterval = null;
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    /* reset global step prop */
    this.props.dispatch(promptActions.updateIssueStep(0));
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentWillUnmount() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  updateField(event, value, target) {
    this.props.dispatch(promptActions.modularSendField(value, target));
  }

  render() {
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
          { this.props.from.laneType === 'Entrance' &&
            <Paper zDepth={1} className="paper-padding">
              <ScriptText>
                If your reservation is not scanning, please pull a ticket and insert it first followed by your reservation when exiting.
              </ScriptText>
            </Paper>
          }
          { this.props.from.laneType === 'Exit' &&
            <Paper zDepth={1} className="paper-padding">
              <ScriptText>
                Please scan your ticket first followed by your reservation.
              </ScriptText>
              <DirectionText>
                If the reservation still is not accepted, please call attendants / manager.
              </DirectionText>
              <ContactManagers showButtons={[]} />
              <DirectionText>
                If <b> every single </b> attendant / manager ignores your call, continue to the Reservation Discrepancy form.
              </DirectionText>
            </Paper>
          }
          { this.props.from.laneType === 'Paystation' &&
            <DirectionText>
              Please direct the parker to move to the exit lane for assistance.
            </DirectionText>
          }
            <Paper zDepth={1} className="paper-padding">
            { this.props.from.laneType === 'Exit' &&
              <Link to="/reservationDiscrepancy">
                <RaisedButton label="No Manager Response" style={{ margin: '0 10px' }} />
              </Link>
            }
              <Link to="/confirmationScreen">
                <RaisedButton label="End Call" />
              </Link>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username')
  };
}

export default withRouter(connect(mapStateToProps)(ReservationIssue));
