import React, { Component, Fragment } from 'react';
import autoBind from 'react-autobind';
import { Snackbar, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
export default class ErrorMessage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
      return (
        <Snackbar
          className={this.props.snackbarType == 'error' ? "snackbar-error" : "snackbar-success"}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.props.error}
          message={this.props.errorMessage}
          autoHideDuration={10000}
          onClose={this.props.handleSnackbarClose}
          action={
            <Fragment>
              <Button style={{ color: 'white' }} onClick={this.props.handleSnackbarClose}>
               <CloseIcon  />
              </Button>
            </Fragment>
          }
        />
      );
  }
}