import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import * as config from '../lib/config';
import * as authActions from '../store/auth/actions';
import * as queueActions from '../store/queue/actions';
import * as queueSelectors from '../store/queue/reducer';
import * as authSelectors from '../store/auth/reducer';
import * as promptSelectors from '../store/prompt/reducer';
import * as promptActions from '../store/prompt/actions';
import QueueList from '../components/queueList';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import SocketContext from '../services/socketContext';
import { retrieveLogger, updateLogger, removeFromLogger } from '../lib/logger';
import { datadogLogs } from '@datadog/browser-logs';
let logger = retrieveLogger();

class QueueScreen extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.reloadInterval = null;
    this.socket = null;
  }

  componentDidMount() {
    this.reloadInterval = setInterval(() => {
      this.reloadQueue();
    }, 500);
    if (config.TestLocation) {
      this.props.dispatch(queueActions.answerConnTest(config.TestLocation, new Date()));
    }

    this.socket = this.props.socket;
    this.socket.on('parker-joined', this.parkerJoined);    
    this.socket.on('parker-left', this.parkerLeft);

    // Clock stuff
    this.startLocalTime();
    // Basically, this is going to see how many seconds are left until the next minute swap, and then starting then, make a setInterval for every minute
    // to update the time. This is to keep the local clocks up to date.
    const secondsUntilNextMinute = Math.abs(60 - parseInt(moment().seconds())) * 1000;
    setTimeout(() => {
      setInterval(() => {
        this.startLocalTime();
      }, 60000)
    }, secondsUntilNextMinute);
  }

  componentDidUpdate() {
    this.shouldDisableGhost();
    // Clock stuff
    const { onCall } = this.props;
    let startLocationTimeInterval;
    if (onCall) {
      this.startLocationTime();
      const secondsUntilNextMinute = Math.abs(60 - parseInt(moment().seconds())) * 1000;
      setTimeout(() => {
        startLocationTimeInterval = setInterval(() => {
          this.startLocationTime();
        }, 60000)
      }, secondsUntilNextMinute);
    } else {
      clearInterval(startLocationTimeInterval);
      this.props.dispatch(authActions.modularSendField(null, 'locationTime'));
    }
    // Once we have an active ongoingCallId, add it to the datadog logger.
    const loggerOngoingCallId = datadogLogs.getLoggerGlobalContext().ongoingCallId;
    if (this.props.ongoingCallId && !loggerOngoingCallId) {
      logger = updateLogger({ ongoingCallId: this.props.ongoingCallId });
      logger.info(`Just added ongoingCallId to logger.`)
    }
    if (!this.props.ongoingCallId && loggerOngoingCallId) {
      logger.info('Removing ongoingCallId from logger.');
      logger = removeFromLogger('ongoingCallId');
    }
    logger = updateLogger({ queue: this.props.queue });
  }

  reloadQueue(event) {
    this.props.dispatch(queueActions.fetchQueue(this.props.targetQueue));
  }

  componentWillUnmount() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }    
    this.socket.removeListener('parker-left', this.parkerLeft);
    this.socket.removeListener('parker-joined', this.parkerJoined);
    this.socket = null;
  }

  parkerJoined(data) {
    logger.info(`Parker has joined the conference - ${data.callSid}`);
    this.props.dispatch(queueActions.modularSendField('onCall', 'parkerCallStatus'));
  }

  parkerLeft(data) {
    if (this.props.onCall === true) {
      logger.info(`Parker has left the conference - ${data.callSid}`);
      const bulkDispatch = { isParkerCallOnHold: false, parkerCallStatus: 'parkerLeft', isDialing: false };
      this.props.dispatch(queueActions.sendQueueObject(bulkDispatch));
    }
  }

  nextInQueue(event) {
    let now = Date.now();
    if (!this.props.isAnswered) {
      this.props.dispatch(queueActions.answerConn(this.props.username, now, this.props.socket));
    }
  }

  endCall(event) {
    this.props.dispatch(queueActions.endConn(this.props.username, this.props.from.locationId, this.props.from.dialToneToggleCall, this.props.from.dialToneExit));
  }
  
  shouldDisableGhost() {
    if (!this.props.answeredTime) {
      return;
    }
    let elapsedTime = moment.now() - this.props.answeredTime;
    if (elapsedTime >= 30000) {
      return this.props.dispatch(promptActions.modifyGhostCall(true));
    }
  }

  toggleParkerCallHold(currentCall) {
    currentCall.ongoingCallId = this.props.ongoingCallId;
    this.props.dispatch(queueActions.toggleParkerCallHold(currentCall, !this.props.isParkerCallOnHold));
  }

  startLocalTime() {
    const now = new Date();
    this.props.dispatch(authActions.modularSendField(moment(now).format("M/D/YY h:mm A"), 'localTime'));
  }

  startLocationTime() {
    const { timezone } = this.props.from;
    const now = moment().tz(timezone);
    this.props.dispatch(authActions.modularSendField(moment(now).format("M/D/YY h:mm A"), 'locationTime'));
  }

  render() {
    return (
      <div>
        <QueueList
          queue={this.props.queue}
          nextInQueue={this.nextInQueue}
          onCall={this.props.onCall}
          toggleParkerCallHold={this.toggleParkerCallHold}
          isParkerCallOnHold={this.props.isParkerCallOnHold}
          isManagerCallOnHold={this.props.isManagerCallOnHold}
          isCallingManager={this.props.isCallingManager}
          username={this.props.username}
          isDialing={this.props.isDialing}
          reload={this.reloadQueue}
          descriptor={(this.props.from && this.props.from.descriptor) ? this.props.from.descriptor : ''}
          locationTime={this.props.locationTime}
          localTime={this.props.localTime}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    queue: queueSelectors.pullQueue(state),
    onCall: queueSelectors.pullCallStatus(state),
    username: authSelectors.pullModular(state, 'username'),
    isDialing: queueSelectors.pullIsDialing(state),
    initialized: queueSelectors.pullInitialized(state),
    answeredTime: queueSelectors.pullAnsweredTime(state),
    isAnswered: queueSelectors.pullModular(state, 'isAnswered'),
    isParkerCallOnHold: queueSelectors.pullModular(state, 'isParkerCallOnHold'),
    isManagerCallOnHold: promptSelectors.pullModular(state, 'isManagerCallOnHold'),
    isCallingManager: queueSelectors.pullModular(state, 'isCallingManager'),
    ongoingCallId: queueSelectors.pullModular(state, 'ongoingCallId'),
    targetQueue: queueSelectors.pullModular(state, 'targetQueue'),
    localTime: authSelectors.pullModular(state, 'localTime'),
    locationTime: authSelectors.pullModular(state, 'locationTime')
  };
}

const queueScreenSocket = (props) => (
  <SocketContext.Consumer>
    {socket => <QueueScreen {...props} socket={socket} />}
  </SocketContext.Consumer>
)

export default withRouter(connect(mapStateToProps)(queueScreenSocket));
