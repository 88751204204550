import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptActions from '../../store/prompt/actions';
import * as promptSelectors from '../../store/prompt/reducer';
import * as queueSelectors from '../../store/queue/reducer';
import * as authSelectors from '../../store/auth/reducer';
import ManagersList from './ManagersList';
import ContactManagerInfo from './ContactManagerInfo';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import DirectionText from '../../components/DirectionText';
import moment from 'moment-timezone';
import { Card, CardContent, Typography, Grid, Tooltip } from '@material-ui/core';
import { titleCase } from "title-case";
import ScriptText from '../../components/ScriptText';
import _ from 'lodash';

class ContactManagers extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { formData: {},showParkerInfoForm: false, showCallDetails: false }
  }

  async componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    const { contactManagerInfoForm } = this.props.from;
    if (contactManagerInfoForm) {
      const formData = await this.props.dispatch(promptActions.shouldShowForm(contactManagerInfoForm, this.props.issue));
      if (!formData) {
        this.setState({ showParkerInfoForm: false });
      } else {
        this.setState({ formData: formData, showParkerInfoForm: true });
      }
    } 
    let mountTime = moment().valueOf();
    this.props.dispatch(promptActions.modularSendField(mountTime, 'contactManagersInitTime'));
    this.props.dispatch(promptActions.modularSendField(false, 'managerVendConfirmation10s'));
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  shouldDisableNextSteps() {
    // If the list increases we should move this to DB.
    if ([3, 4].includes(this.props.targetQueue)) {
      return false;
    }
    if (this.props.from.ignoreContactManagersLockout && this.props.apbLockedOut && !this.props.invalidAccessTime && !this.props.wrongFacilityLockedOut) {
      return false;
    }
    if (this.props.managerVendConfirmation10s) {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    let unmountTime = moment().valueOf();

    let visitLength = (Number(unmountTime) - Number(this.props.contactManagersInitTime));

    let totalVisitLength = this.props.contactManagersTotalTime + visitLength;

    this.props.dispatch(promptActions.modularSendField(totalVisitLength, 'contactManagersTotalTime'));
  }

  sendManagerActionRequest(value, targetRoute) {
    if (value === 'managerRequestedVendGate') {
      this.props.dispatch(promptActions.modularSendField(true, 'managerRequestedVendGate'));
    }
    this.props.dispatch(promptActions.modularSendField(value, 'managerActionRequest'));
    this.props.history.push(`/${targetRoute}`);
  }

  shouldLineHeightWarning() {
    if (this.props.apbLockedOut || this.props.invalidAccessTime || this.props.wrongFacilityLockedOut) {
      return true;
    }
    return false;
  }

  doNotCallManager() {
    const {
      timezone,
      doNotDisturbManagerHours,
      allowContactManager,
      doNotDisturbManagerVend
    } = this.props.from;
    const { issue } = this.props;
    if (allowContactManager) {
      if (issue.subtype && issue.subtype == 'rateDispute') {
        return !allowContactManager.includes(issue.subtype);
      }
      return !allowContactManager.includes(issue.type);
    } else {
      if (issue.type == 'monthly' && doNotDisturbManagerVend) {
        this.props.dispatch(promptActions.modularSendField(true, 'apbLockedOut'));
      }
      const managerLocTime = moment().tz(timezone);
      const hourNow = moment(managerLocTime).hour();
      return doNotDisturbManagerHours.includes(hourNow);
    }
  }

  getCustomButtons(btn) {
    const { label, action, routeTo } = btn;
    const { customButtons } = this.props.from;
    if (customButtons) {
      const thisComponent = customButtons.find(data => { return data.type === this.props.btnFrom });
      if (thisComponent) {
        const { buttons } = thisComponent;
        if (buttons && buttons.includes(action)) {
          return (
            <Grid item>
              <RaisedButton 
                label={label} 
                disabled={process.env.REACT_APP_ENV == 'production' ? this.shouldDisableNextSteps() : false}
                onClick={() => this.props.btnClick(action, routeTo)} 
                />
            </Grid>
          )
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  }

  showContactManager() {
    this.setState({ showParkerInfoForm: false, showCallDetails: true });
  }

  shouldAllowVend() {
    const disableVendRoutes = [
      'Machine ate credit card',
      'Machine did not give change',
      'Gate is broken / damaged / vandalized'
    ]
    return disableVendRoutes.includes(this.props.malfunctionValue);
  }

  getLockoutDetails(detailsType) {
    const { thirdPartyVendorName, thirdPartyReservationNumber, contactNumber, selectedParkerName, selectedParkerCardNumber, selectedParkerMonthlyStatus, companyName, parkerNotFound } = this.props;
    if (parkerNotFound) {
      return;
    }
    const parker = this.props.selectedParker || null;
    const parkerName = parker.FName ? titleCase(parker.FName.toLowerCase()) + " " +  (parker.LName ? titleCase(parker.LName.toLowerCase()) : null) : (selectedParkerName ? titleCase(selectedParkerName.toLowerCase()) : null);
    switch (detailsType) {
      case 'lockoutDetails':
        if (!parker || (Object.keys(parker) && Object.keys(parker).length == 0)) { console.log('weee'); return; }
        return (
          <Card className="apb-alert-violation">
            <CardContent style={{ padding: '12px 14px' }}>
              <Typography style={{ textAlign: 'left', fontSize: '14px', position: 'fixed' }}>LOCKOUT DETAILS</Typography>
              <div style={{ marginTop: 10 }}>
                {parkerName && <Typography variant="body2"> {`Parker Name: ${parkerName}`} </Typography>}
                {parker.Badge 
                ? 
                  <Typography variant="body2"> {`Badge: ${parker.Badge}`} </Typography>
                :  
                  <Fragment>
                    {selectedParkerCardNumber && <Typography variant="body2"> {`Card Number: ${selectedParkerCardNumber}`}  </Typography>}
                  </Fragment>
                }
                {parker.Status && <Typography variant="body2"> {`Status:  ${titleCase(parker.Status.toLocaleLowerCase())}`} </Typography>}
                {companyName && <Typography variant="body2"> {`Company Name:  ${titleCase(companyName.toLocaleLowerCase())}`} </Typography>}
                {selectedParkerMonthlyStatus && <Typography variant="body2"> {`Status: ${titleCase(selectedParkerMonthlyStatus.toLocaleLowerCase())}`} </Typography>}
                { this.props.apbLockedOut && this.props.from.antiPassbackIntegrated &&
                  <Typography variant="body2">{'This parker is experiencing anti-passback lockout!'}</Typography>
                }
                { this.props.invalidAccessTime &&
                  <Typography variant="body2">{'This parker is attempting to use their pass at an invalid time!'}</Typography>
                }
                {
                  (this.props.wrongFacilityLockedOut || this.props.invalidAccessLocation) &&
                  <Typography variant="body2">{'This parker is attempting to use their pass at the wrong facility!'}</Typography>
                }
                {
                  this.props.terminationLockedOut &&
                  <Typography variant="body2">{'The parkers card is blocked!'}</Typography>
                }
              </div>
            </CardContent>
          </Card>
        )
        break;
      case 'prepaidDetails':
        return (
          <Card>
            <CardContent style={{ padding: '12px 14px' }}>
              <Typography style={{ textAlign: 'center', fontSize: '20px', marginBottom: 10 }}>Prepaid Details</Typography>
              <Grid container xs={12} direction="row" style={{ margin: '0 auto', textAlign: 'center'}}>
                <Grid item xs={3}>
                  <Typography variant="body2"> {thirdPartyVendorName && `Vendor: ${thirdPartyVendorName}`} </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">  {thirdPartyReservationNumber && `Registration Number: ${thirdPartyReservationNumber}`} </Typography>  
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">  {contactNumber && `Contact Number: ${contactNumber}`} </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">  {selectedParkerName && `Parker Name: ${selectedParkerName}`} </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )
        break;
      case 'callDetails':
        return (
          <Card className="apb-alert-violation">
            <CardContent style={{ padding: '12px 14px' }}>
              <Typography style={{ textAlign: 'left', fontSize: '14px', position: 'fixed' }}>CALL DETAILS</Typography>
              {this.props.parkerName && <Typography variant="body2"> {`Parker Name: ${this.props.parkerName}`} </Typography>}
              {this.props.ticketNumber && <Typography variant="body2"> {`Ticket Number: ${this.props.ticketNumber}`} </Typography> }
              {this.props.parkerFeeDue && <Typography variant="body2"> {`Fee Due: ${this.props.parkerFeeDue}`} </Typography> }
              {this.props.machineMessage && <Typography variant="body2"> {`Entrance Time: ${moment(this.props.entranceDateTime).format('LLL')}`} </Typography>}
              {this.props.validationSource && <Typography variant="body2"> {`Validation Source: ${this.props.validationSource}`} </Typography>}
              {this.props.validationDuration && <Typography variant="body2"> {`Validation Duration: ${this.props.validationDuration}`} </Typography>}
              {this.props.machineMessage && <Typography variant="body2"> {`Message on the Machine: ${this.props.machineMessage}`} </Typography>}
            </CardContent>
          </Card>
        )
        break;
    }
  }

  render() {
    return (
      <Fragment> 
        {this.state.showParkerInfoForm 
        ?
          <Fragment>
            <ContactManagerInfo formData={this.state.formData} showContactManager={this.showContactManager} />
          </Fragment>
        :
          <Fragment>
            {this.props.issue.type == 'monthly' && this.getLockoutDetails('lockoutDetails')}
            {this.state.showCallDetails && this.getLockoutDetails('callDetails')}
            {this.props.issue.subtype === "prepaidGuest" && this.getLockoutDetails('prepaidDetails')}

            {this.props.issue.subtype === "ticketAcceptanceIssue" &&
              <Card className="apb-alert-violation">
                <CardContent style={{ padding: '12px 14px' }}>
                  {this.props.ticketNumber && <Typography variant="body2"> {`${this.props.from.cardTicket ? "CC Last 4 Digits" : "Ticket Number"}: ${this.props.ticketNumber}`} </Typography> }
                </CardContent>
              </Card>
            }

            {this.props.from.doNotDisturbManagerHours && this.doNotCallManager() 
            ?
              <Paper zDepth={1} className="paper-padding">
                <DirectionText>
                  <span style={{ color: 'Red' }}> Note: </span>
                  { this.props.apbLockedOut ?
                    `Please don't call managers at this time. Proceed to vend gate directly.` :
                    `Please don't call managers at this time. Proceed to fill ticket discrepancy and then vend.`
                  }
                </DirectionText> 
                <div style={{ marginTop: 15 }}>
                  { this.props.apbLockedOut ?
                    <RaisedButton
                      label="Vend Gate"
                      disabled={this.props.from.doNotDisturbManagerHours ? !(this.doNotCallManager()) : this.shouldDisableNextSteps()}
                      onClick={() => { this.sendManagerActionRequest('noManagerResponse', 'vendGate')}}
                    />
                    :
                    <RaisedButton
                      label="Ticket Discrepancy"
                      disabled={this.props.from.doNotDisturbManagerHours ? !(this.doNotCallManager()) : this.shouldDisableNextSteps()}
                      onClick={() => { this.sendManagerActionRequest('noManagerResponse', 'ticketDiscrepancy')}}
                    />
  
                  }
                  <RaisedButton 
                    label="Complete Call"
                    onClick={() => { this.sendManagerActionRequest('exitWithoutManagerAction', 'confirmationScreen')}} 
                  />
                </div>
              </Paper>
            :
              <Fragment>
                {this.props.issue.type == 'monthly' && this.props.from.monthlyPullTicket && this.props.from.laneType === "Entrance" 
                ?
                  <Fragment>
                    <ScriptText>
                      {this.props.from.monthlyRedirect ? this.props.from.monthlyRedirect : "Please pull a ticket to enter the parking garage."} 
                    </ScriptText>
                    <div style={{ marginTop: 15 }}>
                      <RaisedButton 
                        label="Complete Call" 
                        onClick={() => { this.sendManagerActionRequest('exitWithoutManagerAction', 'confirmationScreen')}} 
                      />
                    </div>
                  </Fragment>
                :
                  <Fragment>
                    <ManagersList managers={this.props.from.managers} /> 
                    <Grid container justifyContent="center" spacing={2} alignContent="center" alignItems="center" style={{ margin: '0 auto', textAlign: 'center' }}>
                      <Grid item>
                        <RaisedButton 
                          label="Complete Call" 
                          onClick={() => { this.sendManagerActionRequest('exitWithoutManagerAction', 'confirmationScreen')}} 
                        />
                      </Grid>
                      {this.props.showButtons.includes('vendGate') && 
                        <Grid item>
                          <Tooltip title={this.shouldAllowVend() ? 'Unable to vend gate for this issue' : ''} placement='top'>
                            <RaisedButton 
                              label="Manager Requested Gate Vend" 
                              disabled={(process.env.REACT_APP_ENV == 'production' && this.shouldDisableNextSteps()) && this.shouldAllowVend()}
                              onClick={() => { this.sendManagerActionRequest('managerRequestedVendGate', 'vendGate')}} 
                          />
                          </Tooltip>
                        </Grid>
                      }
                      {this.props.showButtons.includes('managerAssisting') && 
                        <Grid item>
                          <RaisedButton 
                            label="Manager Assisting" 
                            disabled={process.env.REACT_APP_ENV == 'production' ? this.shouldDisableNextSteps() : false}
                            onClick={() => { this.sendManagerActionRequest('managerAssisting', 'confirmationScreen')}} 
                          />
                        </Grid>
                      }
                      
                      {this.props.showButtons.includes('ticketDiscrepancy') && 
                        <Grid item>
                          <RaisedButton 
                            label="No Manager Response"  
                            disabled={process.env.REACT_APP_ENV !== 'production' ? false : this.props.from.alwaysEnableTD ? false : this.shouldDisableNextSteps()}
                            // Oh Airport is the first to use this. They want us to vend the gate if no one answers.
                            onClick={this.props.from.managerNoAnswerVend || (this.props.issue.type == 'monthly' && this.props.from.monthlyNoAnswerVend)?
                              () => { this.sendManagerActionRequest('noManagerResponse', 'vendGate')}
                              :
                              () => { this.sendManagerActionRequest('noManagerResponse', 'ticketDiscrepancy')}}
                          />
                        </Grid>
                      }  
      
                      {this.props.showButtons.includes('showCustomButton') && 
                        <Fragment>
                          {_.map(this.props.customButtonData, this.getCustomButtons)}
                        </Fragment>
                      }   
                    </Grid>            
                  </Fragment>
                }
              </Fragment>
            }
          </Fragment>
        }

      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    contactManagersInitTime: promptSelectors.pullModular(state, 'contactManagersInitTime'),
    contactManagersTotalTime: promptSelectors.pullModular(state, 'contactManagersTotalTime'),
    selectedParkerName: promptSelectors.pullModular(state, 'selectedParkerName'),
    selectedParkerCardNumber: promptSelectors.pullModular(state, 'selectedParkerCardNumber'),
    selectedParkerMonthlyStatus: promptSelectors.pullModular(state, 'selectedParkerMonthlyStatus'),
    selectedParker: promptSelectors.pullModular(state, "selectedParker"),
    companyName: promptSelectors.pullModular(state, "companyName"),
    managerVendConfirmation10s: promptSelectors.pullModular(state, 'managerVendConfirmation10s'),
    apbLockedOut: promptSelectors.pullModular(state, 'apbLockedOut'),
    invalidAccessTime: promptSelectors.pullModular(state, 'invalidAccessTime'),
    wrongFacilityLockedOut: promptSelectors.pullModular(state, 'wrongFacilityLockedOut'),
    terminationLockedOut: promptSelectors.pullModular(state, 'terminationLockedOut'),
    thirdPartyVendorName: promptSelectors.pullModular(state, 'thirdPartyVendorName'), 
    thirdPartyReservationNumber: promptSelectors.pullModular(state, 'thirdPartyReservationNumber'),
    contactNumber: promptSelectors.pullModular(state, 'contactNumber'),
    issue: promptSelectors.pullIssue(state),
    invalidAccessLocation: promptSelectors.pullModular(state, 'invalidAccessLocation'),
    ticketNumber: promptSelectors.pullModular(state, 'ticketNumber'),
    validationSource: promptSelectors.pullModular(state, 'validationSource'),
    validationDuration: promptSelectors.pullModular(state, 'validationDuration'),
    parkerFeeDue: promptSelectors.pullModular(state, 'parkerFeeDue'),
    machineMessage: promptSelectors.pullModular(state, 'machineMessage'),
    parkerEntranceTime: promptSelectors.pullModular(state, 'parkerEntranceTime'),
    parkerNotFound: promptSelectors.pullModular(state, 'parkerNotFound'),
    targetQueue: queueSelectors.pullModular(state, 'targetQueue'),
    malfunctionValue: promptSelectors.pullModular(state, 'malfunctionValue')
  };
}

export default withRouter(connect(mapStateToProps)(ContactManagers));