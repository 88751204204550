import React, { useState, Fragment } from 'react';
import Button from '@mui/material/Button'
import PrepaidDisabledEntrance from './PrepaidNodes/PrepaidDisabledEntrance';
import NoManagerResponsePullTicket from './PrepaidNodes/NoManagerResponsePullTicket';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { pullModular as promptPullModular } from '../../../store/prompt/reducer';
import { pullModular as queuePullModular } from '../../../store/queue/reducer';
import PromptWrapper from './PromptWrapper';
import PrepaidDisabledExit from './PrepaidNodes/PrepaidDisabledExit';
import LogPrepaidDetails from './PrepaidNodes/LogPrepaidDetails';
import FreshContactManagers from './PrepaidNodes/FreshContactManagers';
import { disableContinue } from './lib/contactManagerFunctions';
import { logPrepaidDetailsSubmit } from './lib/logPrepaidDetailsFunctions';

const PrepaidNotAllowed = (props) => {

  const [node, setNode] = useState('0');
  const [howParkerEntered, setHowParkerEntered] = useState('')

  const {
    thirdPartyVendorId,
    thirdPartyReservationNumber,
    thirdPartyVendorName,
    selectedParkerName,
    dispatch,
    isParkerCallOnHold,
    history,
    from,
    managerCalledFor10s,
    setManagerCalledFor10s,
    managerCalled,
    setManagerCalled
  } = props;

  return (
    //since this is our one "default" prepaid flow, it is the only one to contain instructions for both Entrances and Exits/Paystations
    <PromptWrapper>
      {from.laneType == 'Entrance' &&
        <Fragment>
          {node == '0' &&
            <Fragment>
              <PrepaidDisabledEntrance />
              <Button className='button-style' variant='outlined' onClick={() => { history.push('/confirmationScreen') }}>Complete Call</Button>
              <Button className='button-style' variant='outlined' onClick={() => { setNode('1') }}>Contact Managers</Button>
            </Fragment>
          }

          {node == '1' &&
            <Fragment>
              <FreshContactManagers
                managerCalledFor10s={managerCalledFor10s}
                setManagerCalledFor10s={setManagerCalledFor10s}
                managerCalled={managerCalled}
                setManagerCalled={setManagerCalled}
              />
              <Button className='button-style' variant='outlined' onClick={() => { history.push('/confirmationScreen') }}>Complete Call</Button>
              <Button className='button-style' variant='outlined' onClick={() => {
                disableContinue({
                  dispatch,
                  isParkerCallOnHold,
                  action: () => { history.push('/confirmationScreen') },
                  managerCalledFor10s,
                  managerCalled
                })
              }}>
                Manager Assisting
              </Button>
              <Button className='button-style' variant='outlined' onClick={() => {
                disableContinue({
                  dispatch,
                  isParkerCallOnHold,
                  action: () => { setNode('2') },
                  managerCalledFor10s,
                  managerCalled
                })
              }}>
                No Manager Response
              </Button>
              <Button className='button-style' variant='outlined' onClick={() => {
                disableContinue({
                  dispatch,
                  isParkerCallOnHold,
                  action: () => { history.push('/vendGate') },
                  managerCalledFor10s,
                  managerCalled
                })
              }}>
                Manager Requested Gate Vend
              </Button>
            </Fragment>
          }
          {node == '2' &&
            <Fragment>
              <NoManagerResponsePullTicket />
              <Button className='button-style' variant='outlined' onClick={() => { history.push('/confirmationScreen') }}>Complete Call</Button>
            </Fragment>
          }
        </Fragment>
      }
      {from.laneType !== 'Entrance' &&
        <Fragment>
          {node == '0' &&
            <Fragment>
              <PrepaidDisabledExit />
              <Button className='button-style' variant='outlined' onClick={() => { history.push('/confirmationScreen') }}>Complete Call</Button>
              <Button className='button-style' variant='outlined' onClick={() => { setNode('1'); setHowParkerEntered('pulling a ticket')}}>Pulled A Ticket</Button>
              <Button className='button-style' variant='outlined' onClick={() => { setNode('1'); setHowParkerEntered('scanning their reservation barcode')}}>Scanned Barcode</Button>
              <Button className='button-style' variant='outlined' onClick={() => { setNode('1'); setHowParkerEntered('the gate being open on entrance')}}>Gate Open on Entrance</Button>
            </Fragment>
          }
          {node == '1' &&
            <Fragment>
              <LogPrepaidDetails />
              <Button className='button-style' variant='outlined' onClick={() => { history.push('/confirmationScreen') }}>Complete Call</Button>
              <Button className='button-style' variant='outlined' onClick={() => {
                logPrepaidDetailsSubmit({
                  dispatch,
                  thirdPartyVendorId,
                  thirdPartyReservationNumber,
                  selectedParkerName,
                  action: () => { setNode('2') }
                })
              }}>
                Contact Managers
              </Button>
            </Fragment>
          }
          {node == '2' &&
            <Fragment>
              <p>
                <span className='contact-managers-header'>{' '}Reservation Source:{' '}</span>{thirdPartyVendorName},
                <span className='contact-managers-header'>{' '}Reservation Number:{' '}</span>{thirdPartyReservationNumber},
                <span className='contact-managers-header'>{' '}Parker Name:{' '}</span>{selectedParkerName}
              </p>
              <p>{`Parker entered via ${howParkerEntered}.`}</p>
              <FreshContactManagers
                managerCalledFor10s={managerCalledFor10s}
                setManagerCalledFor10s={setManagerCalledFor10s}
                managerCalled={managerCalled}
                setManagerCalled={setManagerCalled}
              />
              {/* nevermind button is Complete Call */}
              <Button className='button-style' variant='outlined' onClick={() => { history.push('/confirmationScreen') }}>Complete Call</Button>
              <Button className='button-style' variant='outlined' onClick={() => {
                disableContinue({
                  dispatch,
                  isParkerCallOnHold,
                  action: () => { history.push('/confirmationScreen') },
                  managerCalledFor10s,
                  managerCalled
                })
              }}>
                Manager Assisting
              </Button>
              <Button className='button-style' variant='outlined' onClick={() => {
                disableContinue({
                  dispatch,
                  isParkerCallOnHold,
                  action: () => { history.push('/vendGate') },
                  managerCalledFor10s,
                  managerCalled
                })
              }}>
                Manager Requested Gate Vend
              </Button>
              <Button className='button-style' variant='outlined' onClick={() => {
                disableContinue({
                  dispatch,
                  isParkerCallOnHold,
                  action: () => { history.push('/vendGate') },
                  managerCalledFor10s,
                  managerCalled
                })
              }}>
                No Manager Response
              </Button>
            </Fragment>
          }
        </Fragment>
      }

    </PromptWrapper>
  )
}
function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from'),
    thirdPartyVendorId: promptPullModular(state, 'thirdPartyVendorId'),
    thirdPartyReservationNumber: promptPullModular(state, "thirdPartyReservationNumber"),
    thirdPartyVendorName: promptPullModular(state, 'thirdPartyVendorName'),
    selectedParkerName: promptPullModular(state, 'selectedParkerName'),
    isParkerCallOnHold: queuePullModular(state, 'isParkerCallOnHold')
  }
}

export default withRouter(connect(mapStateToProps)(PrepaidNotAllowed));