import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import { TextField } from '@material-ui/core';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as promptActions from '../../../store/prompt/actions';
import * as authSelectors from '../../../store/auth/reducer';
import ContactManagers from '../../Prompt/ContactManagers';
import ScriptText from '../../../components/ScriptText';
import DirectionText from '../../../components/DirectionText';
import PromptBox from '../../../components/promptBox';
import RaisedButton from 'material-ui/RaisedButton';
import _ from 'lodash';

class TibaLPRVerifyTicket extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    const loadOptions = inputValue => this.tibaGetLicensePlate(inputValue);
    this.debouncedLoadOptions = _.debounce(loadOptions, 1000, {
      leading: true
    });

    this.state = {
      renderComponent: {
        showLPRSearch: true,
        showVerifyTicketResult: false,
        showManagersList: false,
      },
      licensePlate: null, lostTicketNumber: null
    }
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  updateState(event, target) {
    this.setState({ [target]: event.target.value });
  }

  async tibaGetLicensePlate(value) {
    return new Promise(async (resolve, reject) => {
      const result = await this.props.dispatch(promptActions.tibaGetLicensePlate({ licensePlate: value, locationIdParcsDb: this.props.from.locationIdParcsDb }));
      const { success, lprData, message } = result.data;
      if (success && lprData) {
        const filtered = _.filter(lprData, option => option.label);
        resolve(filtered);
      } else {
        this.props.dispatch(promptActions.showSnackBar({ type: 'error', message: message }));
      }
    });
  }

  async saveLicensePlate(data) {
    if (!data) {
      return;
    }
    await this.setState({ 
      licensePlate: data.label,
      lostTicketNumber: data.value
    });
  }

  async tibaLPRVerifyTicket() {
    this.props.dispatch(promptActions.modularSendField(true, 'showLoader'));
    const { licensePlate, lostTicketNumber } = this.state;
    if (!licensePlate) {
      this.props.dispatch(promptActions.modularSendField(false, 'showLoader'));
      return this.props.dispatch(promptActions.showSnackBar({ type: 'error', message: `Licese Plate is required.` }));
    } else {
      const { apiParams, rateList, gracePeriodInt, timezone, baseURL } = this.props.from;
      const data = { apiParams, rateList, gracePeriodInt, ticketNumber: lostTicketNumber, timezone, baseURL }; 
      const result = await this.props.dispatch(promptActions.tibaVerifyIfTicketClosed(data));
      this.props.dispatch(promptActions.modularSendField(lostTicketNumber, 'ticketNumber'));
      const { notFound } = result.data;
      if (notFound) {
        this.showComponent(['showVerifyTicketResult']);
      } else {
        this.props.history.push('/vendGate')
      }
    }
    this.props.dispatch(promptActions.modularSendField(false, 'showLoader'));
  }


  showComponent(toRender) {
    const allComponents = {...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'showPushRateForm':
        this.props.history.push('/tibaAPI/tibaManagerPushRate');
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  render() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        {this.state.renderComponent.showLPRSearch &&
          <Fragment>
            {this.props.from.laneType == "Paystation" 
            ? 
              <Fragment>
                <DirectionText> Ask the parker to call from an exit. Once they call again from the exit, take their License Plate and verify if they paid. </DirectionText>
                <div style={{ marginTop: 15 }}>
                  <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                </div>
              </Fragment>
            :
              <Fragment>
                <ScriptText>
                  May I please have your License Plate? 
                </ScriptText>
                <AsyncSelect
                  className="MuiOutlinedInput-inputMarginDense max-z-index"
                  placeholder="Enter License Plate"
                  isClearable
                  value={{ value: this.state.licensePlate, label: this.state.licensePlate }}
                  loadOptions={inputValue => this.debouncedLoadOptions(inputValue)}
                  onChange={this.saveLicensePlate}
                />
                <div style={{ marginTop: 15 }}>
                  <RaisedButton label="Submit" onClick={() => this.tibaLPRVerifyTicket()} />
                  <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                </div>
              </Fragment>
            }
          </Fragment>
        }

        {this.state.renderComponent.showVerifyTicketResult &&
          <Fragment>
            <DirectionText> This ticket is not paid, please contact manager for further assistance </DirectionText>
            <div style={{ marginTop: 15 }}>
              <RaisedButton label="Contact Managers" onClick={() => this.showComponent(['showManagersList'])} />
            </div>
          </Fragment>
        }

        {this.state.renderComponent.showManagersList && 
          <ContactManagers 
            showButtons={['ticketDiscrepancy', 'vendGate', 'managerAssisting', 'showCustomButton']} 
            btnClick={this.btnClick}
            btnFrom="lostTicket"
            customButtonData={[
              {
                "label": "Manager Asked to Push Rate",
                "action": "showPushRateForm"
              },
            ]}
          />
        }
      </PromptBox>
    )
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username'),
    ticketNumber: promptSelectors.pullModular(state, 'ticketNumber')
  };
}

export default withRouter(connect(mapStateToProps)(TibaLPRVerifyTicket));