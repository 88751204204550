import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import autoBind from 'react-autobind';
import _ from 'lodash';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Paper, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from 'material-ui/CircularProgress';
import * as promptActions from '../../store/prompt/actions';
import * as queueActions from '../../store/queue/actions';
import * as queueSelectors from '../../store/queue/reducer';
import * as authSelectors from '../../store/auth/reducer';
import * as promptSelectors from '../../store/prompt/reducer';
import SocketContext from '../../services/socketContext'
import * as config from '../../lib/config';
import { retrieveLogger } from '../../lib/logger';
let logger = retrieveLogger();

class ManagersList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.disableTimeout = null;
    this.socket = null;
  }

  componentDidMount() {
    this.socket = this.props.socket;    
    this.socket.on('manager-joined', this.managerJoined);    
    this.socket.on('manager-left', this.managerLeft);
  }

  componentWillUnmount() {
    this.socket.removeListener('manager-joined', this.managerJoined);
    this.socket.removeListener('manager-left', this.managerLeft);
    this.socket = null;
  }

  managerJoined(data) {
    logger.info(`Manager has joined the conference - ${data.callSid}`);
    this.props.dispatch(promptActions.modularSendField(true, 'enableManagerHoldCallButton'));
    const extension = this.props.callingManagerExtension;
    if (extension) {
      // Wait two seconds, and then dispatch the extension.
      return new Promise((resolve) => { setTimeout(() => { resolve(); }, 2000); }).then(() => {
        this.props.dispatch(queueActions.sendDigits(extension));
      });
    } 
  }

  managerLeft(data) {
    logger.info(`Manager has left the conference - ${data.callSid}`);
    this.props.dispatch(promptActions.modularSendField(false, 'enableManagerHoldCallButton'));
    this.props.dispatch(promptActions.modularSendField(false, 'isManagerCallOnHold'));
    const bulkDispatch = { isCallingManager: false, callingManagerExtension: null, callingManagerIndex: null, callingManagerName: '', callingManagerNumber: null };
    this.props.dispatch(queueActions.sendQueueObject(bulkDispatch));
  }

  async managerCall(manager, index, callEvent) {
    // If number has extension then dispatch and update the state to send digits.
    if (callEvent === 'calling') {
      this.props.dispatch(promptActions.modularSendField(false, 'managerVendConfirmation10s'));
    }
    const dialReadyNumber = `+1${(manager.number).replace(/[^\d]/g, '')}`;
    try {
      const managerCallData = {
        "number": dialReadyNumber, "ongoingCallId": this.props.ongoingCallId, "ongoingConferenceSid": this.props.ongoingConferenceSid,
        "username": this.props.username, "managerName": manager.name, "isCallingManager": !this.props.isCallingManager,
        "ongoingManagerCallSid": this.props.ongoingManagerCallSid || null
      };
      this.props.dispatch(queueActions.managerCall(managerCallData));
      this.props.dispatch(promptActions.modularSendField(manager.name, 'selectedManagerName'));
      const bulkDispatch = {
        callingManagerName: manager.name, callingManagerNumber: manager.number, callingManagerIndex: index, 
        isCallingManager: !this.props.isCallingManager, callingManagerExtension: manager.extension
      };
      this.props.dispatch(queueActions.sendQueueObject(bulkDispatch));
    } catch (error) {
      logger.info(`Error making manager call`);
      logger.info(error);
    }
  }

  toggleManagerCallHold(conferenceSid, managerCallSid, toggle) {
    this.props.dispatch(promptActions.modularSendField(toggle, 'isManagerCallOnHold'));
    this.props.dispatch(promptActions.toggleManagerCallHold(conferenceSid, managerCallSid, toggle));
  }

  generateTableRowColumn(manager, index) {
    if (process.env.REACT_APP_MANAGER_TEST_NUMBER != 'false') {
      manager.number = process.env.REACT_APP_MANAGER_TEST_NUMBER;
    }
    return (      
      <Fragment key={(index).toString()}>
        {!this.props.isCallingManager 
        ? 
          (
            <Fragment>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">{manager.name}</TableCell>
                  <TableCell align="center">{manager.availability || 'N/A'}</TableCell>
                  <TableCell align="center">
                    {manager.number 
                    ? 
                      ( 
                        <Tooltip title={manager.number} placement="top">
                          <div>
                            <Button
                              size="small"
                              style={{ backgroundColor: this.props.isParkerCallOnHold ? 'Green' : 'Grey' }}
                              disabled={!this.props.isParkerCallOnHold} 
                              variant="contained" 
                              title={manager.number} 
                              onClick={() => this.managerCall(manager, index, 'calling')}
                            >
                              <i className="material-icons" style={{ color: 'white', fontSize: 25 }}>call</i>
                            </Button>
                          </div>
                        </Tooltip>
                      ) 
                    : 
                      (
                        'N/A'
                      )
                    }       
                  </TableCell>
                  <TableCell align="center">{manager.email || 'N/A'}</TableCell>
                  <TableCell align="center">{manager.callOrder || 'N/A'}</TableCell>
                </TableRow>
              </TableBody>
            </Fragment>
          ) 
        : 
          (
            <Fragment>              
              {this.props.callingManagerIndex === index &&
                <Grid container item xs={12} style={{ padding: 10, margin: 'auto' }}>
                  <Grid item xs={6} style={{ margin: 'auto', textAlign: 'left' }}>                      
                    {`Calling Manager ${this.props.callingManagerName}`}
                  </Grid>
                  <Grid item xs={3}>
                    {manager.number 
                    ?
                      (
                        <Tooltip title={manager.number} placement="top">
                          <div>
                            <Button                            
                              size="small"
                              variant="contained"
                              style={{ 
                                backgroundColor: !this.props.managerVendConfirmation10s ? 'Grey' : (this.props.isManagerCallOnHold ? 'Grey' : 'Red')  
                              }}
                              disabled={!this.props.managerVendConfirmation10s ? true : (this.props.isManagerCallOnHold ? true : false) }
                              title={manager.number} 
                              onClick={ () => this.managerCall(manager, index, 'endCall') }
                            >   
                              <i className="material-icons" style={{ color: 'white', fontSize: 25 }}>call_end</i>
                            </Button>
                          </div>
                        </Tooltip>
                      )
                    :
                      (
                        'N/A'
                      )
                    }
                  </Grid>
                  <Grid item xs={3}>
                    {!this.props.isManagerCallOnHold 
                    ? 
                      (
                        <Tooltip title="Hold Call" placement="top">
                          <div>
                            <Button                              
                              size="small"
                              variant="contained"                              
                              style={{ backgroundColor: this.props.enableManagerHoldCallButton ? 'Orange' : 'Grey' }}
                              disabled={this.props.enableManagerHoldCallButton ? false : true }
                              onClick={() => this.toggleManagerCallHold(this.props.ongoingConferenceSid, this.props.ongoingManagerCallSid, !this.props.isManagerCallOnHold)}
                            >                     
                              <i className="material-icons" style={{ fontSize: 25 }}>pause</i>
                            </Button>
                          </div>
                        </Tooltip>
                      ) 
                    : 
                      (
                        <Tooltip title="Resume Call" placement="top">
                          <div>
                            <Button                              
                              size="small"
                              variant="contained"
                              style={{ backgroundColor: this.props.isParkerCallOnHold ? 'Green' : (this.props.parkerCallStatus === 'parkerLeft' ? 'Green' : 'Grey') }}
                              disabled={this.props.isParkerCallOnHold ? false : (this.props.parkerCallStatus === 'parkerLeft' ? false : true)}                           
                              onClick={() => this.toggleManagerCallHold(this.props.ongoingConferenceSid, this.props.ongoingManagerCallSid, !this.props.isManagerCallOnHold)}
                            >
                              <i className="material-icons" style={{ fontSize: 25 }}>play_arrow</i>
                            </Button>
                          </div>
                        </Tooltip>
                      )
                    }
                  </Grid>
                </Grid>              
              }  
            </Fragment>
          ) 
        }
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        <Paper style={{ marginBottom: 20 }}>
          {this.props.managers && this.props.managers[0] 
          ? 
            ( 
              <Table>
                <TableHead style={{ backgroundColor: '#297EB6' }}>
                  <TableRow>                    
                    {!this.props.isCallingManager && 
                      <Fragment>
                        <TableCell style={{ color: 'White', fontWeight: 'bolder' }} align="left">Name</TableCell>
                        <TableCell style={{ color: 'White', fontWeight: 'bolder' }} align="center">Availability</TableCell>                     
                        <TableCell style={{ color: 'White', fontWeight: 'bolder' }} align="center">Number</TableCell>
                        <TableCell style={{ color: 'White', fontWeight: 'bolder' }} align="center">Email</TableCell>
                        <TableCell style={{ color: 'White', fontWeight: 'bolder' }} align="center">Call Order</TableCell>
                      </Fragment>
                    }                    
                  </TableRow>
                </TableHead>
                <Fragment>
                  {_.map(this.props.managers, this.generateTableRowColumn)}
                  {this.props.noneOption 
                  ? 
                    this.generateNoneOption 
                  : 
                    null
                  }
                </Fragment>
              </Table>
            ) 
          : 
            (
              <CircularProgress style={{ margin: 'calc(50% - 75px) 0 0 0' }} />
            )
          }
        </Paper>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: authSelectors.pullModular(state, 'username'),
    isCallingManager: queueSelectors.pullModular(state, 'isCallingManager'),
    ongoingCallId: queueSelectors.pullModular(state, 'ongoingCallId'),
    ongoingParkerCallSid: queueSelectors.pullModular(state, 'ongoingParkerCallSid'),
    ongoingConferenceSid: queueSelectors.pullModular(state, 'ongoingConferenceSid'),
    ongoingManagerCallSid: queueSelectors.pullModular(state, 'ongoingManagerCallSid'),
    isParkerCallOnHold: queueSelectors.pullModular(state, 'isParkerCallOnHold'),
    isManagerCallOnHold: promptSelectors.pullModular(state, 'isManagerCallOnHold'),
    callingManagerName: queueSelectors.pullModular(state, 'callingManagerName'),
    callingManagerNumber: queueSelectors.pullModular(state, 'callingManagerNumber'),
    parkerCallStatus: queueSelectors.pullModular(state, 'parkerCallStatus'),
    callingManagerIndex: queueSelectors.pullModular(state, 'callingManagerIndex'),
    callingManagerExtension: queueSelectors.pullModular(state, 'callingManagerExtension'),
    managerVendConfirmation10s: promptSelectors.pullModular(state, 'managerVendConfirmation10s'),
    enableManagerHoldCallButton: promptSelectors.pullModular(state, 'enableManagerHoldCallButton'),
  };
}

const managersListSocket = (props) => (
  <SocketContext.Consumer>
    {socket => <ManagersList {...props} socket={socket} />}
  </SocketContext.Consumer>
)

export default withRouter(connect(mapStateToProps)(managersListSocket));