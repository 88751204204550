import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as promptSelectors from '../../store/prompt/reducer';
import * as promptActions from '../../store/prompt/actions';
import RaisedButton from 'material-ui/RaisedButton';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import TextField from '@material-ui/core/TextField';
import AsyncSelect from 'react-select/async';
import * as authSelectors from '../../store/auth/reducer';
import * as queueSelectors from '../../store/queue/reducer';
import Paper from 'material-ui/Paper';
import moment from 'moment-timezone';
import * as config from '../../lib/config';
import DirectionText from '../../components/DirectionText';
import InputMask from 'react-input-mask';
import Checkbox from '@material-ui/core/Checkbox';
import MuiPhoneNumber from 'material-ui-phone-number';

const dateStyles = {
  display: 'inline-block',
  width: '100%'
};


export class TicketDiscrepancy extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.requiredFields = [
      'ticketDiscrepancyEntranceTime',
      'ticketDiscrepancyEntranceDate',
      'ticketDiscrepancyExitTime',
      'ticketDiscrepancyExitDate',
      'ticketDiscrepancyFirstName',
      'ticketDiscrepancyLastName',
      'ticketDiscrepancyAddress',
      'ticketDiscrepancyCity',
      'ticketDiscrepancyState',
      'ticketDiscrepancyZip',
      'ticketDiscrepancyPhoneNumber',
      'ticketDiscrepancyNotes'
    ];

    this.state = { showFeeDueError: false, POBoxAddress: false };
  }

  componentDidMount() {
    // The formComplete field is used across multiple forms. Set it to false on component mount.
    this.props.dispatch(promptActions.modularSendField(false, 'formComplete'));    
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    
    if (this.props.from.laneType == 'Paystation') {
      this.props.history.replace('/routeDisabled', {
        message: 'Please call us from the exit so we can fill out a ticket discrepancy form and vend the gate for you.'
      });
    }
    
    if (this.props.pilPaymentCode) {
      this.props.dispatch(promptActions.modularSendField(false, 'pilCheckTransactionStatus'));
    }
    
    // If Validation Issue then add the fields to 
    if (this.props.from.validationDiscrepancy && this.props.issue.subtype === "validationIssue") {
      this.requiredFields.push('validationCompany');
      this.requiredFields.push('validationNumber');
    } 

    // Add the Licence Plate field to required fields. 
    if (this.props.from.ticketDiscrepancyLicensePlate) {
      this.requiredFields.push('ticketDiscrepancyLicensePlate');
    }

    if (this.props.from.ticketDiscrepancyCarMake) {
      this.requiredFields.push('ticketDiscrepancyCarMake');
    }

    if (this.props.from.ticketDiscrepancyCarModel) {
      this.requiredFields.push('ticketDiscrepancyCarModel');
    }

    if (this.props.from.ticketDiscrepancyEjectTicket) {
      this.requiredFields.push('ticketDiscrepancyEjectTicket');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    const complete = this.requiredFields.every((field) => {
      return !!this.props[field];
    });
    if(complete) {
      this.props.dispatch(promptActions.modularSendField(true, 'formComplete'));
    }
  }

  saveDate(date, target) {
    let formattedDate = moment(date).format('YYYY-MM-DD');
    this.props.dispatch(promptActions.sendDateTime(formattedDate, target));
  }

  saveTime(time, target) {
    let formattedTime = moment(time).startOf('minute').format('THH:mm:ss');
    this.props.dispatch(promptActions.sendDateTime(formattedTime, target));
    let parsableTarget = (target === 'ticketDiscrepancyEntranceTime' ? 'parsableEntranceTime' : 'parsableExitTime');
    this.props.dispatch(promptActions.sendDateTime(time, parsableTarget));
  }

  onCheckBoxSelect(event, target) {
    const value = event.target.checked;
    this.setState({ [target]: value });
  }

  updateField(event, target) {
    let value = target == 'ticketDiscrepancyEjectTicket' ? event.target.checked : event.target.value;
    this.props.dispatch(promptActions.modularSendField(value, target));
    if (target === 'ticketDiscrepancyFeeDue') {
      if (parseInt(value) <= 0) {
        this.setState({ showFeeDueError: true });
        return;
      } else {
        this.setState({ showFeeDueError: false });
      }
    }
    if (!value) {
      this.props.dispatch(promptActions.modularSendField(false, 'formComplete'));
    }
  }

  updatePhoneNumber(value) {
    this.props.dispatch(promptActions.modularSendField((value).slice(2), 'ticketDiscrepancyPhoneNumber'));
  }

  // Get the address based on text search from Google Places Autocomplete API and save them to addressOptions.
  getAddressOptions = ((value, options) => {
    if (options.action == "input-change") {
      this.props.dispatch(promptActions.modularSendField(value, 'ticketDiscrepancyFullAddress'));   
      this.props.dispatch(promptActions.searchText(value));
    }
  });

  renderAddressOptions = ((inputValue) => {
    return this.props.addressOptions.filter((a) => {
      return a.label;
    });
  });

  // Load the address suggestions from addressOptions in the AsyncSelect Component. 
  loadOptions = ((inputValue, callback) => {
    setTimeout(() => {
      callback(this.renderAddressOptions(inputValue));
    }, 1000);
  });

  // On selecting the address parse the data and save them to respective states. 
  saveAddress(address) {     
   this.props.dispatch(promptActions.getGeocodeAddress(address.value));
  }

  async submitTicketDiscrepancy() {
    let rec = {
      Username: this.props.username,
      EntranceTime: this.props.ticketDiscrepancyEntranceDate + this.props.ticketDiscrepancyEntranceTime,
      ExitTime: this.props.ticketDiscrepancyExitDate + this.props.ticketDiscrepancyExitTime,
      FeeDue: this.props.ticketDiscrepancyFeeDue,
      FirstName: this.props.ticketDiscrepancyFirstName,
      LastName: this.props.ticketDiscrepancyLastName,
      Address: this.props.ticketDiscrepancyAddress,
      PhoneNumber: this.props.ticketDiscrepancyPhoneNumber,
      City: this.props.ticketDiscrepancyCity,
      State: this.props.ticketDiscrepancyState,
      Zip: this.props.ticketDiscrepancyZip,
      Email: this.props.ticketDiscrepancyEmail,
      Notes: this.props.ticketDiscrepancyNotes,
      ValidationCompany: this.props.validationCompany,
      ValidationNumber: this.props.validationNumber,
      LicensePlate: this.props.ticketDiscrepancyLicensePlate,
      CarMake: this.props.ticketDiscrepancyCarMake,
      CarModel: this.props.ticketDiscrepancyCarModel,
      timezone: this.props.from.timezone
    };

    if (parseInt(rec.FeeDue) <= 0) {
      this.setState({ showFeeDueError: true });
      return;
    } else {
      this.setState({ showFeeDueError: false });
    }

    // eslint-disable-next-line
    let submitResults = await this.props.dispatch(promptActions.submitTicketDiscrepancy(rec));
    if(submitResults.success) {
      this.props.dispatch(promptActions.modularSendField(true, 'ticketDiscrepancyComplete'));
    }
  }

  render() {
    let alsoValidation = false;
    if (this.props.from.validationDiscrepancy && this.props.issue.subtype === "validationIssue") {
      alsoValidation = true;
    }

    return (
      <div className="home-div">
        <div className="home-div-content">
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            <Paper zDepth={1} className="paper-padding">
              <h3>Ticket Discrepancy Form</h3>
              {this.props.issue.subtype === "lostTicket" && this.props.from.lostTicketTDNote &&
                <DirectionText>
                  <span style={{ color: 'red', fontWeight: 'bolder' }} >Note: </span> {this.props.from.lostTicketTDNote}
                </DirectionText>
              }
              {this.props.issue.subtype == 'validationIssue' && this.props.from.validationTDNote &&
                <DirectionText>
                  <span style={{ color: 'red', fontWeight: 'bolder' }} >Note: </span> {this.props.from.validationTDNote}
                </DirectionText>
              }
              <Grid container spacing={3} style={{ marginTop:2 }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Grid container spacing={3}>
                    <Grid item xs>
                      <DatePicker
                        label="Entrance Date"
                        format="YYYY-MM-DD"
                        value={this.props.ticketDiscrepancyEntranceDate}
                        onChange={date => this.saveDate(date, 'ticketDiscrepancyEntranceDate')}  
                        style={dateStyles}
                        fullWidth={true}
                        inputProps={{ className: 'data-hj-allow' }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TimePicker
                        ampm={false}
                        openTo="hours"
                        views={["hours", "minutes"]}
                        format="HH:mm"
                        label="Entrance Time"
                        value={this.props.parsableEntranceTime}
                        onChange={time => this.saveTime(time, 'ticketDiscrepancyEntranceTime')}
                        style={dateStyles}
                        fullWidth={true}
                        inputProps={{ className: 'data-hj-allow' }}
                      />
                    </Grid>
                    <Grid item xs>
                      <DatePicker
                        format="YYYY-MM-DD"
                        label="Exit Date"
                        value={this.props.ticketDiscrepancyExitDate}
                        onChange={date => this.saveDate(date, 'ticketDiscrepancyExitDate')}
                        style={dateStyles}
                        fullWidth={true}
                        inputProps={{ className: 'data-hj-allow' }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TimePicker
                        ampm={false}
                        openTo="hours"
                        views={["hours", "minutes"]}
                        format="HH:mm"
                        label="Exit Time"
                        value={this.props.parsableExitTime}
                        onChange={time => this.saveTime(time, 'ticketDiscrepancyExitTime')} 
                        style={dateStyles}
                        fullWidth={true}
                        inputProps={{ className: 'data-hj-allow' }}
                      />
                    </Grid>                    
                  </Grid>
                </MuiPickersUtilsProvider>                              
                <Grid container style={{ marginTop: 2 }}>
                  <Grid item xs>
                    <TextField
                      id="ticketDiscrepancyFeeDue"
                      type="number"                 
                      label="Fee Due"
                      placeholder="Enter Fee Due"
                      value={this.props.ticketDiscrepancyFeeDue || ''}
                      fullWidth={true}                  
                      target="ticketDiscrepancyFeeDue"
                      variant="outlined"
                      margin="dense"
                      inputProps={{ className: 'data-hj-allow' }}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      onChange={(e) => this.updateField(e, 'ticketDiscrepancyFeeDue')}
                    />
                  </Grid>
                  {this.state.showFeeDueError &&
                    <Grid item xs={12} style={{ marginTop: 0}}>
                      <DirectionText>
                        <span style={{ color: 'red' }} > Error: Fee Due should be greater than 0. </span>
                      </DirectionText>
                    </Grid>
                  }
                </Grid>                
                <Grid container spacing={3}>
                  <Grid item xs>
                    <TextField
                      id="ticketDiscrepancyFirstName"
                      type="text"
                      label="First Name"
                      value={this.props.ticketDiscrepancyFirstName || ''} 
                      placeholder="Enter First Name" 
                      fullWidth={true}      
                      target="ticketDiscrepancyFirstName"
                      variant="outlined"
                      margin="dense"
                      inputProps={{ className: 'data-hj-allow' }}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      onChange={(e) => this.updateField(e, 'ticketDiscrepancyFirstName')}
                    />
                  </Grid>
                  
                  <Grid item xs>                    
                    <TextField
                      id="ticketDiscrepancyLastName"
                      type="text"
                      label="Last Name"
                      value={this.props.ticketDiscrepancyLastName || ''}
                      placeholder="Enter Last Name" 
                      fullWidth={true}
                      target="ticketDiscrepancyLastName"
                      variant="outlined"
                      margin="dense"
                      inputProps={{ className: 'data-hj-allow' }}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      onChange={(e) => this.updateField(e, 'ticketDiscrepancyLastName')}
                    />
                  </Grid>
                </Grid>      
                <Grid container spacing={3}>
                  <Grid item xs>  
                    <MuiPhoneNumber 
                      defaultCountry={'us'}
                      onlyCountries={['us']}
                      style={{ width: '100%', height: '100%'}}
                      onChange={this.updatePhoneNumber}
                      variant="outlined"
                      margin="dense"
                      inputClass='data-hj-allow'
                    />
                  </Grid>
                  <Grid item xs>  
                    <TextField
                      id="ticketDiscrepancyEmail" 
                      label="Email"
                      value={this.props.ticketDiscrepancyEmail || ''}
                      placeholder="Enter Email"
                      fullWidth={true}
                      target="ticketDiscrepancyEmail"
                      variant="outlined"
                      margin="dense"
                      inputProps={{ className: 'data-hj-allow' }}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      onChange={(e) => this.updateField(e, 'ticketDiscrepancyEmail')} 
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>                  
                    <p 
                      style={{ marginBottom: 0, marginLeft: 3 }}>
                      Please enter the full address here. Street, City, State and Zip will be auto-populated.
                    </p>               
                    <AsyncSelect
                      className="MuiOutlinedInput-inputMarginDense max-z-index"
                      classNamePrefix="address-select"
                      cacheOptions
                      value={{ value: this.props.ticketDiscrepancyFullAddress, label: this.props.ticketDiscrepancyFullAddress }}
                      placeholder="Enter Full Address"
                      loadOptions={this.loadOptions}
                      onInputChange={this.getAddressOptions}
                      onChange={this.saveAddress} 
                    />
                      <span style={{ color: '#e57373 '}}> {this.props.ticketDiscrepancyAddressError || null } </span>
                  </Grid>
                  <Grid item xs={12}>
                    <Fragment>
                      <Checkbox
                        target="POBoxAddress"
                        onChange={(e) => this.onCheckBoxSelect(e, 'POBoxAddress')}
                        inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} 
                      />
                      Address is a PO Box?
                    </Fragment>
                  </Grid>
                </Grid>                  
                <Grid container>
                  <Grid item xs>
                      <TextField
                        id="ticketDiscrepancyAddress"
                        value={this.props.ticketDiscrepancyAddress || ''} 
                        label="Address" 
                        placeholder="Enter Address"                      
                        fullWidth={true}
                        disabled={this.state.POBoxAddress ? false : true}
                        target="ticketDiscrepancyAddress"
                        variant="outlined"
                        margin="dense"
                        inputProps={{ className: 'data-hj-allow' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => this.updateField(e, 'ticketDiscrepancyAddress')}
                      />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <TextField
                      id="ticketDiscrepancyCity"
                      value={this.props.ticketDiscrepancyCity || ''}
                      label="City" 
                      placeholder="Enter City"                      
                      fullWidth={true}
                      disabled={this.state.POBoxAddress ? false : true}
                      target="ticketDiscrepancyCity"
                      variant="outlined"
                      margin="dense"
                      inputProps={{ className: 'data-hj-allow' }}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      onChange={(e) => this.updateField(e, 'ticketDiscrepancyCity')}
                    />                                   
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id="ticketDiscrepancyState"
                      value={this.props.ticketDiscrepancyState || ''}
                      label="State" 
                      placeholder="Enter State"                      
                      fullWidth={true}
                      disabled={this.state.POBoxAddress ? false : true}
                      target="ticketDiscrepancyState"
                      variant="outlined"
                      margin="dense"
                      inputProps={{ className: 'data-hj-allow' }}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      onChange={(e) => this.updateField(e, 'ticketDiscrepancyState')} 
                    />                               
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id="ticketDiscrepancyZip"
                      value={this.props.ticketDiscrepancyZip || ''}
                      label="Zip" 
                      placeholder="Enter Zip Code"                      
                      fullWidth={true}
                      disabled={this.state.POBoxAddress ? false : true}
                      target="ticketDiscrepancyZip"
                      variant="outlined"
                      margin="dense"
                      inputProps={{ className: 'data-hj-allow' }}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      onChange={(e) => this.updateField(e, 'ticketDiscrepancyZip')} >
                        <InputMask mask="99999" maskChar="" />
                    </TextField>
                  </Grid>
                </Grid>
                {this.props.from.validationDiscrepancy && alsoValidation &&
                  <Grid container spacing={3}>
                    <Grid item xs>
                      <TextField
                        id="validationCompany"
                        label="Validation Company"
                        value={this.props.validationCompany || ''}
                        placeholder="Enter Validation Company"                      
                        fullWidth={true}
                        target="validationCompany"
                        variant="outlined"
                        margin="dense"
                        inputProps={{ className: 'data-hj-allow' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => this.updateField(e, 'validationCompany')} 
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        id="validationNumber"
                        label="Validation Number"
                        value={this.props.validationNumber || ''}
                        target="validationNumber"
                        placeholder="Enter Validation Number"                        
                        fullWidth={true}
                        variant="outlined"
                        margin="dense"
                        inputProps={{ className: 'data-hj-allow' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => this.updateField(e, 'validationNumber')} 
                      />
                    </Grid>
                  </Grid>
                }
                <Grid container spacing={3}>
                  { this.props.from.ticketDiscrepancyLicensePlate &&
                    <Grid item xs>
                      <TextField
                        id="ticketDiscrepancyLicensePlate"
                        label="Licence Plate"
                        value={this.props.ticketDiscrepancyLicensePlate || ''}
                        placeholder="Enter Licence Plate"                        
                        fullWidth={true}
                        target="ticketDiscrepancyLicensePlate"
                        variant="outlined"
                        margin="dense"
                        inputProps={{ className: 'data-hj-allow' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => this.updateField(e, 'ticketDiscrepancyLicensePlate')} 
                      />
                    </Grid>
                  }
                  {this.props.from.ticketDiscrepancyCarMake && 
                    <Grid item xs>
                      <TextField
                        id="ticketDiscrepancyCarMake"
                        label="Car Make"
                        value={this.props.ticketDiscrepancyCarMake || ''}
                        placeholder="Enter Car Make"                        
                        fullWidth={true}
                        target="ticketDiscrepancyCarMake"
                        variant="outlined"
                        margin="dense"
                        inputProps={{ className: 'data-hj-allow' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => this.updateField(e, 'ticketDiscrepancyCarMake')}
                      />
                    </Grid>
                  }
                  {this.props.from.ticketDiscrepancyCarModel &&
                    <Grid item xs>
                      <TextField
                        id="ticketDiscrepancyCarModel"
                        label="Car Model"
                        value={this.props.ticketDiscrepancyCarModel || ''}
                        placeholder="Enter Car Model"                        
                        fullWidth={true}
                        target="ticketDiscrepancyCarModel"
                        variant="outlined"
                        margin="dense"
                        inputProps={{ className: 'data-hj-allow' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => this.updateField(e, 'ticketDiscrepancyCarModel')} 
                      />
                    </Grid>
                  }
                </Grid> 
                <Grid container spacing={3}>
                  <Grid item xs>
                    <TextField
                      id="ticketDiscrepancyNotes"
                      label="Notes"
                      value={this.props.ticketDiscrepancyNotes || ''}
                      placeholder="Enter Notes"                      
                      fullWidth={true}
                      target="ticketDiscrepancyNotes"
                      variant="outlined"
                      margin="dense"
                      inputProps={{ className: 'data-hj-allow' }}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      onChange={(e) => this.updateField(e, 'ticketDiscrepancyNotes')} 
                    />
                  </Grid>
                </Grid> 
              </Grid> 
            </Paper>
            <Paper zDepth={1} className="paper-padding">
              {this.props.ticketDiscrepancyComplete  ?                        
                <Link to="/vendGate">
                  <RaisedButton label="Vend Gate"  />
                </Link>
              : 
                <Fragment> 
                  {!this.props.formComplete &&
                    <Fragment>
                      <DirectionText> 
                        <span style={{ color: 'red', fontWeight: 'bolder' }} >Note: </span> Please complete all the form fields to Submit Ticket Discrepancy. 
                      </DirectionText>
                    </Fragment>
                  }
                  {this.props.from.ticketDiscrepancyEjectTicket &&
                    <Fragment>
                      <Checkbox
                        target="ticketDiscrepancyEjectTicket"
                        onChange={(e) => this.updateField(e, 'ticketDiscrepancyEjectTicket')}
                        inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} 
                      />
                      Please ask parker to press cancel button and retrieve their ticket before vending the gate.
                    </Fragment>
                  }
                  {this.props.from.parkerRefusesTDInfoAction == 'vendGate' && <RaisedButton label="Parker Refuses Info" onClick={() => this.props.history.push('/vendGate')} />}    
                  <RaisedButton label="Submit Ticket Discrepancy" disabled={!this.props.formComplete} onClick={this.submitTicketDiscrepancy} /> 
                  <Link to="/confirmationScreen">
                    <RaisedButton label="Exit" />
                  </Link>
                </Fragment>
              }
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    ongoingAnalystCallSid: queueSelectors.pullModular(state, 'ongoingAnalystCallSid'),
    ticketDiscrepancyAddressError: promptSelectors.pullModular(state, 'ticketDiscrepancyAddressError'),
    ticketDiscrepancyEntranceTime: promptSelectors.pullModular(state, 'ticketDiscrepancyEntranceTime'),
    parsableEntranceTime: promptSelectors.pullModular(state, 'parsableEntranceTime'),
    ticketDiscrepancyEntranceDate: promptSelectors.pullModular(state, 'ticketDiscrepancyEntranceDate'),
    ticketDiscrepancyExitTime: promptSelectors.pullModular(state, 'ticketDiscrepancyExitTime'),
    parsableExitTime: promptSelectors.pullModular(state, 'parsableExitTime'),
    ticketDiscrepancyExitDate: promptSelectors.pullModular(state, 'ticketDiscrepancyExitDate'),
    ticketDiscrepancyFeeDue: promptSelectors.pullModular(state, 'ticketDiscrepancyFeeDue'),
    ticketDiscrepancyFirstName: promptSelectors.pullModular(state, 'ticketDiscrepancyFirstName'),
    ticketDiscrepancyLastName: promptSelectors.pullModular(state, 'ticketDiscrepancyLastName'),
    ticketDiscrepancyFullAddress: promptSelectors.pullModular(state, 'ticketDiscrepancyFullAddress'),
    ticketDiscrepancyAddress: promptSelectors.pullModular(state, 'ticketDiscrepancyAddress'),
    ticketDiscrepancyPhoneNumber: promptSelectors.pullModular(state, 'ticketDiscrepancyPhoneNumber'),
    ticketDiscrepancyCity: promptSelectors.pullModular(state, 'ticketDiscrepancyCity'),
    ticketDiscrepancyState: promptSelectors.pullModular(state, 'ticketDiscrepancyState'),
    ticketDiscrepancyZip: promptSelectors.pullModular(state, 'ticketDiscrepancyZip'),
    ticketDiscrepancyEmail: promptSelectors.pullModular(state, 'ticketDiscrepancyEmail'),
    ticketDiscrepancyNotes: promptSelectors.pullModular(state, 'ticketDiscrepancyNotes'),
    ticketDiscrepancyComplete: promptSelectors.pullModular(state, 'ticketDiscrepancyComplete'),
    ticketDiscrepancyLicensePlate: promptSelectors.pullModular(state, 'ticketDiscrepancyLicensePlate'),
    ticketDiscrepancyCarMake: promptSelectors.pullModular(state, 'ticketDiscrepancyCarMake'),
    ticketDiscrepancyCarModel: promptSelectors.pullModular(state, 'ticketDiscrepancyCarModel'),
    ticketDiscrepancyEjectTicket: promptSelectors.pullModular(state, 'ticketDiscrepancyEjectTicket'),
    validationCompany: promptSelectors.pullModular(state, 'validationCompany'),
    validationNumber: promptSelectors.pullModular(state, 'validationNumber'),
    formComplete: promptSelectors.pullModular(state, 'formComplete'),
    addressOptions: promptSelectors.pullModular(state, 'addressOptions'),
    issue: promptSelectors.pullIssue(state),
    pilPaymentCode: promptSelectors.pullModular(state, 'pilPaymentCode')
  };
}

export default withRouter(connect(mapStateToProps)(TicketDiscrepancy));
