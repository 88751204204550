import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import * as config from '../../lib/config';
import * as authSelectors from '../../store/auth/reducer';
import * as promptSelectors from '../../store/prompt/reducer';
import * as promptActions from '../../store/prompt/actions';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import ManagersList from './ManagersList';
import NextSteps from '../../components/nextSteps';
import FlatButton from 'material-ui/FlatButton';
import Done from 'material-ui/svg-icons/action/done';
import _ from 'lodash';
import ScriptText from '../../components/ScriptText';
import ContactManagers from './ContactManagers';
import { RaisedButton } from 'material-ui';
import InputMask from 'react-input-mask';

class DamageClaim extends Component {
  constructor(props) {
    super(props);
    this.reloadInterval = null;
    autoBind(this);
    this.state = {
      email: null,
      firstName: null,
      lastName: null,
      phoneNumber: null
    }
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    /* reset global step prop */
    this.props.dispatch(promptActions.updateIssueStep(0));
    this.props.from.damageClaimMethod === 1 && this.props.dispatch(promptActions.updateIssueStep(-1));
  }

  componentWillUnmount() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
    this.setState({
      email: null
    });
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  completeStep(step) {
    this.props.dispatch(promptActions.updateIssueStep(step));
  }

  updateField(event, field) {
    event.preventDefault();
    if (this.reloadInterval) {
      clearTimeout(this.reloadInterval);
    }
    this.setState({
      [field]: event.target.value
    });
  }

  sendDCToDB() {
    if (!this.state.email) {
      this.props.dispatch(promptActions.showSnackBar({ type: 'error', message: `Please provide an email address.` }));
      return;
    }
    if (!this.state.firstName) {
      this.props.dispatch(promptActions.showSnackBar({ type: 'error', message: `Please provide a first name.` }));
      return;
    }
    if (!this.state.lastName ) {
      this.props.dispatch(promptActions.showSnackBar({ type: 'error', message: `Please provide a last name.` }));
      return;
    }
    if (!this.state.phoneNumber) {
      this.props.dispatch(promptActions.showSnackBar({ type: 'error', message: `Please provide a phone number.` }));
      return;
    }
    const record = {
      Email: this.state.email,
      Username: this.props.username,
      SelectedParkerName: `${this.state.firstName} ${this.state.lastName}`,
      ContactNumber: this.state.phoneNumber, 
      Sent: false
    };
    this.props.dispatch(promptActions.sendDCToDB(record));
  }

  render() {
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>Damage Claim</h2>
          </Paper>
          <div className="home-div-body">

            { this.props.step === -1 && 
              <Fragment>              
                <ScriptText>
                  Please contact one of the following people to resolve this issue:
                </ScriptText>
                <ul className="park-westin-ul">
                  <li>Your Account Manager</li>
                  <li>The Parking Office</li>
                  <li>
                  {_.map(this.props.from.managers, (v, i, c) => {
                    if (v.facilityManager === true) {
                      return (
                        <li key={i}>
                          <span>{v.name}: </span>
                          <span>{v.number} - </span>
                          <span>({v.email})</span>
                        </li>
                      );
                    }
                  })}
                  </li>
                </ul>
                <div style={{ marginTop: 15 }}>
                  <RaisedButton label="Parker Refuses To Proceed" onClick={() => this.completeStep(0)} />
                </div>
              </Fragment>
            }

            {this.props.step === 0 && 
              <Fragment>
                <ScriptText>"Cloudpark Command Center does not directly handle Damage Claims. Give me a moment to reach out to the location manager."</ScriptText>
                <ContactManagers showButtons={[]} />
                <RaisedButton label="No Answer From Any Manager" onClick={() => this.completeStep(1)} />
              </Fragment>
            } 
            
            { this.props.step === 1 && (
              <Fragment>
                  <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
                    <ScriptText>
                      "I am unable to reach any manager. May I please have your email to send you a copy of Propark's damage claim form? I will also provide you with the business
                      number of the facility manager and the corporate claims department."
                    </ScriptText>
                    <TextField inputProps={{ className: 'data-hj-allow' }} hintText="First Name" style={{ width: '50%' }} onChange={(event) => { this.updateField(event, 'firstName')}} key="firstName" />
                    <TextField inputProps={{ className: 'data-hj-allow' }} hintText="Last Name" style={{ width: '50%' }} onChange={(event) => { this.updateField(event, 'lastName')}} key="lastName" />
                    <TextField inputProps={{ className: 'data-hj-allow' }} hintText="Phone Number" style={{ width: '100%' }} onChange={(event) => { this.updateField(event, 'phoneNumber')}} key="phoneNumber">
                      <InputMask mask="(999) 999 9999" maskChar=" " />
                    </TextField>
                    <TextField inputProps={{ className: 'data-hj-allow' }} hintText="Email Address" style={{ width: '100%' }} onChange={(event) => { this.updateField(event, 'email')}} key="email" />
                    <FlatButton label="Submit" labelPosition="before" icon={<Done />} onClick={this.sendDCToDB} />
                    {this.props.dCStatus && <NextSteps />}
                  </Paper>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: authSelectors.pullModular(state, 'username'),
    authLevel: authSelectors.pullModular(state, 'authLevel'),
    from: promptSelectors.pullLocationId(state),
    step: promptSelectors.pullStep(state),
    dCStatus: promptSelectors.pullDCStatus(state)
  };
}

export default withRouter(connect(mapStateToProps)(DamageClaim));
