import React from 'react';
import ScriptText from '../../../../components/ScriptText'
import { connect } from 'react-redux';
import { pullModular as promptPullModular } from '../../../../store/prompt/reducer';
import FreshDirectionText from '../FreshDirectionText';

function PrepaidDisabledEntrance(props) {
  return (
    <div>
      {props.from.prepaidDisabledEntranceNote ?
      <ScriptText>{props.from.prepaidDisabledEntranceNote}</ScriptText>
      : 
      <ScriptText>We don't take prepaid reservations at this location. If you still want to park here, please pull a ticket and pay while exiting.</ScriptText>
    }
    <FreshDirectionText>If the parker insists that they have a prepaid pass, contact the managers</FreshDirectionText>
    </div>
)
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from')
  };
}
export default connect(mapStateToProps)(PrepaidDisabledEntrance);
