import { Button, FormControl, Paper, TextField } from '@mui/material';
import React, { Fragment, useState } from 'react';
import ScriptText from '../../components/ScriptText';
import PromptWrapper from './PrepaidFlows/PromptWrapper';
import { pullModular as authPullModular } from '../../store/auth/reducer';
import { pullModular as queuePullModular } from '../../store/queue/reducer';
import FreshContactManagers from './PrepaidFlows/PrepaidNodes/FreshContactManagers';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import FreshDirectionText from './PrepaidFlows/FreshDirectionText';

const ShuttlePrompt = (props) => {

  const {
    history,
    friendlyName,
    node,
    setNodeState
  } = props;

  const [flightNumber, setFlightNumber] = useState('');
  const [managerCalledFor10s, setManagerCalledFor10s] = useState(false);
  const [managerCalled, setManagerCalled] = useState(false);

  const updateFlightNumber = (event) => {
    setFlightNumber(event.target.value);
  }

  return (
    <PromptWrapper>
      {node == '0' &&
        <Fragment>
          <ScriptText>Cloudpark Command Center, this is {friendlyName} speaking. What is the issue I can assist you with today?</ScriptText>
          <Button className='button-style' variant='outlined' onClick={() => setNodeState('1a')} >JetBlue Crew Waiting for Pickup</Button>
          <Button className='button-style' variant='outlined' onClick={() => setNodeState('1b')}>JetBlue Crew wants to Reschedule Pickup</Button>
          <Button className='button-style' variant='outlined' onClick={() => setNodeState('1c')}>API wants to Schedule/Reschedule Pickup</Button>
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
        </Fragment>
      }
      {node == '1a' &&
        <Fragment>
          <ScriptText>May I please have your flight number?</ScriptText>
          <FormControl fullWidth>
            <TextField onChange={(event) => { updateFlightNumber(event) }} />
          </FormControl>
          <ScriptText>Please wait between Gates 5 and 6, I'm going to call the managers</ScriptText>
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => setNodeState('2')}>Contact Manager</Button>
        </Fragment>
      }
      {node == '1b' &&
        <Fragment>
          <ScriptText>I am not allowed to change or reschedule a pickup. Please contact your scheduling agency at (516) 307-3000. </ScriptText>
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
        </Fragment>
      }
      {node == '1c' &&
        <Fragment>
          <ScriptText>I am not allowed to change or reschedule a pickup. Please call (860) 993-7474. </ScriptText>
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
        </Fragment>
      }
      {node == '2' &&
        <Fragment>
          <p>Crew Member's Flight Number: {flightNumber}</p>
          <FreshDirectionText>Contact the Managers and ask them for their estimated arrival time and relay that to the crew.</FreshDirectionText>
          <FreshContactManagers
            managerCalledFor10s={managerCalledFor10s}
            setManagerCalledFor10s={setManagerCalledFor10s}
            managerCalled={managerCalled}
            setManagerCalled={setManagerCalled}
          />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
        </Fragment>
      }
    </PromptWrapper>

  )
}

function mapStateToProps(state) {
  return {
    friendlyName: authPullModular(state, 'friendlyName'),
    isParkerCallOnHold: queuePullModular(state, 'isParkerCallOnHold'),
    ongoingCallId: queuePullModular(state, 'ongoingCallId'),
  }
}

export default withRouter(connect(mapStateToProps)(ShuttlePrompt));