import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptSelectors from '../../store/prompt/reducer';
import * as promptActions from '../../store/prompt/actions';
import * as authSelectors from '../../store/auth/reducer';
import * as queueSelectors from '../../store/queue/reducer';
import DirectionText from '../../components/DirectionText';
import Paper from 'material-ui/Paper';
import ScriptText from '../../components/ScriptText';
import { Grid, TextField } from '@material-ui/core';
import { RaisedButton } from 'material-ui';
import ContactManagers from './ContactManagers';
import moment from 'moment-timezone';
import TicketSearch from '../Prompt/FlashAPI/TicketSearch';
import ErrorBoundary from '../ErrorBoundary';

class GracePeriod extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      renderComponent: {
        firstStep: true,
        showGracePeriodForm: false,
        showManagersList: false,
        showGracePeriodResult: false
      },
      ticketNumber: null, parkerEntryDateResult: null, calculatedParkerRate: null, managerName: null, flashPriceID: null, flashParcsTicketID: null,
      gracePeriodResultEntryDate: null, gracePeriodResultRate: null, managerName: null, pushRateQuestion: true

    };
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  updateState(event, target) {
    this.setState({ [target]: event.target.value });
  }

  async pushRateToMachine() {
    await this.props.dispatch(promptActions.flashValidateTicket({ priceID: this.state.flashPriceID, flashParcsTicketID: this.state.flashParcsTicketID }));
  }

  async flashSaveTicketDetails(data) {
    const result = await this.props.dispatch(promptActions.flashSaveTicketDetails(data));
    const { shouldVend, ticketNumber, parkerEntryDateResult, calculatedParkerRate, flashParcsTicketID, flashPriceID } = result;
    await this.setState({
      shouldVend,
      ticketNumber,
      parkerEntryDateResult,
      calculatedParkerRate,
      flashParcsTicketID,
      flashPriceID,
    });
    this.showComponent(['showGracePeriodResult']);
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'showPushRateForm':
        this.props.history.push('/tibaAPI/tibaManagerPushRate');
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  async shouldShowPIL() {
    const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('gracePeriod'));
    if (shouldEnablePIL) {
      return true;
    }
  }

  showComponent(toRender) {
    const allComponents = { ...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }

  render() {
    return (
      <ErrorBoundary>
        <div className="home-div">
          <div className="home-div-content">
            <Paper zDepth={1} className="home-div-header-short">
              <h2>{this.props.from.locationName}</h2>
            </Paper>
            <div className="home-div-body">
              <Paper zDepth={1} className="paper-padding">
                {this.state.renderComponent.firstStep &&
                  <Fragment>
                    <ScriptText>"{this.props.from.gracePeriod || 'This lot has no grace period.'}"</ScriptText>
                    {this.props.from.gracePeriodNote &&
                      <DirectionText>
                        {this.props.from.gracePeriodNote}
                      </DirectionText>
                    }
                    <div style={{ marginTop: 15 }}>
                      {this.props.from.gracePeriodTransactionVerify &&
                        <RaisedButton label="Next Step" onClick={() => this.showComponent(['showGracePeriodForm'])} />
                      }
                      {this.props.from.gracePeriodVendGate && <RaisedButton label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />}
                      {this.props.from.gracePeriodTD && <RaisedButton label="Ticket Discrepancy" onClick={() => this.props.history.push('/ticketDiscrepancy')} />}
                      <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                    </div>
                  </Fragment>
                }

                {this.state.renderComponent.showGracePeriodForm &&
                  <Fragment>
                    <ScriptText>
                      {this.props.from.gracePeriodFormNote}
                    </ScriptText>
                    {this.props.from.dynamicTicketSearch
                      ?
                      <Fragment>
                        <TicketSearch flashSaveTicketDetails={this.flashSaveTicketDetails} />
                        <div style={{ marginTop: 15 }}>
                          <RaisedButton label="Ticket Not Found" onClick={async () => {
                            const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('gracePeriod'));
                            if (shouldEnablePIL) {
                              this.props.history.push('/pil/PILEntryInput');
                            } else {
                              this.showComponent(['showManagersList']);
                            }
                          }}
                          />
                          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                        </div>
                      </Fragment>
                      :
                      <Fragment>
                        {this.props.history.push('/tibaAPI/tibaRateByTicket')}
                      </Fragment>
                    }
                  </Fragment>
                }

                {this.state.renderComponent.showGracePeriodResult &&
                  <Fragment>
                    {this.state.shouldVend
                      ?
                      <DirectionText>
                        The parker is within the Grace Period. Please vend the Gate.
                      </DirectionText>
                      :
                      <Fragment>
                        <DirectionText> <span style={{ color: 'red', fontWeight: 'bolder' }} >Note: </span> {`Click the Push Rate button to display the rate on machine.`} </DirectionText>
                        <DirectionText> <span style={{ fontWeight: 'bolder' }}> Entry Date Time: </span> {this.state.parkerEntryDateResult} </DirectionText>
                        <ScriptText> Please pay the ${this.state.calculatedParkerRate} fee to exit the parking garage. </ScriptText>
                        {this.props.from.parcs == 'tibaAPI' &&
                          <DirectionText> <span style={{ fontWeight: 'bolder', color: 'Red' }}> Note: </span> Please make sure that you ask the parker if ticket is in the machine. </DirectionText>}
                      </Fragment>
                    }
                    <div style={{ marginTop: 15 }}>
                      {this.state.shouldVend
                        ?
                        <RaisedButton label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />
                        :
                        <Fragment>
                          <RaisedButton label="Push Rate To Machine" onClick={() => this.pushRateToMachine()} />
                          {this.shouldShowPIL() &&
                            <RaisedButton label="Unable to Pay Through Equipment" onClick={() => this.props.history.push('/pil/PILEntryInput')} />
                          }
                        </Fragment>
                      }
                      <RaisedButton label="Contact Manager" onClick={() => this.showComponent(['showManagersList'])} />
                      <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                    </div>
                  </Fragment>
                }

                {this.state.renderComponent.showManagersList &&
                  <ContactManagers
                    showButtons={['vendGate', 'managerAssisting', 'ticketDiscrepancy', 'showCustomButton']}
                    btnClick={this.btnClick}
                    btnFrom="gracePeriod"
                    customButtonData={[
                      {
                        "label": "Manager Asked to Push Rate",
                        "action": "showPushRateForm"
                      },
                    ]}
                  />
                }
              </Paper>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username'),
    ongoingCallId: queueSelectors.pullModular(state, 'ongoingCallId'),
  };
}

export default withRouter(connect(mapStateToProps)(GracePeriod));
