import React, { Fragment} from "react";
import FreshDirectionText from "../FreshDirectionText";
import { pullModular as promptPullModular } from '../../../../store/prompt/reducer';
import { modularSendField } from '../../../../store/prompt/actions';
import { connect } from "react-redux";
import { TextField } from "@mui/material";

const PushRate = (props) => {

  const { dispatch, tibaRateResult, usedTicket, from } = props

  const updateField = (event, target) => {
    dispatch(modularSendField(event.target.value, target));
  }

  const updateRate = (event) => {
    const tibaRateResult = { rate: event.target.value };
    dispatch(modularSendField(tibaRateResult, 'tibaRateResult')); 
  }

  return (
    <Fragment>
      {from.parcs == 'tibaAPI' &&
        <Fragment>
          <FreshDirectionText>
            {tibaRateResult && tibaRateResult.rate && 'The rate in the Text box below is what the parker owes. '}
            Please input the rate that the manager requested to push.
          </FreshDirectionText>
          <TextField
            label="Manager Name"
            margin="dense"
            variant="outlined"
            inputProps={{ className: 'data-hj-allow' }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => { updateField(event, 'selectedManagerName') }}
            style={{ width: '100%', marginTop: 15 }}
          />
          <TextField
            label="New Rate"
            margin="dense"
            variant="outlined"
            value={tibaRateResult.rate}
            inputProps={{ className: 'data-hj-allow' }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => { updateRate(event) }}
            style={{ width: '100%', marginTop: 15 }}
          />
          
          { usedTicket &&
            <FreshDirectionText>
              Please make sure that you ask the parker if ticket is in the machine.
            </FreshDirectionText>}
        </Fragment>
      }
      {from.parcs !== 'tibaAPI' &&
        <Fragment>
          <FreshDirectionText>This location does not support pushing the rate to the machine. Please report this issue.</FreshDirectionText>
        </Fragment>
      }
    </Fragment>
  )
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from'),
    tibaRateResult: promptPullModular(state, 'tibaRateResult')
  }
}

export default (connect(mapStateToProps)(PushRate));