import React from 'react';

const ScriptText = ({ children }) => (
  <p className="script-text">
    <span
      style={{
        fontStyle: 'normal',
        fontWeight: 'bold',
        marginRight: '0.5em'
      }}
    >
      Say:{' '}
    </span>
    {children}
  </p>
);
export default ScriptText;
