import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import RaisedButton from 'material-ui/RaisedButton';
import MomentUtils from '@date-io/moment';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import _ from 'lodash';
import { Grid, Paper, TextField, Select, OutlinedInput } from '@material-ui/core';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as promptActions from '../../../store/prompt/actions';
import * as authSelectors from '../../../store/auth/reducer';
import ContactManagers from '../../Prompt/ContactManagers';
import ScriptText from '../../../components/ScriptText';
import DirectionText from '../../../components/DirectionText';
import PromptBox from '../../../components/promptBox';
const dateStyles = {
  display: 'inline-block',
  width: '100%'
};
class TibaRateByTicket extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      parkerEntranceDateDisplay: null,
      parkerEntranceTimeDisplay: null
    }
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  updateState(event, target) {
    this.setState({ [target]: event.target.value });
  }

  updateField(event, target) {
    this.props.dispatch(promptActions.modularSendField(event.target.value, target))
  }

  showComponent(toRender) {
    const allComponents = {...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }

  saveDate(date, target) {
    const formattedDate = moment(date).format('YYYY:MM:DD');
    this.props.dispatch(promptActions.modularSendField(formattedDate, 'parkerEntranceDate'));
    this.setState({ parkerEntranceDateDisplay: date });
  }

  saveTime(time, target) {
    const formattedTime = moment(time).startOf('minute').format('THH:mm:ss');
    this.props.dispatch(promptActions.modularSendField(formattedTime, 'parkerEntranceTime'));
    this.setState({ parkerEntranceTimeDisplay: time });
  }

  async tibaRateByEntryTime(type) {
    const { parkerEntranceDate, parkerEntranceTime } = this.props;
    if ((!parkerEntranceDate || !parkerEntranceTime)) {
      this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: 'Entrance Date Time is required.'
      }));
    } else {
      const parkerEntranceDateTime = parkerEntranceDate + parkerEntranceTime;
      const { apiParams, rateList, gracePeriodInt, timezone, baseURL } = this.props.from;
      const data = { apiParams, rateList, gracePeriodInt, timezone, parkerEntranceDateTime, baseURL };
      const result = await this.props.dispatch(promptActions.tibaGetRateByEntranceData(data));
      const { success, rate, message, shouldVend, entryDate, notFound } = result.data;
      const parkerEntryDateResult = moment(entryDate  || parkerEntranceDateTime, 'YYYY:MM:DD HH:mm:ss').format('LLLL');
      if (success && !notFound) {
        await this.props.dispatch(promptActions.modularSendField({ rate, shouldVend, parkerEntryDateResult }, 'tibaRateResult'));
        this.props.history.push('/tibaAPI/tibaRateResult');
      } else {
        this.props.dispatch(promptActions.showSnackBar({ type: 'error', message }));
        if (this.props.from.enablePIL.includes(this.props.issue.subtype)) {
          this.props.history.push('/pil/PILEntryInput');
        }
      }
    }
  }

  render() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        <ScriptText>May I please have the time you entered the parking garage?</ScriptText>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid style={{ marginTop: 15, padding: 5 }} container spacing={2}>
            <Grid item xs>
              <DatePicker
                label="Entrance Date"
                format="YYYY-MM-DD"
                value={this.state.parkerEntranceDateDisplay}
                onChange={date => this.saveDate(date, 'parkerEntranceDate')}  
                style={dateStyles}
                fullWidth={true}
                maxDate={new Date()}
                inputProps={{ className: 'data-hj-allow' }}
              />
            </Grid>
            <Grid item xs>
              <TimePicker
                ampm={false}
                openTo="hours"
                views={["hours", "minutes"]}
                format="HH:mm"
                value={this.state.parkerEntranceTimeDisplay}
                onChange={time => this.saveTime(time, 'parkerEntranceTime')}
                label="Entrance Time"
                style={dateStyles}
                fullWidth={true}
                inputProps={{ className: 'data-hj-allow' }}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        <div style={{ marginTop: 15 }}>
          <RaisedButton label="Calculate Fee" onClick={() => this.tibaRateByEntryTime()} />
          <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
        </div>
      </PromptBox>
    )
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username'),
    ticketNumber: promptSelectors.pullModular(state, 'ticketNumber'),
    parkerEntranceDate: promptSelectors.pullModular(state, 'parkerEntranceDate'),
    parkerEntranceTime: promptSelectors.pullModular(state, 'parkerEntranceTime'),
  };
}

export default withRouter(connect(mapStateToProps)(TibaRateByTicket));