import React from "react";
import { Paper } from "@mui/material";
import { pullModular as promptPullModular } from '../../../store/prompt/reducer';
import { connect } from 'react-redux';

const TitleBox = (props) => {
  return(
    <Paper elevation={1} className='home-div-header-short'>
      <h2>{props.from.locationName}</h2>
    </Paper>
  )
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from'),
  }
} 

// export default withRouter(connect(PrepaidContainer));
export default (connect(mapStateToProps)(TitleBox));