import { retrieveLogger } from '../lib/logger';
import dayjs from 'dayjs';
let logger = retrieveLogger();
const axiosInstance = require('../lib/axiosInstance');

class ServerInteraction {
  
  async onAccept({from, to, callSid, analystCallSid, username}) {
    try {
      const result = await axiosInstance({
        method: 'post',
        url: `/call/onAccept`,
        data: { from, to, callSid, analystCallSid, username }
      });
  
      return result.data;

    } catch (error) {
      logger.error(`Request to Server Failed in - onAccept `);
      logger.error(error);
    }
  }

  async sendTransactionToDB(record) {
    try {
      const result = await axiosInstance({
        method: 'post',
        url: `/sendTransactionToDB`,      
        data : { record }
      });
      
      return result.data;

    } catch (error) {
      logger.error(`Request to Server Failed in - sendTransactionToDB `);
      logger.error(error);
    }
  }

  async sendEquipmentMalfunctionToDB(record) {
    try {
      const result = await axiosInstance({
        method: 'post',
        url: `/sendEquipmentMalfunctionToDB`,
        data: { record }
      });
      
      return result.data;

    } catch (error) {
      logger.error(`Request to Server Failed in - sendEquipmentMalfunctionToDB `);
      logger.error(error);
    }
  }

  async reserveWorker(username, workerSid, activitySid) {
    try {
      const result = await axiosInstance({
        method: 'post',
        url: `/reserveWorker`,
        data: { username, workerSid, activitySid }
      });

      return result;

    } catch (error) {
      logger.error(`Request to Server Failed in - reserveWorker`);
      logger.error(error);
    }
  }

  async resetToIdle(username, workerSid, activitySid) {
    try {
      const result = await axiosInstance({
        method: 'post',
        url: `/resetWorkerToIdle`,
        data: { username, workerSid, activitySid }
      });
      
      return result;

    } catch (error) {
      logger.error(`Request to Server Failed in - resetToIdle`);
      logger.error(error);
    }
  }

  async sendDCToDB(value) {
    try {
      const result = await axiosInstance({
        method: 'post',
        url: `/sendDCToDB`,
        data: { value }
      });
  
      return result.data;

    } catch (error) {
      logger.error(`Request to Server Failed in - sendDCToDB`);
      logger.error(error);
    }
  }

  async sendReceiptRequest(record) {
    try {
      const result = await axiosInstance({
        method: 'post',
        url: `/sendReceiptRequest`,
        data: { record }
      });
      
      return result.data;

    } catch (error) {
      logger.error(`Request to Server Failed in - sendReceiptRequest`);
      logger.error(error);
    }
  }

  async sendDeleteFromQueue(ongoingAnalystCallSid) {
    try {
      const result = await axiosInstance({
        method: 'post',
        url: `/deleteFromQueue`,
        data: { ongoingAnalystCallSid }
      });
      
      return result.data;

    } catch (error) {
      logger.error(`Request to Server Failed in - sendDeleteFromQueue`);
      logger.error(error);
    }
  }

  async updateAirportParkers(airportCode) {
    try {
      await axiosInstance({
        method: 'post',
        url: `/updateAirportParkers`
      });

    } catch (error) {
      logger.error(`Request to Server Failed in - updateAirportParkers`);
      logger.error(error);
    }
  }

  async generalServerPost(data, url) {
    try {
      const result = await axiosInstance({
        method: 'post',
        url: `/${url}`,
        data: data
      });

      return result.data;

    } catch (error) {
      logger.error(`Request to Server Failed in - generalServerPost`);
      logger.error(error);
    }
  }

  async getPlacesAutocomplete(value) {
    try {
      const result =  await axiosInstance({
        method: 'post',
        url: `/getPlacesAutocomplete`,
        data: { searchText: value }
      });
      
      return result.data.locations;

    } catch (error) {
      logger.error(`Request to Server Failed in - getPlacesAutocomplete`);
      logger.error(error);
    }
  }

  async getGeocodeAddress(address) {
    try {
      
      let formatted_address = null;
      let message;
      let success = false;
      
      const result = await axiosInstance({
        method: 'post',
        url: `/getGeocodeAddress`,        
        data: { address: address }
      });

      if (result.data.success === false) {
        formatted_address = null;
        message = result.data.message
      } else {
        formatted_address = result.data.formatted_address;
        message = result.data.message
        success = true
      }
      
      return { formatted_address: formatted_address, message: message, success: success }

    } catch (error) {
      logger.error(`Request to Server Failed in - getGeocodeAddress`);
      logger.error(error);
    }
  }

  async wpsRetrieveMonthlyNames(locId) {
    try {
      const result = await axiosInstance({
        method: 'get',
        url: `/wpsRetrieveMonthlyNames/${locId}`,
      });
      
      return result.data.parkers;

    } catch (error) {
      logger.error(`Request to Server Failed in - wpsRetrieveMonthlyNames`);
      logger.error(error);
    }
  }

  async wpsRetrieveCustomer(locId, customerId) {
    try {
      const result = await axiosInstance({
        method: 'get',
        url: `/wpsRetrieveCustomer/${locId}/${customerId}`
      });
      
      return result.data;

    } catch (error) {
      logger.error(`Request to Server Failed in - wpsRetrieveCustomer`);
      logger.error(error);
    }
    
  }

  async retrieveOutsideCallEndpoints() {
    try {
      const result = await axiosInstance({
        method: 'get',
        url: `/outsideCallEndpoints`
      });

      if (!result.data) {
        logger.error('Error! No result body.');
        return;
      }
      
      return result.data;

    } catch (error) {
      logger.error(`Request to Server Failed in - retrieveOutsideCallEndpoints`);
      logger.error(error);
    }
  }

  async sendToWorkerMap(username) {
    try {
      const result = await axiosInstance({
        method: 'post',
        url: `/sendToWorkerMap`,
        data: { username }
      });
      
      if (!result.data) {
        logger.error('Error! No result body.');
        return;
      }
      
      return result.data;

    } catch (error) {
      logger.error(`Request to Server Failed in - sendToWorkerMap`);
      logger.error(error);
    }
  }

  async toggleParkerCallHold(currentCall, toggle) {
    try { 
      const result = await axiosInstance({
        method: 'post',
        url: `/call/toggleParkerCallHold`,
        data: { currentCall, toggle }
      });

      return result;
    
    } catch (error) {
      logger.error(`Putting parker Call on hold failed in Server Interaction.`);
      logger.error(error);
    }
  }

  async toggleManagerCallHold(conferenceSid, managerCallSid, toggle) {
    try { 
      const result = await axiosInstance({
        method: 'post',
        url: `/call/toggleManagerCallHold`,
        data: { conferenceSid, managerCallSid, toggle }
      });

      return result;

    } catch (error) {
      logger.error(`Putting manager Call on hold failed in Server Interaction.`);
      logger.error(error);
    }
  }

  async managerCall(managerCallData) {
    try { 
      const callRoute = managerCallData.isCallingManager ? 'call/managerCall' : 'call/endManagerCall';
      const result = await axiosInstance({
          method: 'post',
          url: `/${callRoute}`,
          data: { managerCallData }
        });
        
      return result;
    
    } catch (error) {
      logger.error(`Manager call request failed in Server Interaction`);
      logger.error(error);
    }
  }

  async confirmManagerVend(ongoingCallId) {
    try {
      
      const result = await axiosInstance({
        method: 'post',
        url: `/confirmManagerRequestedVend`,
        data: { ongoingCallId }
      });
      
      return result;

    } catch (error) {
      logger.error(`Error confirming Manager Requested Vend`);
      logger.error(error);
    }
  }

  async updateClientSids(reservationSid) {
    try {
      const result = await axiosInstance({
        method: 'post',
        url: `/call/updateClientSids`,
        data: { reservationSid }
      });

      return result;

    } catch (error) {
      logger.error(`Error Retriving Relevant Call Sids in Server Interaction`);
      logger.error(error);
    }
  }

  async sendServerPOSTRequest(url, data) {
    try {
      logger.info(`Starting sendServerPOSTRequest.`, { url, data });
      const startTime = dayjs();
      const results = await axiosInstance({ method: 'post', url, data });
      const timeInSeconds = (dayjs().subtract(startTime).valueOf()) / 1000;
      logger.info(`Received response for sendServerPOSTRequest.`, { durationSeconds: timeInSeconds, url, data });
      return results;
    } catch (error) {
      logger.error(`Error in server POST API Call: ${url}`);
      logger.error(error);
      return error;
    }
  }
  
    async sendServerGETRequest(url, data) {
    try {
      logger.info(`Starting sendServerGETRequest.`, { url, data });
      const startTime = dayjs();
      const results = await axiosInstance({ method: 'get', url, data });
      const timeInSeconds = (dayjs().subtract(startTime).valueOf()) / 1000;
      logger.info(`Received response for sendServerGETRequest.`, { durationSeconds: timeInSeconds, url, data });
      return results;
    } catch (error) {
      logger.error(`Error in server GET API Call: ${url}`);
      logger.error(error);
      return error;
    }
  }
}

export default new ServerInteraction();
