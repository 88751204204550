import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { pullModular as promptPullModular } from '../../../../store/prompt/reducer';
import { pullModular as authPullModular } from '../../../../store/auth/reducer';
import { pullModular as queuePullModular } from '../../../../store/queue/reducer';
import { modularSendField as promptModularSendField, toggleManagerCallHold } from '../../../../store/prompt/actions';
import { managerCall, sendQueueObject, sendDigits } from '../../../../store/queue/actions';
import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import moment from 'moment-timezone';
import SocketContext from '../../../../services/socketContext';
import FreshDirectionText from '../FreshDirectionText';
import { retrieveLogger } from '../../../../lib/logger';
let logger = retrieveLogger();

function FreshContactManagers(props) {

  const { 
    managerCalledFor10s, 
    setManagerCalledFor10s, 
    managerCalled, 
    setManagerCalled, 
    isParkerCallOnHold,
    ongoingCallId,
    isManagerCallOnHold,
    callingManagerExtension,
    dispatch,
    from,
    ongoingConferenceSid,
    ongoingManagerCallSid,
    isCallingManager,
    username 
  } = props;

  const [callColor, setCallColor] = useState('gray');
  const [callDisabled, setCallDisabled] = useState(false);
  const [holdButtonDisable, setHoldButtonDisable] = useState(true);
  const [holdButtonColor, setHoldButtonColor] = useState('gray');
  let socket = useRef(null);
  const timeOut = useRef(null);

  useEffect(() => {
    socket.current = props.socket;
    socket.current.on('manager-joined', managerJoined);
    socket.current.on('manager-left', managerLeft);
    return clearTimeout(timeOut.current);
  }, []);

  // listens for the parker being on call and allows the analyst to call the manager or not
  useEffect(() => {
    if (isParkerCallOnHold) {
      setCallDisabled(false);
      setCallColor('green');
    } else {
      setCallDisabled(true);
      setCallColor('gray');
    }
  }, [isParkerCallOnHold]);

  // listens for the mananger being on hold to set the hold button's color
  useEffect(() => {
    if (managerCalled) {
      if (isManagerCallOnHold) {
        setHoldButtonColor('green');
      } else {
        setHoldButtonColor('orange');
      }
    }
  }, [isManagerCallOnHold]);


  const managerJoined = (data) => {
    logger.info(`Manager has joined the conference - ${data.callSid}`);
    dispatch(promptModularSendField(true, 'enableManagerHoldCallButton'));
    const extension = callingManagerExtension;
    setHoldButtonDisable(false);
    setHoldButtonColor('orange');
    if (extension) {
      // Wait two seconds, and then dispatch the extension.
      return new Promise((resolve) => { setTimeout(() => { resolve(); }, 2000); }).then(() => {
        dispatch(sendDigits(extension));
      });
    }
  }

  const managerLeft = (data) => {
    logger.info(`Manager has left the conference - ${data.callSid}`);
    dispatch(promptModularSendField(false, 'enableManagerHoldCallButton'));
    dispatch(promptModularSendField(false, 'isManagerCallOnHold'));
    const bulkDispatch = { isCallingManager: false, callingManagerExtension: null, callingManagerIndex: null, callingManagerName: '', callingManagerNumber: null };
    dispatch(sendQueueObject(bulkDispatch));
    setCallColor('green');
    setHoldButtonColor('gray');
    setHoldButtonDisable(true);
    setManagerCalled(false);

  }

  // sets the call button to disabled if the current time is within the doNotCallManagerHours
  const doNotCallManager = () => {
    const { doNotDisturbManagerHours, timezone } = from;
    const managerLocTime = moment().tz(timezone);
    const hourNow = moment(managerLocTime).hour();
    if (doNotDisturbManagerHours && doNotDisturbManagerHours.includes(hourNow)) {
      setCallDisabled(true);
      setCallColor('gray');
    }
  }

  const managerCallClick = async (manager, index, isCalling) => {
    // If number has extension then dispatch and update the state to send digits.
    const applicableNumber = process.env.REACT_APP_MANAGER_TEST_NUMBER != 'false' ? process.env.REACT_APP_MANAGER_TEST_NUMBER : manager.number;
    if (isCalling) {
      dispatch(promptModularSendField(false, 'managerVendConfirmation10s'));
    }
    const dialReadyNumber = `+1${(applicableNumber).replace(/[^\d]/g, '')}`;
    try {
      const managerCallData = {
        "number": dialReadyNumber,
        "ongoingCallId": ongoingCallId,
        "ongoingConferenceSid": ongoingConferenceSid,
        "username": username,
        "managerName": manager.name,
        "isCallingManager": !isCallingManager,
        "ongoingManagerCallSid": ongoingManagerCallSid || null
      };
      dispatch(managerCall(managerCallData));
      dispatch(promptModularSendField(manager.name, 'selectedManagerName'));
      const bulkDispatch = {
        callingManagerName: manager.name,
        callingManagerNumber: manager.number,
        callingManagerIndex: index,
        isCallingManager: !isCallingManager,
        callingManagerExtension: manager.extension
      };
      dispatch(sendQueueObject(bulkDispatch));
    } catch (error) {
      logger.info(`Error making manager call`);
      logger.info(error);
    }
  }

  // switches ability to call and hang up with managers
  const managerCallToggle = (manager, index) => {
    if (!managerCalled) {
      managerCallClick(manager, index, true);
      setCallColor('gray');
      setCallDisabled(true);
      const managerFirstCallTimer = setTimeout(() => {
        if (!managerCalledFor10s) {
          setManagerCalledFor10s(true);
        }
        setCallDisabled(false);
        setManagerCalled(true);
        setCallColor('red');
      }, 10000
      )
      timeOut.current = managerFirstCallTimer;
    } else {
      managerCallClick(manager, index, false);
      setManagerCalled(false);
      setCallColor('green');
    }
  }

  const setManagerCallHold = (conferenceSid, managerCallSid, toggle) => {
    dispatch(promptModularSendField(toggle, 'isManagerCallOnHold'));
    dispatch(toggleManagerCallHold(conferenceSid, managerCallSid, toggle));
  }

  return (
    <Fragment>
      {doNotCallManager() &&
        <h3 style={{ color: '#FF0000' }}>The managers are currently unavailable</h3>
      }
      {!from.managersEmailOnly && 
      <Fragment>
        {!isParkerCallOnHold
        ?
        <FreshDirectionText>Please put the parker on hold before calling a manager.</FreshDirectionText>
        :
        <FreshDirectionText>Select a manager's phone icon to dial.</FreshDirectionText>
      }
      </Fragment>
      }
      {(from.managers && from.managers[0])
        ?
        <Fragment>
          <TableContainer style={{ margin: '0 0 1em 0', overflow: 'scroll' }} component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow style={{ background: '#297eb6' }}>
                  <TableCell align='center' style={{ color: '#fff' }}>Phone</TableCell>
                  <TableCell align='center' style={{ color: '#fff' }}>Name</TableCell>
                  <TableCell align='center' style={{ color: '#fff' }}>Availability</TableCell>
                  <TableCell align='center' style={{ color: '#fff' }}>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {from.managers.map((manager, index) => {
                  const applicableNumber = process.env.REACT_APP_MANAGER_TEST_NUMBER != 'false' ? process.env.REACT_APP_MANAGER_TEST_NUMBER : manager.number
                  return (
                    <TableRow
                      key={manager.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align='center'>
                        <Tooltip title={applicableNumber ? applicableNumber : 'Calling is unavailable, please email using the provided address'} placement='top'>
                          <span>
                            <Button
                              style={{ backgroundColor: callColor, borderRadius: '50%', minWidth: '1em', marginRight: '.5em' }}
                              disabled={callDisabled}
                              onClick={() => { managerCallToggle(manager, index) }}>
                              <CallIcon sx={{ color: '#FFF' }} />
                            </Button>
                          </span>
                        </Tooltip>
                        <Tooltip title={!isManagerCallOnHold ? 'Hold call' : 'Return to call'} placement='top'>
                          <span>
                            <Button
                              style={{ backgroundColor: holdButtonColor, borderRadius: '50%', minWidth: '1em' }}
                              disabled={holdButtonDisable}
                              onClick={() => setManagerCallHold(ongoingConferenceSid, ongoingManagerCallSid, !isManagerCallOnHold)}>
                              {!isManagerCallOnHold &&
                                <PauseIcon sx={{ color: '#FFF' }} />}
                              {isManagerCallOnHold &&
                                <PlayArrowIcon sx={{ color: '#FFF' }} />}
                            </Button>
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {manager.name ? manager.name : '-'}
                      </TableCell>
                      <TableCell align='center'>{manager.availability ? manager.availability : '-'}</TableCell>
                      <TableCell align='center'>{manager.email ? manager.email : '-'}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
        :
        <Fragment>
          <CircularProgress style={{ margin: 'calc(50% - 75px) 0 0 0' }} />
        </Fragment>
      }
    </Fragment>
  )
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from'),
    username: authPullModular(state, 'username'),
    isCallingManager: queuePullModular(state, 'isCallingManager'),
    ongoingCallId: queuePullModular(state, 'ongoingCallId'),
    ongoingParkerCallSid: queuePullModular(state, 'ongoingParkerCallSid'),
    ongoingConferenceSid: queuePullModular(state, 'ongoingConferenceSid'),
    ongoingManagerCallSid: queuePullModular(state, 'ongoingManagerCallSid'),
    isParkerCallOnHold: queuePullModular(state, 'isParkerCallOnHold'),
    isManagerCallOnHold: promptPullModular(state, 'isManagerCallOnHold'),
    callingManagerName: queuePullModular(state, 'callingManagerName'),
    callingManagerNumber: queuePullModular(state, 'callingManagerNumber'),
    callingManagerIndex: queuePullModular(state, 'callingManagerIndex'),
    callingManagerExtension: queuePullModular(state, 'callingManagerExtension'),
    managerVendConfirmation10s: promptPullModular(state, 'managerVendConfirmation10s'),
    enableManagerHoldCallButton: promptPullModular(state, 'enableManagerHoldCallButton')
  }
}

const freshContactManagersSocket = (props) => (
  <SocketContext.Consumer>
    {(socket) => {
      return <FreshContactManagers {...props} socket={socket} />
    }
    }
  </SocketContext.Consumer>
)

export default (connect(mapStateToProps)(freshContactManagersSocket));