import { Checkbox, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAnalystList, updateDisableAnalyst } from "../../store/auth/actions";
import { pullModular as authPullModular } from "../../store/auth/reducer";

const DisableAnalyst = (props) => {
  
  const [checked, setChecked] = useState(true);
  
  useEffect(() => {
    props.dispatch(getAnalystList());
  }, [])
  
  const toggleAnalyst = async ({event, value, userId}) => {
    await props.dispatch(updateDisableAnalyst({ userId, userDisabled: value }))
    props.dispatch(getAnalystList());
  }

  const friendlyUsername = (user) => {
    const splitUsername = user.username.split('.');
    const uppercaseSplitUsernames = splitUsername.map((usernamePart) => {
      return usernamePart.charAt(0).toUpperCase() + usernamePart.substring(1);
    })
    const separatedUsername = uppercaseSplitUsernames.join(' ');
    return separatedUsername;
  }

  const generateTableRow = () => {
    return (
    <Fragment>
      {props.analystDetailsList.map((user) => (
      <TableRow key={user.userId} >
        <TableCell component="th" >
          {friendlyUsername(user)}
        </TableCell>
        <TableCell align="center">
          { user.userDisabled ? 'Disabled' : 'Active'}
        </TableCell>
        <TableCell align="center">
          <Checkbox
            onChange={(event, value) => { toggleAnalyst({ event, value, userId: user.userId }) }} 
            checked={!user.userDisabled}
            />
        </TableCell>
      </TableRow>
    ))}
    </Fragment>)
  }

  return (
    <div className='home-div'>
      <h2>Disable/Enable Analyst</h2>
      <p>Disable or re-enable login access for Analysts</p>
      <p>Click the icon next to the Analyst's name to toggle their active status</p>
      <p>The next time an unselected analyst attempts to log in, they will receive a message to speak wth Jacqueline.</p>
      
      <TableContainer className='disable-analyst-table-container'>
      <Table aria-label="simple table" className='analyst-list-table'>
        <TableHead>
          <TableRow>
            <TableCell>Analyst Name</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Set</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         {generateTableRow()}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  )


}

function mapStateToProps(state) {
  return {
    analystDetailsList: authPullModular(state, 'analystDetailsList')
  };
}

export default (connect(mapStateToProps)(DisableAnalyst));