import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptSelectors from '../../store/prompt/reducer';
import * as authSelectors from '../../store/auth/reducer';
import ReasonButtons from '../../components/reasonButtons';
import * as promptActions from '../../store/prompt/actions';
import ScriptText from '../../components/ScriptText';
import DirectionText from '../../components/DirectionText';
import Paper from 'material-ui/Paper';
import ParkWestinPrompt from '../../components/parkWestinPrompt';
import PromptBox from '../../components/promptBox';
import ContactManagers from './ContactManagers';
import RaisedButton from 'material-ui/RaisedButton';
import { retrieveLogger } from '../../lib/logger';
import LightningPayFAQ from '../../LightningPayFAQ';
let logger = retrieveLogger();

class First extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.reloadInterval = null;
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history('/login');
    }
    // Ensure state is set to 0 incase someone is going back to First and going to a new route.
    this.props.dispatch(promptActions.updateIssueStep(0));
    if (this.props.from.vendForAll) {
      this.props.history.push('/vendForAll');
    }
    logger.info('Just hit First component for new call.');
  }

  componentWillUnmount() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (this.props.from.vendForAll) {
      this.props.history.push('/vendForAll');
    }
  }

  sendIssueTypeToStore(event) {
    // On top of sending issue type to store, we should disable Ghost Call once any of the "types" are clicked.
    this.props.dispatch(promptActions.modularSendField(true, 'hideGhostCall'));
    this.props.dispatch(promptActions.updateIssueType(event));
  }

  completeCall() {
    this.sendIssueTypeToStore('incomingManagerCall');
    this.props.history.push('/confirmationScreen');
  }

  render() {
    // Render something special for Park Westin
    if (this.props.from.locationId === 9999) {
      return (
        <div className="home-div">
          <div className="first-div-content">
            <ParkWestinPrompt locationName={this.props.from.locationName} />
          </div>
        </div>
      );
    } else if (this.props.from.barebones) {
      return (
        <PromptBox locationName={this.props.from.locationName}>
          <DirectionText>
            Please call the Hartford Supervisor that is assigned to this lot today in order to assist the parker.
          </DirectionText>
          <DirectionText>
            There will generally be the same person staffed there for an entire shift, so once you figure out who is staffed at this location for your shift,
            I would advise directly contacting them for requests regarding this location.
          </DirectionText>
          <DirectionText>
            All vending for this location will be done via the Emergency Computer in the corner.
          </DirectionText>
          <ContactManagers showButtons={[]} />
        </PromptBox>
      )
    } else if (this.props.from.incomingManagerCall) {
      return (
        <PromptBox locationName={this.props.from.locationName}>
          <DirectionText>
            This is a an incoming call from a lot manager. End the call when done talking.
          </DirectionText>
          <div style={{ marginTop: 15}}>
            <RaisedButton label="Complete Call" onClick={() => this.completeCall()} />
          </div>
        </PromptBox>
      )
    } else if (this.props.from.lightningPayFAQPage) {
      return (
        <LightningPayFAQ />
      )
    }
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
            <ScriptText>"Cloudpark Command Center, this is {this.props.friendlyName} speaking. What is the issue I can assist you with today?"</ScriptText>
            { this.props.from.twoTransientTickets &&
              <ScriptText>
                This lane requires transient parkers to pull two tickets in order to gain access.

              </ScriptText>
            }
            {this.props.from.homePageNote &&
              <DirectionText>
                <span style={{ color: 'Red'}}>
                  Note:
                </span>
                {` ${this.props.from.homePageNote}`}
              </DirectionText>

            }
            <ReasonButtons
              hideGhostCall={this.props.hideGhostCall}
              from={this.props.from}
              sendIssueTypeToStore={this.sendIssueTypeToStore}
              hotel={this.props.from.hotelLocation}
            />
          </Paper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullModular(state, 'issue'),
    username: authSelectors.pullModular(state, 'username'),
    friendlyName: authSelectors.pullModular(state, 'friendlyName'),
    hideGhostCall: promptSelectors.pullModular(state, 'hideGhostCall')
  };
}

export default withRouter(connect(mapStateToProps)(First));
