import { datadogLogs } from '@datadog/browser-logs';

let logger;

export function retrieveLogger () {
  // Leaving this commented out for now, but this is the handler code we are going to want to run in Production after we are done testing.
  // const handlers = process.env.REACT_APP_ENV == 'production' ? [ 'http', 'console' ] : [ 'console' ];
  const handlers = [ 'http', 'console' ];
  logger = datadogLogs.createLogger('general', { level: 'info', handler: handlers });
  logger.addContext('environment', process.env.REACT_APP_ENV);
  return logger;
};

// Think it is pretty easy to just call logger.addContext in-line when we need to. No need for this function.
export function updateLogger(contexts) {
  for (const context in contexts) {
    datadogLogs.addLoggerGlobalContext(context, contexts[context]);
  }
  return logger;
}

export function removeFromLogger(context) {
  datadogLogs.removeLoggerGlobalContext(context);
  return logger;
}