import { Button, Paper } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PromptWrapper from './containers/Prompt/PrepaidFlows/PromptWrapper';
import { pullModular as promptPullModular } from './store/prompt/reducer';
import { pullModular as queuePullModular } from './store/queue/reducer';
import { pullModular as authPullModular } from './store/auth/reducer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import FreshContactManagers from './containers/Prompt/PrepaidFlows/PrepaidNodes/FreshContactManagers';
import ScriptText from './components/ScriptText';
import FreshDirectionText from './containers/Prompt/PrepaidFlows/FreshDirectionText';
import { createLightningPayQuestion, modularSendField } from './store/prompt/actions';

const LightningPayFAQ = (props) => {

  const [shouldShowManager, setShouldShowManager] = useState(false);
  const [managerCalledFor10s, setManagerCalledFor10s] = useState(false);
  const [managerCalled, setManagerCalled] = useState(false);

  const { history, otherQuestion, friendlyName} = props;
  
  const { lightningPayQuestions, nonLightningLocation } = props.from;

  const updateOtherQuestion = (event) => {
    props.dispatch(modularSendField(event.target.value, 'otherQuestion'));
  }

  return (
    <PromptWrapper >
      {!shouldShowManager &&
        <Fragment>
          <ScriptText>Cloudpark Command Center, this is {friendlyName} speaking. What is the issue I can assist you with today?</ScriptText>
          {!nonLightningLocation && 
          <Paper className='lightning-pay-paper-text'>
            If a parker has put in the wrong departure time, tell them that everything is okay. 
              They currently have an active stay that won't end until they click 'End Stay".
          </Paper>}
          <FreshDirectionText>Click one of the questions listed below for the answer, or "Other" to fill in a question that is not present</FreshDirectionText>
          <Paper className='lightning-pay-table-style'>
            {lightningPayQuestions && lightningPayQuestions.map((question) => {
              return (
                <Accordion disableGutters={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{question.q}</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    defaultValue={question.a}
                  >
                    <Typography>{question.a}</Typography>
                  </AccordionDetails>
                </Accordion>
              )
            })}
            <Accordion disableGutters={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Other</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Please write in the parker's question</Typography>
                <TextField
                  value={otherQuestion}
                  hiddenLabel
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  fullWidth={true}
                  onChange={updateOtherQuestion}
                  >
                  <input maxLength={256}>
                  </input>
                  </TextField>
              </AccordionDetails>
            </Accordion>
          </Paper>
          <Button className='button-style' variant='outlined' onClick={() => { history.push('/confirmationScreen') }}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => { setShouldShowManager(true) }}>Contact Managers</Button>
        </Fragment>
      }
      {shouldShowManager &&
        <Fragment>
          <p>
            <span className='contact-managers-header'>{' '}Parker Question:{' '}</span>{otherQuestion}
          </p>
          <FreshContactManagers
            managerCalledFor10s={managerCalledFor10s}
            setManagerCalledFor10s={setManagerCalledFor10s}
            managerCalled={managerCalled}
            setManagerCalled={setManagerCalled}
          />
          <Button className='button-style' variant='outlined' onClick={() => { history.push('/confirmationScreen') }}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => { setShouldShowManager(false) }}>
            Back to FAQ's
          </Button>
        </Fragment>
      }
    </PromptWrapper>
  )
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from'),
    friendlyName: authPullModular(state, 'friendlyName'),
    isParkerCallOnHold: queuePullModular(state, 'isParkerCallOnHold'),
    ongoingCallId: queuePullModular(state, 'ongoingCallId'),
    otherQuestion: promptPullModular(state, 'otherQuestion')
  }
}

export default withRouter(connect(mapStateToProps)(LightningPayFAQ));