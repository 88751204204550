const axiosInstance = require('./axiosInstance');

export default function twilioToken({ username, client, taskrouter }) {
  return new Promise((resolve, reject) => {
    return axiosInstance({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}/token`,
      data: { username, client, taskrouter }
    }).then((d) => {
      const token = d.data.token;
      const taskrouterToken = d.data.taskrouterToken;
      resolve({ token, taskrouterToken });
    });
  });
}
