import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import * as authSelectors from '../../store/auth/reducer';
import * as authActions from '../../store/auth/actions';
import { Grid, Paper, Button, TextField, Select, OutlinedInput } from '@material-ui/core';
import * as promptActions from '../../store/prompt/actions';
import _ from 'lodash';
import { titleCase } from "title-case";

class PasswordReset extends Component {

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { id: null, password: null, confirmPassword: null };
    this.isFormComplete = false;
    this.key = null;
  }

  componentDidMount() {
    if (this.props.authLevel > 0 || this.props.authLevel === null) {
      this.props.history.push('/unauthorized');
    } else {
      this.props.dispatch(authActions.getAllUsers());
    }
  }

  componentDidUpdate() {
    this.isFormComplete = Object.keys(this.state).every(key => {
      this.key = key;
      return !!this.state[key];
    });
  }

  componentWillUnmount() {
    Object.keys(this.state).forEach(key => this.setState({ [key]: null }));
  }

  updateField(event, target) {
    this.setState({ [target]: event.target.value }); 
  }

  generateUserOptions(user, index) {
    const { id, Username } = user;
    return (
      <Fragment key={(index).toString()}>
        <option value={id}> {`${titleCase(Username.replace("."," "))}`} </option>
      </Fragment>
    )
  }

  async resetPassword() {
    if (!this.isFormComplete) {
      this.props.dispatch(promptActions.showSnackBar({ 
        type: 'error', 
        message: `Please enter all the required fields`
      }));
    } else if (this.state.password !== this.state.confirmPassword) {
      this.props.dispatch(promptActions.showSnackBar({ 
        type: 'error', 
        message: `Password does not match`
      }));
    } else {
      const result = await this.props.dispatch(authActions.resetPassword(this.state));
      const { success, message } = result.data;
      if (success) {
        Object.keys(this.state).forEach(key => this.setState({ [key]: null }));
        this.props.dispatch(promptActions.showSnackBar({ type: 'success', message: message }));
      }
    }
  }

  render() {
    return (
      <Fragment>
        <div className="home-div">          
          <h2> User Management </h2>
          <Fragment>
            <Select
              native
              margin="dense"
              value={this.state.id || ""}
              onChange={(event) => {this.updateField(event, 'id')}}
              style={{ width: '60%', marginTop: 2, marginBottom: 10 }}
              input={<OutlinedInput name="User" />}
            > 
              <option> Please select user </option>
              {_.map(this.props.allUsers, this.generateUserOptions)}
            </Select>
            <TextField
              label="New Password"
              placeholder="Enter New Password"
              value={this.state.password || ""}
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {this.updateField(event, 'password')}}
              style={{ width: '60%', marginTop: 2, marginBottom: 10 }}
            />
            <TextField
              label="Confirm Password"
              placeholder="Confirm Password"
              value={this.state.confirmPassword || ""}
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {this.updateField(event, 'confirmPassword')}}
              style={{ width: '60%', marginTop: 2, marginBottom: 10 }}
            />
            <div style={{ marginTop: 5 }}>
              <Button                  
                style={{ marginTop: 40 }} 
                variant="contained" 
                color="primary"
                onClick={this.resetPassword}
              >
                Submit
              </Button>
            </div>
          </Fragment>          
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    authLevel: authSelectors.pullModular(state, 'authLevel'),
    allUsers: authSelectors.pullModular(state, 'allUsers'),
  }
}

export default withRouter(connect(mapStateToProps)(PasswordReset));
