import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import markerImage from '../assets/propark_new.png';
import { mapsApiKey } from '../lib/config';

export class GoogleMap extends Component {
  render() {
    return (
      <div className="outer-map-box">
        <Map
          className="map"
          google={this.props.google}
          zoom={20}
          mapType="hybrid"
          style={{ width: 'calc(100% - 30px)' }}
          center={{
            lat: this.props.locX,
            lng: this.props.locY
          }}
          initialCenter={{
            lat: this.props.locX,
            lng: this.props.locY
          }}
        >
          <Marker
            name={this.props.name}
            position={{ lat: this.props.locX, lng: this.props.locY }}
            icon={{ url: markerImage }}
          />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper ({
  apiKey: mapsApiKey
})(GoogleMap);
