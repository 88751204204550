import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { Provider } from 'react-redux';
import SocketContext from './services/socketContext';
import * as io from 'socket.io-client'
import thunk from 'redux-thunk';
import App from './App';
import './index.css';
import * as reducers from './store/reducers';
import { datadogLogs } from '@datadog/browser-logs';
import AppErrorBoundary from './containers/AppErrorBoundary';

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  service: 'cloudpark-client-browser',
  sampleRate: 100,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(combineReducers(reducers), composeEnhancers(applyMiddleware(thunk)));

const socket = io(process.env.REACT_APP_WSS_URL, {
  transports: ['websocket'],
});

ReactDOM.render(
  <Provider store={store}>
    <SocketContext.Provider value={socket}>
      <AppErrorBoundary>
        <App />
      </AppErrorBoundary>
    </SocketContext.Provider>
  </Provider>,
  document.getElementById('root')
);

export default store;