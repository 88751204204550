import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptActions from '../../store/prompt/actions';
import * as promptSelectors from '../../store/prompt/reducer';
import * as authSelectors from '../../store/auth/reducer';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import DirectionText from '../../components/DirectionText';
import ScriptText from '../../components/ScriptText';
import ContactManagers from './ContactManagers';

class UberDriver extends Component {
  constructor(props) {
    super(props);
    this.reloadInterval = null;
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    /* reset global step prop */
    this.props.dispatch(promptActions.updateIssueStep(0));
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentWillUnmount() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  updateField(event, value, target) {
    this.props.dispatch(promptActions.modularSendField(value, target));
  }

  render() {
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            {this.props.from.laneType == 'Entrance' &&
              <Paper zDepth={1} className="paper-padding">
                <ScriptText>
                  Please pull a ticket and ensure you pick up your passenger and exit within 15 minutes by scanning your ticket at the exit. If you stay longer than 15 minutes you will be charged.
                </ScriptText>
                <Link to="/confirmationScreen">
                  <RaisedButton label="Complete Call" />
                </Link>
              </Paper>
            }
            {this.props.from.laneType == 'Exit' &&
              <Paper zDepth={1} className="paper-padding">
                <ScriptText>
                  This facility doesn't have free parking for Uber/Lyft drivers. You will have to scan your ticket and if you are within the 15 minutes grace period, the gate should go up for you. Otherwise, you will have to pay what the machine is charging.
                </ScriptText>
                <Link to="/gracePeriod">
                  <RaisedButton label="Next Steps" />
                </Link>
                <Link to="/confirmationScreen">
                  <RaisedButton label="Complete Call" />
                </Link>
              </Paper>
            }
            {this.props.from.laneType == 'Paystation' &&
              <Paper zDepth={1} className="paper-padding">
                <DirectionText>
                  Please direct the parker to move to the exit lane for assistance.
                </DirectionText>
                <Link to="/confirmationScreen">
                  <RaisedButton label="Complete Call" />
                </Link>
              </Paper>
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username')
  };
}

export default withRouter(connect(mapStateToProps)(UberDriver));
