const axiosInstance = require('../lib/axiosInstance');

class GetQueueService {
  async getQueue(targetQueue) {
    let url;
    if (targetQueue && targetQueue == null) {
      url = `/reloadQueue`;
    } else {
      url = `/reloadQueue/${targetQueue}`;
    }
    const response = await axiosInstance({
      method: 'get',
      url: url
    });
    let data = response.data;
    return data;
  }
}

export default new GetQueueService();