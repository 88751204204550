import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Paper from 'material-ui/Paper';
import ErrorBoundary from '../containers/ErrorBoundary';

export default class PromptBox extends Component {
  constructor(props) {
    super(props);
    this.reloadInterval = null;
    autoBind(this);
  }

  render() {
    return (
      <ErrorBoundary>
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.locationName}</h2>
          </Paper>
          <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
            { this.props.children }
          </Paper>
        </div>
      </div>
      </ErrorBoundary>
    );
  }
}
