import React from "react";
import ScriptText from "../../../components/ScriptText";
import PromptWrapper from "./PromptWrapper";
import { pullModular as promptPullModular } from '../../../store/prompt/reducer';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Button from '@mui/material/Button';
import { Paper } from "@mui/material";

const PrepaidRedirectExit = (props) => {

  return (
    <PromptWrapper>
        <ScriptText>This exit doesn't take prepaid reservations, so please use one of the following lanes:</ScriptText>
        {/* returns a list of the lanes to redirect to. The div might mess up formatting but probably not */}
        <Paper>
        <ul>
        {props.from.prepaidRedirectLanes && props.from.prepaidRedirectLanes.map((redirectLane) => {
          return <li className='prepaid-redirect-lane-list'>{redirectLane}</li>
        })}
        </ul>
      </Paper>
        <Button className='button-style' variant='outlined' onClick={ () => {props.history.push('/confirmationScreen')}}>Complete Call</Button>
    </PromptWrapper>
  )
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from')
  }
}

export default withRouter(connect(mapStateToProps)(PrepaidRedirectExit));