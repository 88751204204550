import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptSelectors from '../../store/prompt/reducer';
import * as authSelectors from '../../store/auth/reducer';
import * as queueSelectors from '../../store/queue/reducer';
import DirectionText from '../../components/DirectionText';
import Paper from 'material-ui/Paper';
import ScriptText from '../../components/ScriptText';
import { RaisedButton } from 'material-ui';

class RouteDisabled extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (this.props.location.test) {

    }
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <div className="home-div">
        <div className="home-div-content">
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            <Paper zDepth={1} className="paper-padding">
              { this.props.location.state.message ?
                  <ScriptText>{this.props.location.state.message}</ScriptText>
                :
                  <DirectionText>This route is disabled for this lane type. Please end call or hit Home to try again.</DirectionText>
              }
            <Link to="/confirmationScreen">
              <RaisedButton label="Exit" />
            </Link>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username')
  };
}

export default withRouter(connect(mapStateToProps)(RouteDisabled));
