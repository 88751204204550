import React, { useState } from 'react';
import { connect } from 'react-redux';
import { pullModular as promptPullModular } from '../../../store/prompt/reducer';
import PrepaidNotAllowed from '../PrepaidFlows/PrepaidNotAllowed';
import PrepaidEntrance1 from '../PrepaidFlows/PrepaidEntrance1';
import PrepaidEntrance2 from '../PrepaidFlows/PrepaidEntrance2';
import PrepaidExit1 from '../PrepaidFlows/PrepaidExit1';
import PrepaidExit2 from '../PrepaidFlows/PrepaidExit2';
import PrepaidExit3 from '../PrepaidFlows/PrepaidExit3';
import PrepaidRedirectEntrance from '../PrepaidFlows/PrepaidRedirectEntrance';
import PrepaidRedirectExit from '../PrepaidFlows/PrepaidRedirectExit';
import PrepaidRedirectPoF from '../PrepaidFlows/PrepaidRedirectPoF';
import './PrepaidContainer.css';
import ErrorBoundary from '../../ErrorBoundary';


const PrepaidContainer = (props) => {
  
  const [managerCalledFor10s, setManagerCalledFor10s] = useState(false);
  const [managerCalled, setManagerCalled] = useState(false);

  const managerCallProps = {
    managerCalledFor10s,
    setManagerCalledFor10s,
    managerCalled,
    setManagerCalled
  }
  //keyed list of the Prepaid Flows. When a new flow is added, just add it to this list
  const prepaidOfferings = {
    'prepaidNotAllowed': <PrepaidNotAllowed { ...managerCallProps } />,
    'prepaidEntrance1': <PrepaidEntrance1 { ...managerCallProps } />,
    'prepaidEntrance2': <PrepaidEntrance2 />,
    'prepaidExit1': <PrepaidExit1 />,
    'prepaidExit2': <PrepaidExit2 { ...managerCallProps } />,
    'prepaidExit3': <PrepaidExit3 { ...managerCallProps } />,
    'prepaidRedirectEntrance': <PrepaidRedirectEntrance />,
    'prepaidRedirectExit': <PrepaidRedirectExit />,
    'prepaidRedirectPoF': <PrepaidRedirectPoF />
  }

  //checks to see if the locVar for prepaidOffering includes one of the prepaidOfferings, if it does displays that offering's value
  //If not, displays PrepaidNotAllowed
  // const offering = prepaidOfferings[this.props.from.prepaidOffering];
  const offering = prepaidOfferings[props.from.prepaidOffering];
  return (
    <ErrorBoundary>
      {offering ? offering : <PrepaidNotAllowed />}
    </ErrorBoundary>
  )
}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from')
  }
} 

export default (connect(mapStateToProps)(PrepaidContainer));