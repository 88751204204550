import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as promptActions from '../../../store/prompt/actions';
import * as authSelectors from '../../../store/auth/reducer';
import * as queueSelectors from '../../../store/queue/reducer';
import { processLostTicketDigits } from '../../../store/queue/actions';
import RaisedButton from 'material-ui/RaisedButton';
import { Paper, TextField } from '@material-ui/core';
import NextSteps from '../../../components/nextSteps';
import ScriptText from '../../../components/ScriptText';
import DirectionText from '../../../components/DirectionText';
import MonthlyOnlyLane from '../../../components/monthlyOnlyLane';
import SpecialMonthlyOnlyLane from '../../../components/specialMonthlyOnlyLane';
import PromptBox from '../../../components/promptBox';
import ContactManagers from '../ContactManagers';
import ErrorBoundary from '../../ErrorBoundary';

class LostTicket extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      renderComponent: {
        step: true
      },
      stepOneText: '',
    };
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (this.props.from.lostTicketLPR) {
      this.props.history.push('/tibaAPI/tibaLPRSearch');
    }
    /* reset global step prop */
    this.props.dispatch(promptActions.updateIssueStep(0));

    if (this.props.from.lostTicketPayment || this.props.from.lostTicketManager) {
      this.setState({ stepOneText: 'Next Step' })
    } else {
      this.setState({ stepOneText: 'Parker Refuses Payment' })
    }
  }


  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  completeStep(step) {
    this.props.dispatch(promptActions.updateIssueStep(step));
  }

  processLostTicketDigits() {
    this.props.dispatch(processLostTicketDigits(this.props.from.processLostTicketDigits));
    return;
  }

  showNameInput() {
    this.props.dispatch(promptActions.modularSendField(true, 'lostTicketNameInput'));
  }

  updateField(event, target) {
    this.props.dispatch(promptActions.modularSendField(event.target.value, target));
  }

  updateState(event, target) {
    this.setState({ [target]: event.target.value });
  }

  routeToVend() {
    this.props.history.push('/vendGate');
  }

  showComponent(toRender) {
    const allComponents = { ...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }

  lostTicketInfo() {
    if (this.props.from.lostTicketLogData && !this.props.visitPurpose) {
      this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: `Place of Visit is required to Vend the Gate`
      }));
    } else {
      this.props.history.push('/vendGate');
    }
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'showPushRateForm':
        this.props.dispatch(promptActions.modularSendField(false, 'pushRateQuestion'));
        this.props.history.push('/tibaAPI/tibaManagerPushRate');
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  render() {
    if (!this.props.from.transientLane && this.props.from.monthlyLane) {
      return <ErrorBoundary><MonthlyOnlyLane locationName={this.props.from.locationName} ticketAcceptanceRedirect={this.props.from.ticketAcceptanceRedirect} /></ErrorBoundary>
    }
    // Currently only used for Williams Tower
    if (this.props.from.contractorLane && !this.props.from.transientLane) {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className="home-div-content">
              <Paper className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <div className="home-div-body">
                <Paper className="paper-padding">
                  <ScriptText>"You are in a Contractor-Only lane. Please redirect to one of the following lanes: {this.props.from.ticketAcceptanceRedirect}."</ScriptText>
                  <Link to="/confirmationScreen">
                    <RaisedButton label="Complete Call" />
                  </Link>
                </Paper>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      );
      // Conditional render for Lincoln Harbor, as they have special needs for "Special Monthly Parkers".
    } else if (!this.props.from.transientLane && !this.props.from.monthlyLane && this.props.from.specialMonthlyLane) {
      let availableLanes = promptActions.prepareAvailableLanes(this.props.from);
      return (
      <ErrorBoundary>
        <SpecialMonthlyOnlyLane
          locationName={this.props.from.locationName}
          specialMonthlyLane={this.props.from.specialMonthlyLane}
          redirect={availableLanes}
        />
      </ErrorBoundary>
      )
    } else if (this.props.from.lostTicketVendGate) {
      return (
        <PromptBox locationName={this.props.from.locationName}>
          {this.props.from.lostTicketNotes
          ?
            <ScriptText>
              {this.props.from.lostTicketNotes}
            </ScriptText>
          :
            <DirectionText>
              {this.props.from.lostTicketLogData
              ?
                `Please log the Parker info and Vend the Gate.`
              :
                `If the parker has lost the ticket Vend the gate, this location does not have a lost ticket process set up yet`
              }
            </DirectionText>
          }

          {this.props.from.lostTicketLogData &&
            <TextField
              label="Place of Visit"
              margin="dense"
              variant="outlined"
              inputProps={{ className: 'data-hj-allow' }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                this.updateField(event, 'visitPurpose');
              }}
              style={{ width: '100%', marginTop: 15 }}
            />
          }

          <div style={{ marginTop: 15 }}>
            <RaisedButton label="Vend Gate" onClick={() => this.lostTicketInfo()} />
            <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
          </div>
        </PromptBox>
      )
    } else {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className="home-div-content">
              <Paper className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <div className="home-div-body">
                {this.state.renderComponent.step &&
                  <Fragment>
                    <Paper className="paper-padding">
                      {/* If Lost Tickets are enabled in this location's variables, display the Lost Ticket Notes. */}
                      {this.props.from.lostTicket && <ScriptText>"{this.props.from.lostTicketNotes}"</ScriptText>}
                      {/* lostTicketLogName is a location variable set for allowing the parkers to somehow bypass the original Lost Ticket method
                        by simply logging the name of the parker. This should be very rarely used and only with explicit instruction from management.
                    */}
                      {this.props.step === 0 && this.props.from.lostTicketLogName &&
                        <DirectionText>{this.props.from.lostTicketLogName}</DirectionText>
                      }
                      {/* If we are handling lostTicketLogName, once they click the button to go down that route, we need to have a field to take the name. */}
                      {this.props.step === 0 && this.props.lostTicketNameInput &&
                        <TextField
                          label="EHE Parker Name"
                          margin="dense"
                          variant="outlined"
                          inputProps={{ className: 'data-hj-allow' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(event) => {
                            this.updateField(event, 'selectedParkerName');
                          }}
                          style={{ width: '100%', marginTop: 15 }}
                        />
                      }
                    </Paper>
                    {this.props.step === 0 && this.props.from.lostTicketManager &&
                      <Paper zDepth={2} className="paper-padding">
                        {/* If location variables dictate logging name, give a button for that option. */}
                        {this.props.from.lostTicketLogName &&
                          <RaisedButton
                            label="EHE Parker"
                            onClick={this.showNameInput}
                          />
                        }
                        {/* If lostTicketLogName locVar is set, and we have clicked the button above, and if they have entered at least 3 characters into the
                          selectedParkerName input, show the vend button.
                      */}
                        {this.props.from.lostTicketLogName && this.props.lostTicketNameInput &&
                          <RaisedButton
                            // Make the button disabled, but visible, once they have clicked EHE Parker but they have not yet put a sufficient name.
                            disabled={!(this.props.selectedParkerName && this.props.selectedParkerName.length > 3)}
                            // EHE Only is an additional reminder to the analysts. This should not be hard coded, and we should have a locVar such as 
                            // "lostTicketLogNameVendButtonLabel" or something... but I do not want to get that granular with this request.
                            // If we ever end up reusing lostTicketLogName, this comment should suffice in reminding us to make this more robust. 
                            label="Vend Gate (EHE Only)"
                            onClick={this.routeToVend}
                          />
                        }
                        {this.props.from.processLostTicketDigits &&
                          <RaisedButton
                            label="Process Lost Ticket"
                            onClick={this.processLostTicketDigits}
                          />
                        }
                        {this.props.from.lostTicketTD
                        ?
                          <RaisedButton label="Next Step" onClick={async () => {
                            const shouldRedirect = await this.props.dispatch(promptActions.shouldRedirectToIntercom("lostTicket"));
                            if (shouldRedirect) {
                              this.props.history.push('/redirectToIntercom');
                            } else {
                              const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('lostTicket'));
                              if (shouldEnablePIL) {
                                this.props.history.push('/pil/PILEntryInput')
                              } else {
                                this.props.history.push('/ticketDiscrepancy')
                              }
                            }
                          }}
                          />
                        :
                          <RaisedButton
                            label={this.state.stepOneText}
                            onClick={async () => {
                              const shouldRedirect = await this.props.dispatch(promptActions.shouldRedirectToIntercom("lostTicket"));
                              if (shouldRedirect) {
                                this.props.history.push('/redirectToIntercom');
                              } else {
                                const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('lostTicket'));
                                if (shouldEnablePIL) {
                                  this.props.history.push('/pil/PILEntryInput')
                                } else {
                                  this.completeStep(1);
                                }
                              }
                            }}
                          />
                        }
                        <Link to="/confirmationScreen">
                          <RaisedButton label="Complete Call" />
                        </Link>
                      </Paper>
                    }
                    {!this.props.from.lostTicketManager && <NextSteps type="route" name="Parker Refuses Payment" route={'/ticketDiscrepancy'} />}
                    {this.props.step === 1 &&
                      <ContactManagers
                        showButtons={[`${this.props.from.vendingSeconds > 0 && 'ticketDiscrepancy'}`, 'vendGate', 'managerAssisting', 'showCustomButton']}
                        btnClick={this.btnClick}
                        btnFrom="lostTicket"
                        customButtonData={[
                          {
                            "label": "Manager Asked to Push Rate",
                            "action": "showPushRateForm"
                          },
                        ]}
                      />
                    }
                  </Fragment>
                }

                {this.state.renderComponent.showManagersList &&
                  <ContactManagers
                    showButtons={['ticketDiscrepancy', 'vendGate', 'managerAssisting', 'showCustomButton']}
                    btnClick={this.btnClick}
                    btnFrom="lostTicket"
                    customButtonData={[
                      {
                        "label": "Manager Asked to Push Rate",
                        "action": "showPushRateForm"
                      },
                    ]}
                  />
                }
              </div>
            </div>
          </div>
        </ErrorBoundary>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    ongoingCallId: queueSelectors.pullModular(state, 'ongoingCallId'),
    step: promptSelectors.pullStep(state),
    lostTicketNameInput: promptSelectors.pullModular(state, 'lostTicketNameInput'),
    selectedParkerName: promptSelectors.pullModular(state, 'selectedParkerName'),
    visitPurpose: promptSelectors.pullModular(state, 'visitPurpose')
  };
}

export default withRouter(connect(mapStateToProps)(LostTicket));
