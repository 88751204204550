import authService from '../../services/authService';
import serverInteraction from '../../services/serverInteraction';
import { retrieveLogger } from '../../lib/logger';
let logger = retrieveLogger();

export function resetPassword(data) {
  return async(dispatch, getState) => {    
    return await authService.resetPassword(data);
  }
}

export function submitLogin(username, password, socket) {
  return async(dispatch, getState) => {
    dispatch({ type: 'MODULAR_PROMPT_UPDATE', value: true, target: 'showLoader' });
    let results = await authService.sendLogin(username, password);
    if (results.userDisabled) {
      const snackbarData = { type: 'error', message: results.message };
      return dispatch({ type: 'SHOW_SNACKBAR', snackbarData });
    }
    if (!results || !results.username) {
      dispatch({ type: 'MODULAR_PROMPT_UPDATE', value: false, target: 'showLoader' });
      const snackbarData = { type: 'error', message: 'Invalid Username or Password' };
      dispatch({ type: 'SHOW_SNACKBAR', snackbarData });
      return results;
    } else {
      dispatch({ type: 'MODULAR_PROMPT_UPDATE', value: false, target: 'showLoader' });
      dispatch({ type: 'RESET_SNACKBAR'});
      dispatch({ type: 'MODULAR_AUTH_UPDATE', value: results.locX, target: 'locX' });
      dispatch({ type: 'MODULAR_AUTH_UPDATE', value: results.locY, target: 'locY' });
      dispatch({ type: 'MODULAR_AUTH_UPDATE', value: results.workerSid, target: 'workerSid' });
      await (results.workerActivities).forEach(key => {
        dispatch({ type: 'MODULAR_AUTH_UPDATE', value: key.Sid , target: key.Name });
      });
      try {
        const message = {
          socketId: socket.id,
          clientName: username,
          clientType: results.targetQueue == 2 ? 'cloudparkAnalyst' : 'HoustonFirstAnalyst',
          workerSid: results.workerSid
        }
        socket.emit('saveClientSocketInfo', message);
      } catch (error) {
        logger.error(`Sending Save Client Info to Server Failed.`);
        logger.error(`error`);
      }    
      dispatch({ type: 'SUBMIT_LOGIN', username: results.username, authLevel: results.authLevel, friendlyName: results.friendlyName, targetQueue: results.targetQueue});
      // Inject userId into Hotjar code.
      var userId =  username || null;
      window.hj('identify', userId, {});
      return results;
    }
  }
}

export function getAllUsers() {
  return async(dispatch) => {
    const result = await authService.getAllUsers();
    const { success, allUsers } = result.data;
    if (!success) {
      const snackbarData = { type: 'error', message: 'Failed to get all user data.'}
      dispatch({ type: 'SHOW_SNACKBAR', snackbarData });
    } else {
      dispatch({ type: 'MODULAR_AUTH_UPDATE', target: 'allUsers', value: allUsers})
    }
  }
}

export function registerUser(userName, userData) {
  return async(dispatch) => {
    return await authService.registerUser(userName, userData);
  }
}

export function sendLogout(username) {
  return async(dispatch, getState) => {
    const activitySid = getState().auth.Offline;
    const workerSid = getState().auth.workerSid;
    await authService.sendLogout(username, workerSid, activitySid);
    dispatch({ type: 'SUBMIT_LOGOUT'});
  }
}

export function updateWorker(username, activity) {
  return async(dispatch, getState) => {
    const activitySid = getState().auth[activity];
    const workerSid = getState().auth['workerSid'];
    const result = await authService.updateWorker(username, activitySid, workerSid);
    if (result.error) {
      logger.error(`UPDATE WORKER FAILED.`);
      logger.error(result.error);
    }
  }
}

export function modularSendField(val, tar) {
  return async (dispatch) => {
    dispatch({ type: 'MODULAR_AUTH_UPDATE', value: val, target: tar });
  }
}

export function getAvailableLocations() {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/getAvailableLocations');
  }
}

export function saveReportedIssue(currentAppState) {
  return async (dispatch) => {
    let result = await authService.saveReportedIssue(currentAppState);
    if (result.data.success === false) {
      //Dispatch an actiont to display error
    } else {
      //Dispatch action to display success message and close the modal.
      setTimeout(() => {
        dispatch({ type: 'MODULAR_AUTH_UPDATE', value: false, target: 'isModalOpen' });
      }, 500)
    }
  }
}

export function getAnalystList() {
  return async (dispatch) => {
    const result = await serverInteraction.sendServerGETRequest('/retrieveAnalystList');
    const { analystDetailsList } = result.data;
    if (result.data) {
      dispatch({ type: 'MODULAR_AUTH_UPDATE', value: analystDetailsList, target: 'analystDetailsList' });
    }
    else {
      const snackbarData = { type: 'error', message: 'Failed to get analysts.'}
      dispatch({ type: 'SHOW_SNACKBAR', snackbarData });
    }
  }
}

export function updateDisableAnalyst(data) {
  return async (dispatch) => {
    const result = await serverInteraction.sendServerPOSTRequest('/updateDisableAnalyst', data);
    if (result.data) {
      return;
    }
    else {
      const snackbarData = { type: 'error', message: 'Failed to update analyst.'}
      dispatch({ type: 'SHOW_SNACKBAR', snackbarData });
    }
  }
}

export function updateDisabledAnalyst(status) {
  return async (dispatch) => {
    // await authService.sendServerPOSTRequest()
  }
}