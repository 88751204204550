import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import RaisedButton from 'material-ui/RaisedButton';
import _ from 'lodash';
import { Paper } from '@material-ui/core';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as queueSelectors from '../../../store/queue/reducer';
import * as promptActions from '../../../store/prompt/actions';
import * as authSelectors from '../../../store/auth/reducer';
import ScriptText from '../../../components/ScriptText';
import PromptBox from '../../../components/promptBox';
import DirectionText from '../../../components/DirectionText';
import ContactManagers from '../ContactManagers';
import { retrieveLogger } from '../../../lib/logger';
import dayjs from 'dayjs';
import { pilFlatRateCheck } from '../../../lib/pilFlatRateCheck';
let logger = retrieveLogger();

class PILProcessPayment extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      renderComponent: { showScanQRCode: true, showCodeGenerationResult: false, showManagersList: false, showManagerAssisting: false },
      checkTransactionStatus: null, gracePeriod: false, showRetry: true
    }
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (this.props.pilPaymentAmount && this.props.pilPaymentCode) {
      this.showComponent(['showCodeGenerationResult']);
    }
  }

  displayEntryDateTime() {
    const { parkerEntranceDate, parkerEntranceTime } = this.props;
    if (!parkerEntranceDate && !parkerEntranceTime || this.props.issue.subtype == 'lostTicket') {
      return false;
    }
    const parkerEntranceDateTime = parkerEntranceDate + parkerEntranceTime;
    return moment(parkerEntranceDateTime, 'YYYY:MM:DD HH:mm:ss').format('LLLL');
  }

  // Function to get the payment amount and code to share with the parker.
  async pilGetPaymentCode() {
    const {
      parkerEntranceDate,
      parkerEntranceTime,
      parkerExitDate,
      parkerExitTime,
      ongoingCallId,
      username
    } = this.props;
    const {
      locationId,
      lostTicketPushRate,
      gracePeriodInt,
      monthlyApbRedirectPILAmount,
      pilFlatRateInt,
      pilFlatRateTimeGateStart,
      pilFlatRateTimeGateEnd,
      pilFlatRateDay
    } = this.props.from;
    const parkerEntranceDateTime = parkerEntranceDate + parkerEntranceTime;
    const parkerExitDateTime = parkerExitDate + parkerExitTime;
    const amountToPush = () => {
      if (this.props.issue.subtype == 'lostTicket') { return lostTicketPushRate; }
      if (this.props.issue.type == 'monthly') { return monthlyApbRedirectPILAmount; }
      if (pilFlatRateInt && pilFlatRateTimeGateStart && pilFlatRateTimeGateEnd) {
        const pilFlatRateToPush = pilFlatRateCheck({
          parkerEntranceDateTime,
          parkerExitDateTime, 
          parkerEntranceDate, 
          parkerExitDate, 
          pilFlatRateInt, 
          pilFlatRateTimeGateStart, 
          pilFlatRateTimeGateEnd, 
          pilFlatRateDay 
        })
        return pilFlatRateToPush;
      }
      return null;
    }

    const result = await this.props.dispatch(promptActions.pilGetPaymentCode({
      ongoingCallId, username, parkerEntranceDateTime, parkerExitDateTime, locationId, gracePeriodInt,
      lostTicketPushRate: amountToPush()
    }));
    const { paymentAmount, generatedCode, gracePeriod } = result.data;
    if (gracePeriod && paymentAmount == 0) {
      this.setState({ gracePeriod });
      this.props.dispatch(promptActions.modularSendField(paymentAmount, 'pilPaymentAmount'));
      this.showComponent(['showCodeGenerationResult']);
    } else if ((paymentAmount || paymentAmount > 0) && generatedCode) {
      this.setState({ gracePeriod });
      this.props.dispatch(promptActions.modularSendField(paymentAmount, 'pilPaymentAmount'));
      this.props.dispatch(promptActions.modularSendField(generatedCode, 'pilPaymentCode'));
      this.showComponent(['showCodeGenerationResult']);
      this.props.dispatch(promptActions.modularSendField(true, 'pilCheckTransactionStatus'));
    } else {
      this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: 'Error while generating code, please try again.'
      }))
      this.showComponent(['showCodeGenerationResult']);
    }
  }

  // Function to check the status of the payment transaction and display it to the analysts
  getTransactionStatus() {
    const { Status } = this.props.pilTransaction;
    if (Status == 1) {
      return (
        <p style={{ fontSize: 14, fontWeight: 'bolder' }}>
          Transaction Status:
          <span style={{ color: 'green' }}> Waiting for parker to match code. </span>
        </p>
      )
    } else if (Status == 2) {
      return (
        <p style={{ fontSize: 14, fontWeight: 'bolder' }}>
          Transaction Status:
          <span style={{ color: 'green' }}>Parker matched code. Waiting for successful payment.</span>
        </p>
      )
    } else if (Status == 3) {
      return (
        <p style={{ fontSize: 14, fontWeight: 'bolder' }}>
          Transaction Status:
          <span style={{ color: 'green' }}>Detected successful payment. Redirecting to Vend Gate screen.</span>
        </p>
      )
    } else if (Status == 4) {
      return (
        <p style={{ fontSize: 14, fontWeight: 'bolder' }}>
          Transaction Status:
          <span style={{ color: 'red' }}>Payment Declined, fill the ticket discrepancy form.</span>
        </p>
      )
    } else {
      return (
        <p style={{ fontSize: 14, fontWeight: 'bolder' }}>
          Transaction Status:
          <span style={{ color: 'red' }}>Something went wrong.</span>
        </p>
      )
    }
  }

  // Resets the PILTransaction to start over
  async pilResetEntranceTime() {
    const { pilPaymentCode, pilPaymentAmount, ongoingCallId, username } = this.props;
    await this.props.dispatch(promptActions.pilResetEntranceTime({ pilPaymentCode, pilPaymentAmount, ongoingCallId, username }))
    this.props.history.push('/pil/PILEntryInput');
  }

  // Manually checks the payment status in case the setTimeOut is not functioning properly
  async checkPaymentStatus() {
    const { pilPaymentCode } = this.props;
    const manualCheckResults = await this.props.dispatch(promptActions.manualPilCheckTransactionStatus({ generatedCode: pilPaymentCode }));
    if (!manualCheckResults) {
      logger.info('Unable to process manual check results.');
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: 'Unable to process manual check results.'
      }));
    }
    if (!manualCheckResults.data) {
      logger.info('Manual check results did not provide data.');
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: 'Manual check results did not provide data.'
      }));
    }
    const success = manualCheckResults.data.success;
    const pilTransaction = manualCheckResults.data.pilTransaction;
    if (success) {
      this.props.dispatch(promptActions.modularSendField(pilTransaction, 'pilTransaction'));
      this.props.history.push('/pil/pilPaymentResult');
    } else {
      const displayMessage = manualCheckResults.data.message || 'Unknown error occurred. Try again.';
      this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: displayMessage
      }));
    }
    return;
  }

  shouldShowVendGate() {
    const { vendGateForPIL } = this.props.from;
    const { issue } = this.props;
    if (vendGateForPIL && vendGateForPIL.includes(issue.subtype)) {
      return true;
    }
    return false;
  }

  shouldShowCustomButtons() {
    const { customButtons } = this.props.from;
    if (customButtons) {
      return customButtons.find(data => { return data.type === this.props.issue.subtype });
    } else {
      return false;
    }
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'ticketDiscrepancy':
        this.props.history.push('/ticketDiscrepancy');
        break;
      case 'showManagerAssisting':
        this.showComponent(['showManagerAssisting'])
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  showComponent(toRender) {
    const allComponents = { ...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }

  render() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        {this.state.renderComponent.showScanQRCode &&
          <Fragment>
            <ScriptText>
              Please scan the QR code on the machine or visit cloudpark.app on your mobile browser.
            </ScriptText>
            <DirectionText>
              Click "Generate Code" and share the code with the parker.
            </DirectionText>
            <div style={{ marginTop: 15 }}>
              <RaisedButton label="Generate Code" onClick={() => this.pilGetPaymentCode()} />
              <RaisedButton label="Reset Entrance Time" onClick={() => this.pilResetEntranceTime()} />
              <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
            </div>
          </Fragment>
        }

        {this.state.renderComponent.showCodeGenerationResult &&
          <Fragment>
            {this.displayEntryDateTime() &&
              <Paper square={true} elevation={24} style={{ padding: 5, fontSize: 14, fontWeight: 'bolder' }}>
                <DirectionText>
                  Entry Time: {this.displayEntryDateTime()}
                </DirectionText>
              </Paper>
            }
            <Paper square={true} elevation={24} style={{ padding: 5, marginTop: 10, fontSize: 14, fontWeight: 'bolder' }}>
              <DirectionText>
                Amount: ${this.props.pilPaymentAmount}
              </DirectionText>
            </Paper>
            {this.state.gracePeriod
              ?
              <Paper square={true} elevation={24} style={{ padding: 5, textAlign: 'center', marginTop: 10, fontSize: 14, fontWeight: 'bolder' }}>
                <DirectionText>
                  The Parker is in Grace Period. Please vend the gate.
                </DirectionText>
              </Paper>
              :
              <Fragment>
                <Paper square={true} elevation={24} style={{ padding: 5, textAlign: 'center', marginTop: 10, fontSize: 14, fontWeight: 'bolder' }}>
                  <DirectionText>
                    Share the generated code below and ask them to enter it in the text box.
                  </DirectionText>
                  <p style={{ fontSize: 30, color: 'red' }}> {this.props.pilPaymentCode ? this.props.pilPaymentCode.toUpperCase() : ""} </p>
                  <DirectionText>
                    Please stay on this page. Once the payment transaction is successful we will redirect you to the Vend Gate page.
                  </DirectionText>
                </Paper>
              </Fragment>
            }
            {!this.state.gracePeriod && this.props.pilTransaction &&
              <Paper square={true} elevation={24} style={{ padding: 5, textAlign: 'center', marginTop: 10, fontSize: 14, fontWeight: 'bolder' }}>
                <DirectionText>
                  {this.getTransactionStatus()}
                </DirectionText>
              </Paper>
            }
            <Paper square={true} elevation={24} style={{ padding: 5, marginTop: 10, fontSize: 14, fontWeight: 'bolder' }}>
              <DirectionText>
                If parker experiences issues, click Parker Claims Error. On first attempt, this will re-generate a code. Please pass this code to parker and try again. If there is still an issue, click Parker Claims Error again, and it will redirect you to the Ticket Discrepancy Form.
              </DirectionText>
            </Paper>
            <div style={{ marginTop: 25 }}>
              {this.state.gracePeriod
                ?
                <RaisedButton label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />
                :
                <Fragment>
                  {this.state.showRetry
                    ?
                    <RaisedButton label="Parker Claims Error (1ST)" onClick={() => {
                      this.props.dispatch(promptActions.modularSendField(false, 'pilCheckTransactionStatus'));
                      this.props.dispatch(promptActions.modularSendField(null, 'pilPaymentAmount'));
                      this.props.dispatch(promptActions.modularSendField(null, 'pilPaymentCode'));
                      this.setState({ showRetry: false })
                      this.pilGetPaymentCode();
                    }}
                    />
                    :
                    <RaisedButton label="Parker Claims Error (2ND)" onClick={() => {
                      if (this.props.from.vendGatePIL && this.props.issue.type != "equipmentMalfunction") {
                        this.props.history.push('/vendGate');
                      } else {
                        if (this.props.noManagerResponse || (this.props.from.directToTDPIL && this.props.issue.type != "equipmentMalfunction")) {
                          this.props.history.push('/ticketDiscrepancy');
                        }
                        else if (this.shouldShowCustomButtons()) {
                          this.showComponent(['showManagersList'])
                        } else {
                          this.props.history.push('/contactManagers')
                        }
                      }
                    }
                    }
                    />
                  }
                </Fragment>
              }
              {this.shouldShowVendGate() &&
                <RaisedButton label="Parker Denies Payment" onClick={() => this.props.history.push('/vendGate')} />
              }
              <RaisedButton label="Reset Entrance Time" onClick={() => this.pilResetEntranceTime()} />
              <RaisedButton label="Check Payment Status" onClick={() => { this.checkPaymentStatus() }} />
              <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
            </div>
          </Fragment>
        }

        {this.state.renderComponent.showManagersList &&
          <Fragment>
            {this.props.from.contactManagersNotePIL &&
              <ScriptText>
                {this.props.from.contactManagersNotePIL}
              </ScriptText>
            }
            <ContactManagers
              showButtons={['ticketDiscrepancy', 'showCustomButton']}
              btnClick={this.btnClick}
              btnFrom={this.props.issue.subtype}
              customButtonData={[
                {
                  "label": "Manager Assisting",
                  "action": "showManagerAssisting"
                }
              ]}
            />
          </Fragment>
        }

        {this.state.renderComponent.showManagerAssisting &&
          <Fragment>
            <ScriptText>
              {this.props.from.managerAssistingNotePIL}
            </ScriptText>
            <div style={{ marginTop: 15 }}>
              <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
            </div>
          </Fragment>
        }

      </PromptBox>
    )
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    parkerEntranceDate: promptSelectors.pullModular(state, 'parkerEntranceDate'),
    parkerEntranceTime: promptSelectors.pullModular(state, 'parkerEntranceTime'),
    parkerExitDate: promptSelectors.pullModular(state, 'parkerExitDate'),
    parkerExitTime: promptSelectors.pullModular(state, 'parkerExitTime'),
    ongoingCallId: queueSelectors.pullModular(state, 'ongoingCallId'),
    pilPaymentCode: promptSelectors.pullModular(state, 'pilPaymentCode'),
    pilPaymentAmount: promptSelectors.pullModular(state, 'pilPaymentAmount'),
    pilTransaction: promptSelectors.pullModular(state, 'pilTransaction'),
    pilCheckTransactionStatus: promptSelectors.pullModular(state, 'pilCheckTransactionStatus'),
    issue: promptSelectors.pullIssue(state),
    noManagerResponse: promptSelectors.pullModular(state, 'noManagerResponse'),
  };
}

export default withRouter(connect(mapStateToProps)(PILProcessPayment));