import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as queueSelectors from '../../../store/queue/reducer';
import * as authSelectors from '../../../store/auth/reducer';
import RaisedButton from 'material-ui/RaisedButton';
import * as promptActions from '../../../store/prompt/actions';
import Paper from 'material-ui/Paper';
import ScriptText from '../../../components/ScriptText';
import DirectionText from '../../../components/DirectionText';
import MonthlyOnlyLane from '../../../components/monthlyOnlyLane';
import SpecialMonthlyOnlyLane from '../../../components/specialMonthlyOnlyLane';
import PromptBox from '../../../components/promptBox';
import ContactManagers from '../ContactManagers';
import moment from 'moment-timezone';
import { Grid, TextField } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import TicketSearch from '../FlashAPI/TicketSearch';
import ErrorBoundary from '../../ErrorBoundary';
const dateStyles = {
  display: 'inline-block',
  width: '100%'
};
class TicketAcceptanceIssue extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      renderComponent: {
        step: true,
        showManagersList: false,
        showTicketAcceptanceRedirect: false,
        showTicketAcceptanceResult: false,
        showTicketAcceptanceForm: false,
        showDumbTicketInput: false
      },
      ticketNumber: null, shouldVend: null, calculatedParkerRate: null, parkerEntryDateResult: null, managerName: null,
      parkerEntranceDate: null, parkerEntranceDateDisplay: null, parkerEntranceTime: null, parkerEntranceTimeDisplay: null, enableTicketNotFoundButton: false,
      flashPriceID: null, flashParcsTicketID: null
    };
  }

  mapLocToTicketOrientation() {
    switch (this.props.from.locationId) {
      case 1:
        return {
          vertical: 'down',
          horizontal: 'left'
        };
      default:
        return {
          vertical: 'up',
          horizontal: 'right'
        };
    }
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (this.props.from.showDumbTicketInput) {
      const allComponents = { ...this.state.renderComponent };
      allComponents['showDumbTicketInput'] = true;
      this.setState({ renderComponent: allComponents });
    }
  }

  async componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (!this.props.from.ticketAcceptanceRedirect && this.state.renderComponent.showTicketAcceptanceRedirect) {
      if (this.props.from.ticketAcceptanceCalculateFee || this.props.from.ticketAcceptanceFormFields) {
        this.showComponent(['showTicketAcceptanceForm']);
      } else {
        const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('ticketAcceptanceIssue'));
        if (shouldEnablePIL) {
          this.props.history.push('/pil/PILEntryInput');
        } else {
          this.showComponent(['showManagersList']);
        }
      }
    }
  }

  updateState(event, target) {
    this.setState({ [target]: event.target.value });
    if (target == 'ticketNumber') {
      this.props.dispatch(promptActions.modularSendField(event.target.value, 'ticketNumber'));
    }
  }

  saveDate(date, target) {
    const formattedDate = moment(date).format('YYYY:MM:DD');
    this.setState({ parkerEntranceDate: formattedDate, parkerEntranceDateDisplay: date });
  }

  saveTime(time, target) {
    const formattedTime = moment(time).startOf('minute').format('THH:mm:ss');
    this.setState({ parkerEntranceTime: formattedTime, parkerEntranceTimeDisplay: time });
  }

  async pushRateToMachine() {
    await this.props.dispatch(promptActions.flashValidateTicket({ priceID: this.state.flashPriceID, flashParcsTicketID: this.state.flashParcsTicketID }));
  }

  async flashSaveTicketDetails(data) {
    const result = await this.props.dispatch(promptActions.flashSaveTicketDetails(data));
    const { shouldVend, ticketNumber, parkerEntryDateResult, flashParcsTicketID, flashPriceID } = result;
    await this.setState({
      shouldVend,
      ticketNumber,
      parkerEntryDateResult,
      flashParcsTicketID,
      flashPriceID,
    });
    this.showComponent(['showTicketAcceptanceResult']);
  }

  async shouldShowPIL() {
    const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('ticketAcceptanceIssue'));
    if (shouldEnablePIL) {
      return true;
    }
  }

  showComponent(toRender) {
    const allComponents = { ...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'showPushRateForm':
        this.props.history.push('/tibaAPI/tibaManagerPushRate');
        break;
      case 'showTicketAcceptanceRedirect':
        const { ticketAcceptanceFormFields, ticketAcceptanceRedirect } = this.props.from;
        const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('ticketAcceptanceIssue'));
        if (shouldEnablePIL) {
          this.props.history.push('/pil/PILEntryInput');
        } else if (ticketAcceptanceFormFields && ticketAcceptanceRedirect) {
          this.showComponent(['showTicketAcceptanceForm']);
        } else {
          this.showComponent(['showTicketAcceptanceRedirect']);
        }
        break;
      case 'submitTicketAcceptanceForm':
        const { parkerEntranceTime, parkerEntranceDate } = this.state;
        if (!parkerEntranceTime && !parkerEntranceDate) {
          return this.props.dispatch(promptActions.showSnackBar({
            'type': 'error',
            'message': 'Entrance Date Time is required.'
          }))
        } else {
          this.showComponent(['showManagersList']);
        }
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  render() {
    let dirs = {
      tD: {
        vertical: null,
        horizontal: null
      },
      tRO: {
        vertical: null,
        horizontal: null
      }
    };
    if (this.props.from.ticketDirection) {
      dirs.tD.vertical = this.props.from.ticketDirection.vertical;
      dirs.tD.horizontal = this.props.from.ticketDirection.horizontal;
    }
    if (this.props.from.ticketReaderOrientation) {
      dirs.tRO.vertical = this.props.from.ticketReaderOrientation.vertical;
      dirs.tRO.horizontal = this.props.from.ticketReaderOrientation.horizontal;
    }

    // 180 Allyn has a much different, very simple way of handling Ticket Acceptance...
    if (this.props.from.cardTicket) {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className="home-div-content">
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <div className="home-div-body">
                <Paper zDepth={1} className="paper-padding">
                  {this.props.from.cardTicketNote
                  ?
                    (
                      <ScriptText>
                        {this.props.from.cardTicketNote}
                      </ScriptText>
                    )
                  :
                    (
                      <ScriptText>
                        "This is a ticket-less location. You enter using a credit card, and exit by scanning the same credit card. Payment is not processed until the exit swipe."
                      </ScriptText>
                    )
                  }
                  {this.props.from.cardTicketProblem &&
                    <DirectionText>
                      <span style={{ color: 'red', fontWeight: 'bolder' }}>Note: </span> {this.props.from.cardTicketProblem}
                    </DirectionText>
                  }

                  <div style={{ marginTop: 15 }}>
                    <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                    {this.props.from.laneType == 'Exit' && <RaisedButton label="Next Step" onClick={async () => {
                      const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('ticketAcceptanceIssue'));
                      if (shouldEnablePIL) {
                        this.props.history.push('/pil/PILEntryInput');
                      } else {
                        this.props.history.push('/ticketDiscrepancy')
                      }
                    }
                    }
                    />}
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      );
    } else if (this.props.from.locationId === 492) {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className="home-div-content">
              <Paper zDepth={1} className="home-div-header-short">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <div className="home-div-body">
                <Paper zDepth={1} className="paper-padding">
                  <ScriptText>
                    "If you are having issues scanning your ticket, please press the red button to retrieve your ticket, and then visit the attendant inside the building to process
                    payment."
                  </ScriptText>
                  <RaisedButton style={{ marginTop: 15 }} label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                </Paper>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      );
      // Currently only used for Williams Tower
    } else if (!this.props.from.transientLane && this.props.from.contractorLane) {
      return (
        <ErrorBoundary>
          <div className="home-div">
            <div className={`home-div-content`}>
              <Paper zDepth={1} className="home-div-header">
                <h2>{this.props.from.locationName}</h2>
              </Paper>
              <div className="home-div-body">
                <Paper zDepth={1} className="paper-padding">
                  <ScriptText>"You are in a Contractor-Only lane. Please redirect to one of the following lanes: {this.props.from.ticketAcceptanceRedirect}."</ScriptText>
                  <RaisedButton style={{ marginTop: 15 }} label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                </Paper>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      );
      // Currently only used for Williams Tower
    } else if (!this.props.from.transientLane && this.props.from.monthlyLane) {
      return <ErrorBoundary><MonthlyOnlyLane locationName={this.props.from.locationName} ticketAcceptanceRedirect={this.props.from.ticketAcceptanceRedirect} /></ErrorBoundary>
    } else if (!this.props.from.transientLane && !this.props.from.monthlyLane && this.props.from.specialMonthlyLane) {
      let availableLanes = promptActions.prepareAvailableLanes(this.props.from);
      return <ErrorBoundary><SpecialMonthlyOnlyLane
        locationName={this.props.from.locationName}
        specialMonthlyLane={this.props.from.specialMonthlyLane}
        redirect={availableLanes}
      /></ErrorBoundary>
    } else if (this.props.from.ticketDirection && this.props.from.ticketDirection.barcode) {
      return (
        <ErrorBoundary>
          <PromptBox locationName={this.props.from.locationName}>
            {this.state.renderComponent.step &&
              <Fragment>
                <ScriptText>
                  {this.props.from.transientBarcodeNote
                  ?
                    this.props.from.transientBarcodeNote
                  :
                    "Please verify that the parker is scanning the barcode facing up towards the scanner."
                  }
                </ScriptText>
                <div style={{ marginTop: 15 }}>
                  <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                  <RaisedButton label="Next Step" onClick={() => { this.btnClick('showTicketAcceptanceRedirect') }} />
                </div>
              </Fragment>
            }

            {this.state.renderComponent.showTicketAcceptanceRedirect &&
              <Paper zDepth={1} className="paper-padding">
                <ScriptText>"May you please try to process payment at {this.props.from.ticketAcceptanceRedirect}?"</ScriptText>
                <div style={{ marginTop: 15 }}>
                  <RaisedButton label="Parker Refuses to Relocate" onClick={() => this.showComponent(['showManagersList'])} />
                  <RaisedButton label="Parker is Relocating" onClick={() => this.props.history.push('/confirmationScreen')} />
                </div>
              </Paper>
            }

            {this.state.renderComponent.showTicketAcceptanceForm &&
              <Fragment>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Grid style={{ marginTop: 15, padding: 5 }} container spacing={2}>
                    <Grid item xs>
                      <DatePicker
                        label="Entrance Date"
                        format="YYYY-MM-DD"
                        value={this.state.parkerEntranceDateDisplay}
                        onChange={date => this.saveDate(date, 'parkerEntranceDate')}
                        style={dateStyles}
                        fullWidth={true}
                        maxDate={new Date()}
                        inputProps={{ className: 'data-hj-allow' }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TimePicker
                        ampm={false}
                        openTo="hours"
                        views={["hours", "minutes"]}
                        format="HH:mm"
                        value={this.state.parkerEntranceTimeDisplay}
                        onChange={time => this.saveTime(time, 'parkerEntranceTime')}
                        label="Entrance Time"
                        style={dateStyles}
                        fullWidth={true}
                        inputProps={{ className: 'data-hj-allow' }}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
                <div style={{ marginTop: 15 }}>
                  <RaisedButton label="Submit" onClick={() => this.btnClick('submitTicketAcceptanceForm')} />
                  <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                </div>
              </Fragment>
            }

            {this.state.renderComponent.showManagersList && <ContactManagers showButtons={['managerAssisting', 'vendGate', 'ticketDiscrepancy']} />}

          </PromptBox>
        </ErrorBoundary>
      );
    } else if (this.props.from.ticketAcceptanceEntranceInput) {
      return (
        <ErrorBoundary>
          <PromptBox locationName={this.props.from.locationName}>
            <ScriptText>This entrance requires a ticket or access card to enter.</ScriptText>
            <ScriptText>If you do not have one, please find another exit or retrieve a ticket or access card.</ScriptText>
            <DirectionText>Please ask the parker for their ticket number, and record it below.</DirectionText>
            <TextField
              label="Ticket Number"
              onChange={(e) => this.updateState(e, 'ticketNumber')}
              value={this.props.ticketNumber || ""}
              margin="dense"
              variant="outlined"
              inputProps={{ className: 'data-hj-allow' }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: '60%', marginTop: 15, marginBottom: 10 }}
            />
            <div style={{ marginTop: 15 }}>
              <RaisedButton disabled={!(this.props.ticketNumber && this.props.ticketNumber.length > 2)} label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />
              <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
            </div>
          </PromptBox>
        </ErrorBoundary>
      )
    } else if (this.props.from.ticketHasMagneticStrip) {
      return (
        <ErrorBoundary>
          <PromptBox locationName={this.props.from.locationName}>
            <ScriptText>
              {this.props.from.ticketHasMagneticStripText || `Please verify that the ticket is being inserted with the magnetic strip facing ${this.props.from.ticketMagneticStripDirection}`}
            </ScriptText>
            <div style={{ marginTop: 15 }}>
              <RaisedButton label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />
              <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
            </div>
          </PromptBox>
        </ErrorBoundary>
      )
    } else {
      return (
        <ErrorBoundary>
          <PromptBox locationName={this.props.from.locationName}>
            {this.state.renderComponent.step &&
              <Fragment>
                <ScriptText>
                  {/* Remembver to go pull apbLockedOut in the mapStateToProps below */}
                  {/* this.props.from.monthlyApbRedirectPILNote && this.props.apbLockedOut */}
                  {this.props.from.monthlyApbRedirectPILNote && this.props.apbLockedOut
                    &&
                    <Fragment>{this.props.from.monthlyApbRedirectPILNote}</Fragment>}

                  {(!this.props.from.monthlyApbRedirectPILNote || !this.props.apbLockedOut)
                  ?
                    this.props.from.transientBarcodeNote
                    ?
                      this.props.from.transientBarcodeNote
                    :
                      <Fragment>
                        Please verify that the ticket is being inserted with the barcode facing {dirs.tD.vertical}
                        {dirs.tD.horizontal && ` and to the ${dirs.tD.horizontal}`}
                        {this.props.from.ticketAndCard && ` and Credit card Magnetic Strip 
                      ${this.props.from.ticketAndCardDirection.vertical} & ${this.props.from.ticketAndCardDirection.horizontal}.`}
                      </Fragment>

                  :
                    ''
                  }
                </ScriptText>
                <div style={{ marginTop: 15 }}>
                  <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                  {this.props.from.disableTicketAcceptanceRedirect &&
                    <RaisedButton label="Next Step" onClick={async () => {
                      const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('ticketAcceptanceIssue'));
                      if (shouldEnablePIL) {
                        this.props.history.push('/pil/PILEntryInput');
                      } else {
                        this.showComponent(['showManagersList']);
                      }
                    }
                    }
                    />
                  }
                  {this.props.from.showDumbTicketInput &&
                    <RaisedButton label="Next Step" onClick={() => { this.showComponent(['showDumbTicketInput']) }} />
                  }
                  {!this.props.from.disableTicketAcceptanceRedirect && !this.props.from.showDumbTicketInput &&
                    <RaisedButton label="Next Step" onClick={() => this.showComponent(['showTicketAcceptanceRedirect'])} />
                  }
                </div>
              </Fragment>
            }

            {/* Currently only used for the showDumbTicketInput */}
            {this.state.renderComponent.showDumbTicketInput &&
              <Fragment>
                <DirectionText>Please record the parker's ticket number and proceed to contact management.</DirectionText>
                <TextField
                  label="Ticket Number"
                  value={this.state.ticketNumber || ''}
                  margin="dense"
                  variant="outlined"
                  inputProps={{ className: 'data-hj-allow' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => { this.updateState(event, 'ticketNumber') }}
                  style={{ width: '100%', marginTop: 15 }}
                />
                <RaisedButton label="Continue" disabled={!this.state.ticketNumber} onClick={() => { this.props.history.push('/vendGate') }} />
                <RaisedButton label="Exit" onClick={() => { this.props.history.push('/confirmationScreen') }} />
              </Fragment>
            }

            {this.state.renderComponent.showTicketAcceptanceRedirect &&
              <Fragment>
                <ScriptText>"May you please try to process payment at {this.props.from.ticketAcceptanceRedirect}?"</ScriptText>
                <div style={{ marginTop: 15 }}>
                  <RaisedButton label="Parker Refuses to Relocate" onClick={() => { this.showComponent(['showManagersList']) }} />
                  <RaisedButton label="Parker is Relocating" onClick={() => this.props.history.push('/confirmationScreen')} />
                </div>
              </Fragment>
            }

            {this.state.renderComponent.showTicketAcceptanceForm &&
              <Fragment>
                <ScriptText>"May I please have your Ticket Number?"</ScriptText>
                {this.props.from.dynamicTicketSearch
                ?
                  <Fragment>
                    <TicketSearch flashSaveTicketDetails={this.flashSaveTicketDetails} />
                    <div style={{ marginTop: 15 }}>
                      <RaisedButton label="Ticket Not Found" onClick={async () => {
                        const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('ticketAcceptanceIssue'));
                        if (shouldEnablePIL) {
                          this.props.history.push('/pil/PILEntryInput');
                        } else {
                          this.showComponent(['showManagersList']);
                        }
                      }}
                      />
                      <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                    </div>
                  </Fragment>
                :
                  <Fragment>
                    {this.props.history.push('/tibaAPI/tibaRateByTicket')}
                  </Fragment>

                }
              </Fragment>
            }

            {this.state.renderComponent.showTicketAcceptanceResult &&
              <Fragment>
                {this.state.shouldVend
                ?
                  <DirectionText>
                    The parker is within the Grace Period. Please vend the Gate.
                  </DirectionText>
                :
                  <Fragment>
                    <DirectionText> <span style={{ color: 'red', fontWeight: 'bolder' }} >Note: </span> {`Click the Push Rate button to display the rate on machine.`} </DirectionText>
                    <DirectionText> <span style={{ fontWeight: 'bolder' }}> Entry Date Time: </span> {this.state.parkerEntryDateResult} </DirectionText>
                    {this.state.calculatedParkerRate && <ScriptText> Please pay the ${this.state.calculatedParkerRate} fee to exit the parking garage. </ScriptText>}
                  </Fragment>
                }
                <div style={{ marginTop: 15 }}>
                  {this.state.shouldVend
                  ?
                    <RaisedButton label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />
                  :
                    <Fragment>
                      <RaisedButton label="Push Rate To Machine" onClick={() => this.pushRateToMachine()} />
                      {this.shouldShowPIL() &&
                        <RaisedButton label="Unable to Pay Through Equipment" onClick={() => this.props.history.push('/pil/PILEntryInput')} />
                      }
                    </Fragment>
                  }
                  <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                </div>
              </Fragment>
            }

            {this.state.renderComponent.showManagersList &&
              <ContactManagers
                showButtons={['managerAssisting', 'vendGate', 'ticketDiscrepancy', 'showCustomButton']}
                btnClick={this.btnClick}
                btnFrom="ticketAcceptanceIssue"
                customButtonData={[
                  {
                    "label": "Manager Asked to Push Rate",
                    "action": "showPushRateForm"
                  },
                ]}
              />
            }
          </PromptBox>
        </ErrorBoundary>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    ongoingCallId: queueSelectors.pullModular(state, 'ongoingCallId'),
    step: promptSelectors.pullStep(state),
    username: authSelectors.pullModular(state, 'username'),
    ticketNumber: promptSelectors.pullModular(state, 'ticketNumber'),
    isIntercomCall: queueSelectors.pullModular(state, 'isIntercomCall'),
    apbLockedOut: promptSelectors.pullModular(state, 'apbLockedOut'),
  };
}

export default withRouter(connect(mapStateToProps)(TicketAcceptanceIssue));
