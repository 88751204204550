import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as queueSelectors from '../../../store/queue/reducer';
import * as authSelectors from '../../../store/auth/reducer';
import * as promptActions from '../../../store/prompt/actions';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import Paper from 'material-ui/Paper';
import ContactManagers from '../ContactManagers';
import ScriptText from '../../../components/ScriptText';
import DirectionText from '../../../components/DirectionText';
import _ from 'lodash';
import MonthlyOnlyLane from '../../../components/monthlyOnlyLane';
import PromptBox from '../../../components/promptBox';
import InputMask from 'react-input-mask';
import ValidationSplit from './ValidationSplit';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Select from 'react-select';
import { titleCase } from "title-case";
import { retrieveLogger } from '../../../lib/logger';
let logger = retrieveLogger();

const dateStyles = {
  display: 'inline-block',
  width: '100%'
};
class ValidationIssue extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      renderComponent: { showValidationForm: true, showManagersList: false, showValidationForgot: false, showRateCalculationResult: false },
      parkerEntranceTime: null, parkerExitTime: null, validationOptions: [], googleSheetData: null, rateToPush: null, validationDiscountType: null, validationDiscountValue: null,
      numberOfHoursParked: null, rateBeforeValidation: null, dateTimeAfterValidation: null, managerName: null, pushRateQuestion: true
    };

  }

  // Empty array to push validation variables based on location variables.
  requiredFields = ['validationNumber', 'validationSource'];
  // Array of Objects to map location variable to state name.
  possibleRequiredFields = [
    {
      "locVar": "validationInputName",
      "stateName": "selectedParkerName"
    },
    {
      "locVar": "validationInputPhoneNumber",
      "stateName": "contactNumber"
    },
    {
      "locVar": "validationInputTicketNumber",
      "stateName": "ticketNumber"
    },
    {
      "locVar": "validationTimes",
      "stateName": "parkerEntranceDate"
    },
    {
      "locVar": "validationTimes",
      "stateName": "parkerEntranceTime"
    },
    {
      "locVar": "validationTimes",
      "stateName": "parkerExitDate"
    },
    {
      "locVar": "validationTimes",
      "stateName": "parkerExitTime"
    }
  ];

  async componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    // Conditionally push to required fields based on the boolean value of location variables.
    if ((this.props.from.validationInput || this.props.from.validationManager) && !this.props.from.validationDiscrepancy && this.firstTwoSteps()) {
      this.possibleRequiredFields.forEach((key) => {
        // Check if the location variable exists in required fields and if the location variable is true.
        if (!this.requiredFields.includes(key.stateName) && this.props.from[key.locVar]) {
          this.requiredFields.push(key.stateName);
        }
      });
    }
    if (typeof(this.props.from.validationInput) == 'object') {
      this.props.from.validationInput.forEach(input => this.requiredFields.push(input));
    }
    if (this.props.from.validationTimes) {
      const formattedDate = moment().format('YYYY-MM-DD');
      this.props.dispatch(promptActions.sendDateTime(formattedDate, 'parkerExitDate'));
      const formattedTime = moment().startOf('minute').format('THH:mm:ss');
      this.props.dispatch(promptActions.sendDateTime(formattedTime, 'parkerExitTime'));
    }
    /* reset global step prop */
    this.props.dispatch(promptActions.updateIssueStep(0));
    try {
      const { googleSheetInfo } = this.props.from;
      if (googleSheetInfo && googleSheetInfo.validationSources) {
        this.props.dispatch(promptActions.modularSendField(true, 'showLoader'));
        const result = await this.props.dispatch(promptActions.getValidationSourcesFromSheets({ googleSheetInfo }));
        const { sheetData } = result.data;
        this.setState({ googleSheetData: sheetData });
        const options = await sheetData.map(row => {
          return {
            'value': row[0],
            'label': row[0]
          }
        });
        this.setState({ validationOptions: options });
        this.props.dispatch(promptActions.modularSendField(false, 'showLoader'));
      }
    } catch (error) {
      logger.error(error);
    }
  }

  async componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (this.props.from.redirectToIntercomPrompts) {
      const shouldRedirect = await this.props.dispatch(promptActions.shouldRedirectToIntercom("validationIssue"));
      const { redirectToIntercomPrompts } = this.props.from;
      if (shouldRedirect && redirectToIntercomPrompts.validationIssue.step == this.props.step) {
        this.props.history.push('/redirectToIntercom');
      }
    }
    const complete = this.requiredFields.every((value) => {
      return !!this.props[value];
    });
    if(complete) {
      this.props.dispatch(promptActions.modularSendField(true, 'formComplete'));
    }
  }

  completeStep(step) {
    // This isnt working now, figure out why
    this.props.dispatch(promptActions.updateIssueStep(step));
  }

  updateField(event, value, target) {
    //Update the state
    this.props.dispatch(promptActions.modularSendField(value, target));
    //If value is empty do not render the Vend gate button.
    if (!value) {
      this.props.dispatch(promptActions.modularSendField(false, 'formComplete'));
    }
  }

  updateState(event, target) {
    this.setState({ [target] : event.target.value });
  }

  updateSelectField(event, target){
    this.props.dispatch(promptActions.modularSendField(event.value, target));
  }

  displayValidationArray(a) {
    return (
      <li key={a.name}>
        {a.name} {- a.duration ? 'a.duration' : null }
      </li>
    );
  }

  displayValidationInputs(a) {
    // React cries if you dont do this.
    let val;
    if (this.props[a]) {
      val = this.props[a];
    } else {
      val = '';
    }
    return (
      <TextField
        inputProps={{ className: 'data-hj-allow' }}
        floatingLabelText={titleCase(a)}
        hintText={titleCase(a)}
        style={{ width: '98%' }}
        value={val}
        key={`validationField_${a}`}
        onChange={(e, v) => {
          this.updateField(e, v, a);
        }}
      />
    )
  }

  shouldShowVendButton() {
    if (this.props.validationNumber &&
        this.props.validationNumber !== undefined &&
        this.props.validationNumber !== null &&
        this.props.validationNumber.trim().length >= 3 ) {
        // eslint-disable-next-line
        if (this.props.from.validationSources && !this.props.validationSource ||
          // eslint-disable-next-line
          this.props.from.validationSources && this.props.validationSource && this.props.validationSource.length <= 0
           ) {
          logger.info('Forcing false because of validationSources.');
          return false;
        }
      return true;
    }
    return false;
  }

  generateValidationSources(source) {
    return (<li key={source}>{source}</li>)
  }

  firstTwoSteps() {
    if (this.props.step === 0 || this.props.step === 1) { return true; } else { return false;}
  }

  saveDate(date, target) {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    this.props.dispatch(promptActions.sendDateTime(formattedDate, target));
  }

  saveTime(time, target) {
    const formattedTime = moment(time).startOf('minute').format('THH:mm:ss');
    this.props.dispatch(promptActions.sendDateTime(formattedTime, target));
    this.setState({ [target]: time });
  }

  async flashVerifyValidation() {
    const { ticketNumber, validationSource } = this.props;
    if (!validationSource)  {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: 'Validation Source is required.'
      }));
    } else if (!ticketNumber) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: 'Ticket Number is required.'
      }));
    } else {
      const validationData = this.state.googleSheetData.filter(val => val[0] == validationSource);
      const result = await this.props.dispatch(promptActions.flashVerifyValidation(ticketNumber, validationData[0][1]));
      const { notFound, valid, validationMinutes, formattedEntryDate, minuteDiff } = result;
      if (notFound) {
        return this.props.dispatch(promptActions.showSnackBar({
          type: 'error',
          message: 'Ticket Not Found.'
        }));
      } else if (!valid) {
        this.setState({
          numberOfHoursParked: parseInt(minuteDiff/24),
          validationDiscountType: 'minutesValidated',
          parkerEntranceTime: formattedEntryDate,
          validationDiscountValue: validationMinutes
        })
        this.showComponent(['showRateCalculationResult']);
      } else if (valid) {
        this.props.history.push('/vendGate');
        return this.props.dispatch(promptActions.showSnackBar({
          type: 'success',
          message: 'The ticket is within Validation period.'
        }));
      }
    }
  }

  async tibaValidationRateCalculate() {
    const { ticketNumber, validationSource } = this.props;
    if (!validationSource)  {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: 'Validation Source is required.'
      }));
    } else if (!ticketNumber) {
      return this.props.dispatch(promptActions.showSnackBar({
        type: 'error',
        message: 'Ticket Number is required.'
      }));
    } else {
      this.props.dispatch(promptActions.modularSendField(true, 'showLoader'));
      const { apiParams, rateList, gracePeriodInt, timezone, baseURL } = this.props.from;
      const data = { apiParams, rateList, gracePeriodInt, ticketNumber, timezone, baseURL, sheetData: this.state.googleSheetData, validationSource };
      const result = await this.props.dispatch(promptActions.tibaValidationRateCalculate(data));
      const { success, rate, discountType, discountValue, numberOfHoursParked, entryDate, rateBeforeValidation, notFound } = result.data;
      const formattedEntryDate = moment(entryDate, 'YYYY:MM:DD HH:mm:ss').format('LLLL');
      const dateTimeAfterValidation = moment(entryDate, 'YYYY-MM-DD HH:mm:ss').add(discountValue, 'minutes').format('MMMM Do YYYY, h:mm:ss a');
      if (success && notFound) {
        this.props.dispatch(promptActions.modularSendField(false, 'showLoader'));
        return this.props.dispatch(promptActions.showSnackBar({
          type: 'error',
          message: 'Ticket Number not found, please enter a valid ticket number and try again.'
        }));
      } else if (success) {
        await this.setState({ rateToPush: rate, validationDiscountType: discountType, validationDiscountValue: discountValue,
          numberOfHoursParked: numberOfHoursParked, parkerEntranceTime: formattedEntryDate, rateBeforeValidation: rateBeforeValidation,
          dateTimeAfterValidation: dateTimeAfterValidation });
          this.props.dispatch(promptActions.modularSendField({ rate, shouldVend: rate == 0 ? true : false, parkerEntryDateResult: dateTimeAfterValidation }, 'tibaRateResult'))
        this.showComponent(['showRateCalculationResult']);
      } else {
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Failed to Calculate Rate for the Validation. Please report this.'
        }))
      }
    }
    this.props.dispatch(promptActions.modularSendField(false, 'showLoader'));
  }

  async pushRateToMachine() {
    const { apiParams, controlDeviceData, baseURL } = this.props.from;
    const { ongoingCallId, username } = this.props;
    const data = { username, ongoingCallId, apiParams, baseURL, commandType: controlDeviceData.pushRateOpenTicket, controlDeviceData, actionId: controlDeviceData.pushRateDBId, rateToPush: this.state.rateToPush };
    const result = await this.props.dispatch(promptActions.tibaSendControlDeviceAction(data));
    const { success, message } = result.data;
    if (success) {
      this.props.dispatch(promptActions.showSnackBar({
        type: 'success', message: 'Rate pushed to device, if parker denies payment contact managers.'
      }));
    } else if (!success) {
      this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: message
      }));
    }
  }

  showComponent(toRender) {
    const allComponents = {...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'completeStepOne':
        this.completeStep(1)
        break;
      case 'showPushRateForm':
        this.props.dispatch(promptActions.modularSendField(true, 'pushRateQuestion'));
        this.props.history.push('/tibaAPI/tibaManagerPushRate')
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  render() {
    if (this.props.from.validationSplit) {
      return <ValidationSplit />
    }
    if (!this.props.from.transientLane && this.props.from.monthlyLane && this.firstTwoSteps()) {
      return <MonthlyOnlyLane locationName={this.props.from.locationName} ticketAcceptanceRedirect={this.props.from.ticketAcceptanceRedirect}/>
    } else if (this.props.from.vendingSeconds === 0 && this.firstTwoSteps()) {
      return (
        <PromptBox locationName={this.props.from.locationName}>
          <Paper zDepth={1} className="paper-padding">
            <ContactManagers showButtons={[]} />
          </Paper>
        </PromptBox>
      );
    } else if (!this.props.from.validationInput && this.props.from.validationManager && !this.props.from.validationDiscrepancy && this.firstTwoSteps()) {
      return (
        <PromptBox locationName={this.props.from.locationName}>
          { this.props.step === 0 &&
            <div>
              <Paper zDepth={1} className="paper-padding">
                { this.props.from.validationArray &&
                  <ul>
                    {_.map(this.props.from.validationArray, this.displayValidationArray)}
                  </ul>
                }
                <DirectionText>{this.props.from.validationNote}</DirectionText>
              </Paper>
              <Paper zDepth={1} className="paper-padding">
                <RaisedButton label="Contact Management" onClick={() => { this.completeStep(1) } }/>
                <Link to="/confirmationScreen">
                  <RaisedButton label="Confirmation Screen" />
                </Link>
              </Paper>
            </div>
          }
          { this.props.step === 1 &&
            <Paper zDepth={1} className="paper-padding">
              <ContactManagers showButtons={['vendGate', 'managerAssisting', 'ticketDiscrepancy']} />
              {this.props.from.validationInputAfterManager &&  <RaisedButton label="Manager Not Assisting" onClick={() => {this.completeStep(2)}} />}
            </Paper>
          }
        </PromptBox>
      )
    } else if (!this.props.from.validationInput && !this.props.from.validationManager && !this.props.from.validationDiscrepancy && this.firstTwoSteps()) {
      return (
        <PromptBox locationName={this.props.from.locationName} >
          { this.props.step === 0 &&
            <Fragment>
              <DirectionText>{this.props.from.validationNote}</DirectionText>
              <div style={{ marginTop: 15 }}>
                <Link to="/confirmationScreen">
                  <RaisedButton label="Complete Call" />
                </Link>
              </div>
            </Fragment>
          }
        </PromptBox>
      )
    } else if ((this.props.from.validationInput || this.props.from.validationManager) && !this.props.from.validationDiscrepancy && this.firstTwoSteps()) {
      return (
      <Fragment>
        { this.props.step === 0 &&
          <PromptBox locationName={this.props.from.locationName}>
            <Fragment>
              {this.state.renderComponent.showValidationForm &&
                <Fragment>
                  <DirectionText>{this.props.from.validationNote}</DirectionText>
                  { this.props.from.validationArray &&
                    <ul>
                      {_.map(this.props.from.validationArray, this.displayValidationArray)}
                    </ul>
                  }
                  {_.map(this.props.from.validationInput, this.displayValidationInputs)}
                  {!this.props.from.validationScanProblem &&
                    <Fragment>
                      {!this.props.from.validationNumberDisable &&
                        <TextField
                          inputProps={{ className: 'data-hj-allow' }}
                          floatingLabelText="Validation Number"
                          hintText="Validation Number"
                          style={{ width: '98%', marginTop: 15 }}
                          onChange={(e, v) => { this.updateField(e, v, 'validationNumber') }}
                        />
                      }
                      { this.props.from.validationTimes &&
                        <Fragment>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Grid style={{ marginTop: 15, padding: 5 }} container spacing={2}>
                              <Grid item xs>
                                <DatePicker
                                  label="Entrance Date"
                                  format="YYYY-MM-DD"
                                  value={this.props.parkerEntranceDate}
                                  onChange={date => this.saveDate(date, 'parkerEntranceDate')}
                                  style={dateStyles}
                                  fullWidth={true}
                                  inputProps={{ className: 'data-hj-allow' }}
                                />
                              </Grid>
                              <Grid item xs>
                                <TimePicker
                                  ampm={false}
                                  openTo="hours"
                                  views={["hours", "minutes"]}
                                  format="HH:mm"
                                  value={this.state.parkerEntranceTime}
                                  onChange={time => this.saveTime(time, 'parkerEntranceTime')}
                                  label="Entrance Time"
                                  style={dateStyles}
                                  fullWidth={true}
                                  inputProps={{ className: 'data-hj-allow' }}
                                />
                              </Grid>
                              <Grid item xs>
                                <DatePicker
                                  format="YYYY-MM-DD"
                                  value={this.props.parkerExitDate || new Date()}
                                  onChange={date => this.saveDate(date, 'parkerExitDate')}
                                  label="Exit Date"
                                  style={dateStyles}
                                  fullWidth={true}
                                  inputProps={{ className: 'data-hj-allow' }}
                                />
                              </Grid>
                              <Grid item xs>
                                <TimePicker
                                  ampm={false}
                                  openTo="hours"
                                  views={["hours", "minutes"]}
                                  format="HH:mm"
                                  value={this.state.parkerExitTime || new Date()}
                                  onChange={time => this.saveTime(time, 'parkerExitTime')}
                                  label="Exit Time"
                                  style={dateStyles}
                                  fullWidth={true}
                                  inputProps={{ className: 'data-hj-allow' }}
                                />
                              </Grid>
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Fragment>
                      }
                      {this.props.from.googleSheetInfo && this.props.from.googleSheetInfo.validationSources
                      ?
                        <Fragment>
                          <div style={{ marginTop: 15 }}>
                            <Select
                              className="MuiOutlinedInput-inputMarginDense max-z-index"
                              classNamePrefix="address-select"
                              placeholder="Validation Source"
                              options={this.state.validationOptions}
                              onChange={(e) => { this.updateSelectField(e, 'validationSource') }}
                              />
                          </div>
                        </Fragment>
                      :
                        <Fragment>
                          <TextField
                            inputProps={{ className: 'data-hj-allow' }}
                            floatingLabelText="Validation Source"
                            hintText="Validation Source"
                            style={{ width: '98%' }}
                            onChange={(e, v) => { this.updateField(e, v, 'validationSource') }} key="validationSource"
                          />
                        </Fragment>
                      }
                      { this.props.from.validationInputTicketNumber &&
                        <TextField
                          inputProps={{ className: 'data-hj-allow' }}
                          floatingLabelText="Ticket Number"
                          hintText="Original Ticket Number"
                          style={{ width: '98%' }}
                          onChange={(e, v) => { this.updateField(e, v, 'ticketNumber') }} >
                          <InputMask mask="99999999999" maskChar=" " />
                        </TextField>
                      }
                    </Fragment>
                  }
                  <div style={{ marginTop: 15 }}>
                    {!this.props.from.verfiyValidationManagers &&
                      <RaisedButton disabled={!this.props.formComplete} label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />
                    }
                    {this.props.from.validationRateCalculate && <RaisedButton label="Calculate Validation" onClick={() => this.tibaValidationRateCalculate()}/>}
                    {this.props.from.flashVerifyValidation && <RaisedButton label="Verify Validation" onClick={() => this.flashVerifyValidation()} />}
                    {this.props.from.hideValidationContactManager && <RaisedButton label="Contact Managers" onClick={() => {this.showComponent(['showManagersList'])}} />}
                    {this.props.from.validationScanProblem && <RaisedButton label="Scan Didn't Work" onClick={() => this.completeStep(1)} />}
                    {this.props.from.validationForgot && <RaisedButton label="Forgot to Validate" onClick={() => this.showComponent(['showValidationForgot'])} />}
                    <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')}/>
                  </div>
                </Fragment>
              }

              {this.state.renderComponent.showRateCalculationResult &&
                <Fragment>
                  <DirectionText>
                    {this.state.validationDiscountType == 'flatRate' &&
                      <Fragment>
                        <DirectionText> This is a flat rate validation. </DirectionText>
                        <DirectionText> <span style={{ fontWeight: "bolder" }}> Validation Source: </span> {this.props.validationSource} </DirectionText>
                        <DirectionText> <span style={{ fontWeight: 'bolder' }}> Entry Date Time: </span> {this.state.parkerEntranceTime} </DirectionText>
                        <DirectionText> <span style={{ fontWeight: "bolder" }}> Number of Hours Parked: </span> {this.state.numberOfHoursParked} </DirectionText>
                        <DirectionText> <span style={{ fontWeight: "bolder" }}> Flat Parking Rate: </span> {this.state.validationDiscountValue} </DirectionText>
                        <DirectionText> <span style={{ fontWeight: "bolder" }}> Rate Before Discount: </span> ${this.state.rateBeforeValidation} </DirectionText>
                        <DirectionText> <span style={{ fontWeight: "bolder" }}> Rate After Discount: </span> ${this.state.rateToPush} </DirectionText>
                      </Fragment>
                    }
                    {this.state.validationDiscountType == 'ratePerHour' &&
                      <Fragment>
                        <DirectionText> This is a Rate Per Hour validation. </DirectionText>
                        <DirectionText> <span style={{ fontWeight: "bolder" }}> Validation Source: </span> {this.props.validationSource} </DirectionText>
                        <DirectionText> <span style={{ fontWeight: 'bolder' }}> Entry Date Time: </span> {this.state.parkerEntranceTime} </DirectionText>
                        <DirectionText> <span style={{ fontWeight: "bolder" }}> Number of Hours Parked: </span> {this.state.numberOfHoursParked} </DirectionText>
                        <DirectionText> <span style={{ fontWeight: "bolder" }}> Rate Per Hour: </span> ${this.state.validationDiscountValue} </DirectionText>
                        <DirectionText> <span style={{ fontWeight: "bolder" }}> Rate Before Discount: </span> ${this.state.rateBeforeValidation} </DirectionText>
                        <DirectionText> <span style={{ fontWeight: "bolder" }}> Rate After Discount: </span> ${this.state.rateToPush} </DirectionText>
                      </Fragment>
                    }
                    {this.state.validationDiscountType == 'minutesValidated' &&
                      <Fragment>
                        <DirectionText> This is a Minutes Validates Ticket. </DirectionText>
                        {this.props.validationSource && <DirectionText> <span style={{ fontWeight: "bolder" }}> Validation Source: </span> {this.props.validationSource} </DirectionText>}
                        {this.state.parkerEntranceTime && <DirectionText> <span style={{ fontWeight: 'bolder' }}> Entry Date Time: </span> {this.state.parkerEntranceTime} </DirectionText>}
                        {this.state.dateTimeAfterValidation && <DirectionText> <span style={{ fontWeight: 'bolder' }}> Entry Date Time After Validation: </span> {this.state.dateTimeAfterValidation} </DirectionText>}
                        {this.state.numberOfHoursParked && <DirectionText> <span style={{ fontWeight: "bolder" }}> Number of Hours Parked: </span> {this.state.numberOfHoursParked} </DirectionText>}
                        {this.state.validationDiscountValue && <DirectionText> <span style={{ fontWeight: "bolder" }}> Minutes Validated: </span> {this.state.validationDiscountValue} </DirectionText>}
                        {this.state.rateBeforeValidation && <DirectionText> <span style={{ fontWeight: "bolder" }}> Rate Before Discount: </span> ${this.state.rateBeforeValidation} </DirectionText>}
                        {this.state.rateToPush && <DirectionText> <span style={{ fontWeight: "bolder" }}> Rate After Discount: </span> ${this.state.rateToPush} </DirectionText>}
                      </Fragment>
                    }
                    {this.state.validationDiscountType == 'notFound' &&
                      <Fragment>
                        <DirectionText> An option to validate was not found. </DirectionText>
                        <DirectionText> <span style={{ fontWeight: "bolder" }}> Validation Source: </span> {this.props.validationSource || 'Not Found'} </DirectionText>
                        <DirectionText> <span style={{ fontWeight: 'bolder' }}> Entry Date Time: </span> {this.state.parkerEntranceTime} </DirectionText>
                        <DirectionText> <span style={{ fontWeight: "bolder" }}> Minutes Validated: </span> {this.state.validationDiscountValue} </DirectionText>
                        <DirectionText> <span style={{ fontWeight: "bolder" }}> Number of Hours Parked: </span> {this.state.numberOfHoursParked} </DirectionText>
                        <DirectionText> <span style={{ fontWeight: "bolder" }}> Parking Rate: </span> ${this.state.rateToPush} </DirectionText>
                      </Fragment>
                    }
                  </DirectionText>
                  {this.props.from.parcs === 'tibaAPI' &&
                    <DirectionText>
                      <span style={{ fontWeight: 'bolder', color: 'Red' }}> Note: </span>
                      Please make sure that you ask the parker if ticket is in the machine.
                    </DirectionText>
                  }
                  <div style={{ marginTop: 15 }}>
                    {this.props.from.parcs === 'tibaAPI' &&
                      <Fragment>
                        {this.state.rateToPush == 0
                        ?
                          <RaisedButton label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />
                        :
                          <RaisedButton label="Push Rate To Machine" onClick={() => this.pushRateToMachine()} />
                        }
                      </Fragment>
                    }
                    <RaisedButton label="Contact Managers" onClick={() => {this.showComponent(['showManagersList'])}} />
                    <RaisedButton label="End Call" onClick={() => this.props.history.push('/confirmationScreen')}/>
                  </div>
                </Fragment>
              }

              {this.state.renderComponent.showValidationForgot &&
                <Fragment>
                  <TextField
                    inputProps={{ className: 'data-hj-allow' }}
                    floatingLabelText="Parker Name"
                    hintText="Parker Name"
                    style={{ width: '98%' }}
                    onChange={(e, v) => { this.updateField(e, v, 'selectedParkerName') }}
                  />
                  <div style={{ marginTop: 15}}>
                    <RaisedButton disabled={!this.props.selectedParkerName} label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />
                    <RaisedButton label="End Call" onClick={() => this.props.history.push('/confirmationScreen')} />
                  </div>
                </Fragment>
              }

              {this.state.renderComponent.showManagersList &&
                <Fragment>
                  <ContactManagers showButtons={['vendGate', 'managerAssisting', `${this.props.from.flashVerifyValidation && 'ticketDiscrepancy'}`, 'showCustomButton']}
                    btnClick={this.btnClick}
                    btnFrom="validation"
                    customButtonData={[
                      {
                        "label": "No Manager Response",
                        "action": "completeStepOne"
                      },
                      {
                        "label": "Manager Asked to Push Rate",
                        "action": "showPushRateForm"
                      },
                    ]}
                  />
                </Fragment>
              }

            </Fragment>
          </PromptBox>
        }
        { this.props.step === 1 &&
          <PromptBox locationName={this.props.from.locationName}>
            <ScriptText>"May you please read the validation ticket number to me?"</ScriptText>
            <DirectionText>Please record the validation ticket number and then process to vend the gate.</DirectionText>
            { this.props.from.validationTimes &&
              <Fragment>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Grid style={{ marginTop: 15, padding: 5 }} container spacing={2}>
                    <Grid item xs>
                      <DatePicker
                        label="Entrance Date"
                        format="YYYY-MM-DD"
                        value={this.props.parkerEntranceDate}
                        onChange={date => this.saveDate(date, 'parkerEntranceDate')}
                        style={dateStyles}
                        fullWidth={true}
                        inputProps={{ className: 'data-hj-allow' }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TimePicker
                        ampm={false}
                        openTo="hours"
                        views={["hours", "minutes"]}
                        format="HH:mm"
                        value={this.state.parkerEntranceTime}
                        onChange={time => this.saveTime(time, 'parkerEntranceTime')}
                        label="Entrance Time"
                        style={dateStyles}
                        fullWidth={true}
                        inputProps={{ className: 'data-hj-allow' }}
                      />
                    </Grid>
                    <Grid item xs>
                      <DatePicker
                        format="YYYY-MM-DD"
                        value={this.props.parkerExitDate || new Date()}
                        onChange={date => this.saveDate(date, 'parkerExitDate')}
                        label="Exit Date"
                        style={dateStyles}
                        fullWidth={true}
                        inputProps={{ className: 'data-hj-allow' }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TimePicker
                        ampm={false}
                        openTo="hours"
                        views={["hours", "minutes"]}
                        format="HH:mm"
                        value={this.state.parkerExitTime || new Date()}
                        onChange={time => this.saveTime(time, 'parkerExitTime')}
                        label="Exit Time"
                        style={dateStyles}
                        fullWidth={true}
                        inputProps={{ className: 'data-hj-allow' }}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Fragment>
            }

            <TextField
              inputProps={{ className: 'data-hj-allow' }}
              floatingLabelText="Validation Number"
              hintText="Validation Number"
              style={{ width: '98%' }}
              onChange={(e, v) => { this.updateField(e, v, 'validationNumber') }}
            />

            <TextField
              inputProps={{ className: 'data-hj-allow' }}
              floatingLabelText="Validation Source"
              hintText="Validation Source"
              style={{ width: '98%' }}
              onChange={(e, v) => { this.updateField(e, v, 'validationSource') }} key="validationSource"
            />

            { this.props.from.validationInputTicketNumber &&
              <TextField
                floatingLabelText="Ticket Number"
                hintText="Original Ticket Number"
                style={{ width: '98%' }}
                onChange={(e, v) => { this.updateField(e, v, 'ticketNumber') }} >
                <InputMask mask="99999999999" maskChar=" " />
              </TextField>
            }
            { this.props.from.validationInputName &&
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                floatingLabelText="Parker Name"
                hintText="Parker Name"
                style={{ width: '98%' }}
                onChange={(e, v) => { this.updateField(e, v, 'selectedParkerName') }} />
            }
            { this.props.from.validationInputPhoneNumber &&
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                floatingLabelText="Contact Number"
                hintText="Contact Number"
                style={{ width: '98%'}}
                onChange={(e, v) => { this.updateField(e, v, 'contactNumber') }} />
            }

            { this.props.from.validationSources &&
              <Fragment>
                <DirectionText>
                  If the Validation Source is not one of the following, please contact management to assist.
                </DirectionText>
                <div className="bullet-padding">
                  <ul>
                    {_.map(this.props.from.validationSources, this.generateValidationSources)}
                  </ul>
                </div>
                <ContactManagers showButtons={[]} />
              </Fragment>
            }
            <Fragment>
              <RaisedButton disabled={!this.props.formComplete} label="Vend Gate" onClick={() => this.props.history.push('/vendGate')} />
              <RaisedButton label="End Call" onClick={() => this.props.history.push('/confirmationScreen')} />
            </Fragment>
          </PromptBox>
        }
      </Fragment>
      );
    } else if (this.props.from.validationDiscrepancy && this.firstTwoSteps()) {
        return (
        <PromptBox locationName={this.props.from.locationName}>
          <Paper className="paper-padding">
            <ScriptText>"If you allow me to record some of your information, we can send you a bill in the mail and I can let you out."</ScriptText>
            <DirectionText>Please confirm then proceed to Ticket Discrepancy page.</DirectionText>
          </Paper>
          <Paper zDepth={2} className="paper-padding">
            <span className="verification-span">Next Steps</span>
            <RaisedButton label="Ticket Discrepancy" onClick={() => this.props.history.push('/ticketDiscrepancy')} />
            <Link to="/confirmationScreen">
              <RaisedButton label="Complete Call" />
            </Link>
          </Paper>
        </PromptBox>
        )
    } else if (this.props.from.validationInputAfterManager) {
      return (
        <PromptBox locationName={this.props.from.locationName}>
        { this.props.step === 2 &&
          <div>
            <Paper className="paper-padding">
              <DirectionText>Please record parker's full name and the tenant that validated their ticket.</DirectionText>
            </Paper>
            <Paper zDepth={2} className="paper-padding">
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Parker's Full Name"
                style={{ width: '96%' }}
                onChange={(e, v) => {this.updateField(e, v, 'selectedParkerName')}}
                key="parkerFullName"
              />
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Validating Tenant's Full Name"
                style={{ width: '96%' }}
                onChange={(e, v) => {this.updateField(e, v, 'visitingResidentName')}} key="tenantFullName"
              />
            </Paper>
            <Paper zDepth={2} className="paper-padding">
              <Link to="/vendGate">
                <RaisedButton label="Vend Gate" />
              </Link>
              <Link to="/confirmationScreen">
                <RaisedButton label="End Call" />
              </Link>
            </Paper>
          </div>
        }
        </PromptBox>
        );
    }
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    valCompanyIndex: promptSelectors.pullModular(state, 'companyIndex'),
    ticketNumber: promptSelectors.pullModular(state, 'ticketNumber'),
    username: authSelectors.pullModular(state, 'username'),
    step: promptSelectors.pullStep(state),
    selectedParkerName: promptSelectors.pullModular(state, 'selectedParkerName'),
    contactNumber: promptSelectors.pullModular(state, 'contactNumber'),
    validationNumber: promptSelectors.pullModular(state, 'validationNumber'),
    validationSource: promptSelectors.pullModular(state, 'validationSource'),
    parkerEntranceDate: promptSelectors.pullModular(state, 'parkerEntranceDate'),
    parkerEntranceTime: promptSelectors.pullModular(state, 'parkerEntranceTime'),
    parkerExitDate: promptSelectors.pullModular(state, 'parkerExitDate'),
    parkerExitTime: promptSelectors.pullModular(state, 'parkerExitTime'),
    validationInputTicketNumber: promptSelectors.pullModular(state, 'validationInputTicketNumber'),
    formComplete: promptSelectors.pullModular(state, 'formComplete'),
    managerVendConfirmation10s: promptSelectors.pullModular(state, 'managerVendConfirmation10s'),
    ongoingCallId: queueSelectors.pullModular(state, 'ongoingCallId'),
  };
}

export default withRouter(connect(mapStateToProps)(ValidationIssue));
