import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import autoBind from "react-autobind";
import { withRouter, Link } from "react-router-dom";
import * as config from "../../lib/config";
import * as promptSelectors from "../../store/prompt/reducer";
import * as authSelectors from "../../store/auth/reducer";
import * as promptActions from "../../store/prompt/actions";
import RaisedButton from "material-ui/RaisedButton";
import ScriptText from "../../components/ScriptText";
import DirectionText from '../../components/DirectionText';
import PromptBox from '../../components/promptBox';
import ContactManagers from "./ContactManagers";
import * as queueSelectors from '../../store/queue/reducer';
import { TextField } from '@material-ui/core';

class Valet extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.reloadInterval = null;
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history('/login');
    }
  }

  componentWillUnmount() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  completeStep(step) {
    this.props.dispatch(promptActions.updateIssueStep(step));
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'showPushRateForm':
        this.props.dispatch(promptActions.modularSendField(true, 'pushRateQuestion'));
        this.props.history.push('/tibaAPI/tibaManagerPushRate');
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  renderStandard() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        {this.props.from.skipValetBarcodeScan && this.completeStep(1)}
        { this.props.step === 0 &&
          <Fragment>
            <ScriptText>
              { this.props.from.valetMessage ? this.props.from.valetMessage : 
              'Please scan the barcode and proceed through to the valet station.'
              }
            </ScriptText>
            <ContactManagers 
              showButtons={['managerAssisting', 'vendGate', 'ticketDiscrepancy', 'showCustomButton']} 
              btnClick={this.btnClick}
              btnFrom="valet"
              customButtonData={[
                {
                  "label": "Manager Asked to Push Rate",
                  "action": "showPushRateForm"
                },
              ]}
            />
          </Fragment>  
        }
      </PromptBox>
    );
  }

  renderSplit() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        { this.props.step === 0 &&
          <div>
            <ScriptText>
              { this.props.from.valetMessage ? this.props.from.valetMessage : 
              'Please scan the barcode and proceed through to the valet station.'
              }
            </ScriptText>
            <span className="verification-span">Is further assistance required?</span>
            <div style={{ marginTop: '15px'}}>
              <Link to="/confirmationScreen">
                <RaisedButton label="No" />
              </Link>
              <RaisedButton label="Yes" onClick={() => { this.completeStep(1) }} />
            </div>
          </div>
        }
        { this.props.step == 1 && this.props.from.laneType == 'Entrance' &&
          <Fragment>
            <ScriptText>Please pull a ticket and proceed.</ScriptText>
            <RaisedButton label="Complete Call" onClick={() => { this.props.history.push('/confirmationScreen')}}></RaisedButton>
          </Fragment>
        }
        { this.props.step == 1 && this.props.from.laneType !== 'Entrance' &&
          <Fragment>
            <ScriptText>
              { this.props.from.valetExitMessage ? this.props.from.valetExitMessage : 'Please continue to contact managers to address this issue.'}
            </ScriptText>
            <RaisedButton label="Contact Managers" onClick={() => { this.completeStep(2) }} />
            <RaisedButton label="Exit" onClick={() => { this.props.history.push('/confirmationScreen')}} />
          </Fragment>
        }
        {this.props.step === 2 && <ContactManagers showButtons={['managerAssisting', 'vendGate', 'ticketDiscrepancy']} /> }
      </PromptBox>
    );
  }

  renderNotAllowed() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        <DirectionText>{this.props.from.valetNotAllowedNote}</DirectionText>
        <RaisedButton label="Exit" onClick={() => { this.props.history.push('/confirmationScreen') }} />
      </PromptBox>
    )
  }

  render() {
    if (this.props.from.valetNotAllowedLot) {
      return this.renderNotAllowed();
    } else if (this.props.from.valetSplit) {
      return this.renderSplit();
    } else {
      return this.renderStandard();
    }
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullModular(state, 'issue'),
    username: authSelectors.pullModular(state, 'username'),
    ongoingCallId: queueSelectors.pullModular(state, 'ongoingCallId'),
    step: promptSelectors.pullModular(state, 'step')
  };
}

export default withRouter(connect(mapStateToProps)(Valet));
