import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link, Route } from 'react-router-dom';
import * as config from '../../lib/config';
import * as authSelectors from '../../store/auth/reducer';
import * as promptSelectors from '../../store/prompt/reducer';
import * as promptActions from '../../store/prompt/actions';
import RaisedButton from 'material-ui/RaisedButton';
import { Grid, Paper, TextField, Select, OutlinedInput } from '@material-ui/core';
import DirectionText from '../../components/DirectionText';
import * as queueSelectors from '../../store/queue/reducer';
import ContactManagers from './ContactManagers';
import _ from 'lodash';
import ScriptText from '../../components/ScriptText';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import TicketSearch from '../Prompt/FlashAPI/TicketSearch';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const dateStyles = {
  display: 'inline-block',
  width: '100%'
};

class EquipmentMalfunction extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { 
      renderComponent: { stepOne: true,  showManagersList: false, showCustomWorkflow: false, showCalculateRateForm: false,
      showCalculateRateResult: false, showVerifyTicketResult: false, showEjectTicketResult: false },
      ticketNumber: null, licensePlate: null, parkerFirstName: null, parkerLastName: null, parkerEntranceDate: null, parkerEntranceDateDisplay: null, parkerEmail: null, parkerPhoneNumber: null,
      parkerEntranceTime: null, parkerEntranceTimeDisplay: null, workFlowObj: null, formObj: null, ejectTicketResult: false, calculatedParkerRate: false, shouldVend: null, parkerEntryDateResult: null,
      managerName: null, flashParcsTicketID: null, flashPriceID: null, cardLastFour: null, verifyTicketNote: null
    }
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  updateField(event, target) {
    const value = event.target.value;
    this.props.dispatch(promptActions.modularSendField(value, target));
    if (!value) {
      this.props.dispatch(promptActions.modularSendField(false, 'isFormComplete'));
    }
  }

  updateState(event, target) {
    this.setState({ [target]: event.target.value });
  }

  async navigate() {
    const { equipmentMalfunctionCustom, laneType, equipmentMalfunctionPILRoutes } = this.props.from;
    if (this.props.from.redirectToIntercomPrompts) {
      const shouldRedirect = await this.props.dispatch(promptActions.shouldRedirectToIntercom("equipmentMalfunction"));
      const { redirectToIntercomPrompts } = this.props.from;
      if (shouldRedirect && redirectToIntercomPrompts.equipmentMalfunction.includes(this.props.malfunctionValue)) {
        this.props.history.push('/redirectToIntercom');
      }
    }
    const shouldEnablePIL = await this.props.dispatch(promptActions.shouldEnablePIL('equipmentMalfunction'));
    if (shouldEnablePIL && laneType == "Exit" && (equipmentMalfunctionPILRoutes.includes(this.props.malfunctionValue))) {
      this.props.history.push('/pil/PILEntryInput')
    } else {
      const workFlow = equipmentMalfunctionCustom ? await equipmentMalfunctionCustom.find(malfunction => malfunction.option === this.props.malfunctionValue) : null;
      if (!workFlow) {
        this.showComponent(['showManagersList']);
      } else {
        this.setState({ workFlowObj: workFlow });
        if (workFlow && workFlow.routeTo) {
          this.props.history.push(workFlow.routeTo);
        } else if (workFlow && workFlow.skipComponentAction) {
          this.btnClick(workFlow.skipComponentAction);
        } else {
          this.showComponent(['showCustomWorkflow']);
        }
      }
    }
  }

  saveDate(date, target) {
    const formattedDate = moment(date).format('YYYY:MM:DD');
    this.setState({ parkerEntranceDate: formattedDate, parkerEntranceDateDisplay: date});
  }

  saveTime(time, target) {
    const formattedTime = moment(time).startOf('minute').format('THH:mm:ss');
    this.setState({ parkerEntranceTime: formattedTime, parkerEntranceTimeDisplay: time });
  }
  
  async ejectTicket() {
    const { apiParams, controlDeviceData, baseURL } = this.props.from;
    const { ongoingCallId, username } = this.props;
    const data = { username, ongoingCallId, apiParams, baseURL, commandType: controlDeviceData.ejectTicket, actionId: controlDeviceData.ejectTicketDBId, controlDeviceData };
    const result = await this.props.dispatch(promptActions.tibaSendControlDeviceAction(data)); 
    const { success } = result.data;
    if (success) {
      this.showComponent(['showCustomWorkflow','showEjectTicketResult']);
    }
  }
  
  async ejectTicketFailed() {
    const { workFlowObj } = this.state;
     if (workFlowObj && workFlowObj.LPRVerifyTicket) {
      this.props.history.push('/tibaAPI/tibaLPRVerifyTicket');
    } else {
      this.props.history.push(this.props.from.lostTicketLPR ? '/tibaAPI/tibaLPRSearch' : '/tibaAPI/tibaRateByEntryTime');
    }
  }

  async ejectTicketSuccess() {
    const { workFlowObj } = this.state;
    this.props.history.push(workFlowObj.ejectTicketSuccessRoute);
  }

  async verifyTicket(routeTo) {
    const { ticketNumber, licensePlate, parkerEntranceDate, parkerEntranceTime, formObj } = this.state;
    if ((formObj.fields.includes('EntranceTime')) && (!parkerEntranceDate || !parkerEntranceTime)) {
      this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: 'Entrance Date, Time are required for form submission.'
      }));
    } else if ((formObj.fields.includes('TicketNumber')) && (!this.state.ticketNumber)) {
      this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: 'Ticket Number is required for form submission.'
      }));
    } else if ((formObj.fields.includes('LicensePlate')) && (!this.state.licensePlate)) {
      this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: 'License Plate is required for form submission.'
      }));
    } else {
      const { apiParams, timezone, baseURL } = this.props.from;
      const parkerEntranceDateTime = (!parkerEntranceDate || !parkerEntranceTime) ? null : (parkerEntranceDate + parkerEntranceTime);
      if (parkerEntranceDateTime) {
        this.props.dispatch(promptActions.modularSendField(parkerEntranceDate, 'parkerEntranceDate'));
        this.props.dispatch(promptActions.modularSendField(parkerEntranceTime, 'parkerEntranceTime'));
      }
      const data = { ticketNumber, parkerEntranceDateTime, apiParams, licensePlate, timezone, baseURL };
      let result;
      this.props.dispatch(promptActions.modularSendField(true, 'showLoader'));
      if (routeTo === 'licensePlate') {
        result = await this.props.dispatch(promptActions.tibaVerifyTicketByLPR(data));
      } else if (routeTo === 'closedTicket') {
        result = await this.props.dispatch(promptActions.tibaVerifyIfTicketClosed(data));
      } else if (routeTo === 'openTicket') {
        result = await this.props.dispatch(promptActions.tibaVerifyIfTicketOpen(data));
      } else {
        this.props.dispatch(promptActions.modularSendField(false, 'showLoader'));
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Verify Parker button does not have an action type:by LPR || Ticket Number'
        }));
      }
      this.props.dispatch(promptActions.modularSendField(false, 'showLoader'));
      const { success, notFound, shouldVend, message } = result.data;
      if (success && notFound) {
        this.setState({ shouldVend: false });
        this.showComponent(['showVerifyTicketResult']);
      } else if (success && !notFound && shouldVend) {
        this.setState({ shouldVend: true });
        this.showComponent(['showVerifyTicketResult']);
      }
    }
  }

  async saveParkerInfo() {
    const { parkerEntranceDate, parkerEntranceTime, parkerFirstName, parkerLastName, formObj, parkerPhoneNumber, parkerEmail } = this.state;
    if ((formObj.fields.includes('EntranceTime')) && (!parkerEntranceDate || !parkerEntranceTime)) {
      this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: 'Entrance Date, Time are required for form submission.'
      }));
    } else if ((formObj.fields.includes('ParkerName')) && (!parkerFirstName || !parkerLastName)) {
      this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: 'First & Last name are required for form submission.'
      }));
    }  else if ((formObj.fields.includes('ParkerEmail')) && !parkerEmail) {
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Email is required for form submission.'
        }));
    }  else if ((formObj.fields.includes('ParkerPhoneNumber')) && !parkerPhoneNumber) {
      this.props.dispatch(promptActions.showSnackBar({
        type: 'error', message: 'Phone Number is required for form submission.'
      }));
    } else { 
      this.props.dispatch(promptActions.modularSendField(parkerEntranceDate, 'parkerEntranceDate'));
      this.props.dispatch(promptActions.modularSendField(parkerEntranceTime, 'parkerEntranceTime'));
      this.props.dispatch(promptActions.modularSendField(`${parkerFirstName} ${parkerLastName}`,'selectedParkerName'));
      this.props.dispatch(promptActions.modularSendField(parkerEmail,'email'));
      this.props.dispatch(promptActions.modularSendField(parkerPhoneNumber,'email'));
      this.props.history.push('/vendGate');
    }
  }

  async pushRateToMachine() {
    await this.props.dispatch(promptActions.flashValidateTicket({ priceID: this.state.flashPriceID, flashParcsTicketID: this.state.flashParcsTicketID }));
  }

  async flashSaveTicketDetails(data) {
    const result = await this.props.dispatch(promptActions.flashSaveTicketDetails(data));
    const { shouldVend, ticketNumber, parkerEntryDateResult, calculatedParkerRate, flashParcsTicketID, flashPriceID, paidAt } = result;
    const { malfunctionValue } = this.props;
    if (malfunctionValue == `Received ticket but gate didn't go up` || malfunctionValue == `Paid but gate did not go up, machine ate ticket`) {
      await this.setState({
        shouldVend: paidAt != "" ? true : this.props.from.laneType == "Entrance" ? true : false,
        ticketNumber,
        verifyTicketNote: paidAt != "" ? "The Ticket was found and verified, please proceed to Vend the Gate." : "The Ticket was found but not paid."
      });
      this.showComponent(['showVerifyTicketResult']);
    } else {
      await this.setState({ 
        shouldVend,
        ticketNumber,
        parkerEntryDateResult,
        calculatedParkerRate,
        flashParcsTicketID,
        flashPriceID,
      });
      this.showComponent(['showCalculateRateResult']);
    }
  }

  async btnClick(action, routeTo) {
    switch (action) {
      case 'ejectTicket':
        this.ejectTicket();
        break;
      case 'verifyTicket':
        this.verifyTicket(routeTo);
        break;
      case 'contactManagers':
        this.showComponent(['showManagersList']);
        break;
      case 'pushTo':
        this.props.history.push(routeTo);
        break;
      case 'toForm':
        await this.setState({ formObj: this.state.workFlowObj.parkerInfoForm });
        this.showComponent(['showCalculateRateForm']);
        break;
      case 'saveParkerInfo': 
        this.saveParkerInfo();
        break;
      case 'showPushRateForm':
        this.props.history.push('/tibaAPI/tibaManagerPushRate');
        break;
      case 'changeFormObj':
        await this.setState({ formObj: this.state.workFlowObj.entryDateTimeForm });
        this.showComponent(['showCalculateRateForm']);
        break;
      case 'ticketSearchCC':
        await this.setState({ formObj: this.state.workFlowObj.ticketSearchCCForm });
        this.showComponent(['showCalculateRateForm']);
        break;
      case 'findTicketCC': 
        const { cardLastFour } = this.state;
        if (!cardLastFour) {
          return this.props.dispatch(promptActions.showSnackBar({
            type: 'error', message: 'Last four digits of the Credit/ Debit card are required.'
          }));
        } else {
          this.props.dispatch(promptActions.modularSendField(true, 'showLoader'));
          const result = await this.props.dispatch(promptActions.flashTicketSearchCC({ cardLastFour: cardLastFour }));
          const { ticketData, found } = result.data;
          if (found) {
            await this.setState({ 
              shouldVend: ticketData.PaidAt[0] != "" ? true : false, 
              verifyTicketNote: ticketData.PaidAt[0] != "" ? "The Ticket was found and verified, please proceed to Vend the Gate." : "The Ticket was found but not paid, please Contact Managers."  });
            this.showComponent(['showVerifyTicketResult']);
          } else {
            await this.setState({ shouldVend: false, verifyTicketNote: "The Ticket could not be found." });
            this.showComponent(['showVerifyTicketResult']);
          }
        }
        this.props.dispatch(promptActions.modularSendField(false, 'showLoader'));
        break;
      case 'flashTicketNotFound':
        this.showComponent(['showVerifyTicketResult']);
        break;
      default:
        this.props.dispatch(promptActions.showSnackBar({
          type: 'error', message: 'Button has not action defined, please report this.'
        }));
    }
  }

  renderButtons(btn, index) {
    const { label, action, routeTo } = btn;
    return (
      <Fragment key={(index).toString()}>
        <RaisedButton 
          label={label}
          onClick={() => this.btnClick(action, routeTo)}
        />
      </Fragment>
    )
  }

  renderCustomWorkflow(workFlow) {
    const { notes, buttons } = workFlow;
    return (
      <Fragment>
        <DirectionText> {notes} </DirectionText>
        <div style={{ marginTop: 15 }}>
          {_.map(buttons, this.renderButtons)}
        </div>
      </Fragment>
    )
  }

  showComponent(toRender) {
    const allComponents = {...this.state.renderComponent };
    Object.keys(allComponents).forEach(component => {
      if (allComponents[component] === true) {
        allComponents[component] = false;
      }
    });
    toRender.forEach(key => {
      allComponents[key] = true;
    })
    this.setState({ renderComponent: allComponents });
  }
  
  render() {
    return (
      <div className="home-div">
        <div className="home-div-content">
          <Paper className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            {this.state.renderComponent.stepOne &&
              <Paper className="paper-padding">
                <DirectionText>Please record which type of equipment malfunction is occuring and describe it to the best of your knowledge.</DirectionText>
                { this.props.from.equipmentMalfunctionOfferings && this.props.from.equipmentMalfunctionOfferings.length
                ?
                <Select
                  native
                  margin="dense"
                  value={this.props.malfunctionValue || 'Select a Malfunction Type'}
                  onChange={(event) => {this.updateField(event, 'malfunctionValue')}}
                  style={{ width: '98%', textAlign: 'left', marginTop: 2, marginBottom: 10 }}
                  input={<OutlinedInput name="Malfunction Type" />}
                >
                  <option value={0}> Select a Malfunction Type </option>
                  { this.props.from.equipmentMalfunctionOfferings.map((malfunctionType) => {
                    return <option value={malfunctionType}>{malfunctionType}</option>
                  })
                  }
                </Select>
                :
                <Select
                  native
                  margin="dense"
                  value={this.props.malfunctionValue || 'Select a Malfunction Type'}
                  onChange={(event) => {this.updateField(event, 'malfunctionValue')}}
                  style={{ width: '98%', textAlign: 'left', marginTop: 2, marginBottom: 10 }}
                  input={<OutlinedInput name="Malfunction Type" />}
                >
                  {/* PLEASE BE CAREFUL WHEN YOU ARE EDITING THESE NAMES. THEY ARE USED FOR HOUSTON FIRST LOCATIONS */}
                  <option value={0}> Select a Malfunction Type </option>
                  { this.props.from.laneType === "Entrance" && <option value={`Gate went up but didn't receive ticket`}>Gate went up but didn't receive ticket </option> }
                  { this.props.from.laneType === "Entrance" && <option value={`Intercom not working`}>Intercom not working </option> }
                  { this.props.from.laneType === "Entrance" && <option value={`System Error: Out of tickets`}>System Error: Out of tickets </option>}
                  { this.props.from.laneType === "Entrance" && <option value={`Received ticket but gate didn't go up`}>Received ticket but gate didn't go up </option> }
                  { this.props.from.laneType === "Exit" && <option value={`Entrance didn't give ticket`}>Entrance didn't give ticket </option> }
                  { this.props.from.laneType === "Exit" && <option value={`Gate is broken / damaged / vandalized`}>Gate is broken / damaged / vandalized </option> }
                  { this.props.from.laneType === "Exit" && <option value={`Can't vend gate`}>Can't vend gate </option> }
                  { this.props.from.laneType === "Exit" && <option value={`Machine ate credit card`}>Machine ate credit card </option> }
                  { this.props.from.laneType === "Exit" && <option value={`Machine not accepting cash`}>Machine not accepting cash </option> }
                  { this.props.from.laneType === "Exit" && <option value={`Machine not showing the amount charged`}> Machine not showing the amount charged </option> }
                  { this.props.from.laneType === "Exit" && <option value={`Paid at PoF but exit gate did not open`}> Paid at PoF but exit gate did not open </option> }
                  { this.props.from.laneType === "Exit" && <option value={`Paid but gate did not go up, machine ate ticket`}> Paid but gate did not go up, machine ate ticket </option> }
                  { this.props.from.laneType === "Exit" && <option value={`Paid at PoF but the machine didn't give receipt to scan at exit`}> Paid at PoF but the machine didn't give receipt to scan at exit </option> }
                  { this.props.from.laneType === "Exit" && <option value={`Ticket jam`}> Ticket jam </option> }
                  { this.props.from.laneType === "Exit" && <option value={`Machine did not give change`}> Machine did not give change </option> }
                  { this.props.from.laneType === "Paystation" && <option value={`Entrance didn't give ticket`}> Entrance didn't give ticket </option> }
                  { this.props.from.laneType === "Paystation" && <option value={`Can't vend gate`}> Can't vend gate </option> }
                  { this.props.from.laneType === "Paystation" && <option value={`Machine ate credit card`}> Machine ate credit card </option> }
                  { this.props.from.laneType === "Paystation" && <option value={`Machine not accepting cash`}> Machine not accepting cash </option> }
                  { this.props.from.laneType === "Paystation" && <option value={`Machine not accepting credit card`}> Machine not accepting credit card </option> }
                  { this.props.from.laneType === "Paystation" && <option value={`Machine not showing the amount charged`}> Machine not showing the amount charged </option> }
                  { this.props.from.laneType === "Paystation" && <option value={`Paid but does not give receipt to scan at exit`}> Paid but does not give receipt to scan at exit </option> }
                  { this.props.from.laneType === "Paystation" && <option value={`Ticket jam`}> Ticket jam </option> }
                  { this.props.from.laneType === "Paystation" && <option value={`Machine did not give change`}> Machine did not give change </option> }
                </Select>}
                <TextField
                  required                
                  margin="dense"
                  label="Malfunction Description"
                  multiline
                  rows="6"
                  placeholder="Please describe the issue.."
                  variant="outlined"
                  value={this.props.malfunctionDescription || ''}
                  inputProps={{ className: 'data-hj-allow' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => this.updateField(event, 'malfunctionDescription')}               
                  style={{ width: '98%', textAlign: 'left', marginTop: 15, marginBottom: 10 }}
                />
                <RaisedButton 
                  disabled={(this.props.malfunctionValue && this.props.malfunctionDescription ? false : true)}
                  style={{ marginTop: 15 }}
                  onClick={()=> this.navigate()} 
                  label="Submit" 
                />
              </Paper>
            } 
            
            {this.state.renderComponent.showManagersList && 
              <Paper className="paper-padding">
                <DirectionText> Contact the local managers to alert them of the malfunction. </DirectionText>
                {this.props.malfunctionValue && this.props.malfunctionDescription &&
                  <Fragment>
                    <DirectionText><span style={{ fontWeight: 'bolder', marginRight: 5 }}>Malfunction Type: </span> {this.props.malfunctionValue} </DirectionText>
                    <DirectionText><span style={{ fontWeight: 'bolder', marginRight: 5 }}>Malfunction Description: </span> {this.props.malfunctionDescription} </DirectionText>
                  </Fragment>
                }
                
                <ContactManagers showButtons={
                    [
                      'vendGate', 'managerAssisting', 'showCustomButton',
                      `${this.state.workFlowObj && this.state.workFlowObj.noTicketDiscrepancy ? null : 'ticketDiscrepancy'}`
                    ]
                  }
                  btnClick={this.btnClick}
                  btnFrom="equipmentMalfunction"
                  customButtonData={[
                    {
                      "label": "Manager Asked to Push Rate",
                      "action": "showPushRateForm"
                    },
                  ]} 
                />

                {this.state.workFlowObj && this.state.workFlowObj.noTicketDiscrepancy &&
                  <RaisedButton disabled={!this.props.managerVendConfirmation10s} label="No manager Response" onClick={() => this.btnClick('toForm') } />
                }
              </Paper>
            }

            {this.state.renderComponent.showCustomWorkflow && this.state.workFlowObj &&
              <Paper className="paper-padding">
                {this.renderCustomWorkflow(this.state.workFlowObj)}
              </Paper>
            }

            {this.state.renderComponent.showCalculateRateForm && 
              <Paper className="paper-padding">
                <DirectionText>
                  <span style={{ color: 'red', fontWeight: 'bolder' }} >Note: </span>  {this.state.formObj.notes}
                </DirectionText>
                {this.state.formObj.fields.includes('EntranceTime') && 
                  <Fragment>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Grid style={{ marginTop: 15, padding: 5 }} container spacing={2}>
                        <Grid item xs>
                          <DatePicker  
                            label="Entrance Date"
                            format="YYYY-MM-DD"
                            value={this.state.parkerEntranceDateDisplay}
                            onChange={date => this.saveDate(date, 'parkerEntranceDate')}  
                            style={dateStyles}
                            fullWidth={true}
                            maxDate={new Date()}
                            inputProps={{ className: 'data-hj-allow' }}
                          />
                        </Grid>
                        <Grid item xs>
                          <TimePicker
                            ampm={false}
                            openTo="hours"
                            views={["hours", "minutes"]}
                            format="HH:mm"
                            value={this.state.parkerEntranceTimeDisplay}
                            onChange={time => this.saveTime(time, 'parkerEntranceTime')}
                            label="Entrance Time"
                            style={dateStyles}
                            fullWidth={true}
                            inputProps={{ className: 'data-hj-allow' }}
                          />
                        </Grid>
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Fragment>
                }

                {this.state.formObj.fields.includes('LicensePlate') && 
                  <TextField
                    label="License Plate"
                    margin="dense"
                    variant="outlined"
                    inputProps={{ className: 'data-hj-allow' }}
                    InputLabelProps={{
                      shrink: true,
                    }} 
                    onChange={(event) => {this.updateState(event, 'licensePlate')}}
                    style={{ width: '100%', marginTop: 15 }}
                  />
                } 

                {this.state.formObj.fields.includes('TicketNumber') && 
                  <TextField
                    label="Ticket Number"
                    margin="dense"
                    variant="outlined"
                    inputProps={{ className: 'data-hj-allow' }}
                    InputLabelProps={{
                      shrink: true,
                    }} 
                    onChange={(event) => {this.updateState(event, 'ticketNumber')}}
                    style={{ width: '100%', marginTop: 15 }}
                  />
                }

                {this.state.formObj.fields.includes('DynamicTicketSearch') && 
                  <TicketSearch flashSaveTicketDetails={this.flashSaveTicketDetails} />
                }

                {this.state.formObj.fields.includes('TicketSearchCC') &&
                  <Fragment>
                    <TextField
                      label="Credit Card Number"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ maxLength: 12, className: 'data-hj-allow' }}
                      onChange={(event) => {this.updateState(event, 'cardLastFour')}}
                      style={{ width: '100%', marginTop: 15 }}
                    />
                  </Fragment>
                }
                
                {this.state.formObj.fields.includes('ParkerName') && 
                  <Fragment>
                    <TextField
                      label="First Name"
                      margin="dense"
                      variant="outlined"
                      inputProps={{ className: 'data-hj-allow' }}
                      InputLabelProps={{
                        shrink: true,
                      }} 
                      onChange={(event) => {this.updateState(event, 'parkerFirstName')}}
                      style={{ width: '100%', marginTop: 15 }}
                    />
                    <TextField
                      label="Last Name"
                      margin="dense"
                      variant="outlined"
                      inputProps={{ className: 'data-hj-allow' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) => {this.updateState(event, 'parkerLastName')}}
                      style={{ width: '100%', marginTop: 15 }}
                    />
                  </Fragment>
                }

                {this.state.formObj.fields.includes('ParkerEmail') && 
                  <TextField
                    label="Email"
                    margin="dense"
                    variant="outlined"
                    inputProps={{ className: 'data-hj-allow' }}
                    InputLabelProps={{
                      shrink: true,
                    }} 
                    onChange={(event) => {this.updateState(event, 'parkerEmail')}}
                    style={{ width: '100%', marginTop: 15 }}
                  />
                }

                {this.state.formObj.fields.includes('ParkerPhoneNumber') && 
                  <TextField
                    label="Phone Number"
                    margin="dense"
                    variant="outlined"
                    inputProps={{ className: 'data-hj-allow' }}
                    InputLabelProps={{
                      shrink: true,
                    }} 
                    onChange={(event) => {this.updateState(event, 'parkerPhoneNumber')}}
                    style={{ width: '100%', marginTop: 15 }}
                  />
                }

                <div style={{ marginTop: 15 }}>
                  {_ .map(this.state.formObj.buttons, this.renderButtons)}
                </div>
              </Paper>
            }
            {/* Being used by flash */}
            {this.state.renderComponent.showCalculateRateResult && 
              <Paper className="paper-padding">
                  {this.state.shouldVend 
                  ? 
                    <DirectionText>
                      The parker is within the Grace Period. Please vend the Gate.
                    </DirectionText>
                  : 
                    <Fragment>
                      <DirectionText>
                        <span style={{ color: 'red', fontWeight: 'bolder' }} >Note: </span> {`Click the Push Rate button to display the rate on machine.\n`}
                        <span style={{ fontWeight: 'bolder' }} >Entry Date: </span> {this.state.parkerEntryDateResult}
                      </DirectionText>
                      <ScriptText>
                        Please pay the ${this.state.calculatedParkerRate} fee to exit the parking garage.
                      </ScriptText>
                    </Fragment>
                  }
                <div style={{ marginTop: 15 }}>
                  {this.state.shouldVend 
                  ? 
                    <RaisedButton label="Vend Gate" onClick={() => this.btnClick('pushTo', 'vendGate')} />
                  :
                    <RaisedButton label="Push Rate To Machine" onClick={() => this.pushRateToMachine()} />
                  }
                  <RaisedButton label="Contact Managers" onClick={() => this.btnClick('contactManagers')} />
                  <RaisedButton label="Complete Call" onClick={() => this.btnClick('pushTo', '/confirmationScreen')} />
                </div>
              </Paper>
            } 

            {this.state.renderComponent.showVerifyTicketResult &&
              <Paper className="paper-padding">
                {this.state.shouldVend
                ?
                  <DirectionText>
                    {this.state.verifyTicketNote
                    ? 
                      <Fragment>
                        {this.state.verifyTicketNote}
                      </Fragment>
                    :
                      <Fragment>
                        The ticket number is verified and paid please proceed and Vend the Gate.
                      </Fragment>
                    }
                  </DirectionText>
                :
                  <Fragment>
                    <DirectionText>
                      {this.state.verifyTicketNote
                      ?
                        <Fragment>
                          {this.state.verifyTicketNote}
                        </Fragment>
                      :
                        <Fragment>
                          {`The Ticket Number is found, please ask the Parker to`}
                          {this.props.from.laneType == 'Entrance' && ' pull another ticket and proceed.'}
                          {this.props.from.laneType == 'Exit' && ' pay.'}
                        </Fragment>
                      }
                    </DirectionText>
                  </Fragment>
                }
                <div style={{ marginTop: 15 }}>
                  {this.state.shouldVend && <RaisedButton label="Vend Gate" onClick={() => this.btnClick('pushTo', 'vendGate')} />}
                  <RaisedButton label="Contact Managers" onClick={() => this.btnClick('contactManagers')} />
                  <RaisedButton label="Complete Call" onClick={() => this.btnClick('pushTo', '/confirmationScreen')} />
                </div>
              </Paper>
            }

            {this.state.renderComponent.showEjectTicketResult && 
              <Paper className="paper-padding" style={{ marginTop: 15 }}>
                <ScriptText>
                  Please let me know if the Ticket is ejected. 
                </ScriptText>
                <div style={{ marginTop: 15 }}>
                  <RaisedButton label="Eject Ticket Failed" onClick={() => this.ejectTicketFailed()} />
                  <RaisedButton label="Eject Ticket Success" onClick={() => this.ejectTicketSuccess()} />
                </div>
              </Paper>
            }  
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: authSelectors.pullModular(state, 'username'),
    authLevel: authSelectors.pullModular(state, 'authLevel'),
    from: promptSelectors.pullLocationId(state),
    ongoingCallId: queueSelectors.pullModular(state, 'ongoingCallId'),
    malfunctionValue: promptSelectors.pullModular(state, 'malfunctionValue'),
    malfunctionIndex: promptSelectors.pullModular(state, 'malfunctionIndex'),
    malfunctionDescription: promptSelectors.pullModular(state, 'malfunctionDescription'),
    managerVendConfirmation10s: promptSelectors.pullModular(state, 'managerVendConfirmation10s'),
    selectedManagerName: promptSelectors.pullModular(state, 'selectedManagerName')
  };
}

export default withRouter(connect(mapStateToProps)(EquipmentMalfunction));
