import React, { Component } from 'react';
import QueueScreen from './containers/QueueScreen';
import MapContainer from './containers/MapContainer';
import autoBind from 'react-autobind';
import './App.css';
import _ from 'lodash';
import { MemoryRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-grid-system';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import * as authSelectors from './store/auth/reducer';
import * as authActions from './store/auth/actions';
import * as promptSelectors from './store/prompt/reducer';
import * as promptActions from './store/prompt/actions';
import * as queueActions from './store/queue/actions';
import * as queueSelectors from './store/queue/reducer';
import Home from './containers/Prompt/Home';
import PreFirst from './containers/Prompt/PreFirst';
import First from './containers/Prompt/First';
import RedirectToIntercom from './containers/Prompt/RedirectToIntercom';
import TransientIssue from './containers/Prompt/Transient/TransientIssue';
import TicketAcceptanceIssue from './containers/Prompt/Transient/TicketAcceptanceIssue';
import ZipCar from './containers/Prompt/Transient/ZipCar';
import ConfirmationScreen from './containers/Prompt/ConfirmationScreen';
import TicketDiscrepancy from './containers/Prompt/TicketDiscrepancy';
import LostTicket from './containers/Prompt/Transient/LostTicket';
import TibaRateByTicket from './containers/Prompt/TibaAPI/TibaRateByTicket';
import TibaLPRSearch from './containers/Prompt/TibaAPI/TibaLPRSearch';
import TibaLPRVerifyTicket from './containers/Prompt/TibaAPI/TibaLPRVerifyTicket';
import TibaRateByEntryTime from './containers/Prompt/TibaAPI/TibaRateByEntryTime';
import TibaRateResult from './containers/Prompt/TibaAPI/TibaRateResult';
import TibaManagerPushRate from './containers/Prompt/TibaAPI/TibaManagerPushRate';
import PILEntryInput from './containers/Prompt/PayInLane/PILEntryInput';
import PILProcessPayment from './containers/Prompt/PayInLane/PILProcessPayment';
import PILPaymentResult from './containers/Prompt/PayInLane/PILPaymentResult';
import PILCheckTransactionStatus from './containers/Prompt/PayInLane/PILCheckTransactionStatus';
import Brokers from './containers/Prompt/Transient/Brokers';
import RateDispute from './containers/Prompt/Transient/RateDispute';
import PaymentIssue from './containers/Prompt/Transient/PaymentIssue';
import ValidationIssue from './containers/Prompt/Transient/ValidationIssue';
import ReceiptRequest from './containers/Prompt/Transient/ReceiptRequest';
import PriceInput from './containers/Prompt/Transient/PriceInput';
import GeneralInquiry from './containers/Prompt/GeneralInquiry';
import VendGate from './containers/Prompt/VendGate';
import MonthlyIssue from './containers/Prompt/Monthly/MonthlyIssue';
import MonthlyVerification from './containers/Prompt/Monthly/MonthlyVerification';
import FlashResetMonthlyCard from './containers/Prompt/Monthly/FlashResetMonthlyCard';
import MonthlyNotFound from './containers/Prompt/Monthly/MonthlyNotFound';
import Login from './containers/Auth/Login';
import AdminPortal from './containers/Auth/AdminPortal';
import PasswordReset from './containers/Auth/PasswordReset';
import AuthBar from './containers/Auth/AuthBar';
import Register from './containers/Auth/Register';
import VideoPlaceholder from './components/videoPlaceholder';
import HotelIssue from './containers/Prompt/Hotel/HotelIssue';
import HotelVerification from './containers/Prompt/Hotel/HotelVerification';
import HotelNotFound from './containers/Prompt/Hotel/HotelNotFound';
import DamageClaim from './containers/Prompt/DamageClaim';
import CommunicationIssue from './containers/Prompt/CommunicationIssue';
import EquipmentMalfunction from './containers/Prompt/EquipmentMalfunction';
import PrepaidGuest from './containers/Prompt/Transient/PrepaidGuest';
import AttendantAssisting from './containers/AttendantAssisting';
import AirportParking from './containers/Prompt/AirportParking';
import AirportNotFound from './containers/Prompt/AirportNotFound';
import ServiceWorker from './containers/Prompt/ServiceWorker';
import GracePeriod from './containers/Prompt/GracePeriod';
import Landline from "./containers/Prompt/Landline";
import ResidentIssue from './containers/Prompt/ResidentIssue';
import EmployeeIssue from './containers/Prompt/EmployeeIssue';
import ContractorIssue from './containers/Prompt/ContractorIssue';
import VisitorIssue from './containers/Prompt/VisitorIssue';
import UberDriver from './containers/Prompt/UberDriver';
import ReservationIssue from './containers/Prompt/ReservationIssue';
import ReservationDiscrepancy from './containers/Prompt/ReservationDiscrepancy';
import ErrorMessage from './components/errorMessage';
import Shuttle from './containers/Prompt/Shuttle';
import AttendanceCallIn from './containers/Prompt/AttendanceCallIn';
import SpecialMonthly from './containers/Prompt/Monthly/SpecialMonthly';
import MonthlyTransient from './containers/Prompt/Monthly/Transient';
import ContactManagersPage from './containers/Prompt/ContactManagersPage';
import OutsideCall from './containers/Prompt/OutsideCall';
import GhostCall from './containers/Prompt/GhostCall';
import Valet from './containers/Prompt/Valet';
import GenericComponent from './containers/Prompt/GenericComponent';
import * as config from './lib/config.js';
import SocketContext from './services/socketContext'
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'
import { Grid, Button, Modal, Paper }  from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import SetNumberMap from './containers/Auth/SetNumberMap';
import RouteDisabled from './containers/Prompt/RouteDisabled';
import HowToEnter from './containers/Prompt/Transient/HowToEnter';
import HowToPay from './containers/Prompt/Transient/HowToPay';
import ValidationInstructions from './containers/Prompt/Transient/ValidationInstructions';
import UpdateManager from './containers/Auth/UpdateManager';
import PrepaidContainer from './containers/Prompt/Transient/PrepaidContainer';
import { retrieveLogger, updateLogger, removeFromLogger } from './lib/logger';
import { datadogLogs } from '@datadog/browser-logs';
import DisableAnalyst from './containers/Auth/DisableAnalyst';
import ShuttlePrompt from './containers/Prompt/ShuttlePrompt';
import VendForAll from './containers/VendForAll';
let logger = retrieveLogger();


class App extends Component {

  constructor(props) {
    super(props);
    autoBind(this);
    this.socket = null;
    this.state = { node: '0'}
  }

  setNodeState(nodeNum) {
    this.setState({ node: nodeNum })
  }

  componentDidMount() {
    this.socket = this.props.socket;
    this.socket.on('handshakeSuccessful', this.handshakeSuccessful);
    this.socket.on('disconnect', this.socketDisconnect);
    this.socket.on('reconnect_attempt', this.reconnectAttempt);
  }

  componentWillUnmount() {
    this.socket.removeListener('handshakeSuccessful', this.handshakeSuccessful);
    this.socket.removeListener('disconnect', this.socketDisconnect);
    this.socket.removeListener('reconnect_attempt', this.reconnectAttempt);
    this.socket = null;
  }

  componentDidUpdate() {
    if (this.props.showLoader) {
      setTimeout(t => {
        this.props.dispatch(promptActions.modularSendField(false, 'showLoader'))
      }, 10000)
    }
    const loggerUsername = datadogLogs.getLoggerGlobalContext().username;
    if (this.props.username && !loggerUsername) {
      logger.info(`Setting username to ${this.props.username}`)
      logger = updateLogger({ username: this.props.username });
      logger.info('Here is username');
    }
    if (this.props.from) {
      logger = updateLogger({ from: this.props.from });
    } else {
      logger = removeFromLogger('from');
    }
    if (!this.props.username && loggerUsername) {
      logger.info(`Clearing username ${loggerUsername} from logger.`);
      logger = removeFromLogger('username');
    }
  }

  handshakeSuccessful(data) {
    logger.info(data.message);
    if (this.props.username) {
      logger.info(`Sending Socket info to server`);
      const message = {
        socketId: this.socket.id,
        clientName: this.props.username,
        clientType: 'cloudparkAnalyst',
        workerSid: this.props.workerSid
      }
      this.socket.emit('saveClientSocketInfo', message);
    }
  }

  socketDisconnect(data) {
    logger.info(`Web Socket Server is Down.`);
    this.props.dispatch(authActions.modularSendField(true, 'shouldAnalystLogout'));
  }

  reconnectAttempt(data) {
    logger.info(`Attempting to Reconnect.`);
    logger.info(data);
  }

  handleSnackbarClose() {
    this.props.dispatch(promptActions.modularSendField(false, 'error'));
    this.props.dispatch(promptActions.modularSendField('', 'errorMessage'));
  }

  sendLogOut() {
    setTimeout(this.props.dispatch(queueActions.destroyDeviceOnLogout, 5000));
    this.props.dispatch(authActions.sendLogout(this.props.username));
    this.props.dispatch(queueActions.destroyDevice());
    this.props.dispatch(promptActions.modularSendField(false, 'mapLoaded'));
    this.props.dispatch(authActions.modularSendField(false, 'shouldAnalystLogout'));
    setTimeout(() => {this.props.history.push('/')}, 1000);
    setTimeout(() => {window.location.reload(true)}, 1000);
  }

  cancelLogoutModal() {
    this.props.dispatch(authActions.modularSendField(false, 'shouldAnalystLogout'));
  }

  shouldShowLogoutModal() {
    const { shouldAnalystLogout, onCall, username} = this.props;
    if (username && !onCall && shouldAnalystLogout) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    let allRoutes = [
      '/monthly',
      '/transient',
      '/preFirst',
      '/first',
      '/redirectToIntercom',
      '/vendGate',
      '/preFirst',
      '/ticketDiscrepancy',
      '/confirmationScreen',
      '/ghost',
      '/hotel',
      '/damageClaim',
      '/communicationIssue',
      '/equipmentMalfunction',
      '/attendantAssisting',
      '/generalInquiry',
      '/airport',
      '/airportNotFound',
      '/serviceWorker',
      '/priceInput',
      '/gracePeriod',
      '/landline',
      '/resident',
      '/employee',
      '/contractor',
      '/visitor',
      '/uber',
      '/reservation',
      '/reservationDiscrepancy',
      '/shuttle',
      '/attendanceCallIn',
      '/specialMonthly',
      '/contactManagers',
      '/outsideCall',
      '/valet',
      '/genericComponent',
      '/routeDisabled',
      '/tibaAPI/tibaRateByTicket',
      '/tibaAPI/tibaLPRSearch',
      '/tibaAPI/tibaLPRVerifyTicket',
      '/tibaAPI/tibaRateByEntryTime',
      '/tibaAPI/tibaRateResult',
      '/tibaAPI/tibaManagerPushRate',
      '/pil/PILEntryInput',
      '/pil/pilProcessPayment',
      '/pil/pilPaymentResult',
      '/vendForAll'
    ];
    return (
      <div className="container">
        <div className="App">
          <LoadingOverlay
            active={this.props.showLoader}
            spinner={<BounceLoader />}
            styles={{
              overlay: (base) => ({
                ...base,
                background: 'rgba(0, 0, 0, 0)'
              })
            }}
          >
            <MuiThemeProvider>
              <MemoryRouter>
                <Container className="overwrite-container">
                  <PILCheckTransactionStatus />
                  <Row>
                    <ErrorMessage
                      error={this.props.error}
                      errorMessage={this.props.errorMessage}
                      snackbarType={this.props.snackbarType}
                      handleSnackbarClose={this.handleSnackbarClose}
                    />
                    <Col xs={3}>
                      <Switch>
                        {_.map(allRoutes, (v, i) => {
                          return (
                            <Route path={v} key="queueScreen" component={QueueScreen} />
                          );
                        })}
                        <Route exact path="/" key="queueScreen" component={QueueScreen} />
                      </Switch>
                    </Col>
                    <Col xs={6}>
                      <Switch>
                        <Route exact path={'/'} component={config.TestComponent ? config.TestComponent : Home} />
                        <Route exact path={'/login'} component={Login} />
                        <Route exact path={'/preFirst'} component={PreFirst} />
                        <Route exact path={'/first'} component={First} />
                        <Route exact path={'/redirectToIntercom'} component={RedirectToIntercom} />
                        <Route exact path={'/transient'} component={TransientIssue} />
                        <Route exact path={'/transient/ticketAcceptanceIssue'} component={TicketAcceptanceIssue} />
                        <Route exact path={'/transient/zipCar'} component={ZipCar} />
                        <Route exact path="/transient/lostTicket" component={LostTicket} />
                        <Route exact path="/transient/brokers" component={Brokers} />
                        <Route exact path={'/transient/rateDispute'} component={RateDispute} />
                        <Route exact path={'/transient/paymentIssue'} component={PaymentIssue} />
                        <Route exact path={'/transient/validationIssue'} component={ValidationIssue} />
                        <Route exact path="/transient/receiptRequest" component={ReceiptRequest} />
                        <Route exact path="/transient/prepaidGuest" component={PrepaidGuest} />
                        <Route exact path="/transient/priceInput" component={PriceInput} />
                        <Route exact path="/transient/howToEnter" component={HowToEnter} />
                        <Route exact path="/transient/howToPay" component={HowToPay} />
                        <Route exact path="/transient/validationInstructions" component={ValidationInstructions} />
                        <Route exact path="/ghost" component={GhostCall} />
                        <Route exact path="/generalInquiry" component={GeneralInquiry} />
                        <Route exact path='/confirmationScreen' component={ConfirmationScreen} />
                        <Route exact path='/ticketDiscrepancy' component={TicketDiscrepancy} />
                        <Route exact path='/vendGate' component={VendGate} />
                        <Route exact path='/monthly' component={MonthlyIssue} />
                        <Route exact path='/monthly/monthlyVerification' component={MonthlyVerification} />
                        <Route exact path='/monthly/flashResetMonthlyCard' component={FlashResetMonthlyCard} />
                        <Route exact path="/monthly/notFound" component={MonthlyNotFound} />
                        <Route exact path="/specialMonthly" component={SpecialMonthly} />
                        <Route exact path="/monthly/transient" component={MonthlyTransient} />
                        <Route exact path='/adminPortal' component={AdminPortal} />
                        <Route exact path='/passwordReset' component={PasswordReset} />
                        <Route exact path='/register' component={Register} />
                        <Route exact path='/setNumberMap' component={SetNumberMap} />
                        <Route exact path="/hotel" component={HotelIssue} />
                        <Route exact path="/hotel/verification" component={HotelVerification} />
                        <Route exact path="/hotel/notFound" component={HotelNotFound} />
                        <Route exact path="/damageClaim" component={DamageClaim} />
                        <Route exact path="/communicationIssue" component={CommunicationIssue} />
                        <Route exact path="/equipmentMalfunction" component={EquipmentMalfunction} />
                        <Route exact path="/attendantAssisting" component={AttendantAssisting} />
                        <Route exact path="/airport" component={AirportParking} />
                        <Route exact path="/airportNotFound" component={AirportNotFound} />
                        <Route exact path="/serviceWorker" component={ServiceWorker} />
                        <Route exact path="/gracePeriod" component={GracePeriod} />
                        <Route exact path="/resident" component={ResidentIssue} />
                        <Route exact path="/employee" component={EmployeeIssue} />
                        <Route exact path="/contractor" component={ContractorIssue} />
                        <Route exact path="/visitor" component={VisitorIssue} />
                        <Route exact path="/landline" component={Landline} />
                        <Route exact path="/uber" component={UberDriver} />
                        <Route exact path="/reservation" component={ReservationIssue} />
                        <Route exact path="/reservationDiscrepancy" component={ReservationDiscrepancy} />
                        <Route exact path="/shuttle" render={() => <ShuttlePrompt node={this.state.node} setNodeState={this.setNodeState}/>} />
                        <Route exact path="/attendanceCallIn" component={AttendanceCallIn} />
                        <Route exact path="/contactManagers" component={ContactManagersPage} />
                        <Route exact path="/outsideCall" component={OutsideCall} />
                        <Route exact path="/valet" component={Valet} />
                        <Route exact path="/genericComponent" component={GenericComponent} />
                        <Route exact path="/updateManager" component={UpdateManager} />
                        <Route exact path="/tibaAPI/tibaRateByTicket" component={TibaRateByTicket} />
                        <Route exact path="/tibaAPI/tibaLPRSearch" component={TibaLPRSearch} />
                        <Route exact path="/tibaAPI/tibaLPRVerifyTicket" component={TibaLPRVerifyTicket} />
                        <Route exact path="/tibaAPI/tibaRateByEntryTime" component={TibaRateByEntryTime} />
                        <Route exact path="/tibaAPI/tibaRateResult" component={TibaRateResult} />
                        <Route exact path="/tibaAPI/tibaManagerPushRate" component={TibaManagerPushRate} />
                        <Route exact path="/pil/PILEntryInput" component={PILEntryInput} />
                        <Route exact path="/pil/pilProcessPayment" component={PILProcessPayment} />
                        <Route exact path="/pil/pilPaymentResult" component={PILPaymentResult} />
                        <Route exact path="/routeDisabled" component={RouteDisabled} />
                        <Route exact path="/transient/prepaidContainer" component={PrepaidContainer} />
                        <Route exact path="/disableAnalyst" component={DisableAnalyst} />
                        <Route exact path="/vendForAll" component={VendForAll} />
                      </Switch>
                      <Modal
                        open={this.shouldShowLogoutModal()}
                        style={{
                          position: 'absolute',
                          width: 400,
                          backgroundColor: 'white',
                          left: '50%',
                          marginLeft: '-200px',
                          height: '200px',
                          top: '50%',
                          marginTop: '-100px',
                          textAlign: 'center'
                        }}
                      >
                        <Paper style={{ textAlign: 'center', fontWeight: 'bolder' }}>
                          <Grid container justify="flex-start" alignItems="center" style={{ margin: 'auto', textAlign: 'left'}}>
                            <Grid item xs={12} style={{ padding: 15, margin: '0 auto', textAlign: 'center' }}>
                              <Grid item style={{ padding: 15 }}>
                                <ErrorIcon style={{ color: 'red', width: 50, height: 50 }} />
                              </Grid>
                              <Grid item>
                                There was a network connection error during your last call. Please press the Logout button below to fix the issue.
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{ margin: '0 auto', textAlign: 'center', padding: 15 }}>
                              <Grid item xs={6}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => this.cancelLogoutModal()}
                                  >
                                  Logout Later
                                </Button>
                              </Grid>
                              <Grid item xs={6}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={(event) => this.sendLogOut(event)}
                                  >
                                  Logout Now
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Modal>
                    </Col>
                    <Col xs={3}>
                      <div className="outer-map-box half-height">
                        <Switch>
                          <Route path='/' component={MapContainer} />
                        </Switch>
                      </div>
                    </Col>
                  </Row>
                  <AuthBar authLevel={this.props.authLevel} node={this.state.node} setNodeState={this.setNodeState}/>
                </Container>
              </MemoryRouter>
            </MuiThemeProvider>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    workerSid: authSelectors.pullModular(state, 'workerSid'),
    authLevel: authSelectors.pullModular(state, 'authLevel'),
    error: promptSelectors.pullModular(state, 'error'),
    errorMessage: promptSelectors.pullModular(state, 'errorMessage'),
    snackbarType: promptSelectors.pullModular(state, 'snackbarType'),
    showLoader: promptSelectors.pullModular(state, 'showLoader'),
    shouldAnalystLogout: authSelectors.pullModular(state, 'shouldAnalystLogout'),
    onCall: queueSelectors.pullModular(state, 'onCall')
  };
}

const appSocket = (props) => (
  <SocketContext.Consumer>
    {socket => <App {...props} socket={socket} />}
  </SocketContext.Consumer>
)

export default connect(mapStateToProps, null)(appSocket);
