import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptSelectors from '../../store/prompt/reducer';
import * as authSelectors from '../../store/auth/reducer';
import ContactManagers from './ContactManagers';
import PromptBox from '../../components/promptBox';

class ContactManagersPage extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        if (!this.props.username && !config.TestComponent) {
            this.props.history.push('/login');
        }
    }

    componentDidUpdate() {
        if (!this.props.username && !config.TestComponent) {
            this.props.history.push('/login');
        }
    }

    render() {
        return (
          <PromptBox locationName={this.props.from.locationName}>
            <ContactManagers showButtons={['vendGate', 'managerAssisting', 'ticketDiscrepancy']} />
          </PromptBox>
        );
    }
}

function mapStateToProps(state) {
    return {
        from: promptSelectors.pullLocationId(state),
        username: authSelectors.pullModular(state, 'username'),
        friendlyName: authSelectors.pullModular(state, 'friendlyName')
    };
}

export default withRouter(connect(mapStateToProps)(ContactManagersPage));