import sendAction from '../../services/sendAction';
import monthlyService from '../../services/updateMonthlyParkers';
import serverInteraction from '../../services/serverInteraction';
import * as promptActions from '../../store/prompt/actions';
import moment from 'moment-timezone';
import { retrieveLogger } from '../../lib/logger';
let logger = retrieveLogger();

export function sendAccessAction(locInfo, username) {
  return async (dispatch, getState) => {
    let vendingUrl = process.env.REACT_APP_VENDING_URL;
    // Since Spruce Street vend is handled through sending digits on phone, we don't even need to send to server.
    if (locInfo.locationId === 342 || locInfo.dialToneVend) {
      return;
    }
    try {
      const actionResults = await sendAction.sendAccessAction(locInfo, vendingUrl, username);
      dispatch({ type: 'ACTION_SEND', actionResults });
      return;
    } catch (e) {
      logger.error(e);
    }
  };
}

export function updateIssueType(status) {
  return async (dispatch, getState) => {
    if (getState().prompt.vendedGate) {
      return dispatch(promptActions.showSnackBar({ 
        type: 'error', 
        message: `Attempting to change the type of the call after vending a gate is frowned upon!`
      }));
    }
    const currentState = getState();
    const data = {
      username: currentState.auth.username, 
      ongoingCallId: currentState.queue.ongoingCallId,
      tableData: { Type: status }
    }
    serverInteraction.sendServerPOSTRequest('/updateCallsTableFields', data);
    dispatch({ type: 'UPDATE_ISSUE_TYPE', status });
  }
}

export function updateSubtype(v) {
  return async (dispatch, getState) => {
    if (getState().prompt.vendedGate) {
      return dispatch(promptActions.showSnackBar({ 
        type: 'error', 
        message: `Attempting to change the subtype of the call after vending a gate is frowned upon!`
      }));
    }
    const currentState = getState();
    const data = {
      username: currentState.auth.username, 
      ongoingCallId: currentState.queue.ongoingCallId,
      tableData: { Subtype: v }
    }
    serverInteraction.sendServerPOSTRequest('/updateCallsTableFields', data);
    dispatch({ type: 'UPDATE_SUBTYPE', subtype: v });
  }
}

export function updateIssueStep(step) {
  return async (dispatch, getState) => {
    dispatch({ type: 'UPDATE_ISSUE_STEP', stepNum: step });
  }
}

export function sendPaymentIssueType(value) {
  return async (dispatch, getState) => {
    dispatch({ type: 'UPDATE_PAYMENT_ISSUE_TYPE', issueType: value });
  }
}

export function clearPaymentIssueType() {
  return async (dispatch) => {
    dispatch({ type: 'CLEAR_PAYMENT_ISSUE_TYPE' });
  }
}

export function sendValCompanyIndex(index) {
  return async (dispatch, getState) => {
    dispatch({ type: 'SEND_VAL_COMPANY_INDEX', index });
  };
}

export function sendTicketToState(ticketNum) {
  return async (dispatch, getState) => {
    dispatch({ type: 'SEND_TICKET_TO_STATE', ticketNum });
  };
}

export function sendValNumToState(valNum) {
  return async (dispatch, getState) => {
    dispatch({ type: 'SEND_VAL_NUM_TO_STATE', valNum });
  };
}

export function updateMonthlyParkers(locId) {
  return async (dispatch, getState) => {
    dispatch({ type: 'MODULAR_PROMPT_UPDATE', value: true, target: 'showLoader' });
    const monthlyParkers = await monthlyService.updateMonthlies(locId);
    dispatch({ type: 'UPDATE_MONTHLY_PARKERS', monthlyParkers });
    dispatch({ type: 'MODULAR_PROMPT_UPDATE', target: 'rerender', value: true });
    dispatch({ type: 'MODULAR_PROMPT_UPDATE', target: 'rerender', value: false });
    dispatch({ type: 'MODULAR_PROMPT_UPDATE', value: false, target: 'showLoader' });
  };
}

export function updateAirportParkers(airportCode) {
  return async (dispatch) => {
    let data = { 'airportCode': airportCode };
    const results = await serverInteraction.generalServerPost(data, 'updateAirportParkers');
    let airportParkers = results.airportParkers;
    dispatch({ type: 'UPDATE_AIRPORT_PARKERS', value: airportParkers });
  };
}

export function monthlyParkerSearch(value) {
  return async (dispatch, getState) => {
    dispatch({ type: 'MONTHLY_PARKER_SEARCH', value });
  };
}

export function airportParkerSearch(search) {
  return async (dispatch, getState) => {
    dispatch({ type: 'AIRPORT_PARKER_SEARCH', value: search });
  };
}

export function sendParkerToState(value) {
  return async (dispatch, getState) => {
    dispatch({ type: 'UPDATE_SELECTED_PARKER', selectedParker: value });
  };
}

export function retrieveCustomer(locId, customerId) {
  return async (dispatch, getState) => {
    if (getState().prompt.from.parcs !== 'wps') {
      return;
    }
    let customerDetails = await serverInteraction.wpsRetrieveCustomer(locId, customerId);
    let targetCustomerId = customerDetails.CustomerId;
    let filteredParkers = getState().prompt.monthlyParkersListApbFiltered;
    filteredParkers.forEach((parker) => {
      if (parker.CustomerId === targetCustomerId) {
        parker.AidType = customerDetails.AidType;
        parker.LastTrans = customerDetails.LastTrans;
        parker.MType = customerDetails.MType;
        parker.Status = customerDetails.Status;
        parker.AfterHours = customerDetails.AfterHours;
        if (parker.LastTrans === 0 || parker.LastTrans === 'Neutral') {
          dispatch(modularSendField(false, 'apbLockedOut'));
        }
        if (getState().prompt.from.laneType === 'Exit' && (parker.LastTrans === 51 || parker.LastTrans === 'Allowed Out' || parker.LastTrans == null)) {
          dispatch(modularSendField(false, 'apbLockedOut'));
        }
        if (getState().prompt.from.laneType === 'Exit' && (parker.LastTrans === 52 || parker.LastTrans === 'Allowed In')) {
          // CLOUD-816
          if (!getState().prompt.from.allowAntiPassback) {
            dispatch(modularSendField(true, 'apbLockedOut'));
          }
        }
        if (getState().prompt.from.laneType === 'Entrance' && (parker.LastTrans === 51 || parker.LastTrans === 'Allowed Out')) {
          // CLOUD-816
          if (!getState().prompt.from.allowAntiPassback) {
            dispatch(modularSendField(true, 'apbLockedOut'));
          }
        }
        if (getState().prompt.from.laneType === 'Entrance' && (parker.LastTrans === 52 || parker.LastTrans === 'Allowed In' || parker.LastTrans == null)) {
          dispatch(modularSendField(false, 'apbLockedOut'));
        }
        if (getState().prompt.from.antiPassbackAidType && parker.AidType) {
          if (getState().prompt.from.antiPassbackAidType.toLowerCase() === 'rfid' && parker.AidType.toLowerCase() === 'rfid') {
            dispatch(modularSendField(false, 'wrongFacilityLockedOut'));
          }
          if (getState().prompt.from.antiPassbackAidType.toLowerCase() === 'rfid' && parker.AidType.toLowerCase() === 'hid') {
            dispatch(modularSendField(true, 'wrongFacilityLockedOut'));
          }
          if (getState().prompt.from.antiPassbackAidType.toLowerCase() === 'hid' && parker.AidType.toLowerCase() === 'rfid') {
            dispatch(modularSendField(true, 'wrongFacilityLockedOut'));
          }
          if (getState().prompt.from.antiPassbackAidType.toLowerCase() === 'hid' && parker.AidType.toLowerCase() === 'hid') {
            dispatch(modularSendField(false, 'wrongFacilityLockedOut'));
          }
          if (parker.AidType === 'none') {
            dispatch(modularSendField(false, 'wrongFacilityLockedOut'));
          }
        }
      }
    });
    
    dispatch({ type: 'MODULAR_PROMPT_UPDATE', target: 'monthlyParkersListApbFiltered', value: filteredParkers });
    dispatch({ type: 'MODULAR_PROMPT_UPDATE', target: 'rerender', value: true });
    dispatch({ type: 'MODULAR_PROMPT_UPDATE', target: 'rerender', value: false });

    return customerDetails;
  }
}

export function manualReload() {
  return async (dispatch) => {
    dispatch({ type: 'MODULAR_PROMPT_UPDATE', target: 'rerender', value: true });
    dispatch({ type: 'MODULAR_PROMPT_UPDATE', target: 'rerender', value: false });
  }
}

export function sendDCToDB(value) {
  return async (dispatch) => {
    const results = await serverInteraction.sendDCToDB(value);
    dispatch({ type: 'DC_STATUS', status: results.success });
  }
}

export function setVending(bool) {
  return async (dispatch) => {
    dispatch({ type: 'SET_VENDING_STATUS', status: bool });
  }
}

export function showManagers(bool) {
  return async (dispatch) => {
    dispatch({ type: 'UPDATE_SHOW_MANAGERS', value: bool });
  }
}

export function migrateSearchToSelected(searchObject) {
  return async (dispatch) => {
    dispatch({ type: 'MIGRATE_SEARCH_TO_SELECTED', value: searchObject });
  }
}

export function updateEmail(email) {
  return async (dispatch) => {
    dispatch({ type: 'UPDATE_EMAIL', value: email });
  }
}

export function updateContactNumber(contactNumber) {
  return async (dispatch) => {
    dispatch({ type: 'UPDATE_CONTACT_NUMBER', value: contactNumber });
  }
}

export function updateLastFour(lastFour) {
  return async (dispatch) => {
    dispatch({ type: 'UPDATE_LAST_FOUR', value: lastFour });
  }
}

export function updateReceiptRequestSubmitted(record) {
  return async (dispatch) => {
    let results = await serverInteraction.sendReceiptRequest(record);
    if (results.success) {
      dispatch({ type: 'UPDATE_RECEIPT_REQUEST_SUBMITTED', value: true });
    } else {
      dispatch({ type: 'UPDATE_RECEIPT_REQUEST_SUBMITTED', value: false });
    }
  }
}

export function sendMonthlyParkerNameToState(name) {
  return async (dispatch) => {
    dispatch({ type: 'UPDATE_MONTHLY_PARKER_TEXT', value: name });
  }
}

export function sendThirdPartyVendor(index, value) {
  return async (dispatch) => {
    dispatch({ type: 'UPDATE_THIRD_PARTY_VENDOR', index, value });
  }
}

export function sendAttendantName(v) {
  return async (dispatch) => {
    dispatch({ type: 'UPDATE_ATTENDANT_NAME', value: v });
  }
}

export function sendSelectedManager(m) {
  return async (dispatch) => {

    dispatch({ type: 'UPDATE_SELECTED_MANAGER', manager: m })
  }
}

export function sendDateTime(time, tar) {
  return async (dispatch) => {
    dispatch({ type: 'MODULAR_PROMPT_UPDATE', value: time, target: tar });
  }
}

export function sendFeeDue(fee, tar) {
  return async (dispatch) => {
    dispatch({ type: 'MODULAR_PROMPT_UPDATE', value: fee, target: tar });
  }
}

export function modularSendField(val, tar) {
  return async (dispatch) => {
    dispatch({ type: 'MODULAR_PROMPT_UPDATE', value: val, target: tar });
  }
}

export function submitTicketDiscrepancy(tD) {
  return async (dispatch) => {
    let results = await serverInteraction.generalServerPost(tD, 'submitTicketDiscrepancy');
    dispatch({ type: 'SUBMIT_TICKET_DISCREPANCY', success: results.success });
    return results;
  }
}

export function submitReservationDiscrepancy(rD) {
  return async (dispatch) => {
    let results = await serverInteraction.generalServerPost(rD, 'submitReservationDiscrepancy');
    dispatch({ type: 'SUBMIT_RESERVATION_DISCREPANCY', success: results.success });
    return results;
  }
}

export function ticketDiscrepancyComplete(boolean) {
  return async (dispatch) => {
    dispatch({ type: 'SUBMIT_TD_COMPLETE', value: boolean });
  }
}

export function searchText(value) {
  return async (dispatch) => {
    const result = await serverInteraction.getPlacesAutocomplete((value));
    dispatch({ type: 'SUBMIT_LOC_PLACESCOMPLETE', locations: result });
    return result;
  }
}

export function getGeocodeAddress(address) {
  return async (dispatch) => {
    let result = await serverInteraction.getGeocodeAddress(address);
    if (result.success === false) {
      dispatch(modularSendField(result.message, 'ticketDiscrepancyAddressError'));
    } else {
      let formatted_address = result.formatted_address;
      dispatch(modularSendField(result.message, 'ticketDiscrepancyAddressError'));
      dispatch(modularSendField(`${formatted_address.streetNumber} ${formatted_address.streetName}`, 'ticketDiscrepancyAddress'));
      dispatch(modularSendField(formatted_address.city, 'ticketDiscrepancyCity'));
      dispatch(modularSendField(formatted_address.state, 'ticketDiscrepancyState'));
      dispatch(modularSendField(formatted_address.zip, 'ticketDiscrepancyZip'));
    }
  }
}

export function setLocation(city, state) {
  return async (dispatch) => {
    dispatch({ type: 'MODULAR_PROMPT_UPDATE', value: city, target: 'ticketDiscrepancyCity' });
    dispatch({ type: 'MODULAR_PROMPT_UPDATE', value: state, target: 'ticketDiscrepancyState' });
  }
}

export function pushToFirst(value) {
  return async (dispatch) => {
    dispatch({
      type: 'PUSH_TO_FIRST',
      gateId: value.gateId,
      gateName: value.name,
      ticketAcceptanceRedirect: value.ticketAcceptanceRedirect,
      transientLane: value.transientLane,
      monthlyLane: value.monthlyLane,
      specialMonthlyLane: value.specialMonthlyLane,
      laneType: value.laneType,
      prepaidRedirect: value.prepaidRedirect,
      enablePIL: value.enablePIL,
      equipmentMalfunctionPILRoutes: value.equipmentMalfunctionPILRoutes,
      lostTicketPushRate: value.lostTicketPushRate,
      lostTicketNotes: value.lostTicketNotes,
      googleSheetInfo: value.googleSheetInfo,
      customButtons: value.customButtons,
      customManagerNoResponse: value.customManagerNoResponse,
      directToTDPIL: value.directToTDPIL,
      gracePeriodInt: value.gracePeriodInt,
      disablePILFor: value.disablePILFor,
      transientBarcodeNote: value.transientBarcodeNote,
      validationNote: value.validationNote,
      monthlyRedirectPIL: value.monthlyRedirectPIL,
      monthlyRedirect: value.monthlyRedirect,
      valetNotAllowedLot: value.valetNotAllowedLot,
      valetNotAllowedNote: value.valetNotAllowedNote,
      descriptor: value.descriptor,
      monthlyRedirectEntrance: value.monthlyRedirectEntrance,
      transientNotAllowedLot: value.transientNotAllowedLot,
      transientNotAllowedNote: value.transientNotAllowedNote,
      prepaidOffering: value.prepaidOffering,
      prepaidSources: value.prepaidSources,
      prepaidRedirectLanes: value.prepaidRedirectLanes,
      pulledTicketNote: value.pulledTicketNote,
      monthlyShowSubmitSearchText: value.monthlyShowSubmitSearchText,
      genericComponentVend: value.genericComponentVend,
      genericComponentText: value.genericComponentText
    });
  }
}

export function modifyGhostCall(bool) {
  return async(dispatch) => {
    dispatch({ type: 'MODIFY_GHOST_CALL', bool });
  }
}

export function prepareAvailableLanes(propsFrom) {
  let currentLaneType = propsFrom.laneType;
  // Set paystations to exit because for this situation, it is safe to redirect a Paystation customer to an Exit.
  if (currentLaneType === 'Paystation') {
    currentLaneType = 'Exit';
  }

  let availableLanes = [];

  for (let lane in propsFrom.laneList) {
    if (propsFrom.laneList[lane].transientLane && propsFrom.laneList[lane].laneType === currentLaneType) {
      availableLanes.push(propsFrom.laneList[lane].name);
    }
  }
  return availableLanes;
}

export function pullWpsMonthlyNames(locId) {
  return async(dispatch) => {
    let a = await serverInteraction.wpsRetrieveMonthlyNames(locId);
    dispatch({ type: 'UPDATE_MONTHLY_RECORDS', records: a })
  }
}

export function getLocInfoByName(locName, socket) {
  return async (dispatch, getState) => {
    let obj = { locationName: locName };
    let a = await serverInteraction.generalServerPost(obj, 'getLocInfoByName');
    let lI = a.locationInfo;
    let uN = getState().auth.username;
    dispatch({ type: 'OUTSIDE_CALL_INC', locationInfo: lI});
  }
}

export function sendToWorkerMap(username) {
  return async (dispatch) => {
    await serverInteraction.sendToWorkerMap(username);
  }
}

export function confirmManagerVend(ongoingCallId) {
  return async (dispatch) => {
    const result = await serverInteraction.confirmManagerVend(ongoingCallId);
    if(result.body.success) {
      if((result.body.managerVendConfirmation10s).length > 0) {
        dispatch({ type: 'MODULAR_PROMPT_UPDATE', target: 'managerVendConfirmation10s', value: true });
      } else {
        dispatch({ type: 'MODULAR_PROMPT_UPDATE', target: 'managerVendConfirmation10s', value: false });
      }
    }
  }
}

export function toggleManagerCallHold(conferenceSid, managerCallSid, toggle) {
  return async (dispatch) => {
    try {
      const result = await serverInteraction.toggleManagerCallHold(conferenceSid, managerCallSid, toggle);
    } catch (error) {
      logger.error(`Putting Manager Call on hold failed on client side.`);
      logger.error(error);
    }
  }
}

export function showSnackBar(snackbarData) {
  return async (dispatch) => {
    dispatch({ type: 'SHOW_SNACKBAR', snackbarData });
  }
}

// Tiba API functions Begin
export function tibaGetTicketInfo(data) {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/tiba/getTicketInfo', data);
  }
}

export function tibaGetRateByEntranceData(data) {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/tiba/getRateByEntranceData', data);
  }
}

export function tibaVerifyIfTicketClosed(data) {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/tiba/verifyIfTicketClosed', data);
  }
}

export function tibaVerifyIfTicketOpen(data) {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/tiba/verifyIfTicketOpen', data);
  }
}

export function tibaVerifyTicketByLPR(data) {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/tiba/verifyTicketByLPR', data);
  }
}

export function tibaSendControlDeviceAction(data) {
  return async (dispatch) => {
    if (data.actionId == 1) {
      dispatch({ type: 'ACTION_SEND' });
    }
    return await serverInteraction.sendServerPOSTRequest('/tiba/controlDevice', data);
  }
}

export function tibaValidationRateCalculate(data) {
  return async (dispatch, getState) => {
    return await serverInteraction.sendServerPOSTRequest('/tiba/validationRateCalculate', data);
  }
}

export function tibaGetLicensePlate(data) {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/tiba/getLicensePlate', data);
  }
}

// Tiba API Function End

// For Four Oaks leaving console logs for testing purpose.
export function fourOakMonthlyAccess() {
  return async (dispatch, getState) => {
    const { timezone, monthlyRedirect } = await getState().prompt.from;
    const now = moment().tz(timezone);
    const nowDay = now.day();
    const nowHour = now.hour();
    if (nowDay == 0 || nowDay == 6) {
      // If weekend allowed all lanes anytime.
      return true;
    } else if ([18, 19, 20, 21, 22, 23, 24, 5, 4, 3, 2, 1].includes(nowHour)) {
      // If weekday, allowed from 6PM to 5AM all lanes.
      return true;
    } else {
      // If weekday, 5AM to 6PM allowed in monthly lane but not in transient lane.
      if (monthlyRedirect) {
        return false;
      } else {
        return true;
      }
    }
  }
}

// Flash API Function Begin
export function flashMonthlyAccountSearch(data) {
  return async (dispatch, getState) => {
    const { apiParams, locationId } = await getState().prompt.from;
    const { flashSupportCallID } = await getState().prompt;
    data.apiParams = apiParams;
    data.locationId = locationId;
    data.flashSupportCallID = flashSupportCallID;
    logger.info('Retrieved supportCallID from server.', { relatedCard: 'CLOUD-1770', supportCallID: flashSupportCallID, apiParams, locationId });
    return await serverInteraction.sendServerPOSTRequest('/flash/monthlyAccountSearch', data);
  }
}

export function flashGetMonthlyAccountDetails(data) {
  return async (dispatch, getState) => {
    const { apiParams, locationId } = await getState().prompt.from;
    const { flashSupportCallID } = await getState().prompt;
    data.apiParams = apiParams;
    data.locationId = locationId;
    data.flashSupportCallID = flashSupportCallID;
    logger.info('Start of flashGetMonthlyAccountDetails.', { relatedCard: 'CLOUD-1770', supportCallID: flashSupportCallID, apiParams, locationId });
    return await serverInteraction.sendServerPOSTRequest('/flash/getMonthlyAccountDetails', data);
  }
}

export function flashResetMonthlyAccountToNeutral(data) {
  return async (dispatch, getState) => {
    const { apiParams, locationId } = await getState().prompt.from;
    const { flashSupportCallID } = await getState().prompt;
    data.apiParams = apiParams;
    data.locationId = locationId;
    data.flashSupportCallID = flashSupportCallID;
    logger.info('Start of flashResetMonthlyAccountToNeutral.', { relatedCard: 'CLOUD-1770', supportCallID: flashSupportCallID, apiParams, locationId });
    return await serverInteraction.sendServerPOSTRequest('/flash/resetMonthlyAccountToNeutral', data);
  }
}

export function flashTicketSearch(data) {
  return async (dispatch, getState) => {
    const { apiParams, locationId } = await getState().prompt.from;
    const { flashSupportCallID } = await getState().prompt;
    data.apiParams = apiParams;
    data.locationId = locationId;
    data.flashSupportCallID = flashSupportCallID;
    logger.info('Start of flashTicketSearch.', { relatedCard: 'CLOUD-1770', supportCallID: flashSupportCallID, apiParams, locationId });
    return await serverInteraction.sendServerPOSTRequest('/flash/ticketSearch', data);
  }
}

export function flashTicketSearchCC(data) {
  return async (dispatch, getState) => {
    const { apiParams, locationId } = await getState().prompt.from;
    const { flashSupportCallID } = await getState().prompt;
    data.apiParams = apiParams;
    data.locationId = locationId;
    data.flashSupportCallID = flashSupportCallID;
    logger.info('Start of flashTicketSearchCC.', { relatedCard: 'CLOUD-1770', supportCallID: flashSupportCallID, apiParams, locationId });
    return await serverInteraction.sendServerPOSTRequest('/flash/ticketSearchCC', data);
  }
}

export function flashSaveTicketDetails(data) {
  return async (dispatch, getState) => {
    const { ticketNumber, entryDateTime, priceID, flashParcsTicketID, paidAt } = data;
    const { gracePeriodInt, timezone, locationId } = await getState().prompt.from;
    logger.info('Start of flashSaveTicketDetails.', { gracePeriodInt: gracePeriodInt, locationId });
    const formattedEntryDate = moment(entryDateTime).format("YYYY-MM-DD HH:mm:ss");
    let formattedEntryDateTZ = moment.tz(formattedEntryDate, 'Europe/London').tz(timezone);
    formattedEntryDateTZ = (formattedEntryDateTZ).format("YYYY-MM-DD HH:mm:ss");
    const now = moment().tz(timezone).startOf('minutes');
    const formattedNow = moment(now).format('YYYY-MM-DD HH:mm:ss');
    const parkerExitDateTime = moment(formattedNow, 'YYYY-MM-DD HH:mm:ss');
    const minuteDiff = moment.duration(parkerExitDateTime.diff(formattedEntryDateTZ)).asMinutes();
    dispatch(promptActions.modularSendField(ticketNumber, 'ticketNumber'));
    return {
      shouldVend: (minuteDiff <= gracePeriodInt) ? true : false,
      ticketNumber, 
      parkerEntryDateResult: formattedEntryDateTZ, 
      flashParcsTicketID: flashParcsTicketID,
      flashPriceID: priceID,
      paidAt
    }
  }
}

export function flashValidateTicket(data) {
  return async (dispatch, getState) => {
    dispatch(promptActions.modularSendField(true, 'showLoader'));
    const { apiParams, locationId } = await getState().prompt.from;
    const { flashSupportCallID } = await getState().prompt;
    data.apiParams = apiParams;
    data.locationId = locationId;
    data.flashSupportCallID = flashSupportCallID;
    logger.info('Start of flashValidateTicket.', { relatedCard: 'CLOUD-1770', supportCallID: flashSupportCallID, apiParams, locationId });
    const result =  await serverInteraction.sendServerPOSTRequest('/flash/validateTicket', data);
    const { validateTicketResult } = result.data;
    if (validateTicketResult.Code[0] === "Success") {
      dispatch(promptActions.modularSendField(false, 'showLoader'));
      return dispatch(promptActions.showSnackBar({
        type: 'success',
        message: 'Validation was successfully applied to the ticket. If parker denies payment, contact managers.'
      }))
    } else {
      dispatch(promptActions.modularSendField(false, 'showLoader'));
      return dispatch(promptActions.showSnackBar({
        type: 'error',
        message: 'Could not apply validation, please contact managers.'
      }))
    }
  }
}

export function flashCloseCall() {
  return async (dispatch, getState) => {
    const { flashSupportCallID } = getState().prompt;
    const { apiParams, locationId } = getState().prompt.from;
    const data = { flashSupportCallID, apiParams, locationId };
    logger.info('Retrieved supportCallID from server.', { relatedCard: 'CLOUD-1770', supportCallID: flashSupportCallID, apiParams, locationId });
    await serverInteraction.sendServerPOSTRequest('/flash/closeCall', data);
  }
}

export function flashOpenGate() {
  return async (dispatch, getState) => {
    const { flashSupportCallID } = getState().prompt;
    const { apiParams, locationId } = getState().prompt.from;
    const data = { flashSupportCallID, apiParams, locationId };
    logger.info('Retrieved supportCallID from server.', { relatedCard: 'CLOUD-1770', supportCallID: flashSupportCallID, apiParams, locationId });
    await serverInteraction.sendServerPOSTRequest('/flash/openGate', data);
  }
}

export function flashVerifyValidation(ticketNumber, validationMinutes) {
  return async (dispatch, getState) => {
    ticketNumber = parseInt(ticketNumber.trim())
    const { timezone, flashSupportCallID } = getState().prompt;
    const { apiParams, locationId } = getState().prompt.from;
    const data = { ticketNumber, flashSupportCallID, apiParams, locationId }
    logger.info('Retrieved supportCallID from server.', { relatedCard: 'CLOUD-1770', supportCallID: flashSupportCallID, apiParams, locationId });
    const result = await serverInteraction.sendServerPOSTRequest('/flash/ticketSearch', data);
    const { success, found, ticketSearchList } = result.data;
    if (ticketSearchList.length > 0 ) {
      const ticketData = ticketSearchList.filter(ticket => parseInt(ticket.ticketNumber) === parseInt(ticketNumber))
      if (success && found && ticketData.length === 1) {
        const entryDateTime = ticketData[0].entryDateTime;
        const formattedEntryDate = moment(entryDateTime, 'YYYY-MM-DDTHH:mm:ss').format('dddd, MMMM Do YYYY');
        const now = moment().tz(timezone);
        const formattedNow = moment(now).format('YYYY:MM:DD HH:mm:ss');
        const parkerExitDateTime = moment(formattedNow, 'YYYY:MM:DD HH:mm:ss');
        const minuteDiff = moment.duration(parkerExitDateTime.diff(entryDateTime)).asMinutes();
        if (parseInt(minuteDiff) > parseInt(validationMinutes)) {
          return {
            notFound: false, valid: false, validationMinutes, formattedEntryDate, minuteDiff
          }
        } else {
          return {
            notFound: false, valid: true
          }
        }
      } else {
        return { notFound: true }
      }
    } else {
      return { notFound: true }
    }
  }
}

// Flash API Function End

export function shouldShowForm(contactManagerInfoForm, issue) {
  return async (dispatch) => {
    const types = contactManagerInfoForm.map(prompt => { return prompt.type });
    if (types.includes("monthly") && issue.type == 'monthly') {
      const formData = await contactManagerInfoForm.find(data => { return data.type == "monthly"})
      return formData;
    } else if (types.includes("ticketAcceptance") && issue.type == 'transient' && issue.subtype == 'ticketAcceptanceIssue') {
      const formData = await contactManagerInfoForm.find(data => { return data.type == "ticketAcceptance"})
      return formData;
    } else if (types.includes("validation") && issue.type == 'transient' && issue.subtype == 'validationIssue') {
      const formData = await contactManagerInfoForm.find(data => { return data.type == "validation"})
      return formData;
    } else if (types.includes("equipmentMalfunction") && issue.type == 'equipmentMalfunction') {
      const formData = await contactManagerInfoForm.find(data => { return data.type == "equipmentMalfunction"})
      return formData;
    } else if (types.includes("lostTicket") && issue.type == 'transient' && issue.subtype == 'lostTicket') {
      const formData = await contactManagerInfoForm.find(data => { return data.type == "lostTicket"})
      return formData;
    } else {
      return false;
    }
  }
}

// Google Sheet Functions Begin
export function getValidationSourcesFromSheets(data) {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/getValidationSourcesFromSheets', data);
  }
}

export function getMonthlyListFromSheets(data) {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/getMonthlyListFromSheets', data);
  }
}

export function getPrepaidDataFromSheets(data) {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/getPrepaidDataFromSheets', data);
  }
}
// Google Sheet Functions End

// PIL Function Begin

export function shouldEnablePIL(issueType) {
  return async (dispatch, getState) => {
    const promptState = getState().prompt;
    const queueState = getState().queue;
    if (promptState.from.enablePIL && promptState.from.enablePIL.includes(issueType)) {
      if (promptState.from.disablePILFor && promptState.from.disablePILFor.includes(queueState.isIntercomCall)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}

export function pilGetPaymentCode(data) {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/pil/pilGetPaymentCode', data);
  }
}

export function pilCheckTransactionStatus(data) {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/pil/pilCheckTransactionStatus', data);
  }
}

export function manualPilCheckTransactionStatus(data) {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/pil/manualCheckTransactionStatus', data);
  }
}

export function pilSaveTransactionData(data) {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/pil/pilSaveTransactionData', data);
  }
}

export function pilRestorePaymentCode(data) {
  return async (dispatch) => {
    return await serverInteraction.sendServerPOSTRequest('/pil/pilRestorePaymentCode', data);
  }
}

export function pilResetEntranceTime(data) {
  return async (dispatch) => {
    if (data.pilPaymentCode && data.pilPaymentAmount) {
      await serverInteraction.sendServerPOSTRequest('/pil/pilResetEntranceTime', data);
    }
    dispatch(promptActions.modularSendField(null, 'pilPaymentCode'));
    dispatch(promptActions.modularSendField(null, 'pilPaymentAmount'));
    dispatch(promptActions.modularSendField(null, 'pilTransaction'));
    dispatch(promptActions.modularSendField(false, 'pilCheckTransactionStatus'));
    dispatch(promptActions.modularSendField(null, 'parkerEntranceDate'));
    dispatch(promptActions.modularSendField(null, 'parkerEntranceTime'));
    return true;
  }
}

// PIL Functions End

export function shouldRedirectToIntercom(issueType) {
  return async (dispatch, getState) => {
    const currentState = getState();
    const { redirectToIntercomPrompts } = currentState.prompt.from;
    let { username } = currentState.auth;
    let { isIntercomCall, ongoingCallId } = currentState.queue;
    if (!isIntercomCall) {
      const isIntercomCallResult = await serverInteraction.sendServerPOSTRequest('/call/getIsInteromCall', { username, ongoingCallId });
      isIntercomCall = isIntercomCallResult.data.isIntercomCall;
    }
    if (redirectToIntercomPrompts && redirectToIntercomPrompts[issueType] && isIntercomCall == 3) {
      return true;
    } else {
      return false;
    }
  }
}
// New action handlers for new flows should go here:
export const resetPrepaidDetails = () => {
  return async (dispatch) => {
    dispatch({ type: 'RESET_PREPAID_DETAILS' });
  }
}

export function createLightningPayQuestion({data}) {
  return async (dispatch) => {
    const result = await serverInteraction.sendServerPOSTRequest('/createLightningPayQuestion', data);
    if (result.data) {
      return;
    } else {
      const snackbarData = { type: 'error', message: 'Failed to update lightning pay questions.'}
      dispatch({ type: 'SHOW_SNACKBAR', snackbarData});
    }
  }
}

export function gateKitVend({ gateKitId, locationId, locationName }) {
  return async (dispatch) => {
    try {
      const results = await serverInteraction.sendServerPOSTRequest('/gateKit', { gateKitId, locationId, locationName });
      if (results.message === 'Request failed with status code 503') {
        // We should return and then try to do the other programmed gate vending method here instead of just saying "good luck".
        const snackbarData = { type: 'error', message: 'Failed to open gate with GateKit. Please try again to attempt secondary vend method.' };
        return dispatch({ type: 'SHOW_SNACKBAR', snackbarData });
      }
      const snackbarData = { type: 'info', message: 'Gate vended successfully.' };
      dispatch({ type: 'SHOW_SNACKBAR', snackbarData });
      dispatch(promptActions.setVending(false));
      dispatch(modularSendField(true, 'vendedGate'));
      return;
    } catch (e) {
      logger.error(e);
    }
  }
}