import React from 'react';
import { connect } from 'react-redux';
import ScriptText from '../../../../components/ScriptText';
import { pullModular as promptPullModular } from '../../../../store/prompt/reducer';

const NoManagerResponsePullTicket = (props) => {

  return (
    <div>
        {props.from.ticketlessLocation ?
        <ScriptText>I just called the manager and they didn't respond. If you still want to park here, please use your credit/debit card but you could be charged full price for parking as you exit the facility.</ScriptText>
        :
        <ScriptText>I just called the manager and they didn't respond. If you still want to park here, please pull a ticket but you could be charged full price for parking as you exit the facility.</ScriptText>}
    </div>
  )

}

function mapStateToProps(state) {
  return {
    from: promptPullModular(state, 'from')
  }
}

export default (connect(mapStateToProps)(NoManagerResponsePullTicket));
