import React from "react";

const FreshDirectionText = ({ children }) => {
  return (
    <p className='fresh-direction-text'>
      <span
      style={{
        fontStyle: 'normal',
        fontWeight: 'bold',
        marginRight: '0.5em'
      }}
    >
      Action:{' '}
    </span>
      {children}
    </p>
  )
}

export default FreshDirectionText;