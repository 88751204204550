import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptSelectors from '../../store/prompt/reducer';
import * as authSelectors from '../../store/auth/reducer';
import DirectionText from '../../components/DirectionText';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';

class AttendanceCallIn extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
            <DirectionText>
              Please refer to Attendance Call-In Location List on Google Drive for specific information related to how to handle Attendance Call-Ins.
            </DirectionText>
            <DirectionText>
              If attendant is calling more than 5 minutes after their expected call time, <b>you must call the location manager and alert them.</b>
            </DirectionText>
            <DirectionText>
              If you have to call location manager, <b>remember to log that outgoing call in QuickBase.</b>
            </DirectionText>
            <Link to="/confirmationScreen">
              <RaisedButton label="Complete Call" />
            </Link>
          </Paper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    friendlyName: authSelectors.pullModular(state, 'friendlyName')
  };
}

export default withRouter(connect(mapStateToProps)(AttendanceCallIn));
