import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptActions from '../../store/prompt/actions';
import * as promptSelectors from '../../store/prompt/reducer';
import * as authSelectors from '../../store/auth/reducer';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import DirectionText from '../../components/DirectionText';
import InputMask from 'react-input-mask';

class EmployeeIssue extends Component {
  constructor(props) {
    super(props);
    this.reloadInterval = null;
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    /* reset global step prop */
    this.props.dispatch(promptActions.updateIssueStep(0));
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
    if (this.props.selectedParkerName && this.props.contactNumber && this.props.companyName && this.props.reportingTo && this.props.visitPurpose) {
      if (this.props.contactNumber.match(/\(\d\d\d\) \d\d\d \d\d\d\d/)) {
        this.props.dispatch(promptActions.modularSendField(true, 'employeeIssueComplete'))
      }
    }
  }

  componentWillUnmount() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  updateField(event, value, target) {
    this.props.dispatch(promptActions.modularSendField(value, target));
  }

  notEmpty = (propsArr) => {
    for (let i = propsArr.length - 1; i >= 0; --i) {
      if (this.props[propsArr[i]] === null) {
        return false;
      }
      if (this.props[propsArr[i]].trim() === "") {
        return false;
      }
    }
    return true;
  }

  render() {
    let phoneHintText = 'Phone Number';
    if (this.props.contactNumber && this.props.contactNumber.length > 0) {
      phoneHintText = '';
    }
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <div className="home-div-body">
            <Paper zDepth={1} className="paper-padding">
              <DirectionText>
                Please ask the customer for the following pieces of information before allowing access.
              </DirectionText>
              <DirectionText>
                Fields denoted with an asterisk (*) are required, but some parkers may not have all of the information listed below.
              </DirectionText>
              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Employee Name"
                floatingLabelText="Employee Name"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'selectedParkerName');
                }}
              />
              <span>*</span>

              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Company Name"
                floatingLabelText="Company Name"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'companyName');
                }}
              />
              <span>*</span>

              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Who Does Employee Report To?"
                floatingLabelText="Supervisor"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'reportingTo');
                }}
              />
              <span>*</span>

              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                style={{ width: '98%' }}
                hintText={phoneHintText}
                floatingLabelText="Phone Number"
                floatingLabelFixed={true}
                ref="phoneNumber"
                name="phoneNumber"
                type="text"
                value={this.props.contactNumber}
                onChange={(e, v) => {
                  this.updateField(e, v, 'contactNumber');
                }}>
                <InputMask mask="(999) 999 9999" maskChar=" " />
              </TextField>
              <span>*</span>

              <TextField
                inputProps={{ className: 'data-hj-allow' }}
                hintText="Visit Purpose"
                floatingLabelText="Visit Purpose"
                floatingLabelFixed={true}
                style={{ width: '98%' }}
                onChange={(e, v) => {
                  this.updateField(e, v, 'visitPurpose');
                }}
              />
            </Paper>
            <Paper zDepth={1} className="paper-padding">
              {
                this.props.contactNumber.trim().length > 13 &&
                  this.notEmpty(["selectedParkerName", "companyName", "reportingTo"])
                  ?
                  <Link to="/vendGate">
                    <RaisedButton label="Submit Employee Info" />
                  </Link> :
                  <DirectionText>Please complete the form above to continue.</DirectionText>
              }

            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    selectedParkerName: promptSelectors.pullModular(state, 'selectedParkerName'),
    contactNumber: promptSelectors.pullModular(state, 'contactNumber'),
    companyName: promptSelectors.pullModular(state, 'companyName'),
    reportingTo: promptSelectors.pullModular(state, 'reportingTo'),
    visitPurpose: promptSelectors.pullModular(state, 'visitPurpose'),
    employeeIssueComplete: promptSelectors.pullModular(state, 'employeeIssueComplete')
  };
}

export default withRouter(connect(mapStateToProps)(EmployeeIssue));
