import Button from '@mui/material/Button'
import React, { useState, Fragment } from "react";
import PullATicket from "./PrepaidNodes/PullATicket";
import { connect } from 'react-redux';
import { pullModular as promptPullModular } from '../../../store/prompt/reducer';
import { pullModular as queuePullModular } from '../../../store/queue/reducer';
import { withRouter } from "react-router-dom";
import LogPrepaidDetails from "./PrepaidNodes/LogPrepaidDetails";
import PromptWrapper from "./PromptWrapper";
import PrepaidInstructions from "./PrepaidNodes/PrepaidInstructions";
import FreshContactManagers from './PrepaidNodes/FreshContactManagers';
import { disableContinue } from './lib/contactManagerFunctions';
import { logPrepaidDetailsSubmit } from './lib/logPrepaidDetailsFunctions';


const PrepaidEntrance1 = (props) => {
  
  const { 
    thirdPartyVendorId,  
    thirdPartyReservationNumber, 
    thirdPartyVendorName,
    selectedParkerName, 
    dispatch, 
    isParkerCallOnHold,
    history,
    managerCalledFor10s,
    setManagerCalledFor10s,
    managerCalled,
    setManagerCalled
  } = props;
  
  const [node, setNode] = useState('0');


  return (
    <PromptWrapper>
      {node == '0' &&
        <Fragment>
          <PrepaidInstructions />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {setNode('1')}}>Next Steps</Button>
        </Fragment>
      }
      {node == '1' &&
        <Fragment>
          <PullATicket />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {setNode('2')}}>Next Steps</Button>
        </Fragment>
      }
      {node == '2' &&
        <Fragment>
          <LogPrepaidDetails />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {
            logPrepaidDetailsSubmit({
              dispatch,
              thirdPartyVendorId,
              thirdPartyReservationNumber,
              selectedParkerName,
              action: () => {setNode('3')}
            })}}>
            Submit
          </Button>
        </Fragment>
      }
      {node == '3' &&
        <Fragment>
          <p>
            <span className='contact-managers-header'>{' '}Reservation Source:{' '}</span>{thirdPartyVendorName},
            <span className='contact-managers-header'>{' '}Reservation Number:{' '}</span>{thirdPartyReservationNumber},
            <span className='contact-managers-header'>{' '}Parker Name:{' '}</span>{selectedParkerName}
          </p>
          <FreshContactManagers 
            managerCalledFor10s={managerCalledFor10s} 
            setManagerCalledFor10s={setManagerCalledFor10s}
            managerCalled={managerCalled}
            setManagerCalled={setManagerCalled}
          />
          <Button className='button-style' variant='outlined' onClick={() => {history.push('/confirmationScreen')}}>Complete Call</Button>
          <Button className='button-style' variant='outlined' onClick={() => {
            disableContinue({
              dispatch,
              isParkerCallOnHold,
              action: () => { history.push('/confirmationScreen') },
              managerCalledFor10s,
              managerCalled
            })
          }}>
            Manager Assisting
          </Button>
          <Button className='button-style' variant='outlined' onClick={() => {
            disableContinue({
              dispatch,
              isParkerCallOnHold,
              action: () => { history.push('/vendGate') },
              managerCalledFor10s,
              managerCalled
            })
          }}>
            Manager Requested Gate Vend
          </Button>
          <Button className='button-style' variant='outlined' onClick={() => {
            disableContinue({
              dispatch,
              isParkerCallOnHold,
              action: () => { history.push('/vendGate') },
              managerCalledFor10s,
              managerCalled
            })
          }}>
            Manager Did Not Answer
          </Button>
        </Fragment>
      }
    </PromptWrapper>
  )
}

function mapStateToProps(state) {
  //just use PullModular errywhrr
  return {
    from: promptPullModular(state, 'from'),
    thirdPartyVendorId: promptPullModular(state, 'thirdPartyVendorId'),
    thirdPartyReservationNumber: promptPullModular(state, "thirdPartyReservationNumber"),
    thirdPartyVendorName: promptPullModular(state, 'thirdPartyVendorName'),
    selectedParkerName: promptPullModular(state, 'selectedParkerName'),
    isParkerCallOnHold: queuePullModular(state, 'isParkerCallOnHold')
  }
}
export default withRouter(connect(mapStateToProps)(PrepaidEntrance1));