import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter, Link } from 'react-router-dom';
import * as config from '../../lib/config';
import * as promptSelectors from '../../store/prompt/reducer';
import * as authSelectors from '../../store/auth/reducer';
import DirectionText from '../../components/DirectionText';
import Paper from 'material-ui/Paper';
import ContactManagers from './ContactManagers';

class Shuttle extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <div className="home-div">
        <div className={`home-div-content`}>
          <Paper zDepth={1} className="home-div-header-short">
            <h2>{this.props.from.locationName}</h2>
          </Paper>
          <Paper zDepth={1} className="paper-padding prompt-body" style={{ marginTop: '20px' }}>
            <h3>
              General information
            </h3>
            <DirectionText>
              This number is generally called when a member of the shuttles crew is waiting to be picked up and cannot locate the shuttle. Please follow these steps:
            </DirectionText>
            <h3>
              Steps For Using Shuttle Phone
            </h3>
            <ol>
              <li>Pick up the black flip phone that is designated for contact with shuttle drivers.</li>
              <li>Press the red button on the side of flip phone.</li>
              <li>Select "Conference", then press and hold the red button and speak into the phone, walkie-talkie style.</li>
              <li>Remember to release the red button when not actively speaking.</li>
              <li>Press and hold red button each time you want to speak.</li>
            </ol>
            <h3>
              What To Tell The Drivers
            </h3>
            <DirectionText>
              When making contact with the drivers, you will ask where the driver is, as well as tell him that he has crew members waiting for pickup. Please relay any information back to the crew.
            </DirectionText>
            <h3>
              No Drivers Available?
            </h3>
            <DirectionText>
              If no driver is available, please let crew know they will need to find alternate transportation.
            </DirectionText>
            <DirectionText>
              If you have any further questions or concerns, please contact management.
            </DirectionText>
            <ContactManagers showButtons={[]} />
          </Paper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    username: authSelectors.pullModular(state, 'username'),
    friendlyName: authSelectors.pullModular(state, 'friendlyName')
  };
}

export default withRouter(connect(mapStateToProps)(Shuttle));
