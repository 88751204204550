import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import { TextField } from '@material-ui/core';
import * as config from '../../../lib/config';
import * as promptSelectors from '../../../store/prompt/reducer';
import * as queueSelectors from '../../../store/queue/reducer';
import * as promptActions from '../../../store/prompt/actions';
import * as authSelectors from '../../../store/auth/reducer';
import ContactManagers from '../../Prompt/ContactManagers';
import ScriptText from '../../../components/ScriptText';
import DirectionText from '../../../components/DirectionText';
import PromptBox from '../../../components/promptBox';
import RaisedButton from 'material-ui/RaisedButton';
import _ from 'lodash';

class TibaManagerPushRate extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (!this.props.username && !config.TestComponent) {
      this.props.history.push('/login');
    }
  }

  updateField(event, target) {
    this.props.dispatch(promptActions.modularSendField(event.target.value, target));
  }

  updateRate(event) {
    const tibaRateResult = { rate: event.target.value };
    this.props.dispatch(promptActions.modularSendField(tibaRateResult, 'tibaRateResult')); 
  }

  async pushRateToMachine() {
    const { apiParams, controlDeviceData, lostTicketPushRate, baseURL } = this.props.from;
    const { ongoingCallId, username, selectedManagerName, tibaRateResult } = this.props;
    if (!selectedManagerName) {
      this.props.dispatch(promptActions.showSnackBar({ 
        type: 'error', message: 'Manager Name is required.'
      }));
    } else if (tibaRateResult && !tibaRateResult.rate) {
      this.props.dispatch(promptActions.showSnackBar({ 
        type: 'error', message: 'Rate is required.'
      }));
    } else {
      const rateToPush = tibaRateResult.rate || lostTicketPushRate;
      const data = { username, ongoingCallId, apiParams, baseURL, commandType: controlDeviceData.pushRateNewTicket, 
        actionId: controlDeviceData.pushRateDBId, controlDeviceData, rateToPush, managerName: selectedManagerName || null 
      };
      const result = await this.props.dispatch(promptActions.tibaSendControlDeviceAction(data));
      const { success, message } = result.data;
      if (success) {
        this.props.dispatch(promptActions.showSnackBar({ 
          type: 'success', message: `Rate $${rateToPush} pushed to device, if parker denies payment contact managers.` 
        }));
      } else if (!success) {
        this.props.dispatch(promptActions.showSnackBar({ 
          type: 'error', message: message
        }));
        if (this.props.from.enablePIL.includes(this.props.issue.subtype)) {
          this.props.history.push('/pil/PILEntryInput');
        }
      }
    }
  }

  render() {
    return (
      <PromptBox locationName={this.props.from.locationName}>
        {this.props.pushRateQuestion 
          ?
            <Fragment>
              <ScriptText>
                Please insert the ticket in the machine with the barcode facing up.
              </ScriptText>
              <div style={{ marginTop: 15 }}>
                <RaisedButton label="Next Step" onClick={() => this.props.dispatch(promptActions.modularSendField(false, 'pushRateQuestion'))} />
              </div>
            </Fragment>
          : 
            <Fragment>
              <DirectionText>
                {this.props.tibaRateResult && this.props.tibaRateResult.rate && 'The rate in the Text box below is what the parker owes. '}
                If a manager approved a different rate please change the amount and push the rate.
              </DirectionText>
              <TextField
                label="Manager Name"
                margin="dense"
                variant="outlined"
                inputProps={{ className: 'data-hj-allow' }}
                InputLabelProps={{
                  shrink: true,
                }} 
                onChange={(event) => {this.updateField(event, 'selectedManagerName')}}
                style={{ width: '100%', marginTop: 15 }}
              />
              <TextField
                label="New Rate"
                margin="dense"
                variant="outlined"
                value={this.props.tibaRateResult.rate}
                inputProps={{ className: 'data-hj-allow' }}
                InputLabelProps={{
                  shrink: true,
                }} 
                onChange={(event) => {this.updateRate(event)}}
                style={{ width: '100%', marginTop: 15 }}
              />
              <DirectionText>
                <span style={{ fontWeight: 'bolder', color: 'Red' }}> Note: </span> 
                Please make sure that you ask the parker if ticket is in the machine.  
              </DirectionText>
              <div style={{ marginTop: 15 }}>
                <RaisedButton label="Push Rate To Machine" onClick={() => this.pushRateToMachine()} />
                <RaisedButton label="Complete Call" onClick={() => this.props.history.push('/confirmationScreen')} />
              </div>
            </Fragment>
          }
      </PromptBox>
    )
  }
}

function mapStateToProps(state) {
  return {
    from: promptSelectors.pullLocationId(state),
    issue: promptSelectors.pullIssue(state),
    username: authSelectors.pullModular(state, 'username'),
    ticketNumber: promptSelectors.pullModular(state, 'ticketNumber'),
    pushRateQuestion: promptSelectors.pullModular(state, 'pushRateQuestion'),
    tibaRateResult: promptSelectors.pullModular(state, 'tibaRateResult'),
    selectedManagerName: promptSelectors.pullModular(state, 'selectedManagerName'),
    ongoingCallId: queueSelectors.pullModular(state, 'ongoingCallId'),
  };
}

export default withRouter(connect(mapStateToProps)(TibaManagerPushRate));